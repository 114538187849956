/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { env } from "../../env";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        {/* <Nav>
          <NavItem>
            <NavLink href="https://digitalcustodian.co/" target="_blank">
              MACS Front
            </NavLink>
          </NavItem>
        </Nav> */}
        <div className="copyright">
          {/* {process.env.REACT_APP_APP_NAME} - {process.env.REACT_APP_APP_VERSION} */}
          {env.REACT_APP_TITLE} - {env.REACT_APP_VERSION}
        </div>
        {/* <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          <a
            href="https://digitalcustodian.co/"
            target="_blank"
          >
            Digital Custodian Team
          </a>{" "}
        </div> */}
      </Container>
    </footer>
  );
}

export default Footer;
