import Config from "../../config";
import axios from "axios";

export const getDailyBankBalance = async (fund_code, date) => {
  const email = sessionStorage.getItem("email");
  const token = sessionStorage.getItem("token");
  const amc_code = sessionStorage.getItem("amc_code");

  // if (!date) {
  //   let today = new Date();
  //   let dd = String(today.getDate()).padStart(2, "0");
  //   let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //   let yyyy = today.getFullYear();
  //   let date = yyyy + "-" + mm + "-" + dd;
  // }

  // const url = `${Config.baseUrl}/transaction/get-daily-balance-by-fund?${email}&amc_code=${amc_code}&date=2022-04-08&fund_code=${fund_code}`;

  // const url = `${Config.baseUrl}/transaction/get-daily-balance-by-fund?${email}&amc_code=${amc_code}&date=${date}&fund_code=${fund_code}`;

  const url = `${Config.baseUrl}/transaction/get-daily-balance-by-fund?${email}&date=${date}&fund_code=${fund_code}`;

  try {
    const result: any = await axios.get(url, {
      headers: { Authorization: token },
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};
