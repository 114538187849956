declare global {
    interface Window {
      env: any
    }
  }
  
  // change with your own variables
  type EnvType = {
    // for PTCL
    REACT_APP_TITLE: "Back-Office Dev",
    REACT_APP_VERSION: "v0.1",
    REACT_APP_BASE_URL: "http://182.191.93.242:3000/api",
    REACT_APP_SOCKET_URL: "http://182.191.93.242:3000/",
    REACT_APP_IMAGE_URL: "http://182.191.93.242:3000/",
    CAPTCHA_ENABLED: "true", // true or false

    // for NayaTel
    // REACT_APP_TITLE: "MACS Front-Office Alpha UAT QA",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "http://124.109.39.158:3000/api",
    // REACT_APP_SOCKET_URL: "http://124.109.39.158:3000/",
    // REACT_APP_IMAGE_URL: "http://124.109.39.158:3000/",
    // CAPTCHA_ENABLED: "true", // true or false

    // for Production
    // REACT_APP_TITLE: "MACS Front-Office Beta",
    // REACT_APP_VERSION: "v2.2.3",
    // REACT_APP_BASE_URL: "https://beta.macs-core.com:3000/api",
    // REACT_APP_SOCKET_URL: "https://beta.macs-core.com:3000/",
    // REACT_APP_IMAGE_URL: "https://beta.macs-core.com:3000/",
    // CAPTCHA_ENABLED: "true", // true or false
  }
  export const env: EnvType = { ...process.env, ...window.env }