import React from 'react';
import { useState } from 'react';	
import { EditFund, getFundType } from '../../../stores/services/funds.service';	
import { Container, Row, Col,FormGroup,Label,Input, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'; 
import { ToastContainer, toast } from 'react-toastify';	
import 'react-toastify/dist/ReactToastify.css';	
import { Link } from 'react-router-dom';	
import { useHistory } from 'react-router-dom';	
import { IoIosClose } from 'react-icons/io';	
import { Modal } from 'react-bootstrap';	
import ReactTooltip from 'react-tooltip';	
import {	
  getAllSecurities,	
  getAllPsx,	
} from '../../../stores/services/security.service';	
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { numberWithCommas } from '../../../utils/customFunction';
import NumberFormat from "react-number-format";

const EditFunds = () => {	
  const history = useHistory();	
  //hooks for getting all inputs from user	
  const [fundName, setFundName] = useState('');
  const amcCode = sessionStorage.getItem('amc_code') || '';
  const [code, setCode] = useState('');	
  const [amcName, setAmcName] = useState('');	
  const [fundType, setFundType] = useState('');	
  const [incorporationDate, setIncorporationDate] = useState('');	
  const [revocationDate, setRevocationDate] = useState('');	
  const [psxListing, setPsxListing] = useState('false');	
  const [nav, setNav] = useState('');	
  const [faceValue, setFaceValue] = useState('');
  const [navError, setNavError] = useState('');	
  const email = sessionStorage.getItem('email') || '';
  
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);

  const [cdc_participant_id, setCDCParticipantID] = useState('');
  const [cdc_account_number, setCDCAccountNumber] = useState('');

  const [offerPrice, setOfferPrice] = useState('');
  const [redemptionPrice, setRedemptionPrice] = useState('');
  const [outstandingUnits, setOutstandingUnits] = useState('');
  const [managementFee, setManagementFee] = useState('');
  const [sstmanagementfee, setSstManagementFee] = useState('');
  const [amcReimbursementExp, setReinbursementExp] = useState('');
  const [sellingMarketingExp, setSellingMarketingExp] = useState('');
  const [secpFee, setSECPFee] = useState('');
  const [secpSupervisoryFee, setSECPSupervisoryFee] = useState('');

  React.useEffect(() => {	
    const obj = JSON.parse(sessionStorage.getItem('fundobj') || '');	
    setFundName(obj.fund_name);	
    setCode(obj.symbol_code.replace('FUND_',''));	
    setAmcName(obj.amc_code);	
    setFundType(obj.nature);	
    setIncorporationDate(obj.date_of_incorporation);	
    setRevocationDate(obj.date_of_revocation);	
    setPsxListing(obj.psx_listing);	
    setNav(obj.nav);	
    setFaceValue(obj.face_value);
    if (obj.money_market !== '' && obj.money_market !== '[]') {	
      setMoneyMarket(JSON.parse(obj.money_market));	
    }	
    if (obj.stock_market !== '' && obj.stock_market !== '[]') {	
      setStockMarket(JSON.parse(obj.stock_market));	
    }	
    if (obj.term_deposit !== '' && obj.term_deposit !== '[]') {	
      setTermDeposit(JSON.parse(obj.term_deposit));	
    }
    setCDCParticipantID(obj.cdc_participant_id);
    setCDCAccountNumber(obj.cdc_account_number);

    setOfferPrice(obj.offer_price);
    setRedemptionPrice(obj.redemption_price);
    setOutstandingUnits(obj.outstanding_units);
    setManagementFee(obj.management_fee_percentage);
    setSstManagementFee(obj.sst_on_management_fee_percentage);
    setReinbursementExp(obj.amc_reimbursement_exp_percentage);
    setSellingMarketingExp(obj.selling_and_marketing_exp_percentage);
    setSECPFee(obj.secp_fee_percentage);
    setSECPSupervisoryFee(obj.secp_supervisory_fee_percentage);
  }, []);	
  //error getting hooks	
  const [namelError, setNameError] = useState('');	
  const [codeError, setCodeError] = useState('');	
  const [amcNameError, setAmcError] = useState('');	
  const [fundTypeError, setFundTypeError] = useState('');	
  const [incorporationDateError, setIncorporationDateError] = useState('');	
  const [psxListingError, setPsxListingError] = useState('');	
  const [offerPriceError, setOfferPriceError] = useState('');
  const [redemptionPriceError, setRedemptionPriceError] = useState('');
  const [outstandingUnitsError, setOutstandingUnitsError] = useState('');
  const [managementFeeError, setManagementFeeError] = useState('');
  const [sstmanagementfeeError, setSstManagementFeeError] = useState('');
  const [amcReimbursementExpError, setReinbursementExpError] = useState('');
  const [sellingMarketingExpError, setSellingMarketingExpError] = useState('');
  const [secpFeeError, setSECPFeeError] = useState('');
  const [secpSupervisoryFeeError, setSECPSupervisoryFeeError] = useState('');

  const [Loading, setLoading] = useState(false);	
  //for dropdown data	
  const [amcdata, setAmcdata] = useState<any>([]);	
  const [fundTpedata, setFundTpedata] = useState<any>([]);	
  const [isCheckBoxSelected, setCheckBoxSelected] = useState(true);	
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);	
  const [psxList, setPsxList] = useState<any>([]); // PSX securities Hook	
  const validate = () => {	
    let nameError = '';	
    let codeError = '';	
    let amcErr = '';	
    let typeErr = '';	
    let inDateerr = '';	
    let psxErr,	
      navErr ,
      offerPriceErr,
      redemptionPriceErr,
      oustandingUnitsErr,
      managementFeeErr,
      sstmanagementfeeErr,
      amcReimbursementExpErr,
      sellingMarketingExpErr,
      secpFeeErr,
      secpSupervisoryFeeErr = '';	
    fundName.trim() === '' ? (nameError = 'Required') : (nameError = '');	
    code.trim() === '' ? (codeError = 'Required') : (codeError = '');	
    amcName.trim() === '' ? (amcErr = 'Required') : (amcErr = '');	
    fundType.trim() === '' ? (typeErr = 'Required') : (typeErr = '');	
    // incorporationDate.trim() === '' ? inDateerr = "Required" :	
    //     inDateerr = "";	
    psxListing.trim() === '' ? (psxErr = 'Required') : (psxErr = '');	
    nav.trim() === '' ? (navErr = 'Required') : (navErr = '');

    offerPrice.trim() === '' ? (offerPriceErr = 'Required') : (offerPriceErr = '');
    redemptionPrice.trim() === '' ? (redemptionPriceErr = 'Required') : (redemptionPriceErr = '');
    outstandingUnits.trim() === '' ? (oustandingUnitsErr = 'Required') : (oustandingUnitsErr = '');
    managementFee.trim() === '' ? (managementFeeErr = 'Required') : (managementFeeErr = '');
    sstmanagementfee.trim() === '' ? (sstmanagementfeeErr = 'Required') : (sstmanagementfeeErr = '');
    amcReimbursementExp.trim() === '' ? (amcReimbursementExpErr = 'Required') : (amcReimbursementExpErr = '');
    sellingMarketingExp.trim() === '' ? (sellingMarketingExpErr = 'Required') : (sellingMarketingExpErr = '');
    secpFee.trim() === '' ? (secpFeeErr = 'Required') : (secpFeeErr = '');
    secpSupervisoryFee.trim() === '' ? (secpSupervisoryFeeErr = 'Required') : (secpSupervisoryFeeErr = '');
    if (	
      nameError ||	
      codeError ||	
      amcErr ||	
      typeErr ||	
      inDateerr ||	
      psxErr ||	
      navErr ||
      offerPriceErr ||
      redemptionPriceErr ||
      oustandingUnitsErr ||
      managementFeeErr ||
      sstmanagementfeeErr ||
      amcReimbursementExpErr ||
      sellingMarketingExpErr||
      secpFeeErr||
      secpSupervisoryFeeErr
    ) {	
      setNameError(nameError);	
      setCodeError(codeError);	
      setAmcError(amcErr);	
      setNavError(navErr);	
      setFundTypeError(typeErr);	
      setIncorporationDateError(inDateerr);	
      setPsxListingError(psxErr);
      setOfferPriceError(offerPriceErr);
      setRedemptionPriceError(redemptionPriceErr);
      setOutstandingUnitsError(oustandingUnitsErr);
      setManagementFeeError(managementFeeErr);
      setSstManagementFeeError(sstmanagementfeeErr);
      setReinbursementExpError(amcReimbursementExpErr);
      setSellingMarketingExpError(sellingMarketingExpErr);
      setSECPFeeError(secpFeeErr);
      setSECPSupervisoryFeeError(secpSupervisoryFeeErr);
      return false;	
    } else {	
      return true;	
    }	
  };	
  const EditFundData = async () => {	
    const isValid = validate();	
    if (isValid) {	
      setLoading(true);	
      try {	
        const response = await EditFund(	
          email,	
          amcName,	
          fundType,	
          incorporationDate,	
          fundName,	
          code,	
          revocationDate,	
          psxListing,	
          nav,	
          JSON.stringify(moneyMarket),	
          JSON.stringify(stockMarket),
          faceValue,
          JSON.stringify(termDeposit),
          cdc_participant_id,
          cdc_account_number,
          offerPrice,
          redemptionPrice,
          outstandingUnits,
          managementFee,
          sstmanagementfee,
          amcReimbursementExp,
          sellingMarketingExp,
          secpFee,
          secpSupervisoryFee
        );
        if(response.data.status===200){	
        setCheckBoxSelected(true);	
        setFundName('');	
        setCode('');	
        setNav('');	
        setAmcName('');	
        stockMarket.length = 0;	
        setStockMarket(stockMarket);	
        moneyMarket.length = 0;	
        setMoneyMarket(moneyMarket);
        termDeposit.length = 0;
        setTermDeposit(termDeposit);	
        setFundType('');	
        setIncorporationDate('');	
        setRevocationDate('');	
        sessionStorage.removeItem('fundobj');	
        setPsxListing('true');	
        setFaceValue('');
        setOfferPrice('');
        setRedemptionPrice('');
        setOutstandingUnits('');
        setManagementFee('');
        setSstManagementFee('');
        setReinbursementExp('');
        setSellingMarketingExp('');
        setSECPFee('');
        setSECPSupervisoryFee('');
        toast.success(response.data.message);	
        setTimeout(() => {	
          history.replace(`/admin/funds`);	
        }, 2000);
      }else{
        toast.error(response.data.message);	
      }	
      } catch (error:any) {	
        toast.error(error.response.data.message);	
      }	
      setLoading(false);	
    }	
  };	
  React.useEffect(() => {	
    const fetchAmc = async () => {	
      // try {	
      //   const amcResponse = await getAmc(email);	
      //   setAmcdata(amcResponse.data.data);	
      // } catch (error) {}	
      try {	
        const response = await getFundType(email);
        setFundTpedata(response.data.data);	
      } catch (error) {}	
      try {	
        const motResponse = await getAllSecurities(email);	
        setSecurityTypeData(motResponse.data.data);	
      } catch (error) {}	
      try {	
        const response = await getAllPsx(email);	
        let sortedData = response.data.data.sort(function(a,b){
          let x = a.company_name.toLowerCase();
          let y = b.company_name.toLowerCase();
          if(x>y){return 1;}
          if(x<y){return -1;}
          return 0;
        });
        // setPsxList(response.data.data);	
        setPsxList(sortedData);	
      } catch (error) {}	

      // get all banks
      try {
        const bankResponse = await getAllBanks(email);
        // temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(bankResponse.data.data);
      } catch (error) {}

    };	
    fetchAmc();	
  }, []);	


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setBranchData(response.data.data);
    }catch(error){}

  };
  const renderBankDropdown = () => {
    return bankNameData.map((item: any) => {
      return (
        <option key={item.bank_code} value={item.bank_code.replace('BANK_','')}>
          {item.bank_name} ({item.bank_code.replace('BANK_','')})
        </option>
      );
    });
  };

  const renderBranchDropdown = () => {
    return branchData.map((item: any) => {
      return (
        <option key={item.branch_code} value={item.branch_name}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };

  // const renderAmcDropdown = () => {	
  //   return amcdata.map((item: any) => {	
  //     return (	
  //       <option key={item.amc_code} value={item.amc_code.replace('AMC_','')}>	
  //         {item.amc_code.replace('AMC_','')}-{item.name}	
  //       </option>	
  //     );	
  //   });	
  // };	
  //render dropdown for security data	
  const renderSecurityDataDropdown = () => {	
    return securityTypeData.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.code}>	
          {item.name}-{item.code}	
        </option>	
      );	
    });	
  };	
  //render dropdown for psx data	
  const renderPsxDataDropdown = () => {	
    return psxList.map((item: any, index: string) => {	
      return (	
        <option key={index} value={item.symbol}>	
          {item.symbol}	
        </option>	
      );	
    });	
  };	

  const [securityData, setSecurityData] = useState([]);

const renderFilterSecurityDataDropdown = (security_type:string) => {
  const check= securityTypeData.filter((item: any, index: string)=>{
   return (item.security_type===security_type);
  });
  setSecurityData(check);
};

const renderSecuritiesDropDown=()=>{
  return securityData.map((item: any, index: number) => {
    return (
      <option key={index} value={item.code.replace('SECURITY_','')}>
        {item.name}
      </option>
    );
  });
}


  const [modalView, setModalView] = useState(false);	
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);	
  const [moneyMarket, setMoneyMarket] = useState<any>([]);	
  const [moneyMarketEntity, setMoneyMarketEntity] = useState<any>({	
    code: '',	
    total_amount: '0',	
    // name: '',	
  });	
  // ADD TAX Entity inside tax Array	
  const addMoneyMarket = async () => {	
    setDisableAddBtn(true);	
    let count = 0;	
    // push data inside array	
    for (let i in moneyMarketEntity) {	
      if (moneyMarketEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableAddBtn(false);	
    } else {	
      setModalView(false);	
      // const check = moneyMarket.filter((item) => {	
      //   return item.code === moneyMarketEntity['code'];	
      // });	
      // if (check.length > 0) {	
      //   moneyMarket.map((item, index) => {	
      //     if (item.code === moneyMarketEntity['code']) {	
      //       item.total_amount = (+item.total_amount + +moneyMarketEntity['total_amount']).toString();	
      //       item.units = (+item.units + +moneyMarketEntity['units']).toString();
      //     }	
      //   });	
      // } else {	
        moneyMarket.push(moneyMarketEntity);	
        setMoneyMarket(moneyMarket);	
      // }	
      const filedEntirty = {	
        code: '',	
        total_amount: '0',	
        // name: '',	
      };	
      setMoneyMarketEntity(filedEntirty);	
      setDisableAddBtn(false);	
    }	
  };	
  //render Fund table data	
  const renderFundDataInTable = () => {	
    return moneyMarket.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td> 
          <td>{items.maturity_date}</td> 
          <td>{numberWithCommas(items.coupon_rate || '')}</td> 
          <td>{numberWithCommas(items.yield || '')}</td>
          <td>{numberWithCommas(items.mmface_value || '')}</td>
          <td>{numberWithCommas(items.face_value || '')}</td>
          {/* <td>{numberWithCommas(items.price || '')}</td> */}
          <td>{numberWithCommas(items.average_cost_per_unit || '')}</td>
          <td>{numberWithCommas(items.units || '')}</td> 
          <td> {numberWithCommas(items.total_amount || '') && numberWithCommas(parseFloat(items.total_amount || '0').toFixed(2))} </td> 
          <td>{items.tenor}</td> 
          {/* <td>{items.calculatedAmount}</td> */}	
          <td >	
            <IoIosClose	
              style={{ fontSize: '25px', color: 'red' }}	
              onClick={() => {	
                let array = [...moneyMarket];	
                array.splice(index, 1);	
                setMoneyMarket(array);	
              }}	
            />	
          </td>	
        </tr>	
      );	
    });	
  };	
  // render Model for adding Fund	
  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setModalView(false);
            }}
          > 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up"> {stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
             </div>    

            <div className="modal-body">

            <Row>
                <Col>
                <div className="form-group">
                    <div>Select Security</div>
                <select 
                      className='form-control'
                      value={moneyMarketEntity['security_type']}
                      onChange={(e) => {
                        renderFilterSecurityDataDropdown(e.target.value)
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          security_type:e.target.value
                        });
                       
                    }}>
                       <option value="" defaultChecked hidden>
                              {' '}
                        Select Security</option>
                      <optgroup>Money Market</optgroup>
                      <option value='PIB'>Pakistan Investment Bonds</option>
                      <option value='TBILL'>Treasuary bill</option>

                      <optgroup>Debt Market</optgroup>
                      <option value='SUKUK'>Sukuk Certificates</option>
                      <option value='TFC'>Term Finance Certificates</option>
                      <option value='CP'>Commercial Papers</option>

                      <optgroup>Term Deposits</optgroup>
                      <option value='TDR'>Term Deposit Receipts</option>
                      <option value='CDR'>Call Deposit Receipts</option>
                      <option value='LOP'>Letter of Placement</option>
                      </select>
                    </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <div>Select Security</div>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />

                    <select
                      className="form-control"
                      value={moneyMarketEntity['code']}
                      onChange={(e) => {
                        // let value = securityTypeData.filter((item: any) => {
                        //   return item.code === e.target.value;
                        // });
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          // code: e.target.value,
                          code:e.target.value,
                          name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text,
                        });
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        Select Security
                      </option>
                      {/* {renderSecurityDataDropdown()} */}
                      {renderSecuritiesDropDown()}
                    </select>
                  </div>
                </Col>
                 
              </Row>
              
              <Row>
              <Col md="6">
                  <div className="form-group">
                      <div>Issue Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity['issue_date']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            issue_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                </Col>
              <Col md="6">
                  <div className="form-group">
                      <div>Maturity Date</div>
                      <input
                        type="date"
                        className="form-control w-100"
                        value={moneyMarketEntity['maturity_date']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            maturity_date: e.target.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                </Row>

              <Row>
                <Col md="6">
                   <div className="form-group">
                      <div>Coupon Rate</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['coupon_rate']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            coupon_rate: e.value,
                          });
                        }}
                      />
                    </div>
                </Col>

                
              <Col md="6">
                  <div className="form-group">
                      <div>Yield</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['yield']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            yield: e.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                </Row>

                <Row>

                <Col md="6">
                  <div className="form-group">
                      <div>Money Market Face Value</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['mmface_value']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            mmface_value: e.value,
                          });
                        }}
                      />
                    </div>

                </Col>

                <Col md="6">
                  <div className="form-group">
                      <div>Total Face Value</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['face_value']}
                        onValueChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            face_value: e.value,
                          });
                        }}
                      />
                    </div>

                </Col>
                
              </Row>

              <Row>
              {/* <Col md="6">
                  <div className="form-group">
                      <div>Price</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['price']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            price: value,
                          });
                        }}
                      />
                    </div>
                </Col> */}
                <Col md="6">
                  <div className="form-group">
                      <div>Avg Cost/Unit</div>
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={moneyMarketEntity['average_cost_per_unit']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value, 4);
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            average_cost_per_unit: value,
                          });
                        }}
                      />
                    </div>
                  
                </Col>
              <Col md="6">
                  <div className="form-group">
                    <div>Units</div>
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                      value={moneyMarketEntity['units']}
                      onValueChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          units: e.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                </Row>
                <Row>
              <Col md="6">
                  <div className="form-group">
                    <div>Settlement Amount</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={moneyMarketEntity['total_amount']}
                      onValueChange={(e) => {
                        setMoneyMarketEntity({
                          ...moneyMarketEntity,
                          total_amount: e.value,
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">

                  <div className="form-group">
                      <div>Tenor</div>
                      <input
                        type="number"
                        className="form-control w-100"
                        value={moneyMarketEntity['tenor']}
                        onChange={(e) => {
                          setMoneyMarketEntity({
                            ...moneyMarketEntity,
                            tenor: e.target.value,
                          });
                        }}
                      />
                    </div>
                  
                </Col> 
                </Row>
              
              <div className="row"> 
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2 w-100 px-3 rounded"
                    onClick={() => {
                      addMoneyMarket();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  //for stock Market	
  const [equityModalFlag, setEquityModalFlag] = useState(false);	
  const [disableEquityBtn, setDisableEquityBtn] = useState<boolean>(false);	
  const [stockMarket, setStockMarket] = useState<any>([]);	
  const [stockEntity, setStockEntity] = useState<any>({	
    code: '',	
    unit: '',	
    amount: '0',	
    close_rate: '0',
    pledged:'0'	
  });	
  // ADD TAX Entity inside tax Array	
  const addEquityStock = async () => {	
    setDisableEquityBtn(true);	
    let count = 0;	
    //push data inside array	
    for (let i in stockEntity) {	
      if (stockEntity[i] === '') {	
        count += 1;	
      }	
    }	
    if (count > 0) {	
      toast.error('Kindly Fill All Fields');	
      setDisableEquityBtn(false);	
    } else {	
      setEquityModalFlag(false);	
      const check = stockMarket.filter((item) => {	
        return item.code === stockEntity['code'];	
      });	
      if (check.length > 0) {	
        stockMarket.map((item, index) => {	
          if (item.code === stockEntity['code']) {	
            item.unit = (+item.unit + +stockEntity['unit']).toString();	
            item.amount = (parseFloat(item.unit) * parseFloat(item.close_rate)).toFixed(2);	
          }	
        });	
      } else {	
        stockMarket.push(stockEntity);	
        setStockMarket(stockMarket);	
      }	
      const filedEntirty = {	
        code: '',	
        unit: '',	
        amount: '0',	
        close_rate: '0',
        pledged:'0'	
      };	
      setStockEntity(filedEntirty);	
      setDisableEquityBtn(false);	
    }	
  };	
  const [stockModal, setStockModal] = useState(false);	
  //render equity table data	
  const renderEquityInTable = () => {	
    return stockMarket.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.code}</td>	
          <td>{numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(2))}	  </td>	
          <td>{numberWithCommas(items.unit || '') && numberWithCommas(items.unit)}	 </td>	
          <td>{numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}	  </td>	
          <td>{items.market}  </td>	
          <td>{numberWithCommas(items.pledged)}</td>
          {/* <td>{items.calculatedAmount}</td> */}	
          <td >	
            <IoIosClose	
              style={{ fontSize: '25px', color: 'red' }}	
              onClick={() => {	
                let array = [...stockMarket];	
                array.splice(index, 1);	
                setStockMarket(array);	
              }}	
            />	
          </td>	
        </tr>	
      );	
    });	
  };	
  // render Model for adding Equity	
  const renderEquityPopup = () => {	
    switch (equityModalFlag) {	
      case true:	
        return (	
          <Modal	
            className="  "	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => {	
              setEquityModalFlag(false);	
            }}	
          >	 

            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {	
                  setEquityModalFlag(false);	 
                }}	
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">{stockModal ? 'Add Stock Market' : 'Add Money Market'} </h6>
            </div>


            <div className="modal-body">	
                <Row>
                  <Col md="6">
                    <div className="form-group">
                        Market*
                        <select
                          className="form-control w-100"
                          value={stockEntity.market}
                          onChange={(e) => {
                            setStockEntity({
                              ...stockEntity,
                              market: e.target.value
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>Select Market</option>
                          <option value="REG">Regular</option>
                          <option value="ODL">Odd Lot</option>
                          <option value="FUT">Future</option>
                          <option value="BNB">Bills and Bonds</option>
                          <option value="IPO">Initial Public Offering</option>
                          <option value="BKB">Book Building</option>
                          <option value="CSF">Cash Settled Futures</option>
                          <option value="DSF">Direct Settled Futures</option>
                          <option value="SIF">Stock Index Futures</option>
                          <option value="NDM">Negotiable Deal Market</option>
                          <option value="FRO">Future Rollover Contract</option>
                          <option value="MTS">Margin Trading System</option>
                          <option value="SQR">Square Up</option> 
                        </select>
                      </div>
                  </Col>
                  <Col md="6">
                  <div className="form-group">
                      Select Security*
                      <select
                        className="form-control w-100"
                        value={stockEntity['code']}
                        onChange={(e) => {
                          let value = psxList.filter((item: any) => {
                            return item.symbol === e.target.value;
                          });
                          setStockEntity({
                            ...stockEntity,
                            code: e.target.value,
                            // close_rate: value[0].close_rate,
                            // amount: (
                            //   parseFloat(e.target.value) *
                            //   parseFloat(stockEntity.Unit)
                            // ).toFixed(2),
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select Security
                        </option>
                        {renderPsxDataDropdown()}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                      Average Rate
                      <input
                        type="number"
                        className="form-control w-100 disable-input"
                        value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setStockEntity({
                            ...stockEntity,
                            close_rate: e.target.value,
                          });
                        }}
                      />
                    </div>

                  </Col>
                  <Col md="6">
                  <div className="form-group">
                      Units*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['unit']}
                        onValueChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            unit: e.value,
                            amount: (
                              parseFloat(e.value) *
                              parseFloat(stockEntity.close_rate)
                            ).toFixed(2),
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                  <div className="form-group">
                      Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['amount']}
                        readOnly
                      />
                    </div> 
                  </Col> 
                  <Col md="6">
                  <div className="form-group">
                      Pledged
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={stockEntity['pledged']}
                        onValueChange={(e) => {
                          setStockEntity({
                            ...stockEntity,
                            pledged: e.value,
                          });
                        }}
                      />
                    </div> 
                  </Col>
                </Row> 
                <div className="row">
                <div className="col-sm-12">	
                  <button	
                    className="btn btn-primary btn-block"	
                    onClick={() => {	
                      addEquityStock();	
                    }}	
                    disabled={Boolean(disableEquityBtn)}	
                  >	
                    {disableEquityBtn ? (	
                      <>	
                        <span	
                          className="spinner-border login-txt spinner-border-sm"	
                          role="status"	
                          aria-hidden="true"	
                        ></span>	
                        <span className="login-txt"> Loading...</span>	
                      </>	
                    ) : (	
                      <span>Add</span>	
                    )}	
                  </button>	
                </div>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	

  const [termDepositModal, setTermDepositModal] = useState(false);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [termDepositEntity, setTermDepositEntity] = useState<any>({
    code:'',
    name:'',
    principal_amount:'',
    interest_rate: '',
    from_date: '',
    to_date: '',
    bank:'',
    branch:'',
  });
  const addTermDeposit = async () => {
    // setDisableEquityBtn(true);
    let count = 0;
    //push data inside array
    for (let i in termDepositEntity) {
      if (termDepositEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      // setDisableEquityBtn(false);
    } else {
      setTermDepositModal(false);
      // const check = termDeposit.filter((item) => {
      //   return item.interest_rate === termDepositEntity['interest_rate'];
      // });
      // if (check.length > 0) {
      //   termDeposit.map((item, index) => {
      //     if (item.code === termDepositEntity['code']) {
      //       item.interest_rate = (+item.interest_rate + +termDepositEntity['interest_rate']).toString();
      //     }
      //   });
      // } else {
        termDeposit.push(termDepositEntity);
        setTermDeposit(termDeposit);
      // }
      const filedEntirty = {
        code:'',
        name:'',
        principal_amount:'',
        interest_rate: '',
        from_date: '',
        to_date: '',
        bank:'',
        branch:'',
      };
      setTermDepositEntity(filedEntirty);
      // setDisableEquityBtn(false);
    }
  }

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount || '')}</td>
          <td>{numberWithCommas(items.interest_rate || '')}</td>
          <td>{items.from_date}</td>
          <td>{items.to_date}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...termDeposit];
                array.splice(index, 1);
                setTermDeposit(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTermDepositPopup = () => {
    switch (termDepositModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setTermDepositModal(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTermDepositModal(false); 
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Term Deposit</h6>
             </div> 


            <div className="modal-body ">
              <div className="">

              <Row>
                <Col>
                <div className="form-group">
                  Type
                  <select className='form-control w100'
                            onChange={(e)=>{
                              termDepositEntity['code']=e.target.value;
                              termDepositEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            >
                            <option value='' hidden> Select Type</option>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value='CDR'>Call Deposit Receipts</option>
                            <option value='LOP'>Letter of Placement</option>
                    </select>
                </div>
                </Col>
                  <Col>
                  <div className="form-group">
                     Principal Amount
                     <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        // value={stockEntity['close_rate']}
                        onValueChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            principal_amount: e.value
                          });
                        }}
                      />
                    </div> 
                  </Col> 
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                        Interest Rate
                        <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        onValueChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            interest_rate: e.value
                          });
                        }}
                        />
                      </div>
                  </Col>
                </Row>
            
                <Row>
                  <Col>
                  <div className="form-group">
                      From Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            from_date: e.target.value
                          });
                        }}
                      />
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                  <div className="form-group">
                  To Date
                      <input
                        type="date"
                        className="form-control w-100"
                        // value={stockEntity['close_rate']}
                        onChange={(e)=>{
                          setTermDepositEntity({
                            ...termDepositEntity,
                            to_date: e.target.value
                          });
                        }}
                      />
                    </div> 
                  </Col> 
                </Row> 
               
               <Row>
                 <Col>
                    <div className="form-group">
                      Bank
                      <select className="form-control w-100"
                      onChange={(e)=>{
                        setTermDepositEntity({
                          ...termDepositEntity,
                          bank: e.target.value
                        });
                        getCounterBranchByName(e.target.value);
                      }}>

                      <option value="" defaultChecked hidden>
                          {' '}
                          Select Bank
                        </option>
                        {renderBankDropdown()}
                      </select>
                    </div>
                     </Col>
                    <Col>
                      <div className="form-group">
                      Branch
                      <select className="form-control w-100"
                      onChange={(e)=>{
                        setTermDepositEntity({
                          ...termDepositEntity,
                          branch: e.target.value
                        });
                      }}>
                        
                      <option value="" defaultChecked hidden>
                          {' '}
                          Select Branch
                        </option>
                      {renderBranchDropdown()}
                      </select>
                    </div>
                 </Col>
               </Row>
               
                <div className="">
                  <button
                    className="btn btn-primary mt-3 w-100 px-3 rounded"
                    onClick={() => {
                      addTermDeposit();
                    }}
                    disabled={Boolean(disableEquityBtn)}
                  >
                    {disableEquityBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const [codeChange, setCodeChange] = useState('');	
  const decimalValue = (value, len) => {	
    let startingValue = value.split('.')[0];	
    let decimalVal = value.split('.')[1];	
    let temp = '';	
    if (decimalVal !== undefined) {	
      let decimalString = decimalVal.toString();	
      if (decimalString.length <= len) {	
        temp = startingValue + '.' + decimalString;	
      } else {	
        temp = startingValue + '.' + decimalString.substr(0, len);	
      }	
    } else {	
      temp = startingValue;	
    }	
    return temp;	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />		

        <div className="input-holder">	
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      history.replace('/admin/funds')
                  }}></i>Edit - Funds</h4>	 
            <Link to="/admin/funds" className="t-3 ml-auto btn btn-primary btn-sm" replace>	
              <i className="fa fa-eye mr-2"></i> View All	
            </Link>	 
        </div>

        <Row>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Fund Details
                  </CardTitle>
                </CardHeader>
                <CardBody>

                <div className="form-group  ">	
                    <label>Fund Name</label>	
                    <div className=" ">	
                      <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          namelError ? 'required-border' : ''	
                        }`}	
                        value={fundName}	
                        onChange={(e) => {	
                          setFundName(e.target.value);	
                          setNameError('');	
                        }}	
                      />	
                      {/* {namelError ? <p className="error-labels error-message2">{namelError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group  ">	
                    <label>Fund Code</label>	
                    <div className="  disable">	
                      <input	
                        type="text"	
                        readOnly	
                        className={`form-control  w-100 ${	
                          codeError ? 'required-border' : ''	
                        }`}	
                        value={code}	
                        onChange={(e) => {	
                          setCode(e.target.value.toUpperCase());	
                          setCodeError('');	
                        }}	
                      />	
                      {/* {codeError ? <p className="error-labels error-message">{codeError}</p> : ''} */}	
                    </div>	
                  </div>

                  {/* <div className="form-group  ">	
                    <label>AMC Name</label>	
                    <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          amcNameError ? 'required-border' : ''	
                        }`}	
                        value={amcName}	
                        onChange={(e) => {	
                          setAmcError('');	
                          setAmcName(e.target.value);	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                          {' '}	
                          Select An AMC	
                        </option>	
                        {renderAmcDropdown()}	
                      </select>	
                    </div>	
                  </div> */}

                  <div className="form-group  ">	
                    <label>Nature / Type of Fund</label>	
                    <div className="">	
                      {/* <input	
                        type="text"	
                        className={`form-control  w-100 ${	
                          fundTypeError ? 'required-border' : ''	
                        }`}	
                        value={fundType}	
                        onChange={(e) => {	
                          setFundType(e.target.value);	
                          setFundTypeError('');	
                        }}	
                      />	 */}
                       <select
                        className={`form-control input-broker  w-100 ${
                          fundTypeError ? 'required-border' : ''
                        }`}
                        value={fundType}
                        onChange={(e) => {
                          setFundType(e.target.value);
                          setFundTypeError('');
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          Select
                        </option>
                        <option value="Agressive Income Fund">
                          Agressive Income Fund
                        </option>
                        <option value="Asset Allocation Fund">
                        Asset Allocation Fund
                        </option>
                        <option value="Income Fund">Income Fund</option>
                        <option value="Money Market Fund">
                          Money Market Fund
                        </option>
                        <option value="Stock Fund">Stock Fund</option>
                        <option value="Private Fund Managment Co">
                          Private Fund Managment Co
                        </option>
                        <option value="Fund of Fund">Fund of Fund</option>
                        <option value="Special Managed Account">Special Managed Account (SMA)</option>
                        <option value="Private Equity">Private Equity</option>
                        <option value="Venture Capital">Venture Capital</option>
                      </select>
                      {/* {fundTypeError ? <p className="error-labels error-message">{fundTypeError}</p> : ''} */}	
                    </div>	
                  </div>	 
                </CardBody>
              </Card>
          </Col>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Incorporation
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group  ">	
                    <label>Date of Incorporation</label>	
                    <div className="">	
                      <input	
                        type="date"	
                        className={`form-control  w-100 ${	
                          incorporationDateError ? 'required-border' : ''	
                        }`}	
                        value={incorporationDate}	
                        onChange={(e) => {	
                          setIncorporationDate(e.target.value);	
                          setIncorporationDateError('');	
                        }}	
                      />	
                      {/* {incorporationDateError ? <p className="error-labels error-message2">{incorporationDateError}</p> : ''} */}	
                    </div>	
                  </div>

                  <div className="form-group  ">	
                    <label>Date of Revocation</label>	
                    <div className="">	
                      <input	
                        type="date"	
                        className="form-control"	
                        value={revocationDate}	
                        onChange={(e) => {	
                          setRevocationDate(e.target.value);	
                        }}	
                      />	
                    </div>	
                  </div>	

                  <Row>
                    <Col md ="6">
                      <div className="form-group  ">	
                        <label>NAV</label>	
                        <div className="">	
                          <input	
                            type="text"	
                            className={`form-control  w-100 ${	
                              navError ? 'required-border' : ''	
                            }`}	
                            value={nav}	
                            onChange={(e) => {	
                              let value = decimalValue(e.target.value, 4);	
                              setNav(value);	
                              setNavError('');	
                              setCodeChange(value);	
                            }}	
                          />	
                          {/* {navError ? <p className="error-labels error-message2">{navError}</p> : ''} */}	
                        </div>	
                    </div>
                    </Col>
                    <Col md ="6">
                      <div className="form-group">
                        <label htmlFor="">Face Value <span className="color-amber">*</span></label>
                        <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={faceValue}
                          onValueChange={(e) => {
                            setFaceValue(e.value); 
                            // setNameError('');
                          }}
                          />
                        </div>
                    </Col>
                  </Row>

                  <Row>
                   <Col md="6">
                    <div className="form-group">
                      <label>
                        CDC Participant ID 
                      </label>
                      <div  >
                        <input
                          type="text"
                          className={`form-control  w-100 `}
                          value={cdc_participant_id}
                          onChange={(e) => {
                            setCDCParticipantID(e.target.value);
                          }}
                          
                        /> 
                      </div>
                    </div>  
                   </Col>
                   <Col md="6">
                     <div className="form-group">
                       <label htmlFor="">CDC Account Number </label>
                       <input type="text" className="form-control"  
                       value={cdc_account_number}
                        onChange={(e) => {
                          setCDCAccountNumber(e.target.value); 
                        }}
                        />
                      </div>
                   </Col>
                 </Row>
                  

                  <div>	
                    <label>PSX Listing</label>	
                    <div  >	
                      <div>	
                        <form action=""> 	
 	                    <FormGroup check className="form-check-radio d-inline mr-3">	
                        <Label check>	
                          <Input	
                           type="radio"	
                             id="listed"	
                             name="listed"	
                             value="true"	
                             checked={isCheckBoxSelected}	
                             onChange={(e) => {	
                               setPsxListingError('');	
                               setPsxListing(e.target.value);	
                               setCheckBoxSelected(true);	
                             }}	
                          />	
                          <span className="form-check-sign" />	
                          Listed	
                        </Label>	
                      </FormGroup>	
 	
                    <FormGroup check className="form-check-radio d-inline mr-3">	
                        <Label check>	
                          <Input	
                           id="unlisted"	
                           name="listed"	
                           value="false"	
                           onChange={(e) => {	
                             setPsxListingError('');	
                             setPsxListing(e.target.value);	
                             setCheckBoxSelected(false);	
                           }}	
                            type="radio"	
                          />	
                          <span className="form-check-sign" />	
                          Unlisted	
                        </Label>	
                      </FormGroup>	
                          <br />	
                        </form>	
                        {psxListingError ? (	
                          <p className="error-labels error-mes">	
                            {psxListingError}	
                          </p>	
                        ) : (	
                          ''	
                        )}	
                      </div>	
                    </div>	
                  </div> 
                </CardBody>
              </Card>
          </Col>
        </Row>

        <Row>
          <Col md>
          <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Detail
                </CardTitle>
              </CardHeader>
              <CardBody>

                <Row>
                  <Col md="4">
                  <div className="form-group  ">
                    <label>Offer Price</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          offerPriceError ? 'required-border' : ''
                        }`}
                        value={offerPrice}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 4)
                          setOfferPrice(value);
                          setOfferPriceError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                  <Col md="4">
                  <div className="form-group  ">
                    <label>Redemption Price</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          redemptionPriceError ? 'required-border' : ''
                        }`}
                        value={redemptionPrice}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value,2)
                          setRedemptionPrice(value);
                          setRedemptionPriceError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                  <Col md="4">
                  <div className="form-group  ">
                    <label>OutStanding Units</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          outstandingUnitsError ? 'required-border' : ''
                        }`}
                        value={outstandingUnits}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 4)
                          setOutstandingUnits(value);
                          setOutstandingUnitsError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                </Row>

                <Row>
                <Col md="4">
                  <div className="form-group  ">
                    <label>Management Fee%</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          managementFeeError ? 'required-border' : ''
                        }`}
                        value={managementFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setManagementFee(value);
                          setManagementFeeError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>
                  <Col md="4">
                  <div className="form-group  ">
                    <label>SST on Management Fee %</label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          sstmanagementfeeError ? 'required-border' : ''
                        }`}
                        value={sstmanagementfee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSstManagementFee(value);
                          setSstManagementFeeError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>

                  <Col md="4">
                  <div className="form-group  ">
                    <label>AMC Reimbursement Exp % </label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          amcReimbursementExpError ? 'required-border' : ''
                        }`}
                        value={amcReimbursementExp}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setReinbursementExp(value);
                          setReinbursementExpError('');
                        }}
                      />

                    </div>
                  </div>
                  </Col>
 
                  </Row>

                  <Row>
                  <Col md="4">
                  <div className="form-group  ">
                    <label>Selling {'&'} Marketing Exp % </label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          sellingMarketingExpError ? 'required-border' : ''
                        }`}
                        value={sellingMarketingExp}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSellingMarketingExp(value);
                          setSellingMarketingExpError('');
                        }}
                      />

                    </div>
                  </div>
                    
                  </Col>
                    <Col md="4">
                    <div className="form-group  ">
                    <label>SECP Fee % </label>
                    <div  >
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          secpFeeError ? 'required-border' : ''
                        }`}
                        value={secpFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSECPFee(value);
                          setSECPFeeError('');
                        }}
                      />

                    </div>
                  </div>
                    </Col>

                    <Col md="4">
                    <div className="form-group  ">
                    <label>SECP Supervisory Fee %  </label>
                    <div>
                      <input
                        type="number"
                        className={`form-control  w-100 ${
                          secpSupervisoryFeeError ? 'required-border' : ''
                        }`}
                        value={secpSupervisoryFee}
                        onChange={(e) => {
                          let value = decimalValue(e.target.value, 2)
                          setSECPSupervisoryFee(value);
                          setSECPSupervisoryFeeError('');
                        }}
                      />

                    </div>
                  </div>
                    </Col>
                  </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    Money Market Portfolio
                  </CardTitle>
                </CardHeader>
                <CardBody>

                <div className="input-size-pos">	
                <Row>
                <Col>
                <label>Add Portfolio</label> 
                <div>
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  />	
                  <button	
                    data-tip="Add Money Maket"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      setModalView(!modalView);
                      setDisableAddBtn(false);
                      setStockModal(false);	
                    }}	
                  > <i className="fa fa-plus"></i> </button>	
                </div>
                </Col>
                </Row>
                </div>

            <div className="table-responsive">
                {moneyMarket.length > 0 && (	
                <div className="input-size-pos mb-3 mt-2 ">	 
                  <table className="table ">	
                    <thead>	
                      <tr>	
                      <th>Symbol / Code</th>
                        <th>Security Type</th>
                        <th>Name</th>
                        <th>Issue Date</th>
                        <th>Maturity Date</th>
                        <th>Coupon Rate</th>
                        <th>Yield</th>
                        <th>Money Market Face Value</th>
                        <th>Total Face Value</th>
                        {/* <th>Price</th> */}
                        <th>Avg Cost/Unit</th>
                        <th>Units</th>
                        <th>Settlement Amount</th>
                        <th>Tenor</th>
                        <th>Action</th>
                      </tr>	
                    </thead>	
                    <tbody>{renderFundDataInTable()}</tbody>	
                  </table>	
                </div>	
              )}
              </div>	  
                </CardBody>
              </Card>
          </Col>
          <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Stock Market Portfolio
                  </CardTitle>
                </CardHeader>
                <CardBody> 
               	
                 <div className="input-size-pos ">	
                 <Row>
                <Col>
                <label>Add Portfolio</label> 
                <div>
                   <ReactTooltip	 
                     textColor="white"	
                     backgroundColor="black"	
                     effect="float"	
                   />	
                   <button	
                     data-tip="Add Stock Market"	
                     className="btn-round btn-icon btn btn-primary"	
                     onClick={() => {	
                       setEquityModalFlag(!equityModalFlag);	
                       setDisableEquityBtn(false);	
                       setStockModal(true);	
                       const filedEntirty = {	
                         code: '',	
                         unit: '',	
                         amount: '0',	
                         close_rate: '0',	
                       };	
                       setStockEntity(filedEntirty);	
                     }}	
                   > <i className="fa fa-plus"> </i> </button>	
                 </div>	 
                 </Col>
                 </Row>
                 </div>

                 <div className="table-responsive">
                 {stockMarket.length > 0 && (
                <div className="input-size-pos mb-3 mt-2">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Symbol / Code</th>
                        <th>Rate</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Market</th>
                        <th>Pledged</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderEquityInTable()}</tbody>
                  </table>
                </div>
              )}	
</div>
                </CardBody>
              </Card>
          </Col>
        </Row>
 
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Term Deposit Portfolio
                </CardTitle>
              </CardHeader>
              <CardBody>

              <div className="input-size-pos">

                <Row>
                <Col>
                <label>Add Portfolio</label> 
                <div>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />  
                  <button	
                    data-tip="Add Term Deposit"	
                    className="btn-round btn-icon btn btn-primary"	
                    onClick={() => {	
                      // setModalView(!modalView);
                      // setTermDepositModal(!termDepositModal)
                      setDisableAddBtn(false);
                      setTermDepositModal(true);	
                    }}	
                  > <i className="fa fa-plus"></i> </button>
                  </div>
                  </Col>    
             </Row>
                </div>

                <div className="table-responsive">
                {termDeposit.length > 0 && (
                <div className="input-size-pos mb-3 mt-2">
                  {/* <h3>Money Market Portfolio</h3> */}
                  <table className="table   ">
                    <thead>
                      <tr>
                      <th>Code</th>
                        <th>Name</th>
                        <th>Principal Amount</th>
                        <th>Interest Rate</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Bank</th>
                        <th>Branch</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderTermDepositInTable()}</tbody>
                  </table>
                </div>
              )}
</div>
              </CardBody>
            </Card>
          </Col>
          </Row>

          <button	
            className="btn btn-primary"	
            onClick={EditFundData}	
            disabled={Boolean(Loading)}	
          >	
            {Loading ? (	
              <>	
                <span	
                  className="spinner-border login-txt spinner-border-sm"	
                  role="status"	
                  aria-hidden="true"	
                ></span>	
                <span className="login-txt"> Loading...</span>	
              </>	
            ) : (	
              <span>Update</span>	
            )}	
          </button>	 

          <button	
            className="btn btn-default mr-2"	
            onClick={() => {	
              history.replace('/admin/funds');	
            }}	
          >	
            <span>Cancel</span>	
          </button>	 
  	
  	
            {renderModalPopup()}	
            {renderEquityPopup()}	 
            {renderTermDepositPopup()}
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  );	
};	
export default EditFunds;	
