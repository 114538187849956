import React, { useEffect, useState } from 'react';	
import {	
  Container,	
  Row,	
  Col,	
} from 'reactstrap';	
import { useHistory, Link } from 'react-router-dom';	
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from "react-number-format";
import moment from 'moment';

const ViewEquitySecurity = () => {	
  const history = useHistory();	

  /////
  const [companyName, setCompanyName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [sectorCode, setSectorCode] = useState('');
  const [openRate, setOpenRate] = useState('');
  const [highRate, setHighRate] = useState('');
  const [lowRate, setLowRate] = useState('');
  const [closeRate, setCloseRate] = useState('');
  const [volume, setVolume] = useState('');
  const [ldcp, setLDCP] = useState('');
  const [paidUpCapital, setPaidUpCapital] = useState('');
  const [facevalue, setFaceValue] = useState('');
  const [date, setDate] = useState('');

  useEffect(()=>{
     const obj = JSON.parse(sessionStorage.getItem('securityEquityObj') || '');
     setCompanyName(obj.company_name);
     setSymbol(obj.symbol);
     setSectorCode(obj.sector_code);
     setOpenRate(obj.open_rate);
     setHighRate(obj.high_rate);
     setLowRate(obj.low_rate);
     setCloseRate(obj.close_rate);
     setVolume(obj.volume);
     setLDCP(obj.ldcp);
     setPaidUpCapital(obj.paidUpCapital);
     setFaceValue(obj.face_value);
     setDate(moment(obj.date).format('DD-MM-YYYY'));
  },[])

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        <div className="card ">	
          <div className="">	
            <div className="card-header title-row">	
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                   sessionStorage.removeItem('securityEquityObj');
                      history.replace('/admin/securities')
                  }}></i>View - Equity Securities</h4>	
              <Link to="/admin/securities" className="t-3 ml-auto btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>	
            </div>	
            <div className="card-body">	
               <Row>	
            <Col md="4">
                  <div className="form-group">
                    <label>Company Name: <span className="color-amber">*</span></label>
                      <input 
                      type='text'
                      className={`form-control  w-100`}
                      value={companyName}/>
                    </div>
                  
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <label>Symbol: <span className="color-amber">*</span></label>

                      <input 
                      type='text'
                      className={`form-control  w-100`}
                      value={symbol}/>
                    </div>
                  
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <label>Sector</label>
                      <input
                        type="text"
                        className={`form-control  w-100`}
                        value={sectorCode}/>
                         
                    </div>
                </Col>	
            </Row>
            <Row>	
          
              <Col md="4">	
                <div className="form-group">	
                  <label>Open Rate</label>	
                  <div className="">	
                    <input className={`form-control  w-100 `}
                    value={openRate} />		
                  </div>	
                </div>	
              </Col>
              <Col md="4">
                  <div className="form-group">
                    <label>High Rate</label>

                      <input
                        type="number"
                        className='form-control'
                        value={highRate}/>
                        
                        
                    </div>
                </Col>	
                <Col md="4">
                  <div className="form-group">
                    <label>Low Rate</label>

                      <input
                        type="number"
                        className='form-control'
                        value={lowRate} />
                        
                        
                    </div>
                </Col>
            </Row>
            <Row>	
         
              <Col md="4">	
                <div className="form-group">	
                  <label>Close Rate</label>		
                    <input type='number' value={closeRate} className='form-control'/>	
                </div>	
              </Col>	
              <Col md="4">
                  <div className="form-group">
                    <label>Volume</label>
                    <input type='number' value={volume} className='form-control' />	
                  </div>
                </Col>

                <Col md="4">
                  <div className="form-group">
                    <label>LDCP</label>
                    <input type='number' value={ldcp} className='form-control' />	
                  </div>
                </Col>
            </Row>
            <Row>	
                <Col md="4">
                  <div className="form-group">
                    <label>Paid up Capital</label>
                    <input type='number' value={paidUpCapital} className='form-control' />
                        
                    </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <label>Face Value</label>
                    <input type='number' value={facevalue} className='form-control'/>
                        
                    </div>
                </Col>

                <Col md="4">
                  <div className="form-group">
                    <label>Date</label>
                    <input type='date' value={date} className='form-control'/>
                        
                    </div>
                </Col>
            </Row>
            	
            </div>	
            	
          </div>	
        </div>	
      </Container>	
      </Col>
        </Row>
      </div>
    </>	
  )	
};	
export default ViewEquitySecurity;