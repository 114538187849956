import axios from "axios";
import Config from "../../config";

export const getGeneralLedgerReport = async (
  ledger_code,
  fund_code,
  start_date,
  end_date
) => {
  const email = sessionStorage.getItem("email");
  const token = sessionStorage.getItem("token");
  const url = `${Config.baseUrl}/transaction/get-ledger-by-code-and-date?email=${email}&ledger_code=${ledger_code}&start_date=${start_date}&end_date=${end_date}&fund_code=${fund_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: { Authorization: token },
    });
    return result;
  } catch (error: any) {
    console.log(error);
  }
};

export const getDailyBalance = async (start_date, ledger_code, fund_code) => {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const url = `${Config.baseUrl}/transaction/get-daily-balance-by-fund-and-ledger?email=${email}&fund_code=${fund_code}&date=${start_date}&ledger_code=${ledger_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: { Authorization: token },
    });
    return result;
  } catch (error: any) {
    console.log(error);
  }
};
