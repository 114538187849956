// import React from 'react';
// // import Header from './../components/Header';
// import xls from "xlsx";
// import { useState, useRef } from 'react';
// import { useHistory, Link } from 'react-router-dom';
// import ReactTooltip from 'react-tooltip';
// import { Container, Row, Col } from 'reactstrap';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'; 
// import { uploadTransaction } from './../stores/services/beta-transaction.service';
// import {JsonToTable} from 'react-json-to-table';
// import { getFundByAmc } from './../stores/services/funds.service';
// import { getAccountByFund } from './../stores/services/account.service';
// import { Modal } from 'react-bootstrap';
// import moment from 'moment';
// import * as fs from 'fs';
// let csvToJson = require('convert-csv-to-json');
// const csv = require('csvtojson/v2');

// let xlsxToJson = require('convert-excel-to-json');
// // const xlsx = require('xlsx-to-json');

// const BulkUplaod = () => {
//   const history = useHistory();
//   const [loading, setLoading] = useState(false);
//   const [fileData, setfileData] = useState<any>([]);
//   const[fileDataCount, setFileDataCount]=useState(0);
//   const myRef1 = useRef<HTMLInputElement>(null);
//   const [fileName, setFileName] = useState('');
//   const [fileError, setFileError] = useState('');
//   const [file, setFile] = useState('');
//   const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
//   const email = sessionStorage.getItem('email') || '';

//   const [allFunds, setAllFunds] = useState<any>([]);
//   // const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
//   const [invalidTransactions, setInvalidTransactions] = useState<any>([]);
//   const [invalidTransactionsCount, setInvalidTransactionsCount] = useState(0);
//   const [totalFileData, setTotalFileData] = useState(0);
//   // const [fund, setFund] = useState('');
//   // const [fundAccount, setFundAccount] = useState('');
//   // const [accountTitle, setAccountTitle] = useState('');
//   // const [accountno, setAccountNo] = useState('');
//   // const [bank, setBank] = useState('');
//   // const [branch, setBranch] = useState('');
//   const amcCode = sessionStorage.getItem('amc_code') || '';
//   const [errorData, setErrorData] = useState<any>([]);
//   const [errorShow, setErrorShow] = useState(false);

//   React.useEffect(()=>{
//     const getFunds=async()=>{
//       try{
//           const response = await getFundByAmc(email, amcCode);
//           setAllFunds(response.data.data);
//       }catch(error){
  
//       }
//   }

//   getFunds();
// },[])

// // const getFunds=async(code:any)=>{
// //     try{
// //         const response = await getFundByAmc(email, code);
// //         setAllFunds(response.data.data);
// //     }catch(error){

// //     }
// // }

// // const renderFundsDropDown=()=>{
// //   return allFunds.map((item: any, index: number) => {
// //       return (
// //           <option key={index} value={item.symbol_code}>
// //           {item.fund_name} - {item.symbol_code}
// //           </option>
// //       );
// // });
// // }

// // const getFundAccounts=async(fund_code)=>{
// //   try{
// //     allFundAccounts.length=0;
// //     setAllFundsAccounts(allFundAccounts);
// //     const accResponse = await getAccountByFund(email, fund_code);
// //     setAllFundsAccounts(accResponse.data.data);
// //   }catch(error){

// //   }
// // }

// // const renderFundAccountsDropDown=()=>{
// //   return allFundAccounts.map((item: any, index: number) => {
// //       return (
// //           <option key={index} value={item.account_no}>
// //           {item.bank_name} ({item.account_title})
// //           </option>
// //       );
// // });
// // }

// //  const getFundAccountData=(accountno)=>{
// //   allFundAccounts.map((item, index) => {
// //       if (item.account_no === accountno) {
// //         setFundAccount(item.account_code);
// //         setAccountTitle(item.account_title);
// //         setAccountNo(item.account_no);
// //         setBank(item.bank_name);
// //         setBranch(item.branch_name);
// //       }  
// //   });

// //  };

//   //  //retrun error border class
//   //   const ErrorBorder = (value) => {
//   //     if (errorShow && value === '')  {
//   //       return 'required-border';
//   //     } else {
//   //       return '';
//   //     }
//   //   };

//   // here to upload the transation bulk upload
//   const UploadTxn = async () => {
//   //   if(fund==='' || fundAccount===''){
//   //     setErrorShow(true);
//   // }else{
//     if(fileData.length===0 || fileData===[] || fileName===''){
//       toast.error('Relevant transactions are not found.')
//       return;
//     }else{
//     if (file) {
//       setLoading(true);
//       try {
//         const res = await uploadTransaction(email, fileData);
//         if(res.data.message==="Transaction upload started"){
//           setFile('');
//           setFileName('');
//           setfileData(null);
//           setFileDataCount(0);
//           setfileData([]);
//           setFileDataCount(0);
//           setInvalidTransactionsCount(0);
//           setTotalFileData(0);
//           setFileName('');
//           setFileError('');
//           // setFund('');
//           // setFundAccount('');
//           // setAccountTitle('');
//           // setAccountNo('');
//           // setBank('');
//           // setBranch('');
//           // setBranch('');
//           toast.success('Transaction upload completed');
//           setErrorInvalidTxnsBoolean(false);
//         }else{
//           toast.error('Error! Transaction is not uploaded');
//         }
//       } catch (error:any) {
//         if(error.response.data.status==500){
//           toast.error(error.response.data.message);
//         }else if(error.response.data.status==400){
//           setErrorBoolean(true);
//           setErrorInvalidTxnsBoolean(false);
//         setErrorData(error.response.data.data);
//         }else{
//           toast.error(error.response.data.message);
//         }
//       }
//       setLoading(false);
//       setErrorInvalidTxnsBoolean(false);
//     } else {
//       toast.error('Kindly Upload File');
//     }
//   }
//   };

//   const upload = async (e: any) => {
//     setFileError('');
//     setfileData(null);
//     let file = e?.target.files[0];
//     let filesize=parseInt(file.size);
//     if(filesize<=2097152)
//     {
//       let type = file.name.substring(file.name.lastIndexOf('.') + 1);
//       // if (type === 'csv') {
//       //   //code here
//       //   setFile(file);
//       //   setFileName(file.name);
//       //   //start
//       //   let jsonarray = [];
//       //   csv()
//       //     .fromFile(file.path)
//       //     .then((jsonObj) => {
//       //       jsonarray = jsonObj;
//       //       jsonarray.forEach((element) => {});
//       //       setfileData(jsonarray);
//       //       setFileDataCount(jsonarray.length)
//       //     });
  
//       //   //end
//       // }else 
//       if(type==='xlsx' || type==='csv'){
//         setFile(file);
//         setFileName(file.name);
//         //start

//       const reader = new FileReader();
//     const rABS = !!reader.readAsBinaryString;
//     reader.onload = e => {
//       /* Parse data */
//       const bstr = e.target?.result;
//       const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
//       /* Get first worksheet */
//       const wsname = wb.SheetNames[0];
//       const ws = wb.Sheets[wsname];
//       /* Convert array of arrays */
//       const data = xls.utils.sheet_to_json(ws, { header: 2,
//         raw: false,
//         defval: "",
//         dateNF: 'YYYY-MM-DD', });
//       /* Update state */
     
//     //  let jsonarray:any = [];
//      if(data){
//       setTotalFileData(data.length);
//       let finalData:any = data.filter((item :any) => allFunds.some(el=>item.FundCode.trim()===el.symbol_code && amcCode===item.AmcCode.trim()));
//       if(finalData.length>0){
//         for (var i=0;i<finalData.length;i++){
//           // finalData[i].ExecutionDate = moment(data[i].ExecutionDate).format('YYYY-MM-DD');
//           // finalData[i].DividendDate = moment(data[i].DividendDate).format('YYYY-MM-DD');
//           // finalData[i]['realized'] ='true';
//           // finalData[i]['realized_date'] = moment(date).format('YYYY-MM-DD');
//           finalData[i]['InstructionDate'] = moment(finalData[i].InstructionDate).format('YYYY-MM-DD');
//           if(finalData[i]['SaleDate']!==undefined){
//             finalData[i]['SaleDate'] = moment(finalData[i].SaleDate).format('YYYY-MM-DD');
//           }
//           if(finalData[i]['RedemptionDate']!==undefined){
//             finalData[i]['RedemptionDate'] = moment(finalData[i].RedemptionDate).format('YYYY-MM-DD');
//           }
//         }
//       }

//       let invalidTxns = data.filter((item :any) => !allFunds.some(el=>item.FundCode.trim()===el.symbol_code  && amcCode===item.AmcCode.trim()));
//       setInvalidTransactionsCount(invalidTxns.length);
//        // return filterData;
       
//        setInvalidTransactions(invalidTxns);
//        if(invalidTxns.length>0){
//          setErrorInvalidTxnsBoolean(true);
//        }
//        setfileData(finalData);
//        setFileDataCount(finalData.length)
//      }
    
//     };
//     if (rABS) reader.readAsBinaryString(file);
//     else reader.readAsArrayBuffer(file);
//       // end 2
//     } else {
//         toast.error('Please Upload Correct Format of File');
//         setFileDataCount(0);
//       }
//     }else{
//       toast.error("File size should be less than 2MB")
//       setFileDataCount(0);
//     }
    
//   };

//   const [errorBoolean, setErrorBoolean] = useState(false);

//   const renderErrorPopup = () => {
//     switch (errorBoolean) {
//       case true:
//         return (
//           <Modal
//             className=" "
//             dialogClassName="modal60w"
//             show={true}
//           > 
//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setErrorBoolean(false);
//                 }}
//                 >
//                   <i className="tim-icons icon-simple-remove" />
//                 </button>
//                 <h6 className="title title-up">Kindly fill following fields data Missing Fields </h6>
//              </div>   

//             <div className="modal-body">
//               <div className="">
//                 <Row>
//                   {/* <div className="mx-auto">
//                     <h1 className="pb-3">Kindly fill following fields data</h1>
//                     Missing Fields
//                   </div> */}
//                   <div className={'d-flex border w-100'}>
//                     <div className={'w-50'}>Row Number</div>
//                     <div className={'w-50'}>Field Name</div>
//                   </div>
//                   {errorData.map((item, index) => {
//                     return (
//                       <div className={'d-flex w-100'}>
//                         <div className={'w-50'}>{item.row_number}</div>
//                         <div className={'w-50'}>{item.field_name}</div>
//                       </div>
//                     );
//                   })}
//                 </Row>
//               </div>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };

//   const [errorInvalidTxnsBoolean, setErrorInvalidTxnsBoolean] = useState(false);

//   const renderInvalidTransactionsPopup = () => {
//     switch (errorInvalidTxnsBoolean) {
//       case true:
//         return (
//           <Modal
//             className=" "
//             dialogClassName="modal60w"
//             size="lg"
//             show={true}
//           > 
//             <div className="modal-header ">
//               <button
//                 aria-hidden={true}
//                 className="close"
//                 data-dismiss="modal"
//                 type="button"
//                 onClick={() => {
//                   setErrorInvalidTxnsBoolean(false);
//                 }}
//                 >
//                   <i className="tim-icons icon-simple-remove" />
//                 </button>
//                 <h6 className="title title-up">Irrelevant Transactions</h6>
//              </div>   

//             <div className="modal-body">
//               <div className="">
//                 <span className='text-primary'>Note: {invalidTransactionsCount}</span> transactions are irrelevant out of <span className='text-primary'>{totalFileData}.</span>
//               <div className="table-responsive">
//               <JsonToTable json={invalidTransactions} />
//               </div>
//               </div>
//               <button className='btn btn-default'
//               onClick={()=>{
//                 setErrorInvalidTxnsBoolean(false);
//                 setFile('');
//                 setFileName('');
//                 setfileData(null);
//                 setFileDataCount(0);
//                 errorData.length = 0;
//                 setErrorData(errorData);
//                 setfileData([]);
//                 setInvalidTransactions([]);
//                 setTotalFileData(0);
//                 setFileName('');
//                 setFileError('');
//               }}>Cancel</button>
//               <button className='btn btn-primary'
//               onClick={()=>{
//                 UploadTxn();
//               }}
//               disabled={Boolean(loading)}
//               >
//                 {loading ? (
//                   <>
//                     <span
//                       className="spinner-border login-txt spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     <span className="login-txt"> Posting...</span>
//                   </>
//                 ) : (
//                   <span>Continue</span>
//                 )}</button>
//             </div>
//           </Modal>
//         );
//       default:
//         return '';
//     }
//   };


//   return (
//     <>
// <div className="content">
//   <Row>
//     <Col md="12">
//     <Container fluid>
//         <ToastContainer limit={1} />
//         <div className="card">
//           <div className="">
//             <div className="card-header">
//               <h4 className="card-title">

//               <Link to="/admin/create-transaction">
//                   <i className="fa fa-angle-left mr-2"></i>
//               </Link>

//               Transaction Bulk Upload</h4>
//             </div>
//             <div className="card-body">

//             {/* <Row>
//               <Col md="2">
//                 <div className="form-group">
//                   <label>Select Fund</label>
//                   <select
//                     className={"form-control " +
//                     ErrorBorder(fund)}
//                     onChange={(e) => {
//                         // setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
//                         setFund(e.target.value);
//                         getFundAccounts(e.target.value);
  
//                         // clear fields
//                         setFundAccount('');
//                         setAccountTitle('');
//                         setAccountNo('');
//                         setBank('');
//                         setBranch('');
//                     }}>
//                       <option value="" defaultChecked hidden>
//                       Select Fund
//                     </option>
//                       {renderFundsDropDown()}
//                   </select>
//                 </div>
//               </Col>

//               <Col md="2">
//                 <div className="form-group">
//                   <label>Select Fund Account</label>
//                   <select
//                     className={"form-control "+
//                     ErrorBorder(fundAccount)}
//                     onChange={(e) => {
//                         setFundAccount(e.target.value);
//                         getFundAccountData(e.target.value);
//                         // var AccountTitle=e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text;
//                         // setAccountTitle(AccountTitle.split('(')[1].replace(')',''));
//                     }}>
//                       <option value="" defaultChecked hidden>
//                       Select Fund Account
//                     </option>
//                     {renderFundAccountsDropDown()}
//                   </select>
//                 </div>
//               </Col>
//               </Row>
//               <Row>
//                   <Col>
//                   <label>Fund : {fund}</label>
//                     </Col>
//                     <Col>
//                     <label>Fund Acount : {fundAccount}</label>
//                     </Col>
//                     <Col>
//                     <label>Title : {accountTitle}</label>
//                     </Col>
//                     <Col>
//                     <label>Account No. : {accountno}</label>
//                     </Col>
//                     <Col>
//                     <label>Bank : {bank}</label>
//                     </Col>
//                     <Col>
//                     <label>Branch : {branch}</label>
//                     </Col>
//               </Row> */}

//                 <Row>
//                     <Col md="6">  
//                     <label>Choose File</label>
//               <div
//                 className="form-control multi-input ml-auto"
//                 onClick={() => myRef1?.current?.click()}
//               >
                
//                 <div className="form-group">
//                   <div className="fom-control ml-2">
//                     {fileName === '' ? 'Upload File' : fileName}
//                   </div>
//                   <input
//                   className="form-control"
//                     type="file"
//                     ref={myRef1}
//                     style={{ display: 'none' }}
//                     multiple={false}
//                     onChange={(e) => {
//                       upload(e);
//                     }}
//                   />
//                   {fileError ? (
//                     <p className="error-labels error-message2">{fileError}</p>
//                   ) : (
//                     ''
//                   )}

//                 </div>
//               </div>
//               {fileUploadingFlag && <img src="assets/loader.svg" width="50" />}

//               </Col>
//             </Row>
           
//             {fileDataCount<=0 ?(
//               ''
//             ):(
//               <div className="table-responsive">
//               <JsonToTable json={fileData}/>
//               </div>
//             )}
//           Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>
//             <div className="mt-4">
//               <button
//                 className="btn btn-primary"
//                 onClick={() => {
//                   UploadTxn();
//                 }}
//                 disabled={Boolean(loading)}
//               >
//                 {loading ? (
//                   <>
//                     <span
//                       className="spinner-border login-txt spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                     <span className="login-txt"> Loading...</span>
//                   </>
//                 ) : (
//                   <span>Upload</span>
//                 )}
//               </button>
//             </div>
//             </div>
//           </div>
//         </div>
//         {renderErrorPopup()}
//         {renderInvalidTransactionsPopup()}
//       </Container>
    
//     </Col>
//   </Row>
// </div>

    
    
//     </>
//   );
// };

// export default BulkUplaod;



import React from 'react';
// import Header from './../components/Header';
import xls from "xlsx";
import { useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Container, Row, Col } from 'reactstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { uploadTransaction } from './../stores/services/beta-transaction.service';
import {JsonToTable} from 'react-json-to-table';
import { getFundByAmc } from './../stores/services/funds.service';
import { getAccountByFund } from './../stores/services/account.service';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import * as fs from 'fs';
let csvToJson = require('convert-csv-to-json');
const csv = require('csvtojson/v2');

let xlsxToJson = require('convert-excel-to-json');
// const xlsx = require('xlsx-to-json');

const BulkUplaod = () => {
  const history = useHistory();
  const amcCode = sessionStorage.getItem('amc_code') || '';

   const [loading, setLoading] = useState(false);
  const [fileData, setfileData] = useState<any>([]);
  const[fileDataCount, setFileDataCount]=useState(0);
  const myRef1 = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [file, setFile] = useState('');
  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);
  const email = sessionStorage.getItem('email') || '';

  const [allFunds, setAllFunds] = useState<any>([]);
  const [invalidTransactions, setInvalidTransactions] = useState<any>([]);
  const [invalidTransactionsCount, setInvalidTransactionsCount] = useState(0);
  const [totalFileData, setTotalFileData] = useState(0);
  
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [fund, setFund] = useState('');
  const [fundAccount, setFundAccount] = useState('');
  const [accountTitle, setAccountTitle] = useState('');
  const [accountno, setAccountNo] = useState('');
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');

  const [errorData, setErrorData] = useState<any>([]);
  const [errorShow, setErrorShow] = useState(false);

  const [errorInvalidTxnsBoolean, setErrorInvalidTxnsBoolean] = useState<boolean>(false);

  React.useEffect(()=>{
    const getFunds=async()=>{
      try{
          const response = await getFundByAmc(email, amcCode);
          setAllFunds(response.data.data);
      }catch(error){
  
      }
  }

  getFunds();
},[])

const getFunds=async(code:any)=>{
    try{
        const response = await getFundByAmc(email, code);
        setAllFunds(response.data.data);
    }catch(error){

    }
}

const renderFundsDropDown=()=>{
  return allFunds.map((item: any, index: number) => {
      return (
          <option key={index} value={item.symbol_code}>
          {item.fund_name} - {item.symbol_code}
          </option>
      );
});
}

const getFundAccounts=async(fund_code)=>{
  try{
    allFundAccounts.length=0;
    setAllFundsAccounts(allFundAccounts);
    const accResponse = await getAccountByFund(email, fund_code);
    setAllFundsAccounts(accResponse.data.data);
  }catch(error){

  }
}

const renderFundAccountsDropDown=()=>{
  return allFundAccounts.map((item: any, index: number) => {
      return (
          <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
          </option>
      );
});
}

 const getFundAccountData=(accountno)=>{
  allFundAccounts.map((item, index) => {
      if (item.account_no === accountno) {
        setFundAccount(item.account_code);
        setAccountTitle(item.account_title);
        setAccountNo(item.account_no);
        setBank(item.bank_name);
        setBranch(item.branch_name);
      }  
  });

 };

   //retrun error border class
    const ErrorBorder = (value) => {
      if (errorShow && value === '')  {
        return 'required-border';
      } else {
        return '';
      }
    };

  // here to upload the transation bulk upload
  const UploadTxn = async () => {
    if(fund==='' || fundAccount===''){
      setErrorShow(true);
  }else if(fileData.length===0 || fileData===[] || fileName===''){
      toast.error('Relevant transactions are not found.')
      return;
    }else{
    if (file) {
      setLoading(true);
      try {
        const res = await uploadTransaction(email, fileData);
        if(res.data.message==="Transaction upload started"){
          // setFile('');
          // setFileName('');
          // setfileData(null);
          // setFileDataCount(0);
          // setfileData([]);
          // setFileDataCount(0);
          // setInvalidTransactionsCount(0);
          // setTotalFileData(0);
          // setFileName('');
          // setFileError('');
          // setFund('');
          // setFundAccount('');
          // setAccountTitle('');
          // setAccountNo('');
          // setBank('');
          // setBranch('');
          // setBranch('');
          setErrorInvalidTxnsBoolean(false);
          setFile('');
          setFileName('');
          setfileData(null);
          setFileDataCount(0);
          toast.success('Transaction upload completed');
          errorData.length = 0;
          setErrorData(errorData);
          setfileData([]);
          setFileName('');
          setFileError('');
          setFund('');
          setFundAccount('');
          setAccountTitle('');
          setAccountNo('');
          setBank('');
          setBranch('');
          toast.success('Transaction upload completed');
          // setErrorInvalidTxnsBoolean(false);
        }else{
          toast.error('Error! Transaction is not uploaded');
        }
      } catch (error:any) {
        setLoading(false);
        if(error.response.data.status==500){
          toast.error(error.response.data.message);
        }else if(error.response.data.status==400){
          setErrorBoolean(true);
          setErrorInvalidTxnsBoolean(false);
        setErrorData(error.response.data.data);
        }else{
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
      setErrorInvalidTxnsBoolean(false);
    } else {
      toast.error('Kindly Upload File');
    }
  }
  };

  const upload = async (e: any) => {
    setFileError('');
    setfileData(null);
    let file = e?.target.files[0];
    let filesize=parseInt(file.size);
    if(filesize<=2097152)
    {
      let type = file.name.substring(file.name.lastIndexOf('.') + 1);
      if(type==='xlsx' || type==='csv'){
        setFile(file);
        setFileName(file.name);
        //start

      const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data:any = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD', });
      /* Update state */
     
    //  let jsonarray:any = [];
     if(data.length>0){
      for (var i=0;i<data.length;i++){
        // data[i].ExecutionDate = moment(data[i].ExecutionDate).format('YYYY-MM-DD');
        // data[i].ExecutionDate = moment(data[i].ExecutionDate).format('YYYY-MM-DD');
        // finalData[i].DividendDate = moment(data[i].DividendDate).format('YYYY-MM-DD');
        // finalData[i]['realized'] ='true';
        // finalData[i]['realized_date'] = moment(date).format('YYYY-MM-DD');
        data[i]['AmcCode'] = amcCode;
        data[i]['FundCode'] = fund;
        data[i]['FundAccount'] = fundAccount;
        data[i]['AccountTitle'] = accountTitle;
        data[i]['AccountNumber'] = accountno;
        data[i]['Bank'] = bank;
        data[i]['Branch'] = branch;
        data[i]['InstructionDate'] = moment(data[i].InstructionDate).format('YYYY-MM-DD');
        if(data[i]['SaleDate']!==undefined){
          data[i]['SaleDate'] = moment(data[i].SaleDate).format('YYYY-MM-DD');
        }
        if(data[i]['RedemptionDate']!==undefined){
          data[i]['RedemptionDate'] = moment(data[i].RedemptionDate).format('YYYY-MM-DD');
        }
       
      }
      setfileData(data);
      setFileDataCount(data.length)
     }
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
      // end 2
    } else {
        toast.error('Please Upload Correct Format of File');
        // setFileDataCount(0);
      }
    }else{
      toast.error("File size should be less than 2MB")
      // setFileDataCount(0);
    }
    
  };

  const [errorBoolean, setErrorBoolean] = useState(false);

  const renderErrorPopup = () => {
    switch (errorBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            show={true}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorBoolean(false);
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Kindly fill following fields data Missing Fields </h6>
             </div>   

            <div className="modal-body">
              <div className="">
                <Row>
                  {/* <div className="mx-auto">
                    <h1 className="pb-3">Kindly fill following fields data</h1>
                    Missing Fields
                  </div> */}
                  <div className={'d-flex border w-100'}>
                    <div className={'w-50'}>Row Number</div>
                    <div className={'w-50'}>Field Name</div>
                  </div>
                  {errorData.map((item, index) => {
                    return (
                      <div className={'d-flex w-100'}>
                        <div className={'w-50'}>{item.row_number}</div>
                        <div className={'w-50'}>{item.field_name}</div>
                      </div>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  // const [errorInvalidTxnsBoolean, setErrorInvalidTxnsBoolean] = useState(false);

  const renderInvalidTransactionsPopup = () => {
    switch (errorInvalidTxnsBoolean) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal60w"
            size="lg"
            show={true}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setErrorInvalidTxnsBoolean(false);
                }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Irrelevant Transactions</h6>
             </div>   

            <div className="modal-body">
              <div className="">
                <span className='text-primary'>Note: {invalidTransactionsCount}</span> transactions are irrelevant out of <span className='text-primary'>{totalFileData}.</span>
              <div className="table-responsive">
              <JsonToTable json={invalidTransactions} />
              </div>
              </div>
              <button className='btn btn-default'
              onClick={()=>{
                setErrorInvalidTxnsBoolean(false);
                setFile('');
                setFileName('');
                setfileData(null);
                setFileDataCount(0);
                errorData.length = 0;
                setErrorData(errorData);
                setfileData([]);
                setInvalidTransactions([]);
                setTotalFileData(0);
                setFileName('');
                setFileError('');
              }}>Cancel</button>
              <button className='btn btn-primary'
              onClick={()=>{
                UploadTxn();
              }}
              disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Posting...</span>
                  </>
                ) : (
                  <span>Continue</span>
                )}</button>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  return (
    <>
<div className="content">
  <Row>
    <Col md="12">
    <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="">
            <div className="card-header">
              <h4 className="card-title">

              <Link to="/admin/create-transaction">
                  <i className="fa fa-angle-left mr-2"></i>
              </Link>

              Transaction Bulk Upload
              <Dropdown className="float-right">
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                  Download Samples
                </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="./../../Uploader-Sample/Sale-of-unit.xlsx" download>Sale of Units</Dropdown.Item>
                <Dropdown.Item href="./../../Uploader-Sample/Redemption-of-unit.xlsx" download>Redemption of Units</Dropdown.Item>
                <Dropdown.Item href="./../../Uploader-Sample/Conversion-in.xlsx" download>Conversion In</Dropdown.Item>
                <Dropdown.Item href="./../../Uploader-Sample/Conversion-out.xlsx" download>Conversion Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown></h4>
            </div>
            <div className="card-body">

            <Row>
              <Col md="2">
                <div className="form-group">
                  <label>Select Fund</label>
                  <select
                    className={"form-control " +
                    ErrorBorder(fund)}
                    onChange={(e) => {
                        // setFund(e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text)
                        setFund(e.target.value);
                        getFundAccounts(e.target.value);
  
                        // clear fields
                        setFundAccount('');
                        setAccountTitle('');
                        setAccountNo('');
                        setBank('');
                        setBranch('');
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund
                    </option>
                      {renderFundsDropDown()}
                  </select>
                </div>
              </Col>

              <Col md="2">
                <div className="form-group">
                  <label>Select Fund Account</label>
                  <select
                    className={"form-control "+
                    ErrorBorder(fundAccount)}
                    onChange={(e) => {
                        setFundAccount(e.target.value);
                        getFundAccountData(e.target.value);
                        // var AccountTitle=e.nativeEvent.target==null ? '' :e.nativeEvent.target[e.target.selectedIndex].text;
                        // setAccountTitle(AccountTitle.split('(')[1].replace(')',''));
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Fund Account
                    </option>
                    {renderFundAccountsDropDown()}
                  </select>
                </div>
              </Col>
              </Row>
              <Row>
                  <Col>
                  <label>Fund : {fund}</label>
                    </Col>
                    <Col>
                    <label>Fund Acount : {fundAccount}</label>
                    </Col>
                    <Col>
                    <label>Title : {accountTitle}</label>
                    </Col>
                    <Col>
                    <label>Account No. : {accountno}</label>
                    </Col>
                    <Col>
                    <label>Bank : {bank}</label>
                    </Col>
                    <Col>
                    <label>Branch : {branch}</label>
                    </Col>
              </Row>

                <Row>
                    <Col md="6">  
                    <label>Choose File</label>
              <div
                className="form-control multi-input ml-auto"
                onClick={() => myRef1?.current?.click()}
              >
                
                <div className="form-group">
                  <div className="fom-control ml-2">
                    {fileName === '' ? 'Upload File' : fileName}
                  </div>
                  <input
                  className="form-control"
                    type="file"
                    ref={myRef1}
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={(e) => {
                      upload(e);
                    }}
                  />
                  {fileError ? (
                    <p className="error-labels error-message2">{fileError}</p>
                  ) : (
                    ''
                  )}

                </div>
              </div>
              {fileUploadingFlag && <img src="assets/loader.svg" width="50" />}

              </Col>
            </Row>
           
            {fileDataCount<=0 ?(
              ''
            ):(
              <div className="table-responsive">
              <JsonToTable json={fileData}/>
              </div>
            )}
          Total Rows: <span className="text-primary mt-3">{fileDataCount} </span>
            <div className="mt-4">
              <button
                className="btn btn-primary"
                onClick={() => {
                  UploadTxn();
                }}
                disabled={Boolean(loading)}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Upload</span>
                )}
              </button>
            </div>
            </div>
          </div>
        </div>
        {renderErrorPopup()}
        {renderInvalidTransactionsPopup()}
      </Container>
    
    </Col>
  </Row>
</div>
    </>
  );
};

export default BulkUplaod;

