

import React from 'react';
import { useState } from 'react';
import { searchAPI } from './../stores/services/amc.service';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  getFundByAmc,
  getInstrumentType,
} from './../stores/services/funds.service';
import {
  getModeOfPayments,
} from './../stores/services/transactions.service';
import {
  getAccountByFund,
} from './../stores/services/account.service';
import { getAllBanks } from './../stores/services/bank.service';
import { getBranchByBankName } from './../stores/services/branch.service';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from './field';
import {
  addMegaTransaction,
  EditTransaction,
} from './../stores/services/beta-transaction.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllPsx,
  getAllSecurities,
} from './../stores/services/security.service';
import {	
  inWords	
} from './../stores/services/template.service';
import { IoIosClose } from 'react-icons/io';
import { getBrokerByType } from './../stores/services/broker.service';
import { getTaxAmount, getTaxType } from './../stores/services/tax.service';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {numberWithCommas,accountTitle_AccountNumber_Regex, getLedgerAccounts,
 getLedgersByTxn, getAllLedgers, getLedgersByTxnAndPaymentype} from './../utils/customFunction';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";
import {getChartOfAccounts, getTransactionsLedgers, getLedgerInfoByCode} from "../stores/services/chatofaccounts.service";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import './../css/autocomplete.css';
import xls from "xlsx";
import { ImTwitter } from 'react-icons/im';

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type:string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized:string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst:string;
  tax_wht_it:string;
  tax_wht_sst:string;
  tax_cgt:string;
  sale_load:string;
  redemption_load:string;
  low_balance_agree: string;
  offer_price:string;
  load_per_unit:string;
  percentage_of_discount:string;
  applicable_offer_price:string;
  sales_tax_collector: string;
  volume:string;
  par_value:string;
  announcement_date: string; 
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend:string;
  tax_on_bonus:string;
  tax_on_right:string;
  zakat_amount:string;
  ledgers:string;
  average_cost_amount:string;
  gain_loss_amount:string;
  total_face_value:string;
  accrued_amount:string;
  premium_discount:string;
  brokerage:string;
  principal_amount:string;
}

const InvestmentTransactions = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [headLabel, setHeadLabel] = React.useState('');
  const tx = sessionStorage.getItem('rejectedTxName') || '';
  const amcEdit = sessionStorage.getItem('rejectedAmc') || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState('');
  const [accLoading, setAccLoading] = useState(false);
  const paymentType = sessionStorage.getItem('payment_type') || '';

  const [psxList, setPsxList] = useState<any>([]);
  const [stockMarketDataFund, setStockMarketDataFund] = useState<any>([]); // PSX data of fund
  const [moneyMarketDataFund, setMoneyMarketDataFund] = useState<any>([]); // money market data of fund
  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const amcName = sessionStorage.getItem('amc_name') || '';
  // type
  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response
  const [primaryDealerData, setPrimaryDealerData] = useState<any>([]); // Money Maket Securities Hook - primaryDealerData

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data
  const [reinest, setReinvest] = useState<boolean>(false);

  const [fundCDCParticipantID, setCDCParticipantID] = useState('');
  const [pledged_holding, setPledgedHolding] = useState('');
  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  const [excelData , setExcelData] = useState<any>([]);
  const [excelFile, setExcelFile] = useState('');
  const [excelFileName, setExcelFileName] = useState('');

  const [ledgerCode, setLedgerCode] = useState(0);
  const [ledgerName, setLedgerName] = useState('');
  const [subFamilyName, setSubFamilyName] = useState('');
  const [subFamilyCode, setSubFamilyCode] = useState(0);


  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === 'Fund') {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
       // sort data
       let sortedData:any=[]; 
       sortedData = response.data.data.sort(function(a,b){
         let x = a.nature.toLowerCase();
         let y = b.nature.toLowerCase();
         if(x>y){return 1;}
         if(x<y){return -1;}
         return 0;
       });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if(val.nature==='Special Managed Account'){
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
            value: val.symbol_code,
          };
        }else{
        return {
          ...val,
          label: `${val.fund_name} (${val.symbol_code})`,
          value: val.symbol_code,
        };
      }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) {}
   
  };


  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item:any) => {
      if (item.symbol_code === fund_code) {
        fields['nav'] = item.nav;
        fields['face_value']=item.face_value;
           
        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== '' &&
          item.stock_market !== '[]' &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // label: `${val.account_no} (${val.account_code})`,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem('rejectedFlag') || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);
  const [unitsReadOnly, setUnitsReadOnly] = useState(false);
  const [discount_applicable_ReadOnly, setDiscount_Applicable_ReadOnly] = useState(false);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields['txnr_type'] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === 'Other') {
      fields['counter_account_type'] = 'Other';
      setFields(fields);
    }
    if(props.match.params.type==='debtmarketinvestment' || props.match.params.type==='equityinvestment'){
      setGrossAmountReadOnly(true);
      setNetAmountReadOnly(true);
    }
  }, [props.match.params.type]);

  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates=moment(date).format('YYYY-MM-DD');
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: '',
    amc_code: loggedInUser.amc_code,
    dividend_date: '',
    dividend_rate: '',
    fund_code: '',
    reinvest: 'false',
    instruction_date: moment(date).format('YYYY-MM-DD'),
    execution_date: moment(date).format('YYYY-MM-DD'),
    executed_date: moment(date).format('YYYY-MM-DD'),
    fund_account: '',
    account_title: '',
    account_no: '',
    bank: '',
    branch: '',
    counter_account_type: '',
    counter_type:'',
    counter_account_title: '',
    counter_account_number: '',
    counter_bank: '',
    counter_branch: '',
    mode_of_payment: '',
    payment_type: '',
    instrument_no: '',
    instrument_type: '',
    instrument_date: moment(date).format('YYYY-MM-DD'),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format('YYYY-MM-DD'),
    realized:'true',
    gross_amount: '',
    net_amount: '',
    dr_amount: '',
    period: '',
    cr_amount: '',
    balance: '',
    folio_no: '',
    unitholder_name: '',
    units: '',
    nav: '',
    sale_date: moment(date).format('YYYY-MM-DD'),
    current_holding: '0',
    total_holding: '',
    symbol: '',
    dividend_percentage: '',
    credit_date: '',
    maturity_type: '',
    security_type: '',
    issue_date: moment(date).format('YYYY-MM-DD'),
    conversion_date: moment(date).format('YYYY-MM-DD'),
    associated_transaction: '',
    maturity_date: moment(date).format('YYYY-MM-DD'),
    coupon_rate: '',
    price: '',
    face_value: '',
    money_market_face_value: '',
    detail: '',
    type: '',
    redemption_date: moment(date).format('YYYY-MM-DD'),
    remain_holding: '0',
    settlement_by: '',
    tax_type: '',
    settlement_date: moment(date).format('YYYY-MM-DD'),
    total_charges: '0',
    txn_charges: '',
    sale_txn_no: '',
    return_date: '',
    txn_id: '',
    last_coupon_date: moment(date).format('YYYY-MM-DD'),
    next_coupon_date: moment(date).format('YYYY-MM-DD'),
    yield: '',
    tenor: '',
    fund_ips_account: '',
    counter_party_ips_account: '',
    system_tax: '',
    primary_dealer:'',
    broker_commission:'',
    tax_sst:'',
    tax_wht_it:'',
    tax_wht_sst:'',
    tax_cgt:'0',
    sale_load:'',
    redemption_load:'0',
    low_balance_agree:'',
    offer_price:'',
    load_per_unit:'',
    percentage_of_discount:'0',
    applicable_offer_price:'',
    sales_tax_collector:'SRB', // default value SRB
    volume:'',
    par_value:'',
    announcement_date:'',     // Dividend on Investment
    bonus_percentage:'',
    bonus_credit_date:'',
    bonus_gross_volume:'',
    tax_it_bonus_amount:'',
    net_bonus_volume:'',
    right_share_percentage:'',
    right_share_credit_date:'',
    right_share_gross_volume:'',
    tax_it_right_share_amount:'',
    net_right_share_volume:'',
    tax_on_dvidend:'',
    tax_on_bonus:'',
    tax_on_right:'',
    zakat_amount:'0',
    ledgers:'',
    average_cost_amount:'0',
    gain_loss_amount:'0',
    total_face_value:'0',
    accrued_amount:'0',
    premium_discount:'0',
    brokerage:'0',
    principal_amount:'0',
  });

  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [ledgeroptions, setLedgerOptions] = useState<any>([]);

  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [ledgerAccountCode, setLegderAccountCode] = useState('');
  const [ledgerAccountName, setLegderAccountName] = useState('');
  const [allTransactionLedgers, setAllTransactionLedgers] = useState<any>([]);

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{
    amc_code:amc_code,
    fund_code:fields['fund_code'],
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    child_family_code:'',
    child_family_name:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'',
    debit_amount:'',
    description:'',
    entered_by:'',
    evidence:'',
    family_code:'',
    family_name:'',
    gl_code:'',
    gl_name:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    main_family_code:'',
    main_family_name:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    sub_family_code:'',
    sub_family_name:'',
  }]);
  
  const [totalDebit, setTotalDebit] = useState('0');
  const [totalCredit, setTotalCredit] = useState('0');
  const [balance, setBalance] = useState('0');

  const [selectedLedgerCode, setSelectedLedgerCode] = useState('');
  const [selectedInputLedgerCode, setSelectedInputLedgerCode] = useState('');
  const [selectedLedgerName, setSelectedLedgerName] = useState('');
  const [ledgerType, setLedgerType] = useState('');
  const [ledgerAmount, setLedgerAmount] = useState('');
  const [ledgerDescription, setLedgerDescription] = useState('');
  const [ledgerInvoice, setLedgerInvoice] = useState('');
  const [ledgerAmountType, setLedgerAmountType] = useState('');

  //code for edit transaction
  let [count, setCount] = useState(0);
  const [PIB_fields, setPIBFields] = useState(false);
  const [securityCheck, setSecurityCheck] = useState('');

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem('rejectedTxObj') || ' ');
      getFundByAMcCode(obj.amc_code);
      fields['payment_type'] = obj.payment_type || '';
      fields['txnr_type'] = obj.txnr_type || '';
      fields['amc_code'] = obj.amc_code || '';
      getAccountByFundName(obj.fund_code);
      fields['fund_code'] = obj.fund_code || '';
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields['instruction_date'] = obj.instruction_date || '';
      fields['execution_date'] = obj.execution_date || '';
      fields['executed_date'] = obj.executed_date || '';
      fields['fund_account'] = obj.fund_account || '';
      fields['dividend_date'] =
        obj.dividend_date || '';
      fields['dividend_rate'] = obj.dividend_rate || '';
      fields['reinvest'] = obj.reinvest || '';
      fields['period'] = obj.period || '';
      fields['account_title'] = obj.account_title || '';
      fields['account_no'] = obj.account_number || '';
      fields['bank'] = obj.bank || '';
      fields['branch'] = obj.branch || '';
      fields['counter_account_type'] = obj.counter_account_type || '';
      fields['counter_type'] = obj.counter_type || '';
      fields['counter_account_title'] = obj.counter_account_title || '';
      fields['counter_account_number'] = obj.counter_account_number || '';
      fields['counter_bank'] = obj.counter_bank || '';
      fields['counter_branch'] = obj.counter_branch || '';
      fields['mode_of_payment'] = obj.mode_of_payment || '';
      fields['instrument_no'] = obj.instrument_no || '';
      fields['instrument_type'] = obj.instrument_type || '';
      fields['instrument_date'] = obj.instrument_date || '';
      fields['realized_date'] = obj.realized_date || '';
      fields['realized'] = obj.realized || '';
      fields['gross_amount'] = obj.gross_amount.toString() || '';
      fields['net_amount'] = obj.net_amount.toString() || '';
      fields['dr_amount'] = '0';
      fields['cr_amount'] = '0';
      fields['balance'] = obj.balance.toString() || '';
      fields['folio_no'] = obj.folio_no || '';
      fields['unitholder_name'] = obj.unitholder_name || '';
      fields['units'] = obj.units.toString() || '';
      fields['nav'] = obj.nav.toString() || '';
      fields['sale_date'] = obj.sale_date || '';
      fields['current_holding'] = obj.current_holding.toString() || '';
      fields['total_holding'] = obj.total_holding.toString() || '';
      fields['symbol'] = obj.symbol || '';
      fields['dividend_percentage'] = obj.dividend_percentage.toString() || '';
      fields['credit_date'] = obj.credit_date || '';
      fields['maturity_type'] = obj.maturity_type || '';
      fields['security_type'] = obj.security_type || '';
      fields['issue_date'] = obj.issue_date || '';
      fields['conversion_date'] = obj.conversion_date || '';
      fields['associated_transaction'] = obj.associated_transaction || '';
      fields['maturity_date'] = obj.maturity_date || '';
      fields['coupon_rate'] = obj.coupon_rate || '';
      fields['price'] = obj.price.toString() || '';
      fields['face_value'] = obj.face_value.toString() || '';
      fields['money_market_face_value'] = obj.money_market_face_value.toString() || '';
      fields['detail'] = obj.detail || '';
      fields['type'] = obj.type || '';
      if (obj.reinvest === 'true') {
        setReinvest(true);
      } else {
        setReinvest(false);
      }
      fields['redemption_date'] = obj.redemption_date || '';
      fields['remain_holding'] = obj.remain_holding.toString() || '';
      fields['settlement_by'] = obj.settlement_by || '';
      fields['tax_type'] = obj.tax_type || '';
      fields['settlement_date'] = obj.settlement_date || '';
      fields['total_charges'] = obj.total_charges.toString() || '';
      fields['sale_txn_no'] = obj.sale_txn_no || '';
      fields['return_date'] = obj.return_date || '';
      fields['trx_id'] = obj.txn_id || '';
      fields['primary_dealer'] = obj.primary_dealer;
      fields['yield'] = obj.yield || '';
      if(headLabel==='advisoryfee' || headLabel==='auditorsfee' || headLabel==='managementfee' || headLabel==='trusteefee' || headLabel==='custodyfee' 
      || headLabel==='shariahadvisoryfee' || headLabel==='cdcfee' || headLabel==='listingfee' || headLabel==="listingfee" || headLabel==="brokagefee" ||
      headLabel==='printingfee' || headLabel==='professionalfee' || headLabel==='ratingfee' || headLabel==='taxpayment' || headLabel==='secpfee' || headLabel==='otherpayment'){
      fields['tax_sst']=obj.tax_sst || '';
      fields['tax_wht_it']=obj.tax_sst || '';
      fields['tax_wht_sst']=obj.tax_sst || '';
      fields['sales_tax_collector']=obj.sales_tax_collector || '';
      }

      if(headLabel==='' || headLabel===''){
        fields['sale_load']=obj.sale_load || '';
      }

      if(headLabel==='equityinvestment'){
        fields['broker_commission']=obj.broker_commission || '';
        fields['tax_sst']=obj.tax_sst || '';
        fields['sales_tax_collector']=obj.sales_tax_collector || '';
      }

      if(headLabel==='' || headLabel===''){
        fields['redemption_load']=obj.redemption_load || '';
        fields['tax_cgt']=obj.tax_cgt || '';
      }
      if (headLabel === 'moneymarketpurchase' || headLabel === 'saleofsecurities') {
        if (obj.security_type === 'PIB') {
          setPIBFields(true);
          fields['last_coupon_date'] = obj.last_coupon_date || '';
          fields['next_coupon_date'] = obj.next_coupon_date || '';
          fields['tenor'] = obj.tenor || '';
        }
      }
      fields['zakat_amount'] = obj.zakat_amount;
      fields['txn_status'] = 'CREATED';

      setSecurites(obj.securities);
        // Dividend On Investment
        fields['volume'] = obj.volume;
        fields['par_value'] = obj.par_value;
        fields['announcement_date '] = obj.announcement_date ;
        fields['bonus_percentage'] = obj.bonus_percentage;
        fields['bonus_credit_date'] = obj.bonus_credit_date;
        fields['bonus_gross_volume'] = obj.bonus_gross_volume;
        fields['tax_it_bonus_amount'] = obj.tax_it_bonus_amount;
        fields['net_bonus_volume'] = obj.net_bonus_volume;
        fields['right_share_percentage'] = obj.right_share_percentage;
        fields['right_share_credit_date'] = obj.right_share_credit_date;
        fields['right_share_gross_volume'] = obj.right_share_gross_volume;
        fields['tax_it_right_share_amount'] = obj.tax_it_right_share_amount;
        fields['net_right_share_volume'] = obj.net_right_share_volume;
        fields['tax_on_dvidend'] = obj.tax_on_dvidend;
        fields['tax_on_bonus'] = obj.tax_on_bonus;
        fields['tax_on_right'] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields['fund_ips_account'] = obj.fund_ips_account || '';
      fields['counter_party_ips_account'] = obj.counter_party_ips_account || '';
      fields['low_balance_agree']=''
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
 
  React.useEffect(() => {
    if (!flag) {
      fields['payment_type'] = paymentType;
      fields['email'] = email;
      fields['amc_code'] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);
  
      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {
       
      }

      try{
        const response = await getChartOfAccounts(email);
        if(response.data.status==200){
         let ledgersacc = getLedgerAccounts(response.data.data.main_family[0].family[3].sub_family);
         setLegderAccounts(ledgersacc);
         let allLedgers = await getAllLedgers(response.data.data.main_family);
         setAllLedgers(allLedgers);
         await ledgerDropDown(allLedgers);
      }else{
        toast.error(response.data.message);
      }
      }catch(error){

      }


       // get all transaction ledgers
      
       try{
        const response = await getTransactionsLedgers(email);
        setAllTransactionLedgers(response.data.data.txn_catagory[2].investmentTxn)
        if(props.match.params.type=='bankprofit' || props.match.params.type=='cashmargin' || props.match.params.type=='marginreturn'){
        if(response.data.status==200){
          let investmentTxn = response.data.data.txn_catagory[2].investmentTxn;
           let txnLedgers =  await getLedgersByTxn(investmentTxn, props.match.params.type);
           console.log('txnLegders',txnLedgers)
           setTransactionLedgers(txnLedgers);
           // console.log('txnLegders',transactionLedgers)
        }else{
           toast.error(response.data.message);
        }
      }
        }catch(error){

        }

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: 'Other',
          label: 'Other',
        });
        setAmcdataCounter(temp);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }
      }
     
      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace('BANK_','')} (${val.bank_name})`,
            value: val.bank_code.replace('BANK_',''),
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(temp);
      } catch (error) {}
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) {}
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) {}

      if(props.match.params.type==='equityinvestment'){
      //get Broker data
      try {
        const response = await getAllPsx(email);
        let sortedData = response.data.data.sort(function(a,b){
          let x = a.company_name.toLowerCase();
          let y = b.company_name.toLowerCase();
          if(x>y){return 1;}
          if(x<y){return -1;}
          return 0;
        });
        // setPsxList(response.data.data);
        setPsxList(sortedData);
      } catch (error) {}
    }

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) {}
      

      if(props.match.params.type==='taxpayment'){
      //get tax type data
      try {
        const taxTypeRes = await getTaxType(email);
        setTaxData(taxTypeRes.data.tax_names);
      } catch (error) {}
    }

    if(props.match.params.type==='debtmarketinvestment'){
      //get security data
      try {
        setMMSecurityLoading(true);
        const res = await getAllSecurities(email);
        setSecurityTypeData(res.data.data);
        setMMSecurityLoading(false);
      } catch (error) {}
      //API all to get Broker Primary Dealer
      try {
        const res = await getBrokerByType(email, 'Primary Dealer');
        setPrimaryDealerData(res.data.data);
      } catch (error) {}
    }

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if(item.nature==='Special Managed Account'){
        return (
          <>
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code}) - {'SMA'}
          </option>
          </>
        );
      }else{
      return (
        <option key={index} value={item.symbol_code}>
         {item.fund_name} ({item.symbol_code}) 
        </option>
      );
    }
    });
  };

  const ledgerDropDown = (allLedgerData)=>{
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.name}`,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.bank}`,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
          },
        ]);
      }
    });
  }

  const renderFundAccounts = () => {
    return (
      ledgerAccounts && ledgerAccounts.map((item: any, index: number) => {
      //   if(item.plsName==='PLS ACCOUNTS'){
      //   return (
      //     <optgroup style={{
      //       color:'black'
      //     }} label="PLS ACCOUNTS">
      //     <option key={index} value={item.code}>
      //       {item.account_title}
      //     </option>
      //     </optgroup>
      //   );
      // }else{
      //   return (
      //     <optgroup style={{
      //       color:'black'
      //     }}  label="CURRENT ACCOUNTS">
      //     <option key={index} value={item.code}>
      //       {item.account_title}
      //     </option>
      //     </optgroup>
      //   );

      // }
      return (
      <option key={index} value={item.code}>
            {item.account_title}
          </option>
      );
      })
    );
  };


  const getFundMoneyMarketPortfolioData=(fund_code, security_type)=>{
    const s=allFunds.filter((item: any, index: number) => {
     return (fund_code===item.symbol_code)
      });
      let data =JSON.parse(s[0].money_market);
      let securitiesData = data.filter((item:any, index:number)=>{
          return security_type===item.security_type;
      });
      setMoneyMarketDataFund(securitiesData);
    };

  const getFundStockMarketData=(code)=>{
    const s=allFunds.filter((item: any, index: number) => {
     return (code===item.symbol_code)
      });
      setStockMarketDataFund(JSON.parse(s[0].stock_market))
    };
  

  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

   //render dropdown for primary dealer data
   const renderPrimaryDealerDropdown = () => {
    return primaryDealerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.company_name}>
          {/* {item.name} */}
          {`${item.company_name} (${item.company_code}) ` }
        </option>
      );
    });
  };

  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.company_name} ({item.symbol})
        </option>
      );
    });
  };

 //render dropdown for money market securities data of fund
const renderMoneyMarketSecurityData = () => {
  return moneyMarketDataFund.map((item: any, index: string) => {
    return (
      <option
        key={index}
        value={item.code}>{item.name}</option>
    );
  });
};

    //render dropdown for psx data of fund
    const renderStockMarketData = () => {
      return stockMarketDataFund.map((item: any, index: string) => {
        return (
          <option
            key={index}
            value={item.code}
          >{item.code}</option>
        );
      });
    };

  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_','')}>
          {item.name.replace('SECURITY_','')}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);
  // filter securities data based on security_type selection
  const renderFilterSecurityDataDropdown = (security_type:string) => {
    const check= securityTypeData.filter((item: any, index: string)=>{
     return (item.security_type===security_type);
    });
    setSecurityData(check);
  };

const renderSecuritiesDropDown=()=>{
  return securityData.map((item: any, index: number) => {
    return (
      <option key={index} value={item.code.replace('SECURITY_','')}>
        {item.name}
      </option>
    );
  });
}

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === 'Other') {
      setFields({
        ...fields,
        counter_branch: '',
      });
      setInputType(true);
    }
  };

  // handle data for Fund Account Dropdown selection
  const setFundDataCounter = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      accountNoData.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['counter_account_type'] = item.account_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch_name;
          setFields(fields);

            //////
            termDepositEntity['bank']=item.bank_name;
            termDepositEntity['branch']=item.branch;
            setTermDepositEntity(termDepositEntity);
        }
      });
    }
  };

  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState('');

  const createLegders =()=>{
      if(headLabel=='bankprofit'){
        transactionLedgers.map((item:any)=>{
        if(item.type=='Debit'){
        item.amc_code=amc_code;
        item.fund_code=fields['fund_code'];
        item.account_number=fields['account_no'];
        item.account_title=fields['account_title'];
        item.bank_code=fields['bank'];
        item.branch=fields['branch'];
        item.balance='';
        item.counter_account_number=fields['counter_account_number'];
        item.counter_account_title=fields['counter_account_title'];
        item.counter_bank=fields['counter_bank'];
        item.counter_branch=fields['counter_branch'];

        if(item.amount_type!='Other')
        item.description=item.amount_type+' '+fields['detail'];

        item.instruction_date=fields['instruction_date'];
        item.instrument_by='';
        item.instrument_date=fields['instrument_date'];
        item.instrument_no=fields['instrument_no'];
        item.instrument_type=fields['instrument_type'];
        item.realized=fields['realized'];
        item.realized_by='';
        item.realized_date=fields['realized_date'];
        item.entered_by='';
        item.evidence='';
        item.period='2021-2022';
        }else{
        item.amc_code=amc_code;
        item.fund_code=fields['fund_code'];
        item.balance='';

        if(item.amount_type!='Other')
        item.description=item.amount_type+' '+fields['detail'];

        item.instruction_date='';
        item.instrument_by='';
        item.instrument_date='';
        item.instrument_no='';
        item.instrument_type='';
        item.realized='';
        item.realized_by='';
        item.realized_date='';
        item.entered_by='';
        item.evidence='';
        item.period='2021-2022';
        }
        })
        // console.log('after',transactionLedgers)
        return transactionLedgers;
       }

       if(headLabel=='cashmargin'){
            transactionLedgers.map((item:any)=>{
                if(item.amount_type="Gross Amount" && item.type=='Credit'){
                item.amc_code=amc_code;
                item.fund_code=fields['fund_code'];
                item.account_number=fields['account_no'];
                item.account_title=fields['account_title'];
                item.bank_code=fields['bank'];
                item.branch=fields['branch'];
                item.balance='';
                item.counter_account_number=fields['counter_account_number'];
                item.counter_account_title=fields['counter_account_title'];
                item.counter_bank=fields['counter_bank'];
                item.counter_branch=fields['counter_branch'];
        
                if(item.amount_type!='Other')
                item.description=item.amount_type+' '+fields['detail'];
        
                item.instruction_date=fields['instruction_date'];
                item.instrument_by='';
                item.instrument_date=fields['instrument_date'];
                item.instrument_no=fields['instrument_no'];
                item.instrument_type=fields['instrument_type'];
                item.realized=fields['realized'];
                item.realized_by='';
                item.realized_date=fields['realized_date'];
                item.entered_by='';
                item.evidence='';
                item.period='2021-2022';
                }else{
                item.amc_code=amc_code;
                item.fund_code=fields['fund_code'];
                item.balance='';
        
                if(item.amount_type!='Other')
                item.description=item.amount_type+' '+fields['detail'];
        
                item.instruction_date='';
                item.instrument_by='';
                item.instrument_date='';
                item.instrument_no='';
                item.instrument_type='';
                item.realized='';
                item.realized_by='';
                item.realized_date='';
                item.entered_by='';
                item.evidence='';
                item.period='2021-2022';
                }
            })
           return transactionLedgers
       }
       if(headLabel=='marginreturn'){
        transactionLedgers.map((item:any)=>{
            if(item.amount_type="Gross Amount" && item.type=='Debit'){
            item.amc_code=amc_code;
            item.fund_code=fields['fund_code'];
            item.account_number=fields['account_no'];
            item.account_title=fields['account_title'];
            item.bank_code=fields['bank'];
            item.branch=fields['branch'];
            item.balance='';
            item.counter_account_number=fields['counter_account_number'];
            item.counter_account_title=fields['counter_account_title'];
            item.counter_bank=fields['counter_bank'];
            item.counter_branch=fields['counter_branch'];
    
            if(item.amount_type!='Other')
            item.description=item.amount_type+' '+fields['detail'];
    
            item.instruction_date=fields['instruction_date'];
            item.instrument_by='';
            item.instrument_date=fields['instrument_date'];
            item.instrument_no=fields['instrument_no'];
            item.instrument_type=fields['instrument_type'];
            item.realized=fields['realized'];
            item.realized_by='';
            item.realized_date=fields['realized_date'];
            item.entered_by='';
            item.evidence='';
            item.period='2021-2022';
            }else{
            item.amc_code=amc_code;
            item.fund_code=fields['fund_code'];
            item.balance='';
    
            if(item.amount_type!='Other')
            item.description=item.amount_type+' '+fields['detail'];
    
            item.instruction_date='';
            item.instrument_by='';
            item.instrument_date='';
            item.instrument_no='';
            item.instrument_type='';
            item.realized='';
            item.realized_by='';
            item.realized_date='';
            item.entered_by='';
            item.evidence='';
            item.period='2021-2022';
            }
        })
            return transactionLedgers
        }

        if(headLabel=='equityinvestment'){
          transactionLedgers.map((item:any)=>{
          item.amc_code=amc_code;
          item.fund_code=fields['fund_code'];
          item.account_number=fields['account_no'];
          item.account_title=fields['account_title'];
          item.bank_code=fields['bank'];
          item.branch=fields['branch'];
          item.balance='';
          item.counter_account_number=fields['counter_account_number'];
          item.counter_account_title=fields['counter_account_title'];
          item.counter_bank=fields['counter_bank'];
          item.counter_branch=fields['counter_branch'];
  
          if(item.amount_type!='Other')
          item.description=item.amount_type+' '+fields['detail'];
  
          item.instruction_date=fields['instruction_date'];
          item.instrument_by='';
          item.instrument_date=fields['instrument_date'];
          item.instrument_no=fields['instrument_no'];
          item.instrument_type=fields['instrument_type'];
          item.realized='';
          item.realized_by='';
          item.realized_date='';
          item.entered_by='';
          item.evidence='';
          item.period='2021-2022';
        })
        return transactionLedgers
        }
  // }
  // console.log('after',transactionLedgers)

}

  //Create Transaction Api Call
  const createTransaction = async () => {

    if (!chechFieldDisplay(headLabel, 'nav')) {
      fields['nav'] = '';
    }
    if (headLabel === 'unitconversion') {
      let conversionAmount = +fields['net_amount'] / +unitNav;
      fields['total_holding'] = conversionAmount.toFixed(2);
    } else {
      fields['counter_type'] = counterType!==''? counterType: counterAccounter;
    }
    if(headLabel==='debtmarketinvestment'){
      fields['securities'] = JSON.stringify(moneyMarketSecurities);
    }
   
    // else if(headLabel==='termdeposit'){
    //   termDeposit.push(termDepositEntity);
    //   setTermDeposit(termDeposit);
    //   fields['securities'] = JSON.stringify(termDeposit);
    // }
    else{
      fields['securities'] = JSON.stringify(securites);
    }

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (
        headLabel === 'fundtransfer' &&
        fields['counter_account_type'] === fields['fund_account']
      ) {
        toast.error('Both account should be not same');
      } else {
        setLoading(true);
        if (flag) {
          if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0){
            toast.error("Amounts are negative");
          }
          else{
            if(headLabel==='pledgedin' || headLabel==='pledgedout')
            {
              fields['fund_account']='None';
              fields['account_title']='None';
              fields['account_no']='None';
              fields['bank']='None';
              fields['branch']='None';
              fields['mode_of_payment']='None';
              fields['gross_amount']='0';
              fields['net_amount']='0';
              setFields(fields);
            }
          try {
            const response = await EditTransaction(fields); // for Edit existed transaction
            if(response.data.status===400){
              setViewBankBalanceModal(true);
              toast.error(response.data.message);
            }else if(response.data.status===417){
              toast.warning(response.data.message);
            }else{
              setUnderStand(false);
              setViewBankBalanceModal(false);
              toast.success(response.data.message);
              setTimeout(() => {
                history.replace(`/admin/create-transaction`);
              }, 3000);
          }
          } catch (error:any) {
            setLoading(false);
            if(error.response!==undefined){
              toast.error(error.response.data.message);
              }else{
                toast.error('Something went wrong!');
              }
          }
        }
        } else {
          if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0){
            toast.error("Amounts are negative");
          }
          else{
            if(headLabel==='pledgedin' || headLabel==='pledgedout')
            {
              fields['fund_account']='None';
              fields['account_title']='None';
              fields['account_no']='None';
              fields['bank']='None';
              fields['branch']='None';
              fields['mode_of_payment']='None';
              fields['gross_amount']='0';
              fields['net_amount']='0';
              setFields(fields);
            }

            if(+balance!=0){
                toast.error(`Balance should be 0, Other wise you can't create transaction`);
                return;
              }
            let finalLedgers = createLegders();
            fields.ledgers=JSON.stringify(finalLedgers);
            console.log('fields',fields)
          try {
            const response = await addMegaTransaction(fields); // for add new transaction
            if(response.data.status===400){
              setViewBankBalanceModal(true);
              toast.warning(response.data.message);
            }else if(response.data.status===417){
              toast.warning(response.data.message);
            }else{
              setUnderStand(false);
              setViewBankBalanceModal(false);
            sessionStorage.setItem('last_id', response.data.txn_id);
            toast.success(`${response.data.message} - ${response.data.txn_id}`)
            // toast.success(response.data.message);
            setTimeout(() => {
              history.replace(`/admin/create-transaction`);
            }, 3000);
          }
          } catch (error:any) {
            setLoading(false);
            if(error.response!==undefined){
            toast.error(error.response.data.message);
            }else{
              toast.error('Something went wrong!');
            }
          }
        }
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  };

  const [accTilteName, setAccTiltleName] = useState('');
  const [bankBalance, setBankBalance]= useState('');
  // handle data for Fund Account Dropdown selection
  const setFundAccountFieldsData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['account_title'] = '';
      fields['account_no'] = '';
      fields['bank'] = '';
      fields['branch'] = '';
      fields['fund_account'] = 'other';
      setBankBalance("");
      setFields(fields);
    } else {
        ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
        //   fields['fund_account'] = item.account_code;
        //   fields['account_title'] = item.account_title;
        //   fields['account_no'] = item.account_no;
        //   fields['bank'] = item.bank_name;
        //   fields['branch'] = item.branch_name;
        //   setBankBalance(item.balance_amount);
        //   setFields(fields);

        setFields({
            ...fields,
          fund_account:selected,
          account_title: item.account_title,
          account_no: item.account_no,
          bank: item.bank,
          branch: item.branch
        })

          if(headLabel=='bankprofit'){
          transactionLedgers.map((items:any)=>{
            // if(items.amount_type=='Net Amount'){
               items.gl_code=+item.code;
               items.gl_name=item.bank.toString();
               items.sub_family_code= +item.plsCode;
               items.sub_family_name= item.plsName.toString();
            // }
           })
        }
        if(headLabel=='cashmargin'){// cash margin deposit
            transactionLedgers.map((items:any)=>{
                if(items.amount_type=='Gross Amount' && items.type=='Credit'){
                   items.gl_code=+item.code;
                   items.gl_name=item.bank.toString();
                   items.sub_family_code= +item.plsCode;
                   items.sub_family_name= item.plsName.toString();
                }
               })
        }
        if(headLabel=='marginreturn'){ // cash margin release
            transactionLedgers.map((items:any)=>{
                if(items.amount_type=='Gross Amount' && items.type=='Debit'){
                   items.gl_code=+item.code;
                   items.gl_name=item.bank.toString();
                   items.sub_family_code= +item.plsCode;
                   items.sub_family_name= item.plsName.toString();
                }
               })
        }
        if(headLabel=='debtmarketinvestment'){ // Money Market Investment
          setLedgerCode(+item.code);
          setLedgerName(item.bank.toString());
          setSubFamilyName(item.plsName.toString());
          setSubFamilyCode(+item.plsCode);
          // console.log('transactionLedgers',transactionLedgers)
          transactionLedgers.map((items:any)=>{
            // console.log('items',items)
              if(items.amount_type=='Settlement Amount' && items.family_name=='BANK BALANCES'){
                console.log('macthed')
                 items.gl_code=+item.code;
                 items.gl_name=item.bank.toString();
                 items.sub_family_code= +item.plsCode;
                 items.sub_family_name= item.plsName.toString();
              }
             })
      }
        }
      });
    }
  };
  
  const clearFundAccountFields = () => {
    setAccTiltleName('Unset');
    fields['fund_account'] = '';
    fields['account_title'] = '';
    fields['account_no'] = '';
    fields['bank'] = '';
    fields['branch'] = '';
    fields['nav'] = '';
    setFields(fields);
  };

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + 'changes');
          fields['counter_account_type'] = item.company_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.iban;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch;
          fields['sales_tax_collector'] = item.tax_collected;
          setFields(fields);
          
        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      amcdata.map((item, index) => {
        if (item.account_no === selected) {
          setAccTiltleName(item.account_no + 'changes');
          getCounterBranchByName(item.bank);
          fields['counter_account_type'] = item.account_no;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank;
          fields['counter_branch'] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields['current_holding'] + 'change');
    fields['counter_account_type'] = '';
    fields['counter_account_title'] = '';
    fields['counter_account_number'] = '';
    fields['counter_bank'] = '';
    fields['counter_branch'] = '';
    fields['current_holding'] = '';
    fields['folio_no'] = '';
    setFields(fields);
  };

const [grossAmountInWords, setGrossAmountInWords]=useState('');
const [netAmountInWords, setNetAmountInWords]=useState('');
const [netAmountSeparator, setNetAmountSeparator]=useState('');
const [grossAmountSeparator, setGrossAmountSeparator]=useState('');

const setTotalRemainHolding=(amount, type)=>{
  if(type==="NetAmount"){
    if(amount.length===0){
    setFields({
      ...fields,
      net_amount: amount,
    });
    }else{
    setFields({
      ...fields,
      net_amount: amount,
      total_charges: '0',
    });
    }

  }else if(type==="GrossAmount"){
  if(amount.length===0){
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: amount,
    // units:'0.0000'
  });
  setNetAmountSeparator('');
  setNetAmountInWords('');
  setGrossAmountSeparator('');
  setGrossAmountInWords('');
  }else{
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: amount,
  });
    let inwordNetAmount = inWords(amount);
    setNetAmountInWords(inwordNetAmount);
    let inwordGrossAmount = inWords(amount);
    setGrossAmountInWords(inwordGrossAmount);
    let grossAmountSeparator= numberWithCommas(amount);
    setGrossAmountSeparator(grossAmountSeparator);
    let netAmountSeparator= numberWithCommas(amount);
    setNetAmountSeparator(netAmountSeparator);

    let totaldebit=0, totalcredit=0;
    if(headLabel=='bankprofit'){
    transactionLedgers.map((item:any)=>{
        if(item.type=='Debit'){
            item.debit_amount=amount;
            item.credit_amount='0';
            totaldebit+= +item.debit_amount;
        }
           if(item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=amount;
            totalcredit+= +item.credit_amount;
           }
    })
  }
   if(headLabel=='cashmargin'){
    transactionLedgers.map((item:any)=>{
        if(item.type=='Debit'){
            item.debit_amount=amount;
            item.credit_amount='0';
            totaldebit+= +item.debit_amount;
        }
           if(item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=amount;
            totalcredit+= +item.credit_amount;
           }
    })
   }
   if(headLabel=='marginreturn'){
    transactionLedgers.map((item:any)=>{
        if(item.type=='Debit'){
            item.debit_amount=amount;
            item.credit_amount='0';
            totaldebit+= +item.debit_amount;
        }
           if(item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=amount;
            totalcredit+= +item.credit_amount;
           }
    })
    }
    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
  }
 
  }
};

  //set Current holding
  const[termDepositEntity, setTermDepositEntity] = useState<any>({
    code:'',
    name:'',
    principal_amount:'',
    interest_rate:'',
    from_date:moment(date).format('YYYY-MM-DD'),
    to_date:moment(date).format('YYYY-MM-DD'),
    bank:'',
    branch:'',
  });
  
  const [viewMoneyMarketModal, setViewMoneyMarketModal] = useState(false);
  const [moneyMarketSecurities, setMoneyMarketSecurities] = useState<any>([]);
  const [moneyMarketSecuritiesEntity, setMoneyMarketSecuritiesEntity] = useState<any>({
    security_type:'',
    investment_type:'',
    type:'',
    symbol:'',
    name:'',
    issue_date:'',
    maturity_date:'',
    money_market_face_value:'',
    price:'',
    units:'',
    yield:'',
    coupon_rate:'',
    next_coupon_date:'',
    last_coupon_date:'',
    primary_dealer:'',
    fund_ips_account:'',
    counter_party_ips_account:'',
    broker_name:'',
    settlement_amount:'',
    total_face_value:'',
    accrued_amount:'',
    brokerage:'0',
    premium_discount:'',
    accrued_days:'',
    counter_party_name:'',
    detail:'',
    principal_amount:'',
    unredeem_value:'',
    coupon_payment:'',
    days_of_maturity: '',
    average_cost_per_unit:'0',
    average_cost_amount:'0',
    gain_loss_per_unit:'0',
    gain_loss_amount:'0',
  });

  const [viewAddModal, setViewAddModal] = useState(false);
  const [securites, setSecurites] = useState<any>([]);
  const [securitesEntity, setSecuritesEntity] = useState<any>({
    market: '',
    investment_type:'',
    sst_on_commission:'',
    commission_rate:'',
    // capital_value_tax:'',
    net_rate:'',
    type: '',
    symbol: '',
    quantity: '',
    avg_rate: '',
    commission_charges: '0',
    gross_amount: '',
    net_amount: '',
    broker:'',
    sale_price:'0',
    avg_cost_amount:'0',
    gain_loss_per_unit:'0',
    gain_loss_amount:'0'
  });


// add money market security in array.
const addMoneyMrketSecurityInArray = () => {
    if(moneyMarketSecuritiesEntity['symbol']===''){
      toast.error("Symbol field is empty");
    }
    else if(moneyMarketSecuritiesEntity['settlement_amount']===''){
      toast.error("Settlement Amount field is empty");
    }
    else{
    moneyMarketSecurities.push(moneyMarketSecuritiesEntity);
    setMoneyMarketSecurities(moneyMarketSecurities);
    setViewMoneyMarketModal(false);
    setMoneyMarketSecuritiesEntity((prevState) => ({
      ...moneyMarketSecuritiesEntity,
      security_type:'',
      investment_type:'',
      type:'',
      symbol:'',
      name:'',
      issue_date:'',
      maturity_date:'',
      money_market_face_value:'',
      price:'',
      units:'',
      yield:'',
      coupon_rate:'',
      next_coupon_date:'',
      last_coupon_date:'',
      primary_dealer:'',
      fund_ips_account:'',
      counter_party_ips_account:'',
      broker_name:'',
      settlement_amount:'',
      total_face_value:'',
      accrued_amount:'',
      brokerage:'0',
      premium_discount:'',
      accrued_days:'',
      counter_party_name:'',
      detail:'',
      principal_amount:'',
      unredeem_value:'',
      coupon_payment:'',
      days_of_maturity:'',
      average_cost_per_unit:'0',
      average_cost_amount:'0',
      gain_loss_per_unit:'0',
      gain_loss_amount:'0',
    }));
    let gross = 0, avgCostAmount=0, gainLossAmt=0, principalAmt=0, totalFv=0, accruedAmt=0,
    premiumDiscount=0, brokerage=0, unredeemValue=0;
    moneyMarketSecurities.map((item, index) => {
      // let amount=parseFloat(item.price)* parseFloat(item.money_market_face_value)*parseFloat(item.units);
      // item.settlement_amount=amount;
      if (item.type === 'Sell' || item.type === 'Maturity' || item.type === 'Coupon') {
        gross = gross - parseFloat(item.settlement_amount);
        avgCostAmount = avgCostAmount - parseFloat(item.average_cost_amount);
        // gainLossAmt = gainLossAmt - parseFloat(item.gain_loss_amount);
        gainLossAmt = parseFloat(item.gain_loss_amount) + gainLossAmt;
        principalAmt = principalAmt - parseFloat(item.principal_amount);
        totalFv = totalFv - parseFloat(item.total_face_value);
        accruedAmt = accruedAmt - parseFloat(item.accrued_amount);
        premiumDiscount = premiumDiscount - parseFloat(item.premium_discount);
        unredeemValue = unredeemValue - parseFloat(item.unredeem_value);
      } else if(item.type === 'Buy'){
        gross = gross + parseFloat(item.settlement_amount);
        avgCostAmount = avgCostAmount + parseFloat(item.average_cost_amount);
        gainLossAmt = gainLossAmt + parseFloat(item.gain_loss_amount);
        principalAmt = principalAmt + parseFloat(item.principal_amount);
        totalFv = totalFv + parseFloat(item.total_face_value);
        accruedAmt = accruedAmt + parseFloat(item.accrued_amount);
        premiumDiscount = premiumDiscount + parseFloat(item.premium_discount);
        unredeemValue = unredeemValue - parseFloat(item.unredeem_value);
      }
      if(item.brokerage==''){
        item.brokerage = '0';
      }else{
        brokerage = brokerage + parseFloat(item.brokerage);
      }
    });
    if (gross < 0) {
      // let status: any = gross * -1 < 0 ? 'Credit' : 'Debit';
      // setEquityInvestmentStatus(status);
      let obj={
        gross_amount: (gross * -1).toFixed(2),
        net_amount: (gross * -1).toFixed(2),
        average_cost_amount: avgCostAmount.toFixed(2),
        gain_loss_amount: gainLossAmt.toFixed(2),
        principal_amount: principalAmt.toFixed(2),
        total_face_value: totalFv.toFixed(4),
        accrued_amount: accruedAmt.toFixed(2),
        premium_discount: premiumDiscount.toFixed(2),
        brokerage: brokerage.toFixed(2),
        unredeem_value: unredeemValue.toFixed(4),
      }
      setFields((prevState) => ({
        ...fields,
        payment_type: 'Receipt',
        gross_amount: (gross * -1).toFixed(2),
        net_amount: (gross * -1).toFixed(2),
        average_cost_amount: avgCostAmount.toFixed(2),
        gain_loss_amount: gainLossAmt.toFixed(2),
        principal_amount: principalAmt.toFixed(2),
        total_face_value: totalFv.toFixed(4),
        accrued_amount: accruedAmt.toFixed(2),
        premium_discount: premiumDiscount.toFixed(2),
        brokerage: brokerage.toFixed(2)
      }));
     let mmLedgers = getLedgersByTxnAndPaymentype(allTransactionLedgers, headLabel+'_'+moneyMarketSecuritiesEntity['security_type'], 'Receipt');
      console.log('mmLedgers',mmLedgers);
      if(moneyMarketSecuritiesEntity['security_type']=='PIB' || moneyMarketSecuritiesEntity['security_type']=='SUKUK'
      || moneyMarketSecuritiesEntity['security_type']=='TFC'){
        mmLedgers =  mmLedgers.filter((itm:any)=>{
          return itm.amount_type!='Premium/ Discount';
        })
      }
      handleMMLedgers(mmLedgers, obj);
      // setTransactionLedgers(mmLedgers);
    } else {
      // let status: any = gross < 0 ? 'Credit' : 'Debit';
      // setEquityInvestmentStatus(status);
      let obj={
        gross_amount: (gross).toFixed(2),
        net_amount: (gross).toFixed(2),
        average_cost_amount: avgCostAmount.toFixed(2),
        gain_loss_amount: gainLossAmt.toFixed(2),
        principal_amount: principalAmt.toFixed(2),
        total_face_value: totalFv.toFixed(4),
        accrued_amount: accruedAmt.toFixed(2),
        premium_discount: premiumDiscount.toFixed(2),
        brokerage: brokerage.toFixed(2),
        unredeem_value: unredeemValue.toFixed(4),
      }
      setFields((prevState) => ({
        ...fields,
        payment_type: 'Payment',
        gross_amount: gross.toFixed(2),
        net_amount: gross.toFixed(2),
        average_cost_amount: avgCostAmount.toFixed(2),
        gain_loss_amount: gainLossAmt.toFixed(2),
        principal_amount: principalAmt.toFixed(2),
        total_face_value: totalFv.toFixed(4),
        accrued_amount: accruedAmt.toFixed(2),
        premium_discount: premiumDiscount.toFixed(2),
        brokerage: brokerage.toFixed(2)
      }));
      let mmLedgers = getLedgersByTxnAndPaymentype(allTransactionLedgers, headLabel+'_'+moneyMarketSecuritiesEntity['security_type'], 'Payment');
      console.log('mmLedgers',mmLedgers);
      handleMMLedgers(mmLedgers, obj);
    }
    setPIBFields(false);
  }

};

const handleMMLedgers = (ledgers, obj)=>{
  let totaldebit=0, totalcredit=0;

 const updatedLedgers = ledgers.map((item:any)=>{
    if(item.amount_type=='MTM Cost' && item.type=='Credit'){ // Average Cost Amount Credit
      item.debit_amount='0';
      item.credit_amount = obj.average_cost_amount.replace('-','');
    }
    if(item.amount_type=='Accrued Amount' && item.type=='Credit'){ // Accrued Amount Credit
      item.debit_amount='0';
      item.credit_amount = obj.accrued_amount.replace('-','');
    }
    if(item.amount_type=='Accrued Amount' && item.type=='Debit'){ // Accrued Amount Debit
      item.debit_amount=obj.accrued_amount.replace('-','');
      item.credit_amount = '0';
    }
    // if(item.amount_type=='Premium/ Discount' && item.type=='Credit'){ // Premium Discount Credit
    //   item.debit_amount='0';
    //   item.credit_amount = obj.premium_discount.replace('-','');
    // }
    if(item.amount_type=='Premium/ Discount'){ // Premium Discount Debit
      if(+obj.premium_discount>=0){
        item.debit_amount=obj.premium_discount.replace('-','');
        item.credit_amount = '0';
        item.type='Debit';
      }else{
        item.debit_amount='0';
        item.credit_amount = obj.premium_discount.replace('-','');
        item.type='Credit';
      }
    }
    if(item.amount_type=='Brokerage' && item.type=='Credit'){ // Brokergae Credit
      item.debit_amount='0';
      item.credit_amount = obj.brokerage.replace('-','');
    }
    if(item.amount_type=='Brokerage' && item.type=='Debit'){ // Brokerage Debit
      item.debit_amount=obj.brokerage.replace('-','');
      item.credit_amount = '0';
    }
    if(item.amount_type=='Settlement Amount' && item.type=='Debit'){ // Settlement Amount Debit
      item.debit_amount=obj.gross_amount.replace('-','');
      item.credit_amount = '0';
    }
    // if(item.amount_type=='Settlement Amount' && item.type=='Debit'){
    //   item.debit_amount=obj.gross_amount;
    //   item.credit_amount = '0';
    // }
    if(item.amount_type=='Gain/Loss on Investment'){ // Gain/Loss Amount Debit
      if(+obj.gain_loss_amount>=0){
        item.debit_amount='0';
        item.credit_amount = obj.gain_loss_amount.replace('-','');
        item.type='Credit';
      }else{
        item.debit_amount = obj.gain_loss_amount.replace('-','');
        item.credit_amount = '0';
        item.type='Debit';
      }
    }
    if(item.amount_type=='Settlement Amount' && item.type=='Credit'){ // Settlement Amount Credit
      item.debit_amount='0';
      item.credit_amount = obj.gross_amount.replace('-','');
    }
    if(item.amount_type=='Settlement Amount' && item.type=='Debit' && item.family_name=='BANK BALANCES'){ // Settlement Amount Debit, Bank Account
      item.debit_amount=obj.gross_amount.replace('-','');
      item.credit_amount = '0';
      item.gl_code=ledgerCode;
      item.gl_name=ledgerName;
      item.sub_family_code= subFamilyCode;
      item.sub_family_name= subFamilyName;
    }
    if(item.amount_type=='Settlement Amount' && item.type=='Credit' && item.family_name=='BANK BALANCES'){ // Settlement Amount Credit, Bank Account
      item.debit_amount='0';
      item.credit_amount = obj.gross_amount.replace('-','');
      item.gl_code=ledgerCode;
      item.gl_name=ledgerName;
      item.sub_family_code= subFamilyCode;
      item.sub_family_name= subFamilyName;
    }
    if(item.amount_type=='Total Face Value' && item.type=='Debit'){ // Total Face Value Debit
      item.debit_amount=obj.total_face_value.replace('-','');
      item.credit_amount='0';
    }

    if(item.amount_type=='Principal Amount' && item.type=='Debit'){ // Total Face Value Debit
      item.debit_amount=obj.principal_amount.replace('-','');
      item.credit_amount='0';
    }
    if(item.amount_type=='Principal Amount' && item.type=='Credit'){ // Total Face Value Debit
      item.debit_amount='0';
      item.credit_amount=obj.principal_amount.replace('-','');
    }
    if(item.amount_type=='UnRedeem Value' && item.type=='Debit'){ // Total Face Value Debit
      item.debit_amount=obj.unredeem_value.replace('-','');
      item.credit_amount='0';
    }
    if(item.amount_type=='UnRedeem Value' && item.type=='Credit'){ // Total Face Value Debit
      item.debit_amount='0';
      item.credit_amount=obj.unredeem_value.replace('-','');
    }
    if(item.amount_type=='Accrued Interest' && item.type=='Debit'){
      item.debit_amount=obj.accrued_amount.replace('-','');
      item.credit_amount='0';
    }
    if(item.amount_type=='Accrued Interest' && item.type=='Credit'){
      item.debit_amount='0';
      item.credit_amount=obj.accrued_amount.replace('-','');
    }

    // total debit & credit
    if(item.type=='Debit'){
      totaldebit+= +item.debit_amount;
      }
     if(item.type=='Credit'){
      totalcredit+= +item.credit_amount;
     }

    return item;
    })

    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());

    setTransactionLedgers(updatedLedgers)
}

  // add secuirty Entity

  const addSecurityInArray = () => {
    let count = 0;
    for (let i in securitesEntity) {
      if (securitesEntity[i] === '') {
        count = count + 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
    } else {
      // const check = securites.filter((item) => {
      //   return (
      //     item.symbol === securitesEntity['symbol'] &&
      //     item.type === securitesEntity['type']
      //   );
      // });
      // if (check.length > 0) {
      //   securites.map((item, index) => {
      //     if (
      //       item.symbol === securitesEntity['symbol'] &&
      //       item.type === securitesEntity['type']
      //     ) {
      //       item.quantity = (+item.quantity + +securitesEntity['quantity']).toFixed(4);
      //       item.charges = (+item.commission_charges + +securitesEntity['commission_charges']).toFixed(2);
      //       item.gross_amount = (+item.gross_amount + +securitesEntity['gross_amount']).toFixed(2);
      //       item.net_amount = (+item.net_amount + +securitesEntity['net_amount']).toFixed(2);
      //       item.sst_on_commission = (+item.sst_on_commission + +securitesEntity['sst_on_commission']).toFixed(2);
      //       item.commission_charges = (+item.commission_charges + +securitesEntity['commission_charges']).toFixed(2);
      //     }
      //   });
      // } else {
        if(securitesEntity['type']==='Sell'){
          securitesEntity['quantity'] = '-'+securitesEntity['quantity'];
          securitesEntity['gross_amount'] = '-'+securitesEntity['gross_amount'];
          securitesEntity['net_amount'] =  securitesEntity['net_amount'].startsWith('-') ? securitesEntity['net_amount']: '-'+securitesEntity['net_amount'];
          // securitesEntity['avg_cost_amount'] = '-'+securitesEntity['avg_cost_amount'];
          // securitesEntity['gain_loss_amount'] = '-'+securitesEntity['gain_loss_amount'];
        }
        securites.push(securitesEntity);
        setSecurites(securites);
      // }
      setViewAddModal(false);
      setSecuritesEntity((prevState) => ({
        ...securitesEntity,
        gross_amount: '',
        net_amount: '',
        market: '',
        investment_type:'',
        sst_on_commission:'',
        commission_rate:'',
        // capital_value_tax:'',
        net_rate:'',
        type: '',
        symbol: '',
        quantity: '',
        avg_rate: '',
        commission_charges: '0',
        broker:'',
        sale_price:'0',
        avg_cost_amount:'0',
        gain_loss_per_unit:'0',
        gain_loss_amount:'0'
      }));
      let gross = 0, net=0, sst=0, brokercomission=0, avgCostAmt=0, gainLossAmt=0;
      securites.map((item, index) => {
        // if (item.type === 'Sell') {
          net = net + parseFloat(item.net_amount);
          gross = gross + parseFloat(item.gross_amount);
        // } else {
        //   net = net + parseFloat(item.net_amount);
        //   gross = gross + parseFloat(item.gross_amount);
        // }
        sst= sst + parseFloat(item.sst_on_commission);
        brokercomission= brokercomission + parseFloat(item.commission_charges);
        avgCostAmt = avgCostAmt + parseFloat(item.avg_cost_amount);
        gainLossAmt = gainLossAmt + parseFloat(item.gain_loss_amount);
      });
      if (net < 0) {
        let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Receipt',
          gross_amount: (gross * -1).toFixed(2).toString(),
          net_amount: (net * -1).toFixed(2).toString(),
          tax_sst: (sst).toFixed(2).toString(),
          broker_commission: (brokercomission).toFixed(2).toString(),
          average_cost_amount: (avgCostAmt).toFixed(2).toString(),
          gain_loss_amount: (gainLossAmt).toFixed(2).toString(),
        }));
        let ledgers = getLedgersByTxnAndPaymentype(allTransactionLedgers,headLabel, 'Receipt');
        console.log('ledgers',ledgers)
        // setTransactionLedgers(ledgers);
        // getLedgers(ledgers);
        
        // ledgers accounting
        let totaldebit=0, totalcredit=0;
        let updatedLedgers = ledgers.map((item:any)=>{
          if(item.amount_type=='MTM Cost'){
            item.debit_amount='0';
            item.credit_amount=(avgCostAmt).toFixed(2);
          }
          if(item.amount_type=='Commission Charges' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(brokercomission).toFixed(2);
          }
          if(item.amount_type=='Commission Charges' && item.type=='Debit'){
            item.debit_amount=(brokercomission).toFixed(2);
            item.credit_amount='0'
          }
          if(item.amount_type=='SST on Commission' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(sst).toFixed(2);
          }
          if(item.amount_type=='SST on Commission' && item.type=='Debit'){
            item.debit_amount=(sst).toFixed(2);
            item.credit_amount='0';
          }
         if(item.amount_type=='Gross Amount'){
            item.debit_amount=(gross * -1).toFixed(2);
            item.credit_amount='0';
         }
          if(item.amount_type=='Gain/Loss on Investment'){
            if(+gainLossAmt<0){
              item.debit_amount=(gainLossAmt).toFixed(2).toString().replace('-','');
              item.credit_amount='0';
              item.type='Debit';
            }else{
              item.debit_amount='0';
              item.credit_amount=(gainLossAmt).toFixed(2);
              item.type='Credit';
            }
        }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
          }
         if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
         }
        return {...item};
        });

        setTotalDebit(totaldebit.toFixed(2).toString());
        setTotalCredit(totalcredit.toFixed(2).toString());
        setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
        setTransactionLedgers(updatedLedgers);
        
      } else {
        let status: any = net < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Payment',
          gross_amount: gross.toFixed(2).toString(),
          net_amount: net.toFixed(2).toString(),
          tax_sst: sst.toFixed(2).toString(),
          broker_commission: brokercomission.toFixed(2).toString(),
          average_cost_amount: (avgCostAmt).toFixed(2).toString(),
          gain_loss_amount: (gainLossAmt).toFixed(2).toString(),
        }));
        let ledgers = getLedgersByTxnAndPaymentype(allTransactionLedgers,headLabel, 'Payment');
        console.log('ledgers',ledgers)
        // getLedgers(ledgers);
        // setTransactionLedgers(ledgers)
        // ledgers accounting
        let totaldebit=0, totalcredit=0;
        let updatedLedgers = ledgers.map((item:any)=>{

          if(item.amount_type=='Commission Charges' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(brokercomission).toFixed(2);
          }
          if(item.amount_type=='Commission Charges' && item.type=='Debit'){
            item.debit_amount=(brokercomission).toFixed(2);
            item.credit_amount='0'
          }
          if(item.amount_type=='SST on Commission' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(sst).toFixed(2);
          }
          if(item.amount_type=='SST on Commission' && item.type=='Debit'){
            item.debit_amount=(sst).toFixed(2);
            item.credit_amount='0';
          }
         if(item.amount_type=='Gross Amount' && item.type=='Debit'){
            item.debit_amount=(gross).toFixed(2);
            item.credit_amount='0';
         }
         if(item.amount_type=='Gross Amount' && item.type=='Credit'){
          item.debit_amount='0';
          item.credit_amount=(gross).toFixed(2);
       }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
          }
         if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
         }
        return {...item};
        });

        setTotalDebit(totaldebit.toFixed(2).toString());
        setTotalCredit(totalcredit.toFixed(2).toString());
        setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
        setTransactionLedgers(updatedLedgers);
      } 
    }
  };


  const onChangeSecurityValue = (value) => {
    // let grossAmount = +closeRate * parseFloat(value);
    // let netAmount =
    //   +closeRate * parseFloat(value) - parseFloat(securitesEntity.charges);
    let grossAmount=0, netAmount=0;
    if(securitesEntity['type']=='Sell'){
    grossAmount = +securitesEntity['sale_price'] * parseFloat(value);
    netAmount =
      +securitesEntity['sale_price'] * parseFloat(value) - parseFloat(securitesEntity.charges);
    }else{
      grossAmount = +closeRate * parseFloat(value);
      netAmount =
      +closeRate * parseFloat(value) - parseFloat(securitesEntity.charges);
    }
    setSecuritesEntity((prevState) => ({
      ...securitesEntity,
      gross_amount: grossAmount.toFixed(2),
      net_amount: netAmount.toFixed(2),
    }));
    setAccTiltleName(value);
  };

  //onchange Quantity in security Modal  the value of Gross amount calculated here
  const onChangeRate = (value) => {
    let grossAmount =
      parseFloat(securitesEntity['quantity']) * parseFloat(value);
    let netAmount = grossAmount - parseFloat(securitesEntity.charges);
    setSecuritesEntity((prevState) => ({
      ...securitesEntity,
      gross_amount: grossAmount.toFixed(2),
      net_amount: netAmount.toFixed(2),
    }));
    setAccTiltleName(value);
  };

  //On add Charges the value of Net amount calculated here
  const onAddChargeValue = (value,fieldType) => {
    if(fieldType=='commission_charges'){
    if(securitesEntity['type']==='Buy'){
      let netAmount = parseFloat(securitesEntity.gross_amount) + parseFloat(value) + parseFloat(securitesEntity.sst_on_commission);
      setSecuritesEntity((prevState) => ({
        ...securitesEntity,
        net_amount: netAmount.toFixed(2),
      }))
    }else{
      setAccTiltleName(value);
      let netAmount = parseFloat(securitesEntity.gross_amount) - parseFloat(value) - parseFloat(securitesEntity.sst_on_commission);
      setSecuritesEntity((prevState) => ({
        ...securitesEntity,
        net_amount: netAmount.toFixed(2),
      }));
      setAccTiltleName('changed-values');
  }
}else if(fieldType=='sst_commission'){
  if(securitesEntity['type']==='Buy'){
    let netAmount =
    parseFloat(securitesEntity.gross_amount) + parseFloat(securitesEntity.commission_charges) + parseFloat(value);
  setSecuritesEntity((prevState) => ({
    ...securitesEntity,
    net_amount: netAmount.toFixed(2),
  }))
  }else{
  setAccTiltleName(value);
  let netAmount =
    parseFloat(securitesEntity.gross_amount) - parseFloat(securitesEntity.commission_charges) - parseFloat(value);
  setSecuritesEntity((prevState) => ({
    ...securitesEntity,
    net_amount: netAmount.toFixed(2),
  }));
  setAccTiltleName('changed-values');
}
}
  };

  const [closeRate, setCloseRate] = useState('');
  const [bsType, setBSType] = useState('');

  const renderModalForSecuirtyPopup = () => {
    switch (viewAddModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          > 
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewAddModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add Security </h6>
            </div>


            <div className="modal-body">
              <div className="">
               
               <Row>
                 <Col>
               <div className="card">
                 <div className="card-header">
                   <h4 className="card-title">Security</h4>

                   </div>
                   <div className="card-body">
                   <Row>
                     <Col>
                    <div className="form-group">
                      Type*
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          if(e.target.value==='Sell')
                          {
                            if(fields['fund_code']===''){
                              toast.error('Select Fund First')
                            }else{
                            getFundStockMarketData(fields['fund_code']);
                            }
                          }
                          setBSType(e.target.value);
                          securitesEntity['type'] = e.target.value;
                          setSecuritesEntity(securitesEntity);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Type
                        </option>
                        <option value="Buy">Buy</option>
                        <option value="Sell">Sell</option>
                        {/* <option value="Sale">Sale</option> */}
                      </select>
                    </div>
                  </Col>
                       </Row>
                  
                    
                     <Row>
                     <Col>
                    <div className="form-group">
                      Market*
                      <select
                        className="form-control w-100"
                        value={securitesEntity.market}
                        onChange={(e) => {
                          setSecuritesEntity({
                            ...securitesEntity,
                            market: e.target.value,
                          });
                        }}
                      >
                        <option value="" defaultChecked hidden>Select Market</option>
                        <option value="REG">Regular</option>
                        <option value="ODL">Odd Lot</option>
                        <option value="FUT">Future</option>
                        <option value="BNB">Bills and Bonds</option>
                        <option value="IPO">Initial Public Offering</option>
                        <option value="BKB">Book Building</option>
                        <option value="CSF">Cash Settled Futures</option>
                        <option value="DSF">Direct Settled Futures</option>
                        <option value="SIF">Stock Index Futures</option>
                        <option value="NDM">Negotiable Deal Market</option>
                        <option value="FRO">Future Rollover Contract</option>
                        <option value="MTS">Margin Trading System</option>
                        <option value="SQR">Square Up</option> 
                      </select>
                    </div>
                  </Col>
                     </Row>

                     <Row>
                     <Col>
                    <div className="form-group">
                      Symbol*
                      <select
                        className="form-control w-100"
                        onChange={(e) => {
                          if(bsType==='Buy'){
                            securitesEntity['symbol'] = e.target.value;
                            setSecuritesEntity(securitesEntity);
                            let value = psxList.filter((item: any) => {
                              return item.symbol === e.target.value;
                            });
                            securitesEntity['avg_rate'] = value[0].close_rate;
                            setCloseRate(value[0].close_rate);
                            setSecuritesEntity(securitesEntity);
                            setAccTiltleName(e.target.value);
                           }else if(bsType==='Sell'){
                             securitesEntity['symbol'] = e.target.value;
                             setSecuritesEntity(securitesEntity);
                             let value = stockMarketDataFund.filter((item: any) => {
                               return item.code === e.target.value;
                             });
                             securitesEntity['avg_rate'] = value[0].close_rate;
                             setCloseRate(value[0].close_rate);
                             setSecuritesEntity(securitesEntity);
                             setAccTiltleName(e.target.value);
                           }
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select
                        </option>
                        {bsType==='Buy' || bsType==='' ? renderPsxDataDropdown() : renderStockMarketData()}
                      </select>
                    </div>
                  </Col>
                       </Row>

                       <Row>
                       <Col>
                    <div className="form-group">
                     {(securitesEntity['type'] =='Buy' || securitesEntity['type'] =='') ? 'Purchase Price' : 'Average Cost'}
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={closeRate}
                        onValueChange={(e) => {
                          let value =decimalValue(e.value,4);
                          if(securitesEntity['type']=='Sell'){
                            let gainLossUnits = (parseFloat(securitesEntity['sale_price']) - parseFloat(value)).toFixed(2).toString();
                            securitesEntity['gain_loss_per_unit']=gainLossUnits;
                            let avgCostAmount = (parseFloat(value) * parseFloat(securitesEntity['quantity'] || '0')).toFixed(2).toString();
                            securitesEntity['avg_cost_amount'] = avgCostAmount;
                          }
                          if( securitesEntity['type']=='Buy'){
                            let net_rate = (parseFloat(value) -  parseFloat(securitesEntity['commission_rate'] || '0')).toFixed(2);
                            securitesEntity['net_rate'] = net_rate;
                          }
                          
                          securitesEntity['avg_rate'] = value;
                          
                          setCloseRate(value);
                          setSecuritesEntity(securitesEntity);
                          onChangeRate(value);
                        }}
                      />
                    </div>
                  </Col>
                       </Row>

                       <Row>
                     <Col>
                  <div className="form-group">
                             Investment Type
                              <select
                                className="form-control w-100"
                                value={securitesEntity.investment_type}
                                onChange={(e) => {
                                
                                  setSecuritesEntity({
                                    ...securitesEntity,
                                    investment_type:e.target.value
                                  });
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Investment Type
                            </option>
                              <option value="HFT">Held To Trading</option>
                              <option value="HTM">Held To Maturity</option>
                              <option value="AFS">Available For Sale</option>
                              </select>
                            </div>
                  </Col>
                     </Row>

                       <Row>
                         <Col>
                      <div className="form-group">
                        Broker
                        <input
                          type="text"
                          className="form-control w-100"
                          value={securitesEntity['broker']}
                          onChange={(e) => {
                            setSecuritesEntity({
                              ...securitesEntity,
                              broker:e.target.value
                            });
                          }}
                        />
                     </div>
                       </Col>
                         </Row>

                     </div>
                 </div>
                 </Col>

                 <Col>
               <div className="card">
                 <div className="card-header">
                   <h4 className="card-title">Transaction</h4>

                   </div>
                   <div className="card-body">

                  {securitesEntity['type']=='Sell' && (
                   <Row>
                     <Col>
                    <div className="form-group">
                      Sale Price
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={securitesEntity['sale_price']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,4);
                          if(securitesEntity['type']=='Sell'){
                            let gainLossUnits = (parseFloat(value) - parseFloat(securitesEntity['avg_rate'])).toFixed(2).toString();
                            securitesEntity['gain_loss_per_unit']=gainLossUnits;
                            let gainlossAmt=(parseFloat(gainLossUnits) * securitesEntity['quantity']).toFixed(2).toString();
                            securitesEntity['gain_loss_amount']=gainlossAmt
                          }
                          securitesEntity['sale_price'] = value;
                          setSecuritesEntity(securitesEntity);
                          setAccTiltleName(value);
                        }}
                      />
                    </div>
                  </Col>
                     </Row>
                    )}

                     <Row>
                     <Col>
                    <div className="form-group">
                      Quantity*
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={securitesEntity['quantity']}
                        onValueChange={(e) => {
                          let value = e.value;
                          if(securitesEntity['type']=='Sell'){
                            let avgCostAmount = (parseFloat(securitesEntity['avg_rate'] || '0') * parseFloat(value)).toFixed(2).toString();
                            securitesEntity['avg_cost_amount'] = avgCostAmount;
                            let gainlossAmt=(parseFloat(securitesEntity['gain_loss_per_unit']) * parseFloat(value)).toFixed(2).toString();
                            securitesEntity['gain_loss_amount']=gainlossAmt
                          }
                          let commissionRate = (parseFloat(securitesEntity['commission_charges'] || '0') / (parseFloat(value))).toFixed(2);
                          securitesEntity['quantity'] = value;
                          securitesEntity['commission_rate'] = commissionRate;
                          setSecuritesEntity(securitesEntity);
                          onChangeSecurityValue(value);
                        }}
                      />
                    </div>
                  </Col>
                     </Row>
                  
                       <Row>
                       <Col>
                    <div className="form-group">
                      Commission Charges
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control text-right"
                        value={securitesEntity['commission_charges']}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,2);
                          let sst_on_commission= (parseFloat(value) * (13/100)).toFixed(2);
                          let commissionRate =(parseFloat(value) / (parseFloat(securitesEntity['quantity'] || '0'))).toFixed(2);
                          if(securitesEntity['type'].toString()=='Sell'){
                            let netrate = (parseFloat(securitesEntity['sale_price']) - parseFloat(commissionRate)).toFixed(2).toString();
                            securitesEntity['net_rate'] = netrate;
  
                          }else{
                            let netrate = (parseFloat(securitesEntity['avg_rate'] || '0') - parseFloat(commissionRate)).toFixed(2).toString();
                            securitesEntity['net_rate'] = netrate;
                          }
                          securitesEntity['commission_charges'] = value;
                          securitesEntity['commission_rate'] = commissionRate;
                          securitesEntity['sst_on_commission'] = sst_on_commission;
                          setSecuritesEntity(securitesEntity);

                          onAddChargeValue(value,'commission_charges');
                          setAccTiltleName(value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      Commission Rate
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control text-right"
                        value={securitesEntity.commission_rate}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,2);
                          // let net_rate = (parseFloat( securitesEntity['avg_rate'] || '0') -  parseFloat(value)).toFixed(2);
                          if(securitesEntity['type'].toString()=='Sell'){
                            let netrate = (parseFloat(securitesEntity['sale_price']) - parseFloat(value)).toFixed(2).toString();
                            securitesEntity['net_rate'] = netrate;
                          }else{
                            let netrate = (parseFloat(securitesEntity['avg_rate'] || '0') - parseFloat(value)).toFixed(2).toString();
                            securitesEntity['net_rate'] = netrate;
                          }
                          securitesEntity['commission_rate'] = value;
                          // securitesEntity['net_rate'] = net_rate;
                          setSecuritesEntity(securitesEntity);
                          setAccTiltleName(value);
                        }}
                      />
                    </div>
                  </Col>
                       </Row>

                       <Row>
                       <Col>
                    <div className="form-group">
                      Net Rate
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control text-right"
                        // value={securitesEntity.net_rate}
                        value={securitesEntity['net_rate']}
                        onValueChange={(e) => {
                          let value  = decimalValue(e.value,2);
                          setSecuritesEntity(securitesEntity);
                          setAccTiltleName(value);
                        }}
                      />
                    </div>
                    </Col>
                       </Row>

                       {securitesEntity['type']=='Sell' && (
                       <Row>
                     <Col>
                    <div className="form-group">
                      Gain/Loss Per Unit
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={securitesEntity['gain_loss_per_unit']}
                        onValueChange={(e) => {
                          let value = e.value;
                          let gainlossAmt=(parseFloat(value) * parseFloat(securitesEntity['quantity'])).toFixed(2).toString();
                            securitesEntity['gain_loss_amount']=gainlossAmt
                          securitesEntity['gain_loss_per_unit'] = value;
                          setSecuritesEntity(securitesEntity);
        
                        }}
                      />
                    </div>
                  </Col>
                     </Row>
                       )}
                    
                     </div>
                 </div>
                 </Col>

                 <Col>
               <div className="card">
                 <div className="card-header">
                   <h4 className="card-title">Amount</h4>

                   </div>
                   <div className="card-body">

                   {securitesEntity['type']=='Sell' && (
                     <>
                   <Row>
                     <Col>
                    <div className="form-group">
                      Average Cost Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={securitesEntity['avg_cost_amount']}
                        onValueChange={(e) => {
                          let value = e.value;
                          securitesEntity['avg_cost_amount'] = value;
                          setSecuritesEntity(securitesEntity);
        
                        }}
                      />
                    </div>
                  </Col>
                     </Row>

                     <Row>
                     <Col>
                    <div className="form-group">
                      Gain/Loss Amount
                      <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                        className="form-control w-100 text-right"
                        value={securitesEntity['gain_loss_amount']}
                        onValueChange={(e) => {
                          let value = e.value;
                          securitesEntity['gain_loss_amount'] = value;
                          setSecuritesEntity(securitesEntity);
        
                        }}
                      />
                    </div>
                  </Col>
                     </Row>
                     </>
                   )}
                     <Row>
                     <Col>
                    <div className="form-group">
                      Gross Amount
                      <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                        className="form-control w-100 disable-input text-right"
                        value={parseFloat(
                          securitesEntity['gross_amount']
                        ).toFixed(2)}
                        readOnly
                      />
                    </div>
                  </Col>
                       </Row>
                       <Row>
                       <Col>
                    <div className="form-group">
                      SST On Commission
                      <NumberFormat
                          thousandSeparator={true}
                          inputMode="numeric"
                        className="form-control text-right"
                        value={securitesEntity.sst_on_commission}
                        onValueChange={(e) => {
                          let value = decimalValue(e.value,2);
                          securitesEntity['sst_on_commission'] = value;
                          setSecuritesEntity(securitesEntity);
                          setAccTiltleName(value);
                          onAddChargeValue(value,'sst_commission');
                        }}
                      />
                    </div>
                  </Col>
                       </Row>
                      
                       <Row>
                       <Col>
                    <div className="form-group">
                      Net Amount
                      <NumberFormat
                          thousandSeparator={true}
                          inputMode="numeric"
                        className="form-control w-100 disable-input text-right"
                        value={parseFloat(
                          securitesEntity['net_amount']
                        ).toFixed(2)}
                        readOnly
                      />
                    </div>
                  </Col>
                       </Row>

                     </div>
                 </div>
                 </Col>

                 </Row>
                <div className=" mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      securitesEntity['avg_rate'] = closeRate;
                      setSecuritesEntity(securitesEntity);
                      addSecurityInArray();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const clearMoneyMarketEntityFields =()=>{
    moneyMarketSecuritiesEntity['issue_date']='';
    moneyMarketSecuritiesEntity['maturity_date']='';
    moneyMarketSecuritiesEntity['money_market_face_value']='';
    moneyMarketSecuritiesEntity['units']='';
    moneyMarketSecuritiesEntity['price']='';
    moneyMarketSecuritiesEntity['yield']='';
    moneyMarketSecuritiesEntity['investment_type']='';
    moneyMarketSecuritiesEntity['coupon_rate']='';
    moneyMarketSecuritiesEntity['next_coupon_date']='';
    moneyMarketSecuritiesEntity['last_coupon_date']='';
    moneyMarketSecuritiesEntity['primary_dealer']='';
    moneyMarketSecuritiesEntity['fund_ips_account']='';
    moneyMarketSecuritiesEntity['counter_party_ips_account']='';
    moneyMarketSecuritiesEntity['broker_name']='';
    moneyMarketSecuritiesEntity['settlement_amount']='';
    moneyMarketSecuritiesEntity['total_face_value']='';
    moneyMarketSecuritiesEntity['accrued_amount']='';
    moneyMarketSecuritiesEntity['brokerage']='';
    moneyMarketSecuritiesEntity['premium_discount']='';
    moneyMarketSecuritiesEntity['accrued_days']='';
    moneyMarketSecuritiesEntity['counter_party_name']='';
    moneyMarketSecuritiesEntity['detail']='';
    moneyMarketSecuritiesEntity['principal_amount']='';
    moneyMarketSecuritiesEntity['unredeem_value']='';
    moneyMarketSecuritiesEntity['coupon_payment']='';
    moneyMarketSecuritiesEntity['days_of_maturity']='';   
    moneyMarketSecuritiesEntity['average_cost_per_unit']='0';
    moneyMarketSecuritiesEntity['average_cost_amount']='0';
    moneyMarketSecuritiesEntity['gain_loss_per_unit']='0';
    moneyMarketSecuritiesEntity['gain_loss_amount']='0';
    setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
  }

  const[txnType, setTxnType] = useState('');
  const [fieldsReadOnly,setFieldsReadOnly] = useState(true);
  const [mmFieldReadOnly,setMMFieldReadOnly] = useState(true);
  const [otherType,setOtherType] = useState('');
  const [portfolioAmount,setPortfolioAmount] = useState('');
  const [portfolioUnits,setPortfolioUnits] = useState('');

  const [mmFaceValue, setMMFaceValue] = useState('');
  const [totalFaceValueReadOnly, setTotalFaceValueReadOnly] = useState(false);

  const renderModalForMoneyMarketSecurities = () => {
    switch (viewMoneyMarketModal) {
      case true:
        return (
            <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => { 
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
             }}
        > 
        <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setMoneyMarketSecuritiesEntity({});
                setViewMoneyMarketModal(false);
                setPIBFields(false);
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add Money Market Security</h6>
          </div>

            <div className="modal-body">
                <div className="  m-3">

                <Row> 
                  <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Security</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                        <Col>
                          <div className="form-group">
                            Securities / Placement
                            {mmSecurityLoading ? (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                          <select 
                            className='form-control'
                            onChange={(e) => {
                              if(headLabel==='debtmarketinvestment'){
                              if (e.target.value === 'PIB' || e.target.value==='TFC' || e.target.value==='SUKUK') {
                                setPIBFields(true);
                              } else {
                                setPIBFields(false);
                                moneyMarketSecuritiesEntity['coupon_rate'] = '0';
                                moneyMarketSecuritiesEntity['next_coupon_date'] = '';
                                moneyMarketSecuritiesEntity['last_coupon_date'] = '';
                              }
                            }
                            setSecurityCheck(e.target.value);
                            renderFilterSecurityDataDropdown(e.target.value)
                            moneyMarketSecuritiesEntity['security_type'] = e.target.value;
                            setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                          }}>
                            <option value="" defaultChecked hidden>
                            {' '}
                              Select Security</option>
                            <optgroup>Money Market</optgroup>
                            <option value='PIB'>Pakistan Investment Bonds</option>
                            <option value='TBILL'>Treasuary bill</option>

                            <optgroup>Debt Market</optgroup>
                            <option value='SUKUK'>Sukuk Certificates</option>
                            <option value='TFC'>Term Finance Certificates</option>
                            <option value='CP'>Commercial Papers</option>

                            <optgroup>Term Deposits</optgroup>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value='CDR'>Call Deposit Receipts</option>
                            <option value='LOP'>Letter of Placement</option>
                            </select>
                            )}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Type
                              <select
                                className="form-control w-100"
                                onChange={(e) => {
                                  clearMoneyMarketEntityFields();
                                  if(e.target.value==='Sell' || e.target.value==='Maturity' || e.target.value==='Coupon'){
                                    if(fields['fund_code']===''){
                                      toast.error('Select Fund First');
                                    }else if(moneyMarketSecuritiesEntity['security_type']===''){
                                      toast.error('Select Fund First');
                                    }else{
                                        getFundMoneyMarketPortfolioData(fields['fund_code'],moneyMarketSecuritiesEntity['security_type']);
                                    }
                                    // set MM FaceValue readonly false
                                    setMMFieldReadOnly(false);

                                    setTotalFaceValueReadOnly(true);
                                  }else{
                                    // set MM FaceValue readonly true
                                    setMMFieldReadOnly(true);

                                    setTotalFaceValueReadOnly(false);
                                  }
                                  setTxnType(e.target.value);
                                  moneyMarketSecuritiesEntity['type'] = e.target.value;
                                  setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                              <option value="Buy">Buy</option>
                              {(securityCheck=='PIB' || securityCheck=='SUKUK' || securityCheck=='TFC' || securityCheck=='TBILL') && (
                                <option value="Sell">Sell</option>
                              )}
                              <option value="Maturity">Maturity</option>
                              {(securityCheck=='PIB' || securityCheck=='SUKUK' || securityCheck=='TFC') && (
                                <option value="Coupon">Coupon</option>
                              )}
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                          <div className="form-group">
                          Security
                          {mmSecurityLoading ? (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                                <div className="form-group">
                                <select
                            className={
                              'form-control '
                            }
                          //  value={fields['symbol']}
                            onChange={(e) => {
                              if(e.target.value==='Other'){
                                setFieldsReadOnly(false);
                                setMMFieldReadOnly(false);
                                setTotalFaceValueReadOnly(false);
                                // clear fields in case of other
                                clearMoneyMarketEntityFields();
                                // end of clear fields

                                setOtherType(e.target.value);
                                let date = moneyMarketSecuritiesEntity['issue_date'].replace('-','');
                                let date2 = moneyMarketSecuritiesEntity['maturity_date'].replace('-','');
                                let symbol = moneyMarketSecuritiesEntity['security_type']+'-'+ date +'-'+date2;
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  symbol: symbol,
                                  // name: e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text
                                  name: symbol
                                });
                                return;
                              }else if(txnType==='Buy' || txnType===''){
                                setFieldsReadOnly(true);
                                setMMFieldReadOnly(true);
                                setTotalFaceValueReadOnly(false);
                                // clear fields in case of other
                                clearMoneyMarketEntityFields();
                                // end of clear fields
                                let data:any = securityData.filter((item:any, index:number)=>{
                                     return e.target.value==item.code.replace('SECURITY_','');
                                });
                                if(securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK')
                                {
                                  moneyMarketSecuritiesEntity['issue_date']=moment(data[0].issue_date).format('YYYY-MM-DD') || '';
                                  moneyMarketSecuritiesEntity['maturity_date']=moment(data[0].maturity_date).format('YYYY-MM-DD') || '';
                                  moneyMarketSecuritiesEntity['money_market_face_value']=data[0].face_value || '';
                                  // moneyMarketSecuritiesEntity['price']=data[0].price || '';
                                  moneyMarketSecuritiesEntity['symbol']=e.target.value;
                                  moneyMarketSecuritiesEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                                  setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                }else{
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  issue_date:moment(data[0].issue_date).format('YYYY-MM-DD') || '',
                                  maturity_date:moment(data[0].maturity_date).format('YYYY-MM-DD') || '',
                                  symbol:e.target.value,
                                  name:e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text
                                })
                                }

                              }else{
                                setFieldsReadOnly(true);
                                setTotalFaceValueReadOnly(true);
                                 // clear fields in case of other
                                 clearMoneyMarketEntityFields();
                                 // end of clear fields
                                let data:any = moneyMarketDataFund.filter((item:any, index:number)=>{
                                  return e.target.value==item.code;
                                });
                                 if(securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK')
                                 {
                                  if(data[0].mmface_value==undefined || data[0].mmface_value==NaN || data[0].mmface_value=='NaN' || data[0].mmface_value==''){
                                    data[0].mmface_value = '0';
                                  }
                                  setPortfolioUnits(data[0].units);
                                  setMMFaceValue(data[0].mmface_value.toString());
                                  console.log('issue', data[0].issue_date)
                                  console.log('maturity_date', data[0].maturity_date)
                                  moneyMarketSecuritiesEntity['issue_date']=moment(data[0].issue_date).format('YYYY-MM-DD') || '';
                                  moneyMarketSecuritiesEntity['maturity_date']=moment(data[0].maturity_date).format('YYYY-MM-DD') || '';
                                  moneyMarketSecuritiesEntity['money_market_face_value']=data[0].mmface_value || '';
                                  if(txnType!='Coupon'){
                                    moneyMarketSecuritiesEntity['average_cost_per_unit']=data[0].average_cost_per_unit || '';
                                  }
                                  moneyMarketSecuritiesEntity['symbol']=e.target.value;
                                  moneyMarketSecuritiesEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                                  setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                 }else{
                                  setPortfolioAmount(data[0].total_amount)
                                  // setMoneyMarketSecuritiesEntity({
                                  //   ...moneyMarketSecuritiesEntity,
                                  //   issue_date:moment(data[0].issue_date).format('YYYY-MM-DD') || '',
                                  //   maturity_date:moment(data[0].maturity_date).format('YYYY-MM-DD') || '',
                                  //   symbol:e.target.value,
                                  //   name:e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text
                                  // })
                                  console.log('issue', data[0].issue_date)
                                  console.log('maturity_date', data[0].maturity_date)
                                  moneyMarketSecuritiesEntity['issue_date']=moment(data[0].issue_date).format('YYYY-MM-DD') || '';
                                  moneyMarketSecuritiesEntity['maturity_date']=moment(data[0].maturity_date).format('YYYY-MM-DD') || '';
                                  moneyMarketSecuritiesEntity['money_market_face_value']=data[0].mmface_value || '';
                                  moneyMarketSecuritiesEntity['symbol']=e.target.value;
                                  moneyMarketSecuritiesEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                                  setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                 }
                              }

                              if(securityCheck==='PIB'){
                                let totalfacevalue = parseFloat(moneyMarketSecuritiesEntity['money_market_face_value'] || '0') * parseFloat(moneyMarketSecuritiesEntity['units'] || '0');
                                // let prediscount =((parseFloat(moneyMarketSecuritiesEntity['units'] || '0') * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) - totalfacevalue).toFixed(2);
                                // let samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || '0') * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || '0')).toFixed(2);
                                let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                let coupondays:any;
                                coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                                let accruedamount= (((totalfacevalue * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  total_face_value: totalfacevalue.toString(),
                                  // premium_discount: prediscount,
                                  // settlement_amount: samount,
                                  accrued_amount: accruedamount
                                });
                              }
                              if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                let totalfacevalue = parseFloat(moneyMarketSecuritiesEntity['money_market_face_value'] || '0') * parseFloat(moneyMarketSecuritiesEntity['units'] || '0');
                                // let prediscount =(((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || '0'))*1000) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                let coupondays:any;
                                coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                                let accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || '0') * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || '0')/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                let samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || '0') + parseFloat(accruedamount || '0')).toFixed(2);
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  total_face_value: totalfacevalue.toString(),
                                  // premium_discount: prediscount,
                                  settlement_amount: samount,
                                  accrued_amount: accruedamount
                                });
                              }
                              if(securityCheck==="TBILL"){
                                let ndate= new Date(fields['execution_date']);
                                let days:any;
                                let mdate=new Date(moment(moneyMarketSecuritiesEntity['maturity_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                days= (mdate.getTime() - ndate.getTime())/oneDay;
                                console.log('days',days)
                                let totalfacevalue = parseFloat(moneyMarketSecuritiesEntity['money_market_face_value'] || '0') * parseFloat(moneyMarketSecuritiesEntity['units'] || 0);
                                // let samount= ((totalfacevalue * parseFloat(moneyMarketSecuritiesEntity['price'] || '0'))/100).toFixed(2);
                                //////// let prediscount =((parseFloat(moneyMarketSecuritiesEntity['units'] || '0') * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                moneyMarketSecuritiesEntity['days_of_maturity']=days;
                                moneyMarketSecuritiesEntity['total_face_value']=totalfacevalue;
                                
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  days_of_maturity: days,
                                  total_face_value: totalfacevalue,
                                  // settlement_amount: samount,
                                  //////// premium_discount: prediscount
                                });
                              }
                              if((securityCheck=='PIB' || securityCheck=='TBILL' || securityCheck=='TFC' || securityCheck=='SUKUK') && (txnType=='Sell')){
                                let gainlLossPerUnit = (parseFloat(moneyMarketSecuritiesEntity['price'] || '0') - parseFloat(moneyMarketSecuritiesEntity['average_cost_per_unit'])).toFixed(2).toString();
                                let gainLossAmt = (parseFloat(gainlLossPerUnit) * parseFloat(moneyMarketSecuritiesEntity['units'])).toFixed(2).toString();
                                let avergaeCostAmt = (parseFloat(moneyMarketSecuritiesEntity['average_cost_per_unit']) * parseFloat(moneyMarketSecuritiesEntity['units'])).toFixed(2).toString();
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  gain_loss_per_unit: gainlLossPerUnit,
                                  gain_loss_amount: gainLossAmt,
                                  average_cost_amount: avergaeCostAmt

                                });
                              }
                              if((securityCheck==='CP' && txnType=='Maturity') || (securityCheck==='TDR' && txnType=='Maturity') ||
                              (securityCheck==='CDR' && txnType=='Maturity') || (securityCheck==='LOP' && txnType=='Maturity')){
                                let idate= new Date(moment(moneyMarketSecuritiesEntity['issue_date']).format('YYYY-MM-DD'));
                                let days:any;
                                let mdate=new Date(moment(moneyMarketSecuritiesEntity['maturity_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                days= (mdate.getTime() - idate.getTime())/oneDay;
                                
                                let couponrate = parseFloat(moneyMarketSecuritiesEntity['yield'] || '0')/100;
                                let accruedAmt  = (((parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || '0') * couponrate)/365)*days).toFixed(2).toString();
                                let settlementAmt =  (parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || '0') + parseFloat(accruedAmt)).toFixed(2).toString();
                                moneyMarketSecuritiesEntity['days_of_maturity']=days;
                                moneyMarketSecuritiesEntity['accrued_amount']=accruedAmt;
                                moneyMarketSecuritiesEntity['settlement_amount']=settlementAmt;
                                
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  days_of_maturity: days,
                                  accrued_amount: accruedAmt,
                                  settlement_amount: settlementAmt,
                                  // settlement_amount: samount,
                                  //////// premium_discount: prediscount
                                });
                              }
                              
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {txnType==='Buy' || txnType==='' ? renderSecuritiesDropDown() : renderMoneyMarketSecurityData()}
                            {txnType==='Buy' || txnType==='' ? <option value="Other">Other</option> : ''}
                              </select>
                            </div>
                            )}
                      </div>
                    </Col>
                  </Row>

                          <Row>
                          <Col>
                            <div className="form-group">
                             Investment Type
                              <select
                                className="form-control w-100"
                                value={moneyMarketSecuritiesEntity['investment_type']}
                                onChange={(e) => {
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    investment_type: e.target.value
                                  })
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Investment Type
                            </option>
                              <option value="HFT">Held To Trading</option>
                              <option value="HTM">Held To Maturity</option>
                              <option value="AFS">Available For Sale</option>
                              </select>
                            </div>
                        </Col>
                          </Row>
                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Issue Date</div>
                                <div className="form-group">
                                <input
                            type="Date"
                            value={moneyMarketSecuritiesEntity['issue_date']}
                            readOnly={fieldsReadOnly}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              if(otherType==='Other'){
                                let date= e.target.value.replaceAll('-','');
                               let symbol = moneyMarketSecuritiesEntity['security_type']+'-'+ date+'-'+moneyMarketSecuritiesEntity['maturity_date'].replaceAll('-','');
                                moneyMarketSecuritiesEntity['symbol']=symbol;
                                moneyMarketSecuritiesEntity['name']=symbol;
                                setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                              }
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                issue_date: e.target.value
                              })
                           
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          <Row>
                          <Col>
                        <div className="form-group">
                                <div>Maturity Date</div>
                                <div className="form-group">
                                <input
                            type="Date"
                            value={moneyMarketSecuritiesEntity['maturity_date']}
                            readOnly={fieldsReadOnly}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              if(otherType==='Other'){
                                let date= e.target.value.replaceAll('-','');
                                let symbol = moneyMarketSecuritiesEntity['security_type']+'-'+ moneyMarketSecuritiesEntity['issue_date'].replaceAll('-','')+'-'+date;
                                 moneyMarketSecuritiesEntity['symbol']=symbol;
                                 moneyMarketSecuritiesEntity['name']=symbol;
                                 moneyMarketSecuritiesEntity['maturity_date']=e.target.value;
                                 setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                               }
                              if(securityCheck==='TBILL'){
                                let ndate= new Date(fields['execution_date']);
                                let days:any;
                                let mdate=new Date(moment(e.target.value).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;

                                days= (mdate.getTime() - ndate.getTime())/oneDay;
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  maturity_date:e.target.value,
                                  days_of_maturity: days
                                });
                              }else{
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  maturity_date:e.target.value
                                }); 
                              }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          {(securityCheck !== 'TDR' && securityCheck !=='CDR' && securityCheck!=='LOP' && securityCheck!=='CP') && (
                          <>
                          <Row>
                          <Col>
                      <div className="form-group">
                         Money Market Face Value/Unit
                           <div className="form-group">
                           <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                            value={moneyMarketSecuritiesEntity['money_market_face_value']}
                            readOnly={fieldsReadOnly}
                            className={
                              'form-control w-100 text-right'
                            }
                              // readOnly
                              onValueChange={(e) => {
                              let value = decimalValue(e.value, 2);
                              if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK' || securityCheck==='TBILL') {
                              let samount:any;
                              let totalfacevalue = parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['units'] || 0);
                              if(securityCheck==='TBILL'){
                                samount= ((totalfacevalue * parseFloat(moneyMarketSecuritiesEntity['price'] || 0))/100).toFixed(2);
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  money_market_face_value:value,
                                  total_face_value:totalfacevalue, 
                                  settlement_amount: samount
                                });
                              }else{
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                money_market_face_value:value,
                                total_face_value:totalfacevalue
                              });
                            }
                            }else{
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                money_market_face_value:value
                              });
                            }
                            }}
                          />
                                </div>
                            </div>
                      </Col>
                          </Row>
                          </>
                     )}

                          <Row>
                        <Col>
                            <div className="form-group ">
                            <div>Primary Dealer</div> 
                                <select className="form-control w-100" 
                                value= {moneyMarketSecuritiesEntity['primary_dealer']} 
                                onChange={(e) => {
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      primary_dealer: e.target.value
                                    })
                                }}>
                                    <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Type
                                  </option>
                                  <option value="None">None</option>
                                  {renderPrimaryDealerDropdown()}
                                </select> 
                            </div>
                        </Col>
                          </Row>

                      </div>
                    </div>
                    </Col>

                    { PIB_fields===true &&(
                    <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Coupon</h4>
                      </div>
                      <div className="card-body">

                      <Row>
                          <Col>
                            <div className="form-group">
                                <div>Last Coupon Date</div>
                                <div className="form-group">
                                <input
                            type="Date"
                            value={moneyMarketSecuritiesEntity['last_coupon_date']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK') {
                                let ndate= new Date(fields['execution_date']);
                                let days:any;
                                let ldate=new Date(moment(e.target.value).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;

                                let accruedamount:any;
                                let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                // let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                let coupondays:any;
                                let samount:any;
                                coupondays = (nextcoupondate.getTime() - ldate.getTime())/oneDay;
                                if(securityCheck==="PIB"){
                                  accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                  samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                }else if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                  accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                  // samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                  if(txnType=='Buy' || txnType=='Sell'){
                                  samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0) + parseFloat(moneyMarketSecuritiesEntity['premium_discount'] || 0)).toFixed(2);
                                  }else if(txnType=='Maturity'){
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                  }else{
                                    samount = accruedamount;
                                  }
                                }
                                days= (ndate.getTime() - ldate.getTime())/oneDay;
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  settlement_amount: samount,
                                  accrued_amount:accruedamount,
                                  last_coupon_date:e.target.value,
                                  accrued_days: days
                                });
                              }else{
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  last_coupon_date:e.target.value
                                });
                              }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Next Coupon Date</div>
                                <div className="form-group">
                                <input
                                type="Date"
                                value={moneyMarketSecuritiesEntity['next_coupon_date']}
                                className={
                                  'form-control w-100 '
                                }
                            onChange={(e) => {
                              if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK') {
                              let accruedamount:any;
                              let nextcoupondate=new Date(moment(e.target.value).format('YYYY-MM-DD'));
                              let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                              var oneDay = 24 * 60 * 60 * 1000;
                              let coupondays:any;
                              let samount:any;
                              coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                              if(securityCheck==="PIB"){
                                accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                              }else if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                
                                if(txnType=='Buy' || txnType=='Sell'){
                                  samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0) + parseFloat(moneyMarketSecuritiesEntity['premium_discount'] || 0)).toFixed(2);
                                }else if(txnType=='Maturity'){
                                  samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                }else{
                                  samount = accruedamount;
                                }
                              }
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                settlement_amount: samount,
                                accrued_amount:accruedamount,
                                next_coupon_date:e.target.value
                              });
                            }else{
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                next_coupon_date:e.target.value
                              });
                            }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Coupon Rate</div>
                                <div className="form-group">
                                <input
                            type="number"
                            value={moneyMarketSecuritiesEntity['coupon_rate']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK') {
                              let accruedamount:any;
                              let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                              let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                              var oneDay = 24 * 60 * 60 * 1000;
                              let coupondays:any;
                              let samount:any;
                              coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                              if(securityCheck==="PIB"){
                                accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(value)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                              }else if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(value)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                
                                if(txnType=='Buy' || txnType=='Sell'){
                                  samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0) + parseFloat(moneyMarketSecuritiesEntity['premium_discount'] || 0)).toFixed(2);
                                }else if(txnType=='Maturity'){
                                  samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                }else{
                                  samount = accruedamount;
                                }
                                
                              }
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                coupon_rate:value,
                                accrued_amount: accruedamount,
                                settlement_amount: samount
                              });
                            }else{
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                coupon_rate:value
                              });
                            }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>
                        
                          {(securityCheck==="PIB" || securityCheck==="TFC" || securityCheck==="SUKUK") && (
                        <Row>
                        <Col>
                            <div className="form-group">
                                <div>Coupon Payment/Year</div>
                                <div className="form-group">
                                <input
                            type="number"
                            value={moneyMarketSecuritiesEntity['coupon_payment']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 0);
                              if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK') {
                                let accruedamount:any;
                                let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                let coupondays:any;
                                let samount:any;
                                coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                                if(securityCheck==="PIB"){
                                  accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(value))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                  samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                }else if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                  accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(value))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                  
                                  if(txnType=='Buy' || txnType=='Sell'){
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0) + parseFloat(moneyMarketSecuritiesEntity['premium_discount'] || 0)).toFixed(2);
                                  }else if(txnType=='Maturity'){
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                  }else{
                                    samount = accruedamount;
                                  }
                                  
                                }
                                
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  coupon_payment:value,
                                  accrued_amount: accruedamount,
                                  settlement_amount: samount
                                });
                              }else{
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  coupon_payment:value
                                });
                              }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        )}

                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Accrued Days</div>
                                <div className="form-group">
                                <input
                            type="text"
                            value={moneyMarketSecuritiesEntity['accrued_days']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                accrued_days: e.target.value
                              });
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                      </div>
                    </div>
                    </Col>
                    )}

                    <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Transaction</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                        <Col>
                            <div className="form-group">
                                <div>Detail</div>
                                <div className="form-group">
                                <input
                            type="text"
                            value={moneyMarketSecuritiesEntity['detail']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                detail:e.target.value
                              })
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row>

                        {(securityCheck !== 'TDR' && securityCheck !=='CDR' && securityCheck!=='LOP' && securityCheck!=='CP') && (
                          <>
                        <Row>
                        <Col>
                            <div className="form-group">
                                {/* <div>Price</div> */}
                                <div>{txnType=='Sell' ? 'Sale Price' : txnType =='Buy' ? 'Purchase Price' : 'Price'}</div>
                                <div className="form-group">
                                <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    value={moneyMarketSecuritiesEntity['price']}
                                    className={
                                      'form-control w-100 '
                                    }
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 4);
                                      let samount:any;
                                      let prediscount:any;
                                      let gainLossPerUnit='0';
                                      let gainLossAmt='0';
                                      if((securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK') &&
                                      (txnType=='Sell')){
                                        gainLossPerUnit = (parseFloat(value) - parseFloat(moneyMarketSecuritiesEntity['average_cost_per_unit'])).toFixed(4).toString();
                                        gainLossAmt = (parseFloat(gainLossPerUnit) * (parseFloat(moneyMarketSecuritiesEntity['units'] || '0'))).toFixed(2).toString();
                                      }
                                      if(securityCheck==='TFC' || securityCheck==='SUKUK'){
                                      prediscount =((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(value)) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                      }else{
                                        prediscount =((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(value)) - parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0)).toFixed(2);
                                      }
                                      if (securityCheck === 'PIB') {
                                      samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(value)) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || 0)).toFixed(2);
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                      price:value,
                                      settlement_amount:samount,
                                      premium_discount: prediscount,
                                      gain_loss_per_unit:gainLossPerUnit,
                                      gain_loss_amount: gainLossAmt
                                      })  
                                    }else if(securityCheck==='TFC' || securityCheck==='SUKUK'){
                                      if(txnType=='Buy' || txnType=='Sell'){
                                        samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || 0) + parseFloat(prediscount)).toFixed(2);
                                      }else if(txnType=='Maturity'){
                                      samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || 0)).toFixed(2);          
                                      }
                                      // else{
                                      //   samount = accruedamount;
                                      // }
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                      price:value,
                                      // settlement_amount:samount,
                                      premium_discount: prediscount,
                                      gain_loss_per_unit:gainLossPerUnit,
                                      gain_loss_amount: gainLossAmt
                                      });
                                      }else if(securityCheck==='TBILL' && txnType!='Maturity'){
                                        samount= ((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * parseFloat(value))/100).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                      price:value,
                                      settlement_amount:samount,
                                      premium_discount: prediscount,
                                      gain_loss_per_unit:gainLossPerUnit,
                                      gain_loss_amount: gainLossAmt
                                      });
                                    }else{
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                      price:value
                                      })
                                    }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row>

                        
                        <Row>
                        <Col>
                            <div className="form-group">
                                <div>Units</div>
                                <div className="form-group">
                                <NumberFormat  
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                 value={moneyMarketSecuritiesEntity['units']}
                                 onValueChange={(e) => {
                                  let value = decimalValue(e.value, 4);
                                  let totalfacevalue:any;
                                  let prediscount='0';
                                  let gainLossAmt='0';
                                  let avgCostAmt='0';
                                  if((securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK') &&
                                      (txnType=='Sell')){
                                        gainLossAmt = (parseFloat(moneyMarketSecuritiesEntity['gain_loss_per_unit'] || '0') * (parseFloat(value))).toFixed(2).toString();
                                        // avgCostAmt = (parseFloat(moneyMarketSecuritiesEntity['average_cost_per_unit'] || '0') * (parseFloat(value))).toFixed(2).toString();
                                     }
                                  if((securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK') &&
                                      (txnType=='Sell' || txnType=='Maturity')){
                                        // gainLossAmt = (parseFloat(moneyMarketSecuritiesEntity['gain_loss_per_unit'] || '0') * (parseFloat(value))).toFixed(2).toString();
                                        avgCostAmt = (parseFloat(moneyMarketSecuritiesEntity['average_cost_per_unit'] || '0') * (parseFloat(value))).toFixed(2).toString();
                                     }
                                  if(securityCheck==='TBILL'){
                                    totalfacevalue=(parseFloat(moneyMarketSecuritiesEntity['money_market_face_value']) * parseFloat(value)).toFixed(4);
                                    let settlementamount='0';
                                    if(txnType!='Maturity'){
                                     settlementamount= ((parseFloat(totalfacevalue) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0))/100).toFixed(2);
                                    }
                                    if(txnType=='Maturity'){
                                      moneyMarketSecuritiesEntity['settlement_amount']=avgCostAmt;
                                      moneyMarketSecuritiesEntity['total_face_value']=totalfacevalue;
                                      // moneyMarketSecuritiesEntity['settlement_amount']=settlementamount;
                                      moneyMarketSecuritiesEntity['units']=value;
                                      moneyMarketSecuritiesEntity['gain_loss_amount']=gainLossAmt;
                                      moneyMarketSecuritiesEntity['average_cost_amount']=avgCostAmt;
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        units: value,
                                        total_face_value:totalfacevalue,
                                        settlement_amount: settlementamount,
                                        gain_loss_amount: gainLossAmt,
                                        average_cost_amount: avgCostAmt
                                      });
                                    }else{
                                    moneyMarketSecuritiesEntity['total_face_value']=totalfacevalue;
                                    moneyMarketSecuritiesEntity['settlement_amount']=settlementamount;
                                    moneyMarketSecuritiesEntity['units']=value;
                                    moneyMarketSecuritiesEntity['gain_loss_amount']=gainLossAmt;
                                    moneyMarketSecuritiesEntity['average_cost_amount']=avgCostAmt;
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      units: value,
                                      total_face_value:totalfacevalue,
                                      settlement_amount: settlementamount,
                                      gain_loss_amount: gainLossAmt,
                                      average_cost_amount: avgCostAmt
                                    });
                                  }
                                  }
                                  if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK') {
                                  totalfacevalue=(parseFloat(moneyMarketSecuritiesEntity['money_market_face_value']) * parseFloat(value)).toFixed(4);
                                  // console.log('totalfacevalue',totalfacevalue)
                                  if(securityCheck==='TFC' || securityCheck==='SUKUK'){
                                  prediscount =((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || '0')) - parseFloat(totalfacevalue || '0')).toFixed(2);
                                }else{
                                    prediscount =((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) - parseFloat(totalfacevalue || 0)).toFixed(2);
                                  }
                                  //calculate accrued amount
                                  let accruedamount:any;
                                  let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                  let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                  var oneDay = 24 * 60 * 60 * 1000;
                                  let coupondays:any;
                                  let samount:any;
                                  coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                                  if(securityCheck==="PIB" && txnType=='Maturity'){
                                    accruedamount= (((parseFloat(totalfacevalue) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                    samount = (parseFloat(avgCostAmt) + parseFloat(accruedamount || 0)).toFixed(2);
                                  }
                                  if(securityCheck==="PIB" && txnType!='Maturity'){
                                    accruedamount= (((parseFloat(totalfacevalue) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);  
                                    samount = ((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                  }else if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                    accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                    if(txnType=='Buy' || txnType=='Sell'){
                                      samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0) + parseFloat(prediscount)).toFixed(2);
                                    }else if(txnType=='Maturity'){
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                    }else{
                                      samount = accruedamount;
                                    }
                                }
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    units: value,
                                    total_face_value:totalfacevalue,
                                    settlement_amount: samount,
                                    premium_discount: prediscount,
                                    accrued_amount:accruedamount,
                                    gain_loss_amount: gainLossAmt,
                                    average_cost_amount: avgCostAmt
                                  });
                                }else{
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    units: value
                                  });
                                }
      
                                }} />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        </>
                       
                      )}   
                        <Row>
                        <Col>
                            <div className="form-group">
                                <div>Yield/Interest Rate</div>
                                <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['yield']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 4);
                                    if((securityCheck=='CP' && txnType=='Maturity') || (securityCheck=='TDR' && txnType=='Maturity') ||
                                    (securityCheck=='CDR' && txnType=='Maturity') || (securityCheck=='LOP' && txnType=='Maturity')){
                                      let couponrate = parseFloat(value || '0')/100;
                                      let accruedAmt  = (((parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || '0') * couponrate)/365)*parseFloat(moneyMarketSecuritiesEntity['days_of_maturity'] || '0')).toFixed(2).toString();
                                      let settlementAmt =  (parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || '0') + parseFloat(accruedAmt)).toFixed(2).toString();
                                      moneyMarketSecuritiesEntity['accrued_amount']=accruedAmt;
                                      moneyMarketSecuritiesEntity['yield']=value;
                                      moneyMarketSecuritiesEntity['settlement_amount']=settlementAmt;
                                      
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        //  days_of_maturity: days,
                                        yield:value,
                                        accrued_amount: accruedAmt,
                                        settlement_amount: settlementAmt
                                      });
                                    }else{
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        yield:value
                                      })
                                    } 
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row>

                        {(securityCheck !== 'TDR' && securityCheck !=='CDR' && securityCheck!=='LOP' && securityCheck!=='CP') && (
                          <>
                        <Row>
                        <Col>
                            <div className="form-group">
                                <div>Total Face Value</div>
                                <div className="form-group">
                                <NumberFormat thousandSeparator={true}
                                  inputMode="numeric" 
                                  value={moneyMarketSecuritiesEntity['total_face_value']} className="form-control w-100 text-right"
                                  readOnly={totalFaceValueReadOnly}  
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 4);
                                  if (securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK' || securityCheck==='TBILL') {
                                    let prediscount:any;
                                    if(securityCheck==='TFC' || securityCheck==='SUKUK'){
                                    prediscount =(((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0))*1000) - parseFloat(value)).toFixed(2);
                                    }else{
                                      prediscount =((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) - parseFloat(value)).toFixed(2);
                                    }
                                  let accruedamount:any;
                                  let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                  let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                  var oneDay = 24 * 60 * 60 * 1000;
                                  let coupondays:any;
                                  let samount:any;
                                  coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                                  if(securityCheck==='PIB'){
                                    accruedamount= (((parseFloat(value) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                    samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                  }else if(securityCheck==='TFC' || securityCheck==='SUKUK'){
                                    accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                    if(txnType=='Buy' || txnType=='Sell'){
                                      samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0) + parseFloat(prediscount)).toFixed(2);
                                    }else if(txnType=='Maturity'){
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(accruedamount || 0)).toFixed(2);
                                    }else{
                                      samount = accruedamount;
                                    }
                                  }else if(securityCheck==='TBILL'){
                                    samount= ((parseFloat(value) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0))/100).toFixed(2);
                                  }
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    total_face_value: value,
                                    accrued_amount:accruedamount,
                                    premium_discount: prediscount, 
                                    settlement_amount: samount
                                  });
                                }else{
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    total_face_value: value
                                  });
                                }
      
                                }} />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        </>
                        )}

                    {((securityCheck=='PIB' || securityCheck=='TBILL' || securityCheck=='SUKUK' || securityCheck=='TFC') && (txnType=='Sell' || txnType=='Maturity')) && (
                      <Row>
                        <Col>
                          <div className="form-group">
                              <div>Average Cost/Unit</div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    value={moneyMarketSecuritiesEntity['average_cost_per_unit']}
                                    className={'form-control w-100 '}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 4);
                                      let gainLossPerUnit='0';
                                      let gainLossAmt='0';
                                      let avgCostAmt='0';
                                      let samount='0';
                                      if(securityCheck==="PIB" && txnType=='Maturity'){
                                        avgCostAmt = (parseFloat(value) * (parseFloat(moneyMarketSecuritiesEntity['units'] || '0'))).toFixed(2).toString();
                                        samount = (parseFloat(avgCostAmt) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || '0')).toFixed(2);
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          settlement_amount: samount,
                                          average_cost_amount: avgCostAmt,
                                          average_cost_per_unit:value,
                                      })
                                      }
                                      else if((securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK') &&
                                      (txnType=='Sell')){
                                        gainLossPerUnit = (parseFloat(moneyMarketSecuritiesEntity['price']) - parseFloat(value)).toFixed(4).toString();
                                        gainLossAmt = (parseFloat(gainLossPerUnit) * (parseFloat(moneyMarketSecuritiesEntity['units']))).toFixed(2).toString();
                                        
                                        avgCostAmt = (parseFloat(value) * (parseFloat(moneyMarketSecuritiesEntity['units'] || '0'))).toFixed(2).toString();
                                        
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          average_cost_per_unit:value,
                                          gain_loss_per_unit: gainLossPerUnit,
                                          gain_loss_amount: gainLossAmt,
                                          average_cost_amount: avgCostAmt
                                      })
                                      }else if((securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK') &&
                                      (txnType=='Maturity')){
                                        // gainLossAmt = (parseFloat(moneyMarketSecuritiesEntity['gain_loss_per_unit'] || '0') * (parseFloat(value))).toFixed(2).toString();
                                        avgCostAmt = (parseFloat(value) * (parseFloat(moneyMarketSecuritiesEntity['units'] || '0'))).toFixed(2).toString();
                                     
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          average_cost_per_unit:value,
                                          // gain_loss_per_unit: gainLossPerUnit,
                                          // gain_loss_amount: gainLossAmt,
                                          average_cost_amount: avgCostAmt
                                      })
                                      }else{
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        average_cost_per_unit:value,
                                    })
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          
                        </Row>
                    )}

                    {((securityCheck=='PIB' || securityCheck=='TBILL' || securityCheck=='SUKUK' || securityCheck=='TFC') && (txnType=='Sell' || txnType=='Maturity')) && (
                    <Row>
                        <Col>
                          <div className="form-group">
                              <div>Average Cost Amount</div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    value={moneyMarketSecuritiesEntity['average_cost_amount']}
                                    className={'form-control w-100 '}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 4);
                                      if(securityCheck=='TBILL' && txnType=='Maturity'){
                                        setMoneyMarketSecuritiesEntity({
                                          ...moneyMarketSecuritiesEntity,
                                          average_cost_amount: value,
                                          settlement_amount: value
                                      })
                                      }else{
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        average_cost_amount: value
                                    })
                                  }
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                    )}

              {((securityCheck=='PIB' || securityCheck=='TBILL' || securityCheck=='SUKUK' || securityCheck=='TFC') && (txnType=='Sell')) && (
                      <>
                        <Row>
                        <Col>
                          <div className="form-group">
                              <div>Gain/Loss Per Unit</div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    value={moneyMarketSecuritiesEntity['gain_loss_per_unit']}
                                    className={'form-control w-100 '}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 4);
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        gain_loss_per_unit: value
                                    })
                                }}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                        <Col>
                          <div className="form-group">
                              <div>Gain/Loss Amount</div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    inputMode="numeric"
                                    value={moneyMarketSecuritiesEntity['gain_loss_amount']}
                                    className={'form-control w-100 '}
                                    onValueChange={(e) => {
                                      let value = decimalValue(e.value, 4);
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        gain_loss_amount: value
                                    })
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        </>
                    )}
                            

                        {(securityCheck==="TFC" || securityCheck==="SUKUK"  || securityCheck=='PIB') && (
                        <Row>
                        <Col>
                            <div className="form-group">
                                <div>UnRedeem Value</div>
                                <div className="form-group">
                                <NumberFormat
                            thousandSeparator={true}
                            inputMode="numeric"
                            // value={moneyMarketSecuritiesEntity['yield']}
                            className={
                              'form-control w-100 text-right'
                            }
                            onValueChange={(e) => {
                              let value = decimalValue(e.value, 4);
                              let samount:any;
                              if((securityCheck==='PIB' && txnType=='Coupon')){
                                // accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);  
                                samount = (parseFloat(value) + parseFloat(moneyMarketSecuritiesEntity['accrued_amount'] || '0')).toFixed(2);
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  settlement_amount: samount,
                                  unredeem_value:value
                                });
                              }else
                              if (securityCheck==='TFC' || securityCheck==='SUKUK') {
                                let accruedamount:any;
                                let nextcoupondate=new Date(moment(moneyMarketSecuritiesEntity['next_coupon_date']).format('YYYY-MM-DD'));
                                let lastcoupondate= new Date(moment(moneyMarketSecuritiesEntity['last_coupon_date']).format('YYYY-MM-DD'));
                                var oneDay = 24 * 60 * 60 * 1000;
                                let coupondays:any;
                                // let samount:any;
                                coupondays = (nextcoupondate.getTime() - lastcoupondate.getTime())/oneDay;
                                // if(securityCheck==="PIB"){
                                //   accruedamount= (((parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                //   samount = ((parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0)) + parseFloat(accruedamount || 0)).toFixed(2);
                                // }else 
                                if(securityCheck==="TFC" || securityCheck==="SUKUK"){
                                  accruedamount= (((parseFloat(value) * ((parseFloat(moneyMarketSecuritiesEntity['coupon_rate'] || 0)/100)/parseFloat(moneyMarketSecuritiesEntity['coupon_payment']))) / (parseFloat(coupondays))) * parseFloat(moneyMarketSecuritiesEntity['accrued_days'] || '0')).toFixed(2);
                                  if(txnType=='Buy' || txnType=='Sell'){
                                    samount = (parseFloat(value) + parseFloat(accruedamount || 0) + parseFloat(moneyMarketSecuritiesEntity['premium_discount'] || 0)).toFixed(2);
                                  }else if(txnType=='Maturity'){
                                  samount = (parseFloat(value) + parseFloat(accruedamount || 0)).toFixed(2);
                                  }else{
                                    samount = accruedamount;
                                  }
                                }
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  settlement_amount: samount,
                                  accrued_amount:accruedamount,
                                  unredeem_value:value
                                });
                              }else{
                                setMoneyMarketSecuritiesEntity({
                                  ...moneyMarketSecuritiesEntity,
                                  unredeem_value:value
                                });
                              }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row>

                        )}

                      </div>
                    </div>
                    </Col>

                    <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Amount</h4>
                      </div>
                      <div className="card-body">

                      <Row>
                        <Col>
                            <div className="form-group">
                                <div>Principal Amount</div>
                                <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['principal_amount']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 2);
                                    if((securityCheck === 'TDR' || securityCheck ==='CDR' || securityCheck==='LOP' || securityCheck==='CP') && (txnType!='Maturity')){
                                      // let samount = (parseFloat(value) - parseFloat(moneyMarketSecuritiesEntity['brokerage'] || 0)).toFixed(2);
                                    setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    settlement_amount: value,
                                    principal_amount:value,
                                  });
                           }else if((securityCheck==='CP' && txnType=='Maturity') || (securityCheck==='TDR' && txnType=='Maturity') ||
                           (securityCheck==='CDR' && txnType=='Maturity') || (securityCheck==='LOP' && txnType=='Maturity')){
                             let couponrate = parseFloat(moneyMarketSecuritiesEntity['yield'] || '0')/100;
                             let accruedAmt  = (((parseFloat(value) * couponrate)/365)*parseFloat(moneyMarketSecuritiesEntity['days_of_maturity'] || '0')).toFixed(2).toString();
                             
                             let settlementAmt =  (parseFloat(value) + parseFloat(accruedAmt)).toFixed(2).toString();
                             moneyMarketSecuritiesEntity['accrued_amount']=accruedAmt;
                             moneyMarketSecuritiesEntity['settlement_amount']=settlementAmt;
                             
                             setMoneyMarketSecuritiesEntity({
                               ...moneyMarketSecuritiesEntity,
                              //  days_of_maturity: days,
                              principal_amount:value,
                              accrued_amount: accruedAmt,
                              settlement_amount: settlementAmt,
                             });
                           }
                           else{
                            setMoneyMarketSecuritiesEntity({
                              ...moneyMarketSecuritiesEntity,
                              principal_amount:value,
                            });
                           }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row>

                      {(securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK' || 
                      (securityCheck==='CP' && txnType=='Maturity') || (securityCheck==='TDR' && txnType=='Maturity') ||
                      (securityCheck==='CDR' && txnType=='Maturity') || (securityCheck==='LOP' && txnType=='Maturity')) && (
                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Accrued Amount</div>
                                <div className="form-group">

                                <NumberFormat thousandSeparator={true}
                            inputMode="numeric" value={moneyMarketSecuritiesEntity['accrued_amount']} className="form-control w-100 text-right" 
                            onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  let samount ='0';
                                  if (securityCheck === 'PIB') {
                                  let Settlement_Amount = (( parseFloat(moneyMarketSecuritiesEntity['units'] || 0) * parseFloat(moneyMarketSecuritiesEntity['price'] || 0) ) + parseFloat(value)).toFixed(2); 
                                  // let prediscount = (parseFloat(Settlement_Amount) - parseFloat(moneyMarketSecuritiesEntity['total_face_vale'] || 0)).toFixed(2);
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                  accrued_amount:value,
                                  settlement_amount: Settlement_Amount,
                                  // premium_discount: prediscount
                                  });
                                }else if(securityCheck==='TFC' || securityCheck==='SUKUK'){
                                  if(txnType=='Buy' || txnType=='Sell'){
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(value) + parseFloat(moneyMarketSecuritiesEntity['premium_discount'] || 0)).toFixed(2);
                                  }else{
                                    samount = (parseFloat(moneyMarketSecuritiesEntity['unredeem_value'] || 0) + parseFloat(value)).toFixed(2);
                                  }
                                  // let prediscount = (parseFloat(samount) - parseFloat(moneyMarketSecuritiesEntity['total_face_vale'] || 0)).toFixed(2);
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                  accrued_amount:value,
                                  settlement_amount: samount,
                                  // premium_discount: prediscount
                                  });
                                }else{
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                  accrued_amount:value
                                  });
                                }
      
                                }} />
                                </div>
                            </div>
                        </Col>
                      </Row>
                      )}

                {txnType!='Coupon' && (
                     <Row>
                          <Col>
                            <div className="form-group">
                                <div>Brokerage</div>
                                <div className="form-group">
                                <NumberFormat thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['brokerage']} className="form-control w-100 text-right" onValueChange={(e) => {
                                        let value = decimalValue(e.value, 2);
                                  // if(securityCheck === 'TDR' || securityCheck ==='CDR' || securityCheck==='LOP' || securityCheck==='CP'){
                                  //     //  let samount = (parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || 0) - parseFloat(value)).toFixed(2);
                                  //   setMoneyMarketSecuritiesEntity({
                                  //     ...moneyMarketSecuritiesEntity,
                                  //     settlement_amount: value,
                                  //     brokerage:value,
                                  //   });
                                  // }else{
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                  brokerage:value,
                                  });
                                // }
                                }} />
                                </div>
                            </div>
                        </Col>
                      </Row>
                      )}

                      {/* <Row>
                        <Col>
                            <div className="form-group">
                                <div>Principal Amount</div>
                                <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['principal_amount']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                  onValueChange={(e) => {
                                    let value = decimalValue(e.value, 2);
                                    if((securityCheck === 'TDR' || securityCheck ==='CDR' || securityCheck==='LOP' || securityCheck==='CP') && (txnType!='Maturity')){
                                      // let samount = (parseFloat(value) - parseFloat(moneyMarketSecuritiesEntity['brokerage'] || 0)).toFixed(2);
                                    setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    settlement_amount: value,
                                    principal_amount:value,
                                  });
                           }else if((securityCheck==='CP' && txnType=='Maturity') || (securityCheck==='TDR' && txnType=='Maturity') ||
                           (securityCheck==='CDR' && txnType=='Maturity') || (securityCheck==='LOP' && txnType=='Maturity')){
                             let couponrate = parseFloat(moneyMarketSecuritiesEntity['yield'] || '0')/100;
                             let accruedAmt  = (((parseFloat(value) * couponrate)/365)*parseFloat(moneyMarketSecuritiesEntity['days_of_maturity'] || '0')).toFixed(2).toString();
                             
                             let settlementAmt =  (parseFloat(value) + parseFloat(accruedAmt)).toFixed(2).toString();
                             moneyMarketSecuritiesEntity['accrued_amount']=accruedAmt;
                             moneyMarketSecuritiesEntity['settlement_amount']=settlementAmt;
                             
                             setMoneyMarketSecuritiesEntity({
                               ...moneyMarketSecuritiesEntity,
                              //  days_of_maturity: days,
                              principal_amount:value,
                              accrued_amount: accruedAmt,
                              settlement_amount: settlementAmt,
                             });
                           }
                           else{
                            setMoneyMarketSecuritiesEntity({
                              ...moneyMarketSecuritiesEntity,
                              principal_amount:value,
                            });
                              // moneyMarketSecuritiesEntity['principal_amount'] = value;
                              // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                           }
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                        </Row> */}

                      <Row>
                      <Col>
                            <div className="form-group">
                                <div>{txnType==='Coupon' ? 'Coupon Maturity Amount' : txnType==='Maturity' ? 'Maturity Amount' : 'Settlement Amount'}</div>
                                <div className="form-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  inputMode="numeric"
                                  value={moneyMarketSecuritiesEntity['settlement_amount']}
                                  className={
                                    'form-control w-100 text-right'
                                  }
                                onValueChange={(e) => {
                               let value = decimalValue(e.value, 2);
                              //  let premiumdiscount= parseFloat(value)-parseFloat(moneyMarketSecuritiesEntity['total_face_value'] || 0);
                               setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                settlement_amount:value,
                                // premium_discount:premiumdiscount
                              });
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                      </Row>

                      {((securityCheck=='TBILL') || (securityCheck=='CP' && txnType=='Maturity') || (securityCheck=='TDR' && txnType=='Maturity') ||
                      (securityCheck=='CDR' && txnType=='Maturity') || (securityCheck=='LOP' && txnType=='Maturity')) && (
                        <Row>
                        <Col>
                          <div className="form-group">
                              <div>Days to Maturity</div>
                              <div className="form-group">
                              <input
                          type="text"
                          value={moneyMarketSecuritiesEntity['days_of_maturity']}
                          className={
                            'form-control w-100 '
                          }
                          onChange={(e) => {
                            let value = decimalValue(e.target.value,0);
                            if((securityCheck=='CP' && txnType=='Maturity') || (securityCheck=='TDR' && txnType=='Maturity') ||
                                    (securityCheck=='CDR' && txnType=='Maturity') || (securityCheck=='LOP' && txnType=='Maturity')){
                                      let couponrate = parseFloat(moneyMarketSecuritiesEntity['yield'] || '0')/100;
                                      let accruedAmt  = (((parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || '0') * couponrate)/365)*parseFloat(value)).toFixed(2).toString();
                                      let settlementAmt =  (parseFloat(moneyMarketSecuritiesEntity['principal_amount'] || '0') + parseFloat(accruedAmt)).toFixed(2).toString();
                                      moneyMarketSecuritiesEntity['accrued_amount']=accruedAmt;
                                      moneyMarketSecuritiesEntity['yield']=value;
                                      moneyMarketSecuritiesEntity['settlement_amount']=settlementAmt;
                                      console.log('accrued_amount',accruedAmt);
                                      
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        days_of_maturity: value,
                                        accrued_amount: accruedAmt,
                                        settlement_amount: settlementAmt,
                                      });
                                    }else{
                                      setMoneyMarketSecuritiesEntity({
                                        ...moneyMarketSecuritiesEntity,
                                        days_of_maturity: value
                                      });
                                }
                          }}
                        />
                              </div>
                          </div>
                      </Col>
                        </Row>
                      )}

                   

                      {(securityCheck === 'PIB' || securityCheck==='TFC' || securityCheck==='SUKUK') && (
                      <Row>
                      <Col>
                            <div className="form-group">
                                <div>Premium / Discount</div>
                                <div className="form-group">
                                <NumberFormat thousandSeparator={true}
                            inputMode="numeric" value={moneyMarketSecuritiesEntity['premium_discount']} className="form-control w-100 text-right" 
                            onValueChange={(e) => {
                                    
                                  moneyMarketSecuritiesEntity['premium_discount'] = e.value;
                                  setMoneyMarketSecuritiesEntity({
                                    ...moneyMarketSecuritiesEntity,
                                    premium_discount:e.value
                                  });
      
                                }} />
                                </div>
                            </div>
                        </Col>
                        </Row>
                      )}

                      </div>
                    </div>
                    <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Party</h4>
                      </div>
                      <div className="card-body">

                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Fund IPS Account</div>
                                <div className="form-group">
                                <input
                            type="text"
                            value={moneyMarketSecuritiesEntity['fund_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              // moneyMarketSecuritiesEntity['fund_ips_account'] = e.target.value;
                              // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                fund_ips_account: e.target.value
                              })
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          <Row>
                          <Col>
                            <div className="form-group">
                                Counter Party IPS Account
                                <div className="form-group">
                                <input
                            type="text"
                            value={moneyMarketSecuritiesEntity['counter_party_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              // moneyMarketSecuritiesEntity['counter_party_ips_account'] = e.target.value;
                              // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                              setMoneyMarketSecuritiesEntity({
                                ...moneyMarketSecuritiesEntity,
                                counter_party_ips_account: e.target.value
                              })
                            }}
                          />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Broker Name</div>
                                <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['broker_name']}
                                  className={'form-control w-100 '}
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['broker_name'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      broker_name: e.target.value
                                    })
                                  }}
                                  
                                />
                                </div>
                            </div>
                        </Col>
                          </Row>

                          <Row>
                          <Col>
                            <div className="form-group">
                                <div>Counter Party Name</div>
                                <div className="form-group">
                                <input
                                  type="text"
                                  value={moneyMarketSecuritiesEntity['counter_party_name']}
                                  className={'form-control w-100 '}
                                  onChange={(e) => {
                                    // moneyMarketSecuritiesEntity['counter_party_name'] = e.target.value;
                                    // setMoneyMarketSecuritiesEntity(moneyMarketSecuritiesEntity);
                                    setMoneyMarketSecuritiesEntity({
                                      ...moneyMarketSecuritiesEntity,
                                      counter_party_name: e.target.value
                                    })
                                  }}
                                />
                                </div>
                            </div>
                        </Col>
                          </Row>

                      </div>
                    </div>
                    </Col>

                    </Row>

                    <div className=" ">

                    <div className=" mt-3">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => {
                          if((txnType==='Sell' || txnType==='Maturity') && (securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK')
                          && (+moneyMarketSecuritiesEntity['units']> +portfolioUnits || +moneyMarketSecuritiesEntity['units']< 0)){
                              toast.error('Units should be less than {'+portfolioUnits+'} and greater than {'+0+'}');
                              return;
                          }else if((txnType==='Sell' || txnType==='Maturity') && (securityCheck==='CP' || securityCheck==='TDR' || securityCheck==='CDR' || securityCheck==='LOP')
                          && (+moneyMarketSecuritiesEntity['principal_amount']> +portfolioAmount)){
                            toast.error('Principal amount should not be greater than {'+portfolioAmount+'}')
                            return;
                          }else if((txnType==='Sell' || txnType==='Maturity') && (securityCheck==='PIB' || securityCheck==='TBILL' || securityCheck==='TFC' || securityCheck==='SUKUK')
                          && (+moneyMarketSecuritiesEntity['money_market_face_value']> +mmFaceValue || +moneyMarketSecuritiesEntity['money_market_face_value']< 0)){
                            toast.error('Money Market Face Value should be less than {'+mmFaceValue+'} and greater than {'+0+'}');
                            return;
                          }else{
                            addMoneyMrketSecurityInArray();
                          }
                           
                        }}  >Add</button>
                    </div>
                </div>
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };

 
  const renderMoneyMarketData = () => {
    return moneyMarketSecurities.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.security_type}</td>
          <td>{items.investment_type}</td>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>  
          <td>{items.maturity_date}</td>
          <td>{items.money_market_face_value}</td>
          <td>{items.price}</td>
          <td>{items.units}</td>
          <td>{items.yield}</td>
          <td>{items.coupon_rate}</td>
          <td>{items.next_coupon_date}</td>
          <td>{items.last_coupon_date}</td>
          <td>{items.primary_dealer}</td>
          <td>{items.fund_ips_account}</td>
          <td>{items.counter_party_ips_account}</td>
          <td>{items.broker_name}</td>
          <td>{items.settlement_amount}</td>
          <td>{items.total_face_value}</td>
          <td>{items.accrued_amount}</td>
          <td>{items.brokerage}</td>
          <td>{items.premium_discount}</td>
          <td>{items.accrued_days}</td>
          <td>{items.counter_party_name}</td>
          <td>{items.detail}</td>
          <td>{items.principal_amount}</td>
          <td>{items.unredeem_value}</td>
          <td>{items.coupon_payment}</td>
          <td>{items.days_of_maturity}</td>
          <td>{items.average_cost_per_unit}</td>
          <td>{items.average_cost_amount}</td>
          <td>{items.gain_loss_per_unit}</td>
          <td>{items.gain_loss_amount}</td>
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...moneyMarketSecurities];
                array.splice(index, 1);
                setMoneyMarketSecurities(array);
                let gross = 0,avgCostAmount=0, gainLossAmt=0, principalAmt=0, totalFv=0,accruedAmt=0,
                premiumDiscount=0, brokerage=0;
                array.map((item, index) => {
                  if (item.type === 'Sell') {
                    gross = gross - parseFloat(item.settlement_amount);
                  } else {
                    gross = gross + parseFloat(item.settlement_amount);
                  }
                  avgCostAmount = avgCostAmount + parseFloat(item.average_cost_amount);
                  gainLossAmt = gainLossAmt + parseFloat(item.gain_loss_amount);
                  principalAmt = principalAmt + parseFloat(item.principal_amount);
                  totalFv = totalFv + parseFloat(item.total_face_vale);
                  accruedAmt = accruedAmt + parseFloat(item.accrued_amount);
                  premiumDiscount = premiumDiscount + parseFloat(item.premium_discount);
                  brokerage = brokerage + parseFloat(item.brokerage);
                });
                if (gross < 0) {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Receipt',
                    gross_amount: (gross * -1).toFixed(2),
                    net_amount: (gross * -1).toFixed(2),
                    average_cost_amount: avgCostAmount.toFixed(2),
                    gain_loss_amount: gainLossAmt.toFixed(2),
                    principal_amount: principalAmt.toFixed(2),
                    total_face_vale: totalFv.toFixed(4),
                    accrued_amount: accruedAmt.toFixed(2),
                    premium_discount: premiumDiscount.toFixed(2),
                    brokerage: brokerage.toFixed(2)
                  }));
                  
                } else {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Payment',
                    gross_amount: gross.toFixed(2),
                    net_amount: gross.toFixed(2),
                    average_cost_amount: avgCostAmount.toFixed(2),
                    gain_loss_amount: gainLossAmt.toFixed(2),
                    principal_amount: principalAmt.toFixed(2),
                    total_face_vale: totalFv.toFixed(4),
                    accrued_amount: accruedAmt.toFixed(2),
                    premium_discount: premiumDiscount.toFixed(2),
                    brokerage: brokerage.toFixed(2)
                  }));
                }
                // setAccTiltleName(items.symbol);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  
  
  const renderPsxData = () => {
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.investment_type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{parseFloat(items.sale_price).toFixed(2)}</td>
          <td>{parseFloat(items.quantity).toFixed(2)}</td>
          <td>{parseFloat(items.avg_rate).toFixed(4)}</td>
          <td>{items.broker}</td>
          <td>{items.commission_rate}</td>
          <td>{items.sst_on_commission}</td>
          {/* <td>{items.capital_value_tax}</td> */}
          <td>{items.net_rate}</td>
          <td>{parseFloat(items.gain_loss_per_unit).toFixed(2)}</td>
          <td>{parseFloat(items.gain_loss_amount).toFixed(2)}</td>
          <td>{parseFloat(items.avg_cost_amount).toFixed(2)}</td>
          <td>{parseFloat(items.gross_amount).toFixed(2)}</td>
          <td>{parseFloat(items.commission_charges).toFixed(2)}</td>
          <td>{parseFloat(items.net_amount).toFixed(2)}</td>
          <td>
          <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...securites];
                array.splice(index, 1);
                setSecurites(array);
                let gross = 0, net=0, sst=0, brokercomission=0, avgCostAmt=0, gainLossAmt=0;

                array.map((item, index) => {
                  if (item.type === 'Sell') {
                    net = net - parseFloat(item.net_amount);
                    gross = gross - parseFloat(item.gross_amount);
                  } else {
                    net = net + parseFloat(item.net_amount);
                    gross = gross + parseFloat(item.gross_amount);
                  }
                  sst= sst+ parseFloat(item.sst_on_commission);
                  brokercomission= brokercomission+ parseFloat(item.commission_charges);

                  avgCostAmt= avgCostAmt+ parseFloat(item.avg_cost_amount);
                  gainLossAmt= gainLossAmt+ parseFloat(item.gain_loss_amount);
                });

                if (net < 0) {
                  let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
                  setEquityInvestmentStatus(status);
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Receipt',
                    gross_amount: (gross * -1).toFixed(2),
                    net_amount: (net * -1).toFixed(2),
                    tax_sst: (sst).toFixed(2),
                    broker_commission: (brokercomission).toFixed(2),
                    average_cost_amount:(avgCostAmt).toFixed(2),
                    gain_loss_amount:(gainLossAmt).toFixed(2),
                  }));
                  let ledgers = getLedgersByTxnAndPaymentype(allTransactionLedgers,headLabel, 'Receipt');
                  console.log('ledgers',ledgers)
                  // setTransactionLedgers(ledgers);
                  // getLedgers(ledgers);
                  
                  // ledgers accounting
                  let totaldebit=0, totalcredit=0;
                  let updatedLedgers = ledgers.map((item:any)=>{
                    if(item.amount_type=='MTM Cost'){
                      item.debit_amount='0';
                      item.credit_amount=(avgCostAmt).toFixed(2);
                    }
                    if(item.amount_type=='Commission Charges' && item.type=='Credit'){
                      item.debit_amount='0';
                      item.credit_amount=(brokercomission).toFixed(2);
                    }
                    if(item.amount_type=='Commission Charges' && item.type=='Debit'){
                      item.debit_amount=(brokercomission).toFixed(2);
                      item.credit_amount='0'
                    }
                    if(item.amount_type=='SST on Commission' && item.type=='Credit'){
                      item.debit_amount='0';
                      item.credit_amount=(sst).toFixed(2);
                    }
                    if(item.amount_type=='SST on Commission' && item.type=='Debit'){
                      item.debit_amount=(sst).toFixed(2);
                      item.credit_amount='0';
                    }
                   if(item.amount_type=='Gross Amount'){
                      item.debit_amount=(gross * -1).toFixed(2);
                      item.credit_amount='0';
                   }
                    if(item.amount_type=='Gain/Loss on Investment'){
                      if(+gainLossAmt<0){
                        item.debit_amount=(gainLossAmt).toFixed(2).toString().replace('-','');
                        item.credit_amount='0';
                        item.type='Debit';
                      }else{
                        item.debit_amount='0';
                        item.credit_amount=(gainLossAmt).toFixed(2);
                        item.type='Credit';
                      }
                  }
                  if(item.type=='Debit'){
                    totaldebit+= +item.debit_amount;
                    }
                   if(item.type=='Credit'){
                    totalcredit+= +item.credit_amount;
                   }
                  return {...item};
                  });
          
                  setTotalDebit(totaldebit.toFixed(2).toString());
                  setTotalCredit(totalcredit.toFixed(2).toString());
                  setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                  setTransactionLedgers(updatedLedgers);
                } else {
                  let status: any = gross < 0 ? 'Credit' : 'Debit';
                  setEquityInvestmentStatus(status);
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Payment',
                    gross_amount: gross.toFixed(2),
                    net_amount: net.toFixed(2),
                    tax_sst: sst.toFixed(2),
                    broker_commission: brokercomission.toFixed(2),
                    average_cost_amount:(avgCostAmt).toFixed(2),
                    gain_loss_amount:(gainLossAmt).toFixed(2),
                  }));
                  let ledgers = getLedgersByTxnAndPaymentype(allTransactionLedgers,headLabel, 'Payment');
                  let totaldebit=0, totalcredit=0;
                  let updatedLedgers = ledgers.map((item:any)=>{
          
                    if(item.amount_type=='Commission Charges' && item.type=='Credit'){
                      item.debit_amount='0';
                      item.credit_amount=(brokercomission).toFixed(2);
                    }
                    if(item.amount_type=='Commission Charges' && item.type=='Debit'){
                      item.debit_amount=(brokercomission).toFixed(2);
                      item.credit_amount='0'
                    }
                    if(item.amount_type=='SST on Commission' && item.type=='Credit'){
                      item.debit_amount='0';
                      item.credit_amount=(sst).toFixed(2);
                    }
                    if(item.amount_type=='SST on Commission' && item.type=='Debit'){
                      item.debit_amount=(sst).toFixed(2);
                      item.credit_amount='0';
                    }
                   if(item.amount_type=='Gross Amount' && item.type=='Debit'){
                      item.debit_amount=(gross).toFixed(2);
                      item.credit_amount='0';
                   }
                   if(item.amount_type=='Gross Amount' && item.type=='Credit'){
                    item.debit_amount='0';
                    item.credit_amount=(gross).toFixed(2);
                 }
                  if(item.type=='Debit'){
                    totaldebit+= +item.debit_amount;
                    }
                   if(item.type=='Credit'){
                    totalcredit+= +item.credit_amount;
                   }
                  return {...item};
                  });
          
                  setTotalDebit(totaldebit.toFixed(2).toString());
                  setTotalCredit(totalcredit.toFixed(2).toString());
                  setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                  setTransactionLedgers(updatedLedgers);
                }
                setAccTiltleName(items.symbol);
              }}
            />
            {/* <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...securites];
                array.splice(index, 1);
                setSecurites(array);
                let gross = 0;
                array.map((item, index) => {
                  if (item.type === 'Sale') {
                    gross = gross - parseFloat(item.net_amount);
                  } else {
                    gross = gross + parseFloat(item.net_amount);
                  }
                });
                if (gross < 0) {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Receipt',
                    gross_amount: (gross * -1).toFixed(2),
                    net_amount: (gross * -1).toFixed(2),
                  }));
                } else {
                  setFields((prevState) => ({
                    ...fields,
                    payment_type: 'Payment',
                    gross_amount: gross.toFixed(2),
                    net_amount: gross.toFixed(2),
                  }));
                }
                setAccTiltleName(items.symbol);
              }}
            /> */}
          </td>
        </tr>
      );
    });
  };

  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === '') || (value.substring(0,1)==='-')) {
      return 'required-border';
    } else {
      return '';
    }
  };
  //

  const renderStockData = () => {
    return stockMarketData.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td className="text-right">{parseFloat(items.unit)}</td>
          <td className="text-right">{parseFloat(items.close_rate).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const myRef1 = React.useRef<HTMLInputElement>(null);
  const [filename, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [datafetch, setDataFetch] = useState<any>([]);
  const [equityInvestmentStatus, setEquityInvestmentStatus] = useState('');

  const addUploadedSecurityInArray = (tempData) => {
      securites.push(tempData);
      setSecurites(securites);
    let gross = 0, net=0, sst=0, brokercomission=0;
    securites.map((item, index) => {
      if (item.type === 'Sell') {
        net = net - parseFloat(item.net_amount);
        gross = gross - parseFloat(item.gross_amount);
      } else {
        net = net + parseFloat(item.net_amount);
        gross = gross + parseFloat(item.gross_amount);
      }
      sst= sst+ parseFloat(item.sst_on_commission);
      brokercomission= brokercomission + parseFloat(item.commission_charges);
    });
    if (net < 0) {
      let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
      setEquityInvestmentStatus(status);
      setFields((prevState) => ({
        ...fields,
        payment_type: 'Receipt',
        gross_amount: (gross * -1).toFixed(2),
        net_amount: (net * -1).toFixed(2),
        tax_sst: (sst).toFixed(2),
        broker_commission: (brokercomission).toFixed(2),
      }));
    } else {
      let status: any = net < 0 ? 'Credit' : 'Debit';
      setEquityInvestmentStatus(status);
      setFields((prevState) => ({
        ...fields,
        payment_type: 'Payment',
        gross_amount: gross.toFixed(2),
        net_amount: net.toFixed(2),
        tax_sst: sst.toFixed(2),
        broker_commission: brokercomission.toFixed(2),
      }));
    }
  };

  const setDataFunction = (temp: any) => {
    temp.map((item, index) => {
      let obj = {
        gross_amount: item.Value,
        net_amount: item.Value,
        market: item.MarketType,
        type: item.BuySell === 'B' ? 'Buy' : 'Sell',
        symbol: item.Symbol,
        quantity: item.Volume,
        avg_rate: item.AveragePrice,
        commission_charges: '0',
        commission_rate : (parseFloat('0')/parseFloat(item.Volume === '-' ? item.Volume.substring(1) : item.Volume)).toFixed(2),
        sst_on_commission: (0 * 13/100).toFixed(2),
        net_rate : (parseFloat(item.AveragePrice)-(parseFloat('0')/parseFloat(item.Volume === '-' ? item.Volume.substring(1) : item.Volume))).toFixed(2)
      };
      addUploadedSecurityInArray(obj);
    });
  };

  async function processLineByLine(fileObj) {
    var reader = new FileReader();
    reader.readAsText(fileObj);
    let content:any=[];
    reader.onload = function(fileObj) {
     content = reader.result;
     content = content.split('\n');
     let headings = content[0].split(':')
     let code = headings[2].replace(/"/g, '');
     let date = headings[1].replace(/"/g, '');
     moment.defaultFormat = "DD/MM/YYYY";
    //  if(code===fundCDCParticipantID && moment(date,moment.defaultFormat).format('YYYY-MM-DD')===fields['execution_date']){
     if(code===fundCDCParticipantID && moment(date).format('YYYY-MM-DD')===fields['execution_date']){
     let column = [] as any;
     var data = [] as any;
     var i = 0;
     let TotalLine= content.length-1;
     for (i=0;i<TotalLine; i++){
     content[i] = content[i].replaceAll('"','').trim();
     let str = content[i].split(':');
     if(i==1){
          column = str.map(function (x) {
        return x.replace(/ /g, '');
      });
      column = column.map(function (x) {
        return x.replace('/', '');
      });
    }
    if(i>=2){
    let obj = {
        [column[0]]: str[0],
        [column[1]]: str[1],
        [column[2]]: str[2],
        [column[3]]: str[3],
        [column[4]]: str[4],
        [column[5]]: str[5],
        [column[6]]: str[6],
        [column[7]]: str[7],
        [column[8]]: str[8],
        [column[9]]: str[9],
        [column[10]]: str[10],
        [column[11]]: str[11],
      };
      data.push(obj);
  }
}
  setDataFetch(data);
  setDataFunction(data);
}else{
  setFileName('');
  setFile('');
  toast.error("Invalid file for the selected fund or you are uploading previous file.")
  // toast.error("Invalid file for the selected fund.")
}
}
}

  const uploadCDCFile = (e: any) => {
    securites.length=0;
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (type === 'txt') {
    setFileName(file.name);
    setFile(file);
    processLineByLine(file);
    }else{
      toast.error('Please Upload Correct Format of File');
    }
  };

  // upload Excelfile
   const uploadExcel =(e)=>{
    let file = e?.target.files[0];
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);
    if(type==="xlsx" || type === 'csv'){
      
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
      /* Parse data */
      const bstr = e.target?.result;
      const wb = xls.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data:any = xls.utils.sheet_to_json(ws, { header: 2,
        raw: false,
        defval: "",
        dateNF: 'YYYY-MM-DD', });
      /* Update state */
    
     if(data){
       let fundCode = data[0].FundCode;
       if(fields['fund_code']==''){
         return toast.error('Select Fund First!');
       }
       if(fundCode!=fields['fund_code']){
         return toast.error('Invalid File for Selected Fund!');
       }else{
        setExcelFile(file);
        setExcelFileName(file.name);
      let updatedData = data.map((item:any)=>{
        return{
          gross_amount: item.GrossAmount.toString(),
          net_amount: item.NetAmount.toString(),
          market: item.Market.toString(),
          investment_type: item.InvestmentType.toString(),
          sst_on_commission:item.SSTOnCommission.toString(),
          commission_rate:item.CommissionRate.toString(),
          net_rate:item.NetRate.toString(),
          type: item.Type.toString(),
          symbol: item.Symbol.toString(),
          quantity: item.Quantity.toString(),
          avg_rate: item.AvgRate.toString(),
          commission_charges: item.CommissionCharges.toString(),
          broker:item.Broker.toString(),
          sale_price:item.SalePrice.toString(),
          avg_cost_amount:item.AvgCostAmount.toString(),
          gain_loss_per_unit:item.GainLossPerUnit.toString(),
          gain_loss_amount:item.GainLossAmount.toString(),
        }
      })
      processExcelData(updatedData)
      //setFileDataCount(data.length)
    }
     } // end of data
    
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  
    } else {
      toast.error('Please Upload Correct Format of File');
    }
   }

   const processExcelData = (data)=>{
    securites.push(...data);
    setSecurites(securites);
    let gross = 0, net=0, sst=0, brokercomission=0, avgCostAmt=0, gainLossAmt=0;
    securites.map((item:any, index:number) => {
      console.log('item',item)
    if (item.type === 'Sell') {
      net = net - parseFloat(item.net_amount);
      gross = gross - parseFloat(item.gross_amount);
    } else {
      net = net + parseFloat(item.net_amount);
      gross = gross + parseFloat(item.gross_amount);
    }
    sst= sst+ parseFloat(item.sst_on_commission);
    brokercomission= brokercomission + parseFloat(item.commission_charges);
    avgCostAmt = avgCostAmt + parseFloat(item.avg_cost_amount);
    gainLossAmt = gainLossAmt + parseFloat(item.gain_loss_amount);
  });
    if (net < 0) {
        let status: any = net * -1 < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Receipt',
          gross_amount: (gross * -1).toFixed(2).toString(),
          net_amount: (net * -1).toFixed(2).toString(),
          tax_sst: (sst).toFixed(2).toString(),
          broker_commission: (brokercomission).toFixed(2).toString(),
          average_cost_amount: (avgCostAmt).toFixed(2).toString(),
          gain_loss_amount: (gainLossAmt).toFixed(2).toString(),
        }));
        let ledgers = getLedgersByTxnAndPaymentype(allTransactionLedgers,headLabel, 'Receipt');
        console.log('ledgers',ledgers)
        // ledgers accounting
        let totaldebit=0, totalcredit=0;
        let updatedLedgers = ledgers.map((item:any)=>{
          if(item.amount_type=='MTM Cost'){
            item.debit_amount='0';
            item.credit_amount=(avgCostAmt).toFixed(2);
          }
          if(item.amount_type=='Commission Charges' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(brokercomission).toFixed(2);
          }
          if(item.amount_type=='Commission Charges' && item.type=='Debit'){
            item.debit_amount=(brokercomission).toFixed(2);
            item.credit_amount='0'
          }
          if(item.amount_type=='SST on Commission' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(sst).toFixed(2);
          }
          if(item.amount_type=='SST on Commission' && item.type=='Debit'){
            item.debit_amount=(sst).toFixed(2);
            item.credit_amount='0';
          }
         if(item.amount_type=='Gross Amount'){
            item.debit_amount=(gross * -1).toFixed(2);
            item.credit_amount='0';
         }
          if(item.amount_type=='Gain/Loss on Investment'){
            if(+gainLossAmt<0){
              item.debit_amount=(gainLossAmt).toFixed(2).toString().replace('-','');
              item.credit_amount='0';
              item.type='Debit';
            }else{
              item.debit_amount='0';
              item.credit_amount=(gainLossAmt).toFixed(2);
              item.type='Credit';
            }
        }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
          }
         if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
         }
        return {...item};
        });

        setTotalDebit(totaldebit.toFixed(2).toString());
        setTotalCredit(totalcredit.toFixed(2).toString());
        setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
        setTransactionLedgers(updatedLedgers);
        
      } else {
        let status: any = net < 0 ? 'Credit' : 'Debit';
        setEquityInvestmentStatus(status);
        setFields((prevState) => ({
          ...fields,
          payment_type: 'Payment',
          gross_amount: gross.toFixed(2).toString(),
          net_amount: net.toFixed(2).toString(),
          tax_sst: sst.toFixed(2).toString(),
          broker_commission: brokercomission.toFixed(2).toString(),
          average_cost_amount: (avgCostAmt).toFixed(2).toString(),
          gain_loss_amount: (gainLossAmt).toFixed(2).toString(),
        }));
        let ledgers = getLedgersByTxnAndPaymentype(allTransactionLedgers,headLabel, 'Payment');
        console.log('ledgers',ledgers)
        // getLedgers(ledgers);
        // setTransactionLedgers(ledgers)
        // ledgers accounting
        let totaldebit=0, totalcredit=0;
        let updatedLedgers = ledgers.map((item:any)=>{

          if(item.amount_type=='Commission Charges' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(brokercomission).toFixed(2);
          }
          if(item.amount_type=='Commission Charges' && item.type=='Debit'){
            item.debit_amount=(brokercomission).toFixed(2);
            item.credit_amount='0'
          }
          if(item.amount_type=='SST on Commission' && item.type=='Credit'){
            item.debit_amount='0';
            item.credit_amount=(sst).toFixed(2);
          }
          if(item.amount_type=='SST on Commission' && item.type=='Debit'){
            item.debit_amount=(sst).toFixed(2);
            item.credit_amount='0';
          }
         if(item.amount_type=='Gross Amount' && item.type=='Debit'){
            item.debit_amount=(gross).toFixed(2);
            item.credit_amount='0';
         }
         if(item.amount_type=='Gross Amount' && item.type=='Credit'){
          item.debit_amount='0';
          item.credit_amount=(gross).toFixed(2);
       }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
          }
         if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
         }
        return {...item};
        });

        setTotalDebit(totaldebit.toFixed(2).toString());
        setTotalCredit(totalcredit.toFixed(2).toString());
        setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
        setTransactionLedgers(updatedLedgers);
      } 
    
   }


  const [viewBankBalanceModal, setViewBankBalanceModal]= useState(false);
  const [underStand, setUnderStand] = useState(false)
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
             <div className="modal-header ">
              <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setUnderStand(false);
                    setViewBankBalanceModal(false); 
                  }}
              >
                  <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
          </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <div></div> Balance
                            <input type="number"
                            value={bankBalance || '0'}
                            readOnly
                            className="form-control w-100" />
                               
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            Detail*
                            <input
                              type="text"
                              defaultValue={fields['detail']}
                              className={
                                'form-control w-100 '
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  detail: e.target.value,
                                });
                              }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                    <div className="form-inline">
                        <div className="form-group mr-2">
                            <input
                              type="checkbox"
                              // defaultValue={underStand}
                              // className={
                              //   'form-control w-100 '
                              // }
                              onChange={(e) => {
                               setUnderStand(!underStand)
                              }}
                            />
                        </div>
                        <span>I understand</span>
                    </div>
                    </div>
                    </div>
                    {underStand===true ? (
                      <>
                    <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: 'Yes',
                          });
                          createTransaction();
                          
                        }}>
                            {Loading ? (
                        <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                      </>
                      ) : (
                        <span>Proceed</span>
                      )}
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-info btn-block" onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}>
                           <span>Cancel</span>
                        </button>
                    </div>
                       </div>
                       </>
                       ):(
                         ''
                       )}
                {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const getLedgesDetailByLedgerCode = async(ledgerCode)=>{
    try{
      const response = await getLedgerInfoByCode(email,ledgerCode)
      return response.data.data;

    }catch(error:any){

    }
  }

  const [viewModalForLedgers, setViewModalForLedgers] = useState(false);
  // add more ledgers
  const renderModalForAddMoreLedgers = () => {
    switch (viewModalForLedgers) {
      case true:
        return (
            <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { 
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
             }}
        > 
        <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewModalForLedgers(false);
                clearFields();
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add New Ledger</h6>
          </div>

            <div className="modal-body">
                <div className="  m-3">

                <Row> 
                  <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Ledgers</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                        <Col>
                        <div className="form-group " data-tip="Select Ledger">
                  {ledgeroptions.length > 0 ? (
                    <Autocomplete
                      className="mt-2 border-bottom autocomplete border-gray"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgeroptions}
                      style={{ width: "100%" }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{ background: "#1E1E2F", color: "white" }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={selectedLedgerCode}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedLedgerCode(newValue);
                        // console.log('onChange',event);
                      }}
                      inputValue={selectedInputLedgerCode}
                      onInputChange={(event, newInputValue) => {
                        setSelectedInputLedgerCode(newInputValue);
                        // console.log('onInputChange',event);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <div className="form-control w-100">
                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                    </div>
                  )}
                </div>
                          </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Amount Type
                              <select
                                className="form-control w-100"
                                value={ledgerAmountType}
                                onChange={(e) => {
                                   setLedgerAmountType(e.target.value);
                                   if(e.target.value=='Gross Amount'){
                                     setLedgerAmount(fields['gross_amount'].toString())
                                   }
                                   if(e.target.value=='Sale load'){
                                    setLedgerAmount(fields['sale_load'].toString())
                                  }
                                  if(e.target.value=='CGT'){
                                    setLedgerAmount(fields['tax_cgt'].toString())
                                  }
                                  if(e.target.value=='Redemption load'){
                                    setLedgerAmount(fields['redemption_load'])
                                  }
                                  if(e.target.value=='Zakat'){
                                    setLedgerAmount(fields['zakat_amount'].toString())
                                  }
                                  if(e.target.value=='Net Amount'){
                                    setLedgerAmount(fields['net_amount'].toString())
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Amount Type
                            </option>
                              <option value="Gross Amount">Gross Amount</option>
                              <option value="Sale load">Sale load</option>
                              {(headLabel==='redemptionofunits' || headLabel==='conversionout') && (
                                <>
                                <option value="Redemption load">Redemption load</option>
                                <option value="CGT">CGT</option>
                                <option value="Zakat">Zakat</option>
                                </>
                              )}
                              <option value="Net Amount">Net Amount</option>
                              <option value="Other">Other</option>
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Type
                              <select
                                className="form-control w-100"
                                value={ledgerType}
                                onChange={(e) => {
                                   setLedgerType(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                              <option value="Debit">Debit</option>
                              <option value="Credit">Credit</option>
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                          <div className="form-group">
                          Amount
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerAmount}
                            onChange={(e) => {
                             setLedgerAmount(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>

                  <Row>
                        <Col>
                          <div className="form-group">
                          Description
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerDescription}
                            onChange={(e) => {
                             setLedgerDescription(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>
                  <Row>
                        <Col>
                          <div className="form-group">
                          Invoice
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerInvoice}
                            onChange={(e) => {
                             setLedgerInvoice(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>

                      </div>
                    </div>
                    </Col>

                    </Row>

                    <div className=" ">

                    <div className=" mt-3">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => {
                          if(selectedInputLedgerCode==''){
                            toast.error('Select Ledger');
                            return;
                          }
                          if(ledgerAmount==''){
                            toast.error('Enter Amount');
                            return;
                          }
                          if(ledgerType==''){
                            toast.error('Select Type');
                            return;
                          }
                          addLedgersInArray();
                          setViewModalForLedgers(false);
                        }}  >Add</button>
                    </div>
                </div>
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };

  const addLedgersInArray=async()=>{
    const ledgerInfo = await getLedgesDetailByLedgerCode(selectedInputLedgerCode.split("-")[0].trim());

    // if(ledgerType=='Debit'){
    //   let totaldebit =(+totalDebit + +ledgerAmount).toString()
    //   let totalcredit =(+fields['sale_load'] + +fields['net_amount'] + 0).toString();
    //   setTotalDebit(totaldebit);
    //   setTotalCredit(totalcredit);
    //   setBalance((+totaldebit - +totalcredit).toString())
    // }else{
    //   let totaldebit =(+totalDebit).toString()
    //   let totalcredit =(+fields['sale_load'] + +fields['net_amount'] + +ledgerAmount).toString();
    //   setTotalDebit(totaldebit);
    //   setTotalCredit(totalcredit);
    //   setBalance((+totaldebit - +totalcredit).toString())
    // }
    let newLedger = {
      amc_code:amc_code,
      fund_code:fields['fund_code'],
      account_number:fields['account_no'],
      account_title:fields['account_title'],
      bank_code:fields['bank'],
      branch:fields['branch'],
      child_family_code:ledgerInfo.child_family_code,
      child_family_name:ledgerInfo.child_family_name,
      counter_account_number:fields['counter_account_number'],
      counter_account_title:fields['counter_account_title'],
      counter_bank:fields['bank'],
      counter_branch:fields['branch'],
      credit_amount:ledgerType=='Credit' ? ledgerAmount : '0',
      debit_amount:ledgerType=='Debit' ? ledgerAmount : '0',
      description:ledgerDescription,
      entered_by:'',
      evidence:'',
      family_code:ledgerInfo.family_code,
      family_name:ledgerInfo.family_name,
      gl_code:+(selectedInputLedgerCode.split("-")[0].replace(/\s+/g, "")),
      gl_name:selectedInputLedgerCode.split("-")[1],
      instruction_date:fields['instruction_date'],
      instrument_by:'',
      instrument_date:fields['instrument_date'],
      instrument_no:fields['instrument_no'],
      instrument_type:fields['instrument_type'],
      main_family_code:ledgerInfo.main_family_code,
      main_family_name:ledgerInfo.main_family_name,
      realized:'',
      realized_by:'',
      realized_date:'',
      sub_family_code:ledgerInfo.sub_family_code,
      sub_family_name:ledgerInfo.sub_family_name,
      amount_type:ledgerAmountType,
      type:ledgerType
    }
    setTransactionLedgers([...transactionLedgers, newLedger]);
    let totaldebit=0, totalcredit=0;
    let data =[...transactionLedgers, newLedger]
    data.map((item:any)=>{
      if(item.type=='Debit'){
        totaldebit+= +item.debit_amount;
        }
       if(item.type=='Credit'){
        totalcredit+= +item.credit_amount;
       }
    });
    console.log('totaldebit',totaldebit)
    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
    console.log([...transactionLedgers, newLedger])

    clearFields();
  }

  const clearFields=()=>{
    setSelectedLedgerCode('');
    setSelectedInputLedgerCode('');
    setSelectedLedgerName('');
    setLedgerType('');
    setLedgerAmount('');
    setLedgerDescription('');
    setLedgerInvoice('');
  }

  return (
    <>
     <div className="content">
      <Row>
       <Col md="12">
      <Container fluid>
        <ToastContainer />
        <div className="">
        <div className="input-holder"> 
                  <div className="row">
                    <h4 className=" card-title">
                        <i className="fa fa-angle-left mr-2 ml-3 mt-1 pointer" onClick={() => {
                            history.replace('/admin/create-transaction')
                        }}></i>
                        {CapTxnType(headLabel)} Transaction</h4> 
                    </div>

                <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm mr-3" replace>
                     <i className="fa fa-upload mr-2"></i> Bulk Upload
               </Link> 

              </div>
          <div className=" ">
            
            <Row >
              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Instruction</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      {/* fund dropdown , itx value depend on amc value  */}
                      <div
                        className="form-group "
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div>
                        ) : (
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['fund_code'])
                            }
                            value={fields['fund_code']}
                            onChange={(e) => {
                              fields['fund_code'] = e.target.value;
                              setFields(fields);
                              
                              clearFundAccountFields();
                              getAccountByFundName(e.target.value);
                              if (headLabel === 'unitconversion') {
                                let filterToAcc = allFunds.filter(
                                  (acc) => acc.symbol_code !== e.target.value
                                );
                                let temp = filterToAcc.map((val) => {
                                  if(val.nature==='Special Managed Account'){
                                    return {
                                      ...val,
                                      label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
                                      value: val.symbol_code,
                                    };
                                  }else{
                                  return {
                                    ...val,
                                    label: `${val.fund_name} (${val.symbol_code})`,
                                    value: val.symbol_code,
                                  };
                                }
                                });
                                setAllCounterFunds(temp);
                              }

                              if(headLabel==='equityinvestment'){
                                let filter_CDC_Participant_ID = allFunds.filter(
                                  (acc) => acc.symbol_code == e.target.value
                                );
                                setCDCParticipantID(filter_CDC_Participant_ID[0].cdc_participant_id)
                              }

                              if(headLabel==='cashdividend' || headLabel==='pledgedin' || headLabel==='pledgedout'){
                              {
                                  getFundStockMarketData(e.target.value)}
                              }
                                // // clear counter account
                                // if(headLabel)
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {fundCheck ? 'No Fund Found' : 'Select Fund'}
                            </option>
                            {renderFundsDropdown()}
                          </select>
                        )}
                      </div>
                      <div className="form-group">
                        <div>Instruction Date</div>
                        <input
                          type="date"
                          value={fields['instruction_date']}
                          readOnly
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['instruction_date'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              instruction_date: e.target.value,
                              //realized_date: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {/* <div>Execution Date</div> */}
                        <div>Settlement Date</div>
                        <input
                          type="date"
                          value={fields['execution_date']}
                          min={disableDates}
                          className={'form-control w-100' + ErrorBorder(fields['execution_date'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              execution_date: e.target.value,
                              settlement_date: e.target.value
                            });
                          }}
                        />
                      </div>
                      {headLabel!=='pledgedin' && headLabel!=='pledgedout' && (
                      <div className="form-group">
                        <div>Mode of Payment*</div>
                        <select
                          value={fields['mode_of_payment']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['mode_of_payment'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              mode_of_payment: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Payment
                          </option>
                          {renderModeOfPayments()}
                        </select>
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>

              {(headLabel==='pledgedin' || headLabel==='pledgedout') && (
                    <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">{CapTxnType(headLabel)}</h4>
                    </div>
                    <div className="card-body">
                      <div>

                      {chechFieldDisplay(headLabel, 'symbol') && (
                          <div className="form-group">
                            <div>Symbol*</div>
                            <select
                              className={
                                'form-control w-100 ' + ErrorBorder(fields['symbol'])
                              }
                              defaultValue={fields['symbol']}
                              onChange={(e) => {
                                if(headLabel==='pledgedin'){
                                  let vol= stockMarketDataFund.filter((item, index)=>{
                                    return (item.code===e.target.value);
                                    });
                                    let plegged = +vol[0].unit - +vol[0].pledged
                                    setPledgedHolding(plegged.toString());
                                    setFields({
                                      ...fields,
                                      symbol: e.target.value,
                                    });

                                }else if(headLabel==='pledgedout'){
                                  let vol= stockMarketDataFund.filter((item, index)=>{
                                    return (item.code===e.target.value);
                                    });
                                    setPledgedHolding(vol[0].pledged);
                                    setFields({
                                      ...fields,
                                      symbol: e.target.value,
                                    });
                                }else{
                                let vol= stockMarketDataFund.filter((item, index)=>{
                                  return (item.code===e.target.value);
                                  })
                                setFields({
                                  ...fields,
                                  volume: vol[0].unit,
                                  symbol: e.target.value,
                                });
                              }
                                // let vol= stockMarketDataFund.filter((item, index)=>{
                                // return (item.code===e.target.value);
                                // })
                                // setVolume(vol[0].unit);
                              }}
                            >
                              <option value="" defaultChecked hidden>
                                {' '}
                                Select Type
                              </option>
                              {renderStockMarketData()}
                            </select>
                          </div>
                        )}
                        {(headLabel==='pledgedin' || headLabel==='pledgedout') && (
                          <div className="form-group">
                            <div>Available for {CapTxnType(headLabel)}</div>
                            <input
                              type="number"
                              defaultValue={pledged_holding}
                              className={
                                'form-control w-100 '
                              }
                              readOnly
                            />
                          </div>
                        )}

                      {chechFieldDisplay(headLabel, 'volume') && (
                        <div className="form-group">
                            <div>Shares to {CapTxnType(headLabel)}</div>
                            <input
                              type="number"
                              value={fields['volume']}
                              className={
                                'form-control w-100 '+ ErrorBorder(fields['volume'])
                              }
                              onChange={(e) => {
                                let value=e.target.value;
                                if(headLabel==='pledgedin'){
                                let pledged = parseInt(pledged_holding) - parseInt(value);
                                if(pledged>0){
                                setFields({
                                  ...fields,
                                  volume: value,
                                });
                              }
                            }else{
                              if(parseInt(value) <= parseFloat(pledged_holding) || value==='' ){
                                setFields({
                                  ...fields,
                                  volume: value,
                                });
                              }
                            }
                              }}
                            />
                          </div>
                        )}


                      </div>
                    </div>
                  </div>
                </Col>
                  )}

              {(headLabel!=='pledgedin' && headLabel!=='pledgedout') && (
              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      {headLabel === 'fundtransfer'
                        ? 'From Account'
                        : 'Fund Account'}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div>

                      <div className="form-group">
                        <div>Fund Account*</div>
                        {accLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div>
                        ) : fields['fund_code'].trim() === '' ? (
                          <div className="form-control    w-100">
                            Kindly First Select Fund
                          </div>
                        ) : (
                            <select className='form-control' value={fields['fund_account']} onChange={(e) => {
                                fields['fund_account']=e.target.value;
                                setFields(fields);
                                setFundAccountFieldsData(e.target.value);
                              }}>
                            <option value="" defaultChecked hidden>
                            {' '}
                            Select Fund Account
                          </option>
                            {renderFundAccounts()}
                          </select>
                        )}
                      </div>

                      <div className="form-group">
                        <div>Account Title*</div>
                        <input
                          type="text"
                          value={fields.account_title}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['account_title'])
                          }
                          readOnly
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              account_title: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div>Account Number*</div>
                        <input
                          type="text"
                          value={fields['account_no']}
                          className={
                            'form-control w-100 ' + ErrorBorder(fields['account_no'])
                          }
                          readOnly
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              account_no: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group w-100 d-flex">
                        <div className="w-50 flex mr-2">
                          <div>Bank</div>
                          <div className="form-group">
                          <input className="form-control"
                              value={fields['bank']}
                              readOnly
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    bank: e.target.value,
                                  });
                                }} />
                                </div>
                          {/* <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={'Select Bank'}
                            isDisabled={false}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                bank: e.value,
                              });
                              getBranchByName(e.value);
                            }}
                            value={bankNameData.filter(
                              (option) => option.value === fields['bank']
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={bankNameData}
                          /> */}
                        </div>
                        <div className="w-50 flex">
                          <div>Branch</div>
                          <div className="form-group">
                          <input className="form-control"
                              value={fields['branch']}
                              readOnly
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    branch: e.target.value,
                                  });
                                }} />
                                </div>

                          {/* {branchInputType ? (
                            <div className="d-flex">
                              <input
                                type="text"
                                value={fields['branch']}
                                className="form-control w-100 "
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    branch: e.target.value,
                                  });
                                }}
                              />
                              <RiArrowGoForwardFill
                                onClick={() => {
                                  setBranchInputType(false);
                                  setFields({
                                    ...fields,
                                    branch: '',
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select Branch'}
                              isDisabled={false}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  branch: e.value,
                                });
                                onOtherBranchSelection(e.value);
                              }}
                              value={allbranchesData.filter(
                                (option) => option.value === fields['branch']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={allbranchesData}
                            />
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              )}

              {(headLabel!=='pledgedin' && headLabel!=='pledgedout') && (
              <Col>
                <div className="card">
                  <div className="card-header">
                    {/* condition to for label selection */}
                    <h4 className="card-title">
                      {headLabel === 'fundtransfer'
                        ? 'To Account'
                        : 'Counter Account'}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div>

                        <div className="form-group">
                          <ReactTooltip
                            textColor="black"
                            backgroundColor="white"
                            effect="float"
                          />

                          <div className="text-capitalize">
                            {counterAccounter === 'Fund'
                              ? 'Fund Account'
                              : counterAccounter}
                          </div>

                          {counterAccounter === 'Fund' ? (
                            //For fund
                            <div data-tip="First Select Fund Name">
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accLoading ? (
                                <div className="form-control w-100">
                                   <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                                </div>
                              ) : fields['fund_code'].trim() === '' ? (
                                <div className="form-control    w-100">
                                  First Select Fund
                                </div>
                              ) : (
                                <Select
                                className="react-select info"
                                classNamePrefix={"react-select "+ ErrorBorder(fields['counter_account_type'])}
                                  label={'Select'}
                                  isDisabled={false}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      counter_account_type: e.value,
                                    });
                                    setFundDataCounter(e.value);
                                  }}
                                  value={toAccountData.filter(
                                    (option) =>
                                      option.value ===
                                      fields['counter_account_type']
                                  )}
                                  isClearable={false}
                                  isSearchable={true}
                                  name="color"
                                  options={toAccountData}
                                />
                              )}
                            </div>
                          ) : //For Other Counter Amc
                          counterAccounter === 'amc' ? (
                            accFundLoading ? (
                              <div className="form-control w-100">
                                 <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                              <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  let taxCollector='';
                                  if(e.value==='Other'){
                                    taxCollector='Other'
                                  }else{
                                 let amcTaxCollector = amcdataCounter.filter((item) =>
                                  item.value ===e.value)

                                  taxCollector=amcTaxCollector[0].tax_collector;
                                  }
                                  
                                  fields['counter_account_type'] = e.value;
                                  fields['sales_tax_collector'] = taxCollector;
                                  setFields(fields);
                                  // setFields({
                                  //   ...fields,
                                  //   counter_account_type: e.value,
                                  // });
                                  setAmcAccountData(e.value);
                                }}
                                value={amcdataCounter.filter(
                                  (option) =>
                                    option.value ===
                                    fields['counter_account_type']
                                )}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={amcdataCounter}
                              />
                            )
                          ) : //For Other Counter Accounts

                          counterAccounter === 'Other' ? (
                            <input
                              type="text"
                              value={fields['counter_account_type']}
                              className="form-control w-100"
                              readOnly
                            />
                          ) : (
                            //For Other Brokers
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select'}
                              isDisabled={false}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  counter_account_type: e.value,
                                });
                                setBrokerData(e.value);
                              }}
                              value={borkerByTypeData.filter(
                                (option) =>
                                  option.value ===
                                  fields['counter_account_type']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={borkerByTypeData}
                            />
                          )}
                        </div>

                      <div className="form-group">
                        <div>Account Title</div>
                        <input
                          type="text"
                          value={fields['counter_account_title']}
                          className={'form-control w-100 '+ErrorBorder(fields['counter_account_title'])}
                          onChange={(e) => {
                            if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                              {
                                counterType='Other';
                                setCounterType(counterType);
                                setFields({
                                  ...fields,
                                  counter_account_title: e.target.value || '',
                                });
                              }
                            // setFields({
                            //   ...fields,
                            //   counter_account_title: e.target.value,
                            // });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div>Account Number</div>
                        <input
                          type="text"
                          value={fields['counter_account_number']}
                          className={'form-control w-100 '}
                          onChange={(e) => {
                            if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                            {
                              counterType='Other';
                              setCounterType(counterType);
                              setFields({
                              ...fields,
                              counter_account_number: e.target.value,
                              });
                            }
                            // setFields({
                            //   ...fields,
                            //   counter_account_number: e.target.value,
                            // });
                          }}
                        />
                      </div>
                      <div className="form-group w-100 d-flex">
                        <div className="w-50 flex mr-2">
                          <div>Bank</div>
                          {/* <div className="form-group">
                          <input className="form-control"
                              value={fields['counter_bank']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_bank: e.target.value,
                                  });
                                }} />
                                </div> */}
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={'Select Bank'}
                            // isDisabled={false}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                counter_bank: e.value,
                              });
                              getCounterBranchByName(e.value);
                              termDepositEntity['bank']=e.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            value={bankNameData.filter(
                              (option) =>
                                option.value === fields['counter_bank']
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={bankNameData}
                          />
                        </div>

                        <div className="w-50 flex mr-2">
                          <div>Branch</div>
                          <div className="form-group">
                          {/* <input className="form-control"
                              value={fields['counter_branch']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }} /> */}
                                
                          {inputType ? (
                            <div className="d-flex">
                              <input
                                type="text"
                                value={fields['counter_branch']}
                                className="form-control w-100 "
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }}
                              />
                              <RiArrowGoForwardFill
                                onClick={() => {
                                  setInputType(false);
                                  setFields({
                                    ...fields,
                                    counter_branch: '',
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select Branch'}
                              // isDisabled={false}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  counter_branch: e.branch_name,
                                });
                                onOtherSelection(e.value);
                                 termDepositEntity['branch']=e.branch_name;
                                 setTermDepositEntity(termDepositEntity);
                              }}
                              value={allCounterbranchesData.filter(
                                (option) =>
                                  option.value === fields['counter_branch']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={allCounterbranchesData}
                            />
                          )}
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              )}
            </Row>

            {(headLabel!=='pledgedin' && headLabel!=='pledgedout') && (
            <Row>
              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Instrument</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      <div className="form-group">
                        <div>Instrument Type</div>
                        <select
                          className={'form-control w-100 '}
                          value={fields['instrument_type']}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              instrument_type: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Type
                          </option>
                          {renderiTypeDataDropdown()}
                        </select>
                      </div>
                      {fields.payment_type === 'Receipt' && (
                        <>
                          <div className="form-group">
                            <div>Instrument No./Reference No</div>
                            <input
                              defaultValue={fields['instrument_no']}
                              type="text"
                              className={'form-control w-100 '}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  instrument_no: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <div>Instrument Date / Deposit Date</div>
                            <input
                              type="date"
                              defaultValue={fields['instrument_date']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['instrument_date'])
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  instrument_date: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}


                        {/* <div className="form-check">
                              <label className="form-check-label1">
                                <input type="checkbox" className="form-check-input" value="" checked={realize}
                                onChange={(e)=>{
                                  let flag='';	                                  
                                  if(realize===true){
                                    setRealize(!realize);
                                    flag='false';
                                  }else{
                                    setRealize(!realize);
                                    flag='true';
                                  }
                                  setFields({
                                    ...fields,
                                    realized: flag,
                                  });
                                }}
                                />
                                <span className="form-check-sign"><span className="check"></span></span>
                               Realized in Bank
                                </label>
                           </div>
                      
                            <div className="form-group">
                              <input
                                type="date"
                                defaultValue={fields['realized_date']}
                                className={'form-control w-100 '}
                                onChange={(e) => {
                                  let date =  moment(e.target.value).format('YYYY-MM-DD');
                                  setFields({
                                    ...fields,
                                    realized_date: date,
                                  });
                                }}
                              />
                            </div> */}

                    </div>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transaction Details</h4>
                  </div>
                  <div className="card-body">
                    <div>

                      {chechFieldDisplay(headLabel, 'dividend_date') && (
                        <div className="form-group">
                          <div>Dividend Date</div>
                          <input
                            type="date"
                            defaultValue={fields['dividend_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['dividend_date'])
                            }
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFields({
                                ...fields,
                                dividend_date: date,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'dividend_rate') && (
                        <div className="form-group">
                          <div>Dividend Rate</div>
                          <input
                            type="number"
                            value={fields['dividend_rate']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['dividend_rate'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              let grossAmount =
                                parseFloat(value) *
                                parseFloat(fields['current_holding']) *
                                parseFloat(fields['face_value']);
                              setFields({
                                ...fields,
                                dividend_rate: value,
                                gross_amount: grossAmount.toFixed(2),
                                net_amount: grossAmount.toFixed(2),
                              });
                            }}
                          />
                        </div>
                      )}

                      {chechFieldDisplay(headLabel, 'sale_txn_no') && (
                        <div className="form-group">
                          <div>Sale Txn No</div>
                          <input
                            type="number"
                            defaultValue={fields['sale_txn_no']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['sale_txn_no'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                sale_txn_no: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'return_date') && (
                        <div className="form-group">
                          <div>Return Date</div>
                          <input
                            type="date"
                            defaultValue={fields['return_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['return_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                return_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'symbol') && (
                        <div className="form-group">
                          <div>Symbol*</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['symbol'])
                            }
                            defaultValue={fields['symbol']}
                            onChange={(e) => {
                              let vol= stockMarketDataFund.filter((item, index)=>{
                                return (item.code===e.target.value);
                                })
                              setFields({
                                ...fields,
                                volume: vol[0].unit,
                                symbol: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderStockMarketData()}
                          </select>
                        </div>
                      )}

                      {/* {headLabel==='cashdividend' && ( */}
                        {chechFieldDisplay(headLabel, 'volume') && (
                        <div className="form-group">
                            <div>Volume</div>
                            <input
                              type="number"
                              defaultValue={fields['volume']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['volume'])
                              }
                              onChange={(e) => {
                                let value=decimalValue(e.target.value,2);
                                let grossamount = (parseFloat(value) * (parseFloat(fields['dividend_percentage'])/100) * parseFloat(fields['par_value'] || '0')).toFixed(2);
                                setFields({
                                  ...fields,
                                  volume: value,
                                  gross_amount: grossamount,
                                });
                              }}
                            />
                          </div>
                        )}

                    {/* {headLabel==='cashdividend' && ( */}
                      {chechFieldDisplay(headLabel, 'par_value') && (
                    <div className="form-group">
                        <div>Par Value</div>
                        <input
                          type="number"
                          defaultValue={fields['par_value']}
                          className={
                            'form-control w-100 '+ErrorBorder(fields['par_value'])
                          }
                          onChange={(e) => {
                            let value=decimalValue(e.target.value,2);
                            let grossamount = (parseFloat(fields['volume'] || '0') * (parseFloat(fields['dividend_percentage'])/100) * parseFloat(value)).toFixed(2);
                            setFields({
                              ...fields,
                              par_value: value,
                              gross_amount: grossamount,
                            });
                          }}
                        />
                      </div>
                      )}

                    {/* {headLabel==='cashdividend' && ( */}
                      {chechFieldDisplay(headLabel, 'announcement_date') && (
                         <div className="form-group">
                         <div>Announcement Date</div>
                         <input
                              type="date"
                              defaultValue={fields['announcement_date']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['announcement_date'])
                              }
                              onChange={(e) => {
                               let date= moment(e.target.value).format('YYYY-MM-DD')
                                setFields({
                                  ...fields,
                                  announcement_date: date,
                                });
                              }}
                            />
                       </div>
                       )}

                      {/* {chechFieldDisplay(headLabel, 'credit_date') && (
                        <div className="form-group">
                          <div>Credit Date</div>
                          <input
                            type="date"
                            defaultValue={fields['credit_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['credit_date'])
                            }
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD')
                              setFields({
                                ...fields,
                                credit_date: date,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'maturity_type') && (
                        <div className="form-group">
                          <div>Maturity Type*</div>
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['maturity_type'])
                            }
                            defaultValue={fields['maturity_type']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                maturity_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            <option value="Security Maturity">
                              Security Maturity
                            </option>
                            <option value="Coupon Maturity">
                              Coupon Maturity
                            </option>
                          </select>
                        </div>
                      )}

                        {chechFieldDisplay(headLabel, 'primary_dealer') && (
                            <div className="form-group">
                              <div>Primary Dealer</div>
                              <select 
                                className="form-control" 
                                // defaultValue={}
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    primary_dealer: e.target.value,
                                  });
                                }}
                              >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Type
                                  </option>
                                  {renderPrimaryDealerDropdown()}
                                </select> 
                            </div>

                          )}

                      {chechFieldDisplay(headLabel, 'security_type') && (
                        <div className="form-group">
                          <div>Security Type*</div>
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['security_type'])
                            }
                            defaultValue={fields['security_type']}
                            onChange={(e) => {
                              if (
                                headLabel == 'saleofsecurities' ||
                                headLabel === 'moneymarketpurchase'
                              ) {
                                if (e.target.value == 'PIB') {
                                  setPIBFields(true);
                                } else {
                                  setPIBFields(false);
                                }
                              }
                              setFields({
                                ...fields,
                                security_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderSecurityDataDropdown()}
                          </select>
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'issue_date') && (
                        <div className="form-group">
                          <div>Issue Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['issue_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['issue_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                issue_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      
                      {chechFieldDisplay(
                        headLabel,
                        'associated_transaction'
                      ) && (
                        <div className="form-group">
                          <div>Associated Txn No*</div>
                          <input
                            type="text"
                            defaultValue={fields['associated_transaction']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['associated_transaction'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                associated_transaction: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'maturity_date') && (
                        <div className="form-group">
                          <div>Maturity Date</div>
                          <input
                            type="date"
                            defaultValue={fields['maturity_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['maturity_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                maturity_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'coupon_rate') && (
                        <div className="form-group">
                          <div>Coupon Rate*</div>
                          <input
                            type="number"
                            value={fields['coupon_rate']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['coupon_rate'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                coupon_rate: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {/* {PIB_fields && (
                        <div className="form-group">
                          <div>Last Coupon Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['last_coupon_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['last_coupon_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                last_coupon_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {PIB_fields && (
                        <div className="form-group">
                          <div>Next Coupon Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['next_coupon_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['next_coupon_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                next_coupon_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'yield') && (
                        <div className="form-group">
                          <div>Yield*</div>
                          <input
                            type="number"
                            value={fields['yield']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['yield'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 4);
                              setFields({
                                ...fields,
                                yield: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {/* {PIB_fields && (
                        <div className="form-group">
                          <div>Tenor</div>
                          <input
                            defaultValue={fields['tenor']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['tenor'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                tenor: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'price') && (
                        <div className="form-group">
                          <div>Price*</div>
                          <input
                            type="number"
                            value={fields['price']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['price'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                price: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'face_value') && (
                        <div className="form-group">
                          <div>Face Value*</div>
                          <input
                            type="number"
                            value={fields['face_value']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['face_value'])
                            }
                            readOnly
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                face_value: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'money_market_face_value') && (
                        <div className="form-group">
                          <div>Money Market Face Value*</div>
                          <input
                            type="number"
                            value={fields['money_market_face_value']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['money_market_face_value'])
                            }
                            // readOnly
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                money_market_face_value: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'detail') && (
                        <div className="form-group">
                          <div>Detail*</div>
                          <input
                            type="text"
                            defaultValue={fields['detail']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['detail'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                detail: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}


                {headLabel==='equityinvestment'  && (
                        <div className="">
                          <div> Sales Tax Collector </div>
                          <select	
                        className={`form-control  w-100 `}	
                        value={fields['sales_tax_collector']}	
                        onChange={(e) => {	
                          setFields({
                            ...fields,
                            sales_tax_collector:e.target.value
                          })
                        }}	
                      >	
                        {/* <option value="" defaultChecked hidden>	
                          Select Revenue Authority
                        </option>	 */}
                        <option value="SRB">SRB - Sindh Revenue Board</option>	
                        {/* <option value="FBR">FBR - Federal Board Of Revenue</option>	 */}
                        <option value="PRA">PRA - Punjab Revenue Authority</option>	                       
                        <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>	
                        <option value="BRA">BRA - Balochistan Revenue Authority</option>	
                        <option value="">None</option>	
                      </select>

                          </div>
                          )}

                        {headLabel==='termdeposit' && (
                          <>
                          <div className="form-group">
                            <div> Type</div>
                            <select className='form-control w100'
                            onChange={(e)=>{
                              termDepositEntity['code']=e.target.value;
                              // termDepositEntity['name']=e.nativeEvent.target[e.target.selectedIndex].text;
                               termDepositEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            >
                              <option value='' hidden>Select Type</option>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value='CDR'>Call Deposit Receipts</option>
                            <option value='LOP'>Loan on Phone</option>
                            </select>

                          </div>
                          <div className="form-group">
                          <div>Principal Amount</div>
                          <input
                            type="number"
                            // defaultValue={fields['detail']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['principal_amount']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                          <div className="form-group">
                          <div>Interest Rate</div>
                          <input
                            type="number"
                            // defaultValue={fields['detail']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['interest_rate']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <div>From Date</div>
                          <input
                            type="date"
                            defaultValue={termDepositEntity['from_date']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['from_date']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <div>To Date</div>
                          <input
                            type="date"
                            defaultValue={termDepositEntity['to_date']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['to_date']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                        </>
                        )}

                      {/* {chechFieldDisplay(headLabel, 'redemption_date') && (
                        <div className="form-group">
                          <div>Redemption Date</div>
                          <input
                            type="date"
                            defaultValue={fields['redemption_date']}
                            max={disableDates}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['redemption_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                redemption_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                    
                      {chechFieldDisplay(headLabel, 'settlement_by') && (
                        <div className="form-group mt-3">
                          <div>Settlement By*</div>
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['settlement_by'])
                            }
                            value={fields['settlement_by']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                settlement_by: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            <option value="NCCPL">NCCPL</option>
                            <option value="Direct">Direct</option>
                          </select>
                        </div>
                      )}
                      {/* {chechFieldDisplay(headLabel, 'settlement_date') && (
                        <div className="form-group">
                          <div>Settlement Date</div>
                          <input
                            type="date"
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['settlement_date'])
                            }
                            defaultValue={fields['settlement_date']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                settlement_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {/* {headLabel === 'moneymarketpurchase' && (
                        <div className="form-group">
                          <div>Fund IPS Account</div>
                          <input
                            type="text"
                            defaultValue={fields['fund_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                fund_ips_account: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {headLabel === 'moneymarketpurchase' && (
                        <div className="form-group">
                          <div>Counter Party IPS Account</div>
                          <input
                            type="text"
                            defaultValue={fields['counter_party_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                counter_party_ips_account: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'period') && (
                        <div className="form-group">
                          <div>Select Period</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['period'])
                            }
                            defaultValue={fields['period']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                period: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select period
                            </option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="Annual">Annual</option>
                            <option value="Occasional">Occasional</option>
                          </select>
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'reinvest') && ( 

                        <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox"	
                          checked={reinest}
                          onChange={(e) => {
                            setReinvest(!reinest);
                            setFields({
                              ...fields,
                              reinvest: e.target.value,
                            });
                          }} />	
                          <span className="form-check-sign" />	
                          Reinvest
                        </Label>	
                        </FormGroup>
                        )}

                      {chechFieldDisplay(headLabel, 'tax_type') && (
                        <div className="form-group mt-2">
                          <div>Tax Type*</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['tax_type'])
                            }
                            defaultValue={fields['tax_type']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                tax_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderTaxTypeDropdown()}
                          </select>
                        </div>
                      )}

                        {/* Money Market Securities Modal */}
                        {headLabel==='debtmarketinvestment' && (
                        <>

                      <div className="row">
                          <div className="col">
                        <div className="form-group">
                        <div>Add Money Market Security</div>
                           <button className=" btn-round btn-icon btn btn-success" onClick={() => {
                              setMoneyMarketSecuritiesEntity(prevState => ({
                                  ...moneyMarketSecuritiesEntity,
                                  security_type:'',
                                  investment_type:'',
                                  type:'',
                                  symbol:'',
                                  name:'',
                                  issue_date:'',
                                  maturity_date:'',
                                  money_market_face_value:'',
                                  price:'',
                                  units:'',
                                  yield:'',
                                  coupon_rate:'',
                                  next_coupon_date:'',
                                  last_coupon_date:'',
                                  primary_dealer:'',
                                  fund_ips_account:'',
                                  counter_party_ips_account:'',
                                  broker_name:'',
                                  settlement_date:'',
                                  total_face_value:'',
                                  accrued_amount:'',
                                  brokerage:'0',
                                  premium_discount:'',
                                  accrued_days:'',
                                  counter_party_name:'',
                                  detail:'',
                                  principal_amount:'',
                                  unredeem_value:'',
                                  coupon_payment:'',
                                  days_of_maturity: '',
                                  average_cost_per_unit:'0',
                                  average_cost_amount:'0',
                                  gain_loss_per_unit:'0',
                                  gain_loss_amount:'0',

                              }));
                              setViewMoneyMarketModal(!viewMoneyMarketModal);
                          }}> <i className="fa fa-plus"></i> </button>
                          </div> 
                          </div>
                          </div>

                        </>
                        )}

                      {moneyMarketSecurities.length > 0 && (
                        <>
                          <div className="table-responsive">
                          <div className="form-group">
                            <table className="table  ">
                              <thead>
                                <tr>
                                  <th>Security Type</th>
                                  <th>Investment Type</th>
                                  <th>Type</th>
                                  <th>Symbol</th>
                                  <th>Name</th>
                                  <th>Issue Date</th>
                                  <th>Maturity Date</th>
                                  <th>Face Value</th>
                                  <th>Price</th>
                                  <th>Units</th>
                                  <th>Yield/Interest Rate</th>
                                  <th>Coupon Rate</th>
                                  <th>Next Coupon Date</th>
                                  <th>Last Coupon Date</th>       
                                  <th>Primary Dealer</th>
                                  <th>Fund IPS Account</th>
                                  <th>Counter Party IPS Account</th>
                                  <th>Broker Name</th>
                                  <th>Settlement Amount</th>
                                  <th>Total Face Value</th>
                                  <th>Accrued Amount</th>
                                  <th>Brokerage</th>
                                  <th>Premium Discount</th>
                                  <th>Accrued Days</th>
                                  <th>Counter Party Name</th>
                                  <th>Detail</th>
                                  <th>Principal Amount</th>
                                  <th>UnRedeem Value</th>
                                  <th>Copoun Payment/Year</th>
                                  <th>Days of Maturity</th>
                                  <th>Avg Cost/Unit</th>
                                  <th>Avg Cost Amt</th>
                                  <th>Gain/Loss Per Unit</th>
                                  <th>Gain/Loss Amt</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>{renderMoneyMarketData()}</tbody>
                            </table>
                            </div></div>
                            </>
                            )}

                      {chechFieldDisplay(headLabel, 'settlement_by') && (
                        <div className="row">
                          <div className="col">
                        <div className="form-group">
                        <div>Add Security</div>
                           <button className=" btn-round btn-icon btn btn-success" onClick={() => {
                              setSecuritesEntity(prevState => ({
                                  ...securitesEntity,
                                  gross_amount: '',
                                  net_amount: '',
                                  market: '',
                                  investment_type:'',
                                  sst_on_commission:'',
                                  commission_rate:'',
                                  // capital_value_tax:'',
                                  net_rate:'',
                                  type: '',
                                  symbol: '',
                                  quantity: '',
                                  avg_rate: '',
                                  commission_charges: '0',
                                  broker:'',
                                  sale_price:'0',
                                  avg_cost_amount:'0',
                                  gain_loss_per_unit:'0',
                                  gain_loss_amount:'0'
                              }));
                              setViewAddModal(!viewAddModal);
                              setCloseRate('');
                          }}> <i className="fa fa-plus"></i> </button>
                          </div> 
                          </div>

                          <div className="col">
                            <div>Upload Securities</div>
                             <div
                             onClick={() => myRef1?.current?.click()} >
                            <div className="form-group">
                          <div className="form-control" data-tip="Upload Excel File">
                          
                            {excelFileName === '' ? 'Upload File' : excelFileName}
                          </div> 
                                  <input
                                  className="form-control mt-1"
                                    type="file"
                                    ref={myRef1}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      uploadExcel(e);
                                    }}
                                    onClick={(e)=>{
                                      e.currentTarget.value='';
                                    }}
                                  />
                                </div>
                              </div>
                        </div>

                          {/* <div className="col">
                            <div>Upload Securities</div>
                             <div
                             onClick={() => myRef1?.current?.click()} >
                            <div className="form-group">
                          <div className="form-control" data-tip="Upload File">
                          
                            {filename === '' ? 'Upload File' : filename}
                          </div> 
                                  <input
                                  className="form-control mt-1"
                                    type="file"
                                    ref={myRef1}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      uploadCDCFile(e);
                                    }}
                                    onClick={(e)=>{
                                      e.currentTarget.value='';
                                    }}
                                  />
                                </div>
                              </div>
                        </div> */}
                        </div>
                      )}
                      {headLabel == 'equityinvestment' &&
                        stockMarketData.length > 0 && (
                          <div className="form-group">
                            <div className="d-flex justify-content-center my-4">
                              Current Holding
                            </div>
                            <table className="table   px-5">
                              <thead>
                                <tr>
                                  <th>Symbol</th>
                                  <th className="text-right">Quantity</th>
                                  <th className="text-right">Avg Rate</th>
                                </tr>
                              </thead>
                              <tbody>{renderStockData()}</tbody>
                            </table>
                          </div>
                        )}
                      {chechFieldDisplay(headLabel, 'settlement_by') &&
                        securites.length > 0 && (
                          <div className="table-responsive">
                          <div className="form-group">
                            <table className="table  ">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Inv Type</th>
                                  <th>MKT</th>
                                  <th>Symbol</th>
                                  <th>Sale Price</th>
                                  <th>Quantity</th>
                                  <th>Avg Rate</th>
                                  <th>Broker</th>
                                  <th>Comm Rate</th>
                                  <th>SST On Comm</th>
                                  {/* <th>Capital Value Tax</th> */}
                                  <th>Net Rate</th>
                                  <th>Gain/Loss Per Unit</th>
                                  <th>Gain/Loss Amount</th>
                                  <th>Average Cost Amount</th>
                                  <th>Gross Amount</th>
                                  <th>Commission</th>
                                  <th>Net Amount</th>
                                  <th>Act</th>
                                </tr>
                              </thead>
                              <tbody>{renderPsxData()}</tbody>
                            </table>
                            {/* {securites.lenght>0 &&(
                          <> */}
                          <div className="ml-3" onClick={()=>{
                             securites.length=0;
                             setSecurites(securites);
                             setFields({
                               ...fields,
                               gross_amount:'0',
                               net_amount:'0',
                               broker_commission: '0',
                               tax_sst: '0'
                             })
                            setFile('');
                            setFileName('');

                            setExcelFile('');
                            setExcelFileName('');

                            setGrossAmountSeparator('');
                            setGrossAmountInWords('')

                            setNetAmountInWords('');
                            setNetAmountSeparator('');    
                            transactionLedgers.length=0;
                            setTransactionLedgers(transactionLedgers);                 
                          }}>Clear All</div>
                          {/* </>
                        )} */}
                          </div>
                          </div>
                        )}
                        {/* {securites.lenght>0 &&(
                          <>
                          <div className="float-right" onClick={()=>{
                            securites.length=0;
                            setFile('');
                            setFileName('');
                          }}>Clear All</div>
                          </>
                        )} */}
                        
                    </div>
                  </div>
                </div>
              </Col>

            {headLabel==='cashdividend' && (
                  <>
                  <Col md="4">
                  {headLabel==='cashdividend' && (
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Cash Dividend</h4>
                        </div>
                      <div className="card-body">
                        <div>
                        {chechFieldDisplay(
                          headLabel,
                          'dividend_percentage'
                        ) && (
                          <div className="form-group">
                            <div>Dividend Percentage*</div>
                            <input
                              type="number"
                              defaultValue={fields['dividend_percentage']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['dividend_percentage'])
                              }
                              onChange={(e) => {
                                // let value=decimalValue(e.target.value,2);
                                let grossamount = (parseFloat(fields['volume'] || '0') * (parseFloat(e.target.value)/100) * parseFloat(fields['par_value'] || '0')).toFixed(2);

                                setFields({
                                  ...fields,
                                  dividend_percentage: e.target.value,
                                  gross_amount: grossamount,
                                });
                              }}
                            />
                          </div>
                        )}

                      {chechFieldDisplay(headLabel, 'credit_date') && (
                          <div className="form-group">
                            <div>Dividend Credit Date</div>
                            <input
                              type="date"
                              defaultValue={fields['credit_date']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['credit_date'])
                              }
                              onChange={(e) => {
                               let date= moment(e.target.value).format('YYYY-MM-DD')
                                setFields({
                                  ...fields,
                                  credit_date: date,
                                });
                              }}
                            />
                          </div>
                        )}

                        </div>
                      </div>
                      </div>
                  )}
                    </Col>

                  <Col md="4">
                  {headLabel==='cashdividend' && (
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Bonus Shares</h4>
                        </div>
                      <div className="card-body">
                        <div>
                        {chechFieldDisplay(headLabel, 'bonus_percentage') && (
                        <div className="form-group">
                            <div>Bonus Percentage*</div>
                            <input
                              type="number"
                              value ={fields['bonus_percentage']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['bonus_percentage'])
                              }
                              onChange={(e) => {
                                let grossvolume = Math.floor(parseFloat(fields['volume'] || '0') * (parseFloat(e.target.value)/100));

                                setFields({
                                  ...fields,
                                  bonus_gross_volume: grossvolume.toString(),
                                  bonus_percentage: e.target.value
                                })
                              }}
                            />
                          </div>
                        )}

                      {chechFieldDisplay(headLabel, 'bonus_gross_volume') && (
                          <div className="form-group">
                            <div>Gross Bonus Share*</div>
                            <input
                              type="number"
                              value={fields['bonus_gross_volume']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['bonus_gross_volume'])
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                if(!value.includes('.')){
                                let tax_it_bonus_amount = Math.floor(parseFloat(value) * (parseFloat(fields['tax_on_bonus'] || '0')/100));
                                let net_bonus_volume = Math.floor(parseFloat(value) - tax_it_bonus_amount);
                                setFields({
                                  ...fields,
                                  bonus_gross_volume: value,
                                  tax_it_bonus_amount: tax_it_bonus_amount.toString(),
                                  net_bonus_volume:net_bonus_volume.toString()
                                })
                              }
                              }}
                            />
                          </div>
                          )}
                          <Row>
                          <Col>
                          {chechFieldDisplay(headLabel, 'tax_on_bonus') && (
                          <div className="form-group">
                            <div>Tax on Bonus (%)*</div>
                            <input
                              type="number"
                              value={fields['tax_on_bonus']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['tax_on_bonus'])
                              }
                              onChange={(e) => {
                                let tax_it_bonus_amount = Math.floor(parseFloat(fields['bonus_gross_volume'] || '0') * (parseFloat(e.target.value)/100));
                                let net_bonus_volume = Math.floor(parseFloat(fields['bonus_gross_volume'] || '0') - tax_it_bonus_amount);
                                  
                                setFields({
                                  ...fields,
                                  tax_on_bonus: e.target.value,
                                  tax_it_bonus_amount: tax_it_bonus_amount.toString(),
                                  net_bonus_volume:net_bonus_volume.toString()
                                })
                              }}
                            />
                          </div>
                          )}
                          </Col>
                          <Col>
                          {chechFieldDisplay(headLabel, 'tax_it_bonus_amount') && (
                          <div className="form-group">
                            <div>Tax on Bonus Shares</div>
                            <input
                              type="number"
                              value={fields['tax_it_bonus_amount']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['tax_it_bonus_amount'])
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                if(!value.includes('.')){
                                let net_bonus_volume = Math.floor(parseFloat(fields['bonus_gross_volume'] || '0') - parseFloat(value));

                                setFields({
                                  ...fields,
                                  tax_it_bonus_amount: value,
                                  net_bonus_volume: net_bonus_volume.toString()
                                })
                              }
                              }}
                            />
                          </div>
                          )}
                          </Col>
                          </Row>

                          {chechFieldDisplay(headLabel, 'net_bonus_volume') && (
                          <div className="form-group">
                            <div>Net Bonus Shares</div>
                            <input
                              type="number"
                              value={fields['net_bonus_volume']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['net_bonus_volume'])
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                if(!value.includes('.')){

                                setFields({
                                  ...fields,
                                  net_bonus_volume: value,
                                })
                              }
                              }}
                            />
                          </div>
                          )}
  
                      {chechFieldDisplay(headLabel, 'bonus_credit_date') && (
                          <div className="form-group">
                            <div>Bonus Credit Date</div>
                            <input
                              type="date"
                              defaultValue={fields['bonus_credit_date']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['bonus_credit_date'])
                              }
                              onChange={(e) => {
                               let date= moment(e.target.value).format('YYYY-MM-DD')
                                setFields({
                                  ...fields,
                                  bonus_credit_date: date,
                                });
                              }}
                            />
                          </div>
                        )}

                        </div>
                      </div>
                      </div>
                  )}
                    </Col>

                    <Col md="4">
                    {headLabel==='cashdividend' && (
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Right Shares</h4>
                        </div>
                      <div className="card-body">
                        <div>
                        {chechFieldDisplay(headLabel, 'right_share_percentage') && (
                        <div className="form-group">
                            <div>Right Share Percentage*</div>
                            <input
                              type="number"
                              value={fields['right_share_percentage']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['right_share_percentage'])
                              }
                              onChange={(e) => {
                                let right_share_gross_volume = Math.floor(parseFloat(fields['volume'] || '0') * (parseFloat(e.target.value)/100))
                                setFields({
                                  ...fields,
                                  right_share_percentage: e.target.value,
                                  right_share_gross_volume:right_share_gross_volume.toString()
                                })
                              }}
                            />
                          </div>
                        )}
 
                      {chechFieldDisplay(headLabel, 'right_share_gross_volume') && (
                          <div className="form-group">
                            <div>Gross Right Share*</div>
                            <input
                              type="number"
                              value={fields['right_share_gross_volume']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['right_share_gross_volume'])
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                if(!value.includes('.')){
                                setFields({
                                  ...fields,
                                  right_share_gross_volume: value
                                })
                              }
                              }}
                            />
                          </div>
                          )}
                          <Row>
                          <Col>
                          {chechFieldDisplay(headLabel, 'tax_on_right') && (
                          <div className="form-group">
                            <div>Tax on Right (%)*</div>
                            <input
                              type="number"
                              value={fields['tax_on_right']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['tax_on_right'])
                              }
                              onChange={(e) => {                             
                                let tax_it_right_share_amount = Math.floor(parseFloat(fields['right_share_gross_volume'] || '0') * (parseFloat(e.target.value)/100));
                                let net_right_share_volume = Math.floor(parseFloat(fields['right_share_gross_volume'] || '0') - tax_it_right_share_amount);

                                setFields({
                                  ...fields,
                                  tax_on_right: e.target.value,
                                  tax_it_right_share_amount: tax_it_right_share_amount.toString(),
                                  net_right_share_volume: net_right_share_volume.toString()
                                })
                              }}
                            />
                          </div>
                          )}
                          </Col>
                          <Col>
                          {chechFieldDisplay(headLabel, 'tax_it_right_share_amount') && (
                          <div className="form-group">
                            <div>Tax on Right Shares</div>
                            <input
                              type="number"
                              value={fields['tax_it_right_share_amount']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['tax_it_right_share_amount'])
                              }
                              onChange={(e) => {
                                let value= e.target.value;
                                if(!value.includes('.')){
                                let net_right_share_volume = Math.floor(parseFloat(fields['right_share_gross_volume'] || '0') - parseFloat(value));
                                setFields({
                                  ...fields,
                                  tax_it_right_share_amount: value,
                                  net_right_share_volume:net_right_share_volume.toString()
                                })
                              }
                              }}
                            />
                          </div>
                          )}
                          </Col>
                          </Row>

                          {chechFieldDisplay(headLabel, 'net_right_share_volume') && (
                          <div className="form-group">
                            <div>Net Right Shares</div>
                            <input
                              type="number"
                              value={fields['net_right_share_volume']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['net_right_share_volume'])
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                if(!value.includes('.')){
                                setFields({
                                  ...fields,
                                  net_right_share_volume: e.target.value
                                })
                              }
                              }}
                            />
                          </div>
                          )}

                        {chechFieldDisplay(headLabel, 'right_share_credit_date') && (
                          <div className="form-group">
                            <div>Right Share Credit Date</div>
                            <input
                              type="date"
                              defaultValue={fields['right_share_credit_date']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['right_share_credit_date'])
                              }
                              onChange={(e) => {
                               let date= moment(e.target.value).format('YYYY-MM-DD')
                                setFields({
                                  ...fields,
                                  right_share_credit_date: date,
                                });
                              }}
                            />
                          </div>
                        )}

                        </div>
                      </div>
                      </div>
                    )}
                    </Col>
                    </>
                  )}

              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Amount</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      <div className="form-group">
                        <div>Gross Amount*  {grossAmountSeparator===''? '' : <span className="float-right text-primary">{grossAmountSeparator}</span>}</div> 
                        <input
                          type="number"
                          value={fields['gross_amount']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['gross_amount'])
                          }
                          readOnly={grossAmountReadOnly}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            if(value.split('.')[0].length<=12){
                            setTotalRemainHolding(value,"GrossAmount");  
                            value==='' ? setUnitsReadOnly(false) : setUnitsReadOnly(true);
                            value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);           
                            }               
                          }}
                        />
                        <small className="truncate" title={grossAmountInWords}>{grossAmountInWords==='' ? '' : (grossAmountInWords)}</small>
                      </div> 

                        {(headLabel=='equityinvestment' || headLabel=='debtmarketinvestment') && (
                          <>
                          <Row>
                            <Col md="6">
                              <div className="form-group">
                            <div>Average Cost Amount</div>
                            <input
                              type="number"
                              value={fields['average_cost_amount']}
                              className={
                                'form-control w-100 text-right'+ErrorBorder(fields['average_cost_amount'])
                              }
                              onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                                setFields({
                                  ...fields,
                                  average_cost_amount: value,
                                });
                              }}
                            />
                              </div>
                          </Col>
 
                          <Col md="6">
                          <div className="form-group">
                            <div>Gain Loss Amount</div>
                            <input
                              type="number"
                              value={fields['gain_loss_amount']}
                              className={
                                'form-control w-100 text-right '+ErrorBorder(fields['gain_loss_amount'])
                              }
                              onChange={(e) => {
                               let value = decimalValue(e.target.value, 2)
                                setFields({
                                  ...fields,
                                  gain_loss_amount: value,
                                });
                              }}
                            />
                          </div>
                          </Col>
                          </Row>
                          </>
                          )}

                          {headLabel=='debtmarketinvestment' && (
                            <>
                           <Row>
                              <Col md="6">
                              <div className="form-group">
                                <div>Total Face Value</div>
                                  <input
                                    type="number"
                                    value={fields['total_face_value']}
                                    className={
                                      'form-control w-100 text-right '+ErrorBorder(fields['total_face_value'])
                                    }
                                    onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2)
                                      setFields({
                                        ...fields,
                                        total_face_value: value,
                                      });
                                    }}
                                  />
                          </div>
                              </Col>

                              <Col md="6">
                              <div className="form-group">
                                <div>Accrued Amount</div>
                                  <input
                                    type="number"
                                    value={fields['accrued_amount']}
                                    className={
                                      'form-control w-100 text-right '+ErrorBorder(fields['accrued_amount'])
                                    }
                                    onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2)
                                      setFields({
                                        ...fields,
                                        accrued_amount: value,
                                      });
                                    }}
                                  />
                                  </div>
                              </Col>
                             </Row>

                            <Row>
                            <Col md="4">
                            <div className="form-group">
                                <div>Premium Discount</div>
                                  <input
                                    type="number"
                                    value={fields['premium_discount']}
                                    className={
                                      'form-control w-100 text-right '+ErrorBorder(fields['premium_discount'])
                                    }
                                    onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2)
                                      setFields({
                                        ...fields,
                                        premium_discount: value,
                                      });
                                    }}
                                  />
                                  </div>
                            </Col>

                            <Col md="4">
                            <div className="form-group">
                                <div>Brokerage</div>
                                  <input
                                    type="number"
                                    value={fields['brokerage']}
                                    className={
                                      'form-control w-100 text-right '+ErrorBorder(fields['brokerage'])
                                    }
                                    onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2)
                                      setFields({
                                        ...fields,
                                        premium_discount: value,
                                      });
                                    }}
                                  />
                                  </div>
                            </Col>
                            <Col md="4">
                            <div className="form-group">
                                <div>Principal Amount</div>
                                  <input
                                    type="number"
                                    value={fields['principal_amount']}
                                    className={
                                      'form-control w-100 text-right '+ErrorBorder(fields['principal_amount'])
                                    }
                                    onChange={(e) => {
                                    let value = decimalValue(e.target.value, 2)
                                      setFields({
                                        ...fields,
                                        principal_amount: value,
                                      });
                                    }}
                                  />
                                  </div>
                            </Col>
                            </Row>
                            </>
                          )}

                      {headLabel==='equityinvestment' && (
                           <>
                        <div className="form-group">
                          <div>Brokerage Commission</div>
                          <input
                          type="number"
                          value={fields['broker_commission']}
                          className={
                          'form-control w-100 ' +
                          ErrorBorder(fields['broker_commission'])
                          }
                          onChange={(e) => {
                          let value = decimalValue(e.target.value, 2);
                          setFields({
                            ...fields,
                            broker_commission:value
                          })

                          }}
                          />
                          </div>

                           <div className="form-group">
                             <div>SST on Commission</div>
                           <input
                            type="number"
                            value={fields['tax_sst']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['tax_sst'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                tax_sst:value
                              })
                            }}
                          />
                            </div>

                       
                        </>
                         )}

                  {headLabel==='cashdividend' && (
                          
                          <Row>
                            <Col>
                          <div className="form-group">
                          <div>Tax Percentage on Dividend</div>
                          <input
                            type="number"
                            defaultValue={fields['tax_on_dvidend']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['tax_on_dvidend'])
                            }
                            onChange={(e) => {
                              // let value=decimalValue(e.target.value,2);
                              let tax_wht_it = (parseFloat(fields['gross_amount'] || '0') * (parseFloat(e.target.value)/100)).toFixed(2);
                              let netamount =(parseFloat(fields['gross_amount'] || '0')-parseFloat(tax_wht_it)).toFixed(2);

                              setFields({
                                ...fields,
                                tax_on_dvidend: e.target.value,
                                tax_wht_it: tax_wht_it,
                                net_amount: netamount
                              });
                            }}
                          />
                        </div>
                        </Col>
                        <Col>
                        <div className="form-group">
                          <div>Tax Amount on Dividend</div>
                          <input
                            type="number"
                            defaultValue={fields['tax_wht_it']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['tax_wht_it'])
                            }
                            onChange={(e) => {
                              // let value=decimalValue(e.target.value,2);
                              let netamount = (parseFloat(fields['gross_amount']) - parseFloat(e.target.value)).toFixed(2);
                              setFields({
                                ...fields,
                                tax_wht_it: e.target.value,
                                net_amount: netamount,
                              });
                            }}
                          />
                        </div>
                        </Col>
                        </Row>

                         )}

                      <div className="form-group">
                        {/* <div className="d-flex"> */}
                        <div>Net Amount* {netAmountSeparator===''? '' : <span className="float-right text-primary">{netAmountSeparator}</span>}</div>
                            
                        {headLabel === 'equityinvestment' && (
                          <div
                            className="ml-2"
                            style={{
                              color:
                                fields.payment_type === 'Receipt'
                                  ? 'red'
                                  : 'green',
                            }}
                          >
                            {headLabel === 'equityinvestment' &&
                            fields['net_amount'] !== '0.00' &&
                            securites.length > 0
                              ? fields.payment_type === 'Payment'
                                ? '(Debit)'
                                : fields.payment_type === 'Receipt'
                                ? '(Credit)'
                                : ''
                              : ''}
                          </div>
                        )}
 
                        <input
                          type="number"
                          value={fields['net_amount']}
                          className={
                            'form-control w-100 ' + ErrorBorder(fields['net_amount'])
                          }
                          readOnly={netAmountReadOnly}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            if(value.split('.')[0].length<=12){
                            setTotalRemainHolding(value,"NetAmount");
                            if(value!==''){
                              let a = inWords(value);
                              setNetAmountInWords(a);
  
                               //// separator
                               let netAmountSeparator= numberWithCommas(value);
                               setNetAmountSeparator(netAmountSeparator);
                              }else{
                                setNetAmountInWords('');
                                setNetAmountSeparator('');
                              }
                            }
                          }}
                        />
                      <small className="truncate" title={netAmountInWords}>{netAmountInWords==='' ? '' : (netAmountInWords)}</small>
                      </div>

                      <div className="form-group">
                          <div> Payment Type </div>
                          <div  className="form-control" >
                            {fields['payment_type']}
                          </div>
                        </div>

                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            )}


            {transactionLedgers.length>0 && (
            <Row>
              <Col>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title text-primary">Ledgers
                  <button className="btn-round btn-icon btn btn-success float-right" onClick={() => {
                              setViewModalForLedgers(!viewModalForLedgers);
                          }}> <i className="fa fa-plus"></i> </button>
                  </h4>
                </div>
                <div className="card-body">
                <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Ledger</th>
                          <th className="text-right">Debit Amount</th>
                          <th className="text-right">Credit Amount</th>
                          <th>Type</th>
                          <th>Ledger Family</th>
                          <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                        {transactionLedgers.map((item:any, index:number)=>{
                          // console.log('item=>',item)
                          return(
                            <tr>
                            <td>
                              <label>{item.gl_name} <br/>
                              {item.gl_code=='' ? '' : <small className='text-muted'>({item.gl_code})</small>}
                              </label></td>
                            <td className="text-right">{numberWithCommas(item.debit_amount)}</td>
                            <td className="text-right">{numberWithCommas(item.credit_amount)}</td>
                            <td>{item.type}</td>
                            <td>{item.main_family_name}</td>
                            <td>
                            <IoIosClose
                              style={{ fontSize: '25px', color: 'red' }}
                              onClick={() => {
                                let array = [...transactionLedgers];
                                array.splice(index, 1);
                                setTransactionLedgers(array);
                                let totaldebit=0, totalcredit=0;
                                array.map((items, index) => {
                                  if(items.type=='Debit'){
                                    totaldebit+= +items.debit_amount;
                                    }
                                  if(items.type=='Credit'){
                                    totalcredit+= +items.credit_amount;
                                  }
                                });
                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }}
                            />
                            </td>
                          </tr>
                          )
                        })}

                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total Debit: <span className='text-primary'>{numberWithCommas(totalDebit)}</span></td>
                          <td>Total Credit: <span className='text-primary'>{numberWithCommas(totalCredit)}</span></td>
                          <td>Balance: <span className='text-primary'>{numberWithCommas(balance)}</span></td>
                        </tr>
                      </tfoot>

                    </table>
                  </div>
                </div>
              </div>
              </Col>
            </Row>
            )}

            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    createTransaction();
                  }}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>{tx === 'cashdividend' ? 'Update' : 'Create'}</span>
                  )}
                </button>
              </div>
            </div>

          </div>
          {renderModalForSecuirtyPopup()}
          {renderModalForMoneyMarketSecurities()}
          {renderModalForBankBalance()}
          {renderModalForAddMoreLedgers()}
        </div>
        <div style={{ display: 'none' }}>{accTilteName}</div>
      </Container>
      </Col>
      </Row>
      </div>
    </>
  );
};

export default InvestmentTransactions;

