
var accountTitle_AccountNumber_Regex = /^[0-9a-zA-Z -]+$/;
function numberWithCommas(amount:any){
  if(amount==='NaN' || amount===NaN || amount==='' || amount===undefined){
    return ''
 }else{
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
 }
}
function toProperCase(str) {
    return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
}

function getLedgerAccounts(data:any){
    let accountsData = data.map((item:any, index:number)=>{
      let gl=item.gl;
        let glData = gl.map((gl:any)=>{
          return {
           plsCode:item.code,
           plsName: item.name, ...gl
          };
        })
       return glData;
    });
    return accountsData.flat();
}

// function getLedgersOfTxn(data:any){
  //   // console.log('data',data.main_family)
  //  const ledgers = data.main_family.map((item:any, index:number)=>{
  //   let familyData = item.family;
  //   //  console.log('familyData',familyData)
  //    const family = familyData.map((familyF:any, ind:number)=>{
  //     // console.log('family',familyF)
  //     let subData = familyF.sub_family;
  //     const subFamily = subData.map((subfamily:any, i:number)=>{
  //       // console.log('subfamily',subfamily)
  //       if(subfamily.child_family!=undefined){
  //         let child_family= subfamily.child_family;
  //         // console.log('child_family',child_family)
  //         const childFamilyData = child_family.map((itm:any,n:number)=>{
  //           let gl= itm.gl;
  //           // console.log('gl',gl)
  //           const glData = gl.map((glItem:any, j:number)=>{
  //             // console.log('glItem',glItem)
  //             return{
  //               main_family_code:item.code,
  //               main_family_name:item.name,
  //               family_code:familyF.code,
  //               family_name:familyF.name,
  //               sub_family_code:subfamily.code,
  //               sub_family_name:subfamily.name,
  //               child_family_code:itm.code,
  //               child_family_name:itm.name,
  //               ...glItem,
  //             }
  //           })
  //         })
  //       }else{
  //         let gl= subfamily.gl;
  //         // console.log('gl',gl)
  //         const glData = gl.map((glItem:any, j:number)=>{
  //           // console.log('glItem',glItem)
  //           return{
  //             main_family_code:item.code,
  //             main_family_name:item.name,
  //             family_code:familyF.code,
  //             family_name:familyF.name,
  //             sub_family_code:subfamily.code,
  //             sub_family_name:subfamily.name,
  //             child_family_code:'',
  //             child_family_name:'',
  //             ...glItem,
  //           }
  //         })
  //       }
  //     })
  //    })
  //  })
// }

function getLedgersByTxn(txnCategory, txnType:any){
  const txn = txnCategory.filter((item:any)=>{
    return item.api_name===txnType || item.txn_type===txnType;
  })
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item:any)=>{
    if(Item.ledger_root.length>1){
      return {
        amount_type:Item.amount_type,
        desc:'',
        type:'Debit',
        amc_code:'',
        fund_code:'',
        account_number:'',
        account_title:'',
        bank_code:'',
        branch:'',
        balance:'',
        counter_account_number:'',
        counter_account_title:'',
        counter_bank:'',
        counter_branch:'',
        credit_amount:'0',
        debit_amount:'0',
        description:'',
        entered_by:'',
        evidence:'',
        instruction_date:'',
        instrument_by:'',
        instrument_date:'',
        instrument_no:'',
        instrument_type:'',
        realized:'',
        realized_by:'',
        realized_date:''};
    }else{
    const findLedgers = Item.ledger_root.map((itm:any)=>{
    return {
    ...itm, amount_type:Item.amount_type,
    desc:Item.desc,
    type:Item.type,
    amc_code:'',
    fund_code:'',
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    balance:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'0',
    debit_amount:'0',
    description:'',
    entered_by:'',
    evidence:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    }
    })
    return findLedgers;
  }
  })

  return legders.flat();
}

function getLedgersByTxnAndPaymentype(txnCategory, txnType:any, paymentType:string){
  // console.log(`txnCategory => ${txnCategory}, txnType => ${txnType}, paymentType => ${paymentType}`)
  const txn = txnCategory.filter((item:any)=>{
    return (item.api_name===txnType || item.txn_type===txnType) && (item.payment_type==paymentType);
  })
  // console.log('txn',txn)
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item:any)=>{
    const findLedgers = Item.ledger_root.map((itm:any)=>{
    return {
    ...itm, amount_type:Item.amount_type,
    desc:Item.desc,
    type:Item.type,
    amc_code:'',
    fund_code:'',
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    balance:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'0',
    debit_amount:'0',
    description:'',
    entered_by:'',
    evidence:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    }
    })
    return findLedgers;
  })

  return legders.flat();
}

function getGrossAmountLedgersByTxn(txnCategory, txnType:any){
  const txn = txnCategory.filter((item:any)=>{
    return item.api_name===txnType || item.txn_type===txnType;
  })
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item:any)=>{
    if(Item.ledger_root.length>1 && Item.amount_type=='Gross Amount'){
    const findLedgers = Item.ledger_root.map((itm:any)=>{
    return {
    ...itm, amount_type:Item.amount_type,
    desc:Item.desc,
    type:Item.type,
    amc_code:'',
    fund_code:'',
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    balance:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'0',
    debit_amount:'0',
    description:'',
    entered_by:'',
    evidence:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    }
    })
    return findLedgers;
  }else{
    return {};
  }
  })

  const results = legders.flat().filter(element => {
    if (Object.keys(element).length !== 0) {
      return true;
    }
  
    return false;
  })

  return results;
}

function getSSTAmountLedgersByTxn(txnCategory, txnType:any){
  const txn = txnCategory.filter((item:any)=>{
    return item.api_name===txnType || item.txn_type===txnType;
  })
  let txnLedgers = txn[0].ledgers;
  const legders = txnLedgers.map((Item:any)=>{
    if(Item.ledger_root.length>1 && Item.amount_type=='SST/PST Amount'){
    const findLedgers = Item.ledger_root.map((itm:any)=>{
    return {
    ...itm, amount_type:Item.amount_type,
    desc:Item.desc,
    type:Item.type,
    amc_code:'',
    fund_code:'',
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    balance:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'0',
    debit_amount:'0',
    description:'',
    entered_by:'',
    evidence:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    }
    })
    return findLedgers;
  }else{
    return {};
  }
  })

  const results = legders.flat().filter(element => {
    if (Object.keys(element).length !== 0) {
      return true;
    }
  
    return false;
  })

  return results;
}

function getAllLedgers(data:any){
  let finalLedgers:any=[];
   const ledgers = data.map((item:any, index:number)=>{
    let familyData = item.family;
     const family = familyData.map((familyF:any, ind:number)=>{
      let subData = familyF.sub_family;
      const subFamily = subData.map((subfamily:any, i:number)=>{
        if(subfamily.child_family!=undefined){
          let child_family= subfamily.child_family;
          const childFamilyData = child_family.map((itm:any,n:number)=>{
            let gl= itm.gl;
            const glData = gl.map((glItem:any, j:number)=>{
              finalLedgers.push({...glItem});
            })
          })
        }else{
          let gl= subfamily.gl;
          const glData = gl.map((glItem:any, j:number)=>{
            finalLedgers.push({...glItem});
          })
        }
      })
     })
   })
   return finalLedgers;
}

function getCounterAccountLedgers(data:any,txnType:string){
     const ledgers = [data].map((items:any, index:number)=>{
      let gl = items.gl;
      let gls = gl.map((item:any)=>{
        return {sub_family_code: items.code, sub_family_name: items.name, ...items}
      });
      return gls;
     })
    //  console.log('ledgers',ledgers.flat())
     return ledgers.flat();
  
}

export {numberWithCommas, toProperCase, accountTitle_AccountNumber_Regex,
  getLedgerAccounts, getLedgersByTxn, getAllLedgers, getCounterAccountLedgers,
getGrossAmountLedgersByTxn, getLedgersByTxnAndPaymentype, getSSTAmountLedgersByTxn};