
import React from 'react';
import { useState } from 'react';
import { searchAPI } from '../stores/services/amc.service';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  getFundByAmc,
  getInstrumentType,
  getDailyNavByDate
} from '../stores/services/funds.service';
import {
  getModeOfPayments,
} from '../stores/services/transactions.service';
import {
  getAccountByFund,
} from '../stores/services/account.service';
import { getUnitHoldersByAmcAndFolioNo } from '../stores/services/unit-holder.service';
import { getAllBanks } from '../stores/services/bank.service';
import { getBranchByBankName } from '../stores/services/branch.service';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from './field';
import {
  addMegaTransaction,
  EditTransaction,
  getFlow,
  addConversionOfUnitsTransaction
} from '../stores/services/beta-transaction.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {	
  inWords	
} from '../stores/services/template.service';
import { getBrokerByType } from '../stores/services/broker.service';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {numberWithCommas,accountTitle_AccountNumber_Regex, getLedgerAccounts, getLedgersByTxn, getAllLedgers} from '../utils/customFunction';
import { Link } from 'react-router-dom';

import AddUnitHolder from  './add-new-unitholder-txn';
import NumberFormat from "react-number-format";

import {getChartOfAccounts, getTransactionsLedgers,getLedgerInfoByCode} from "../stores/services/chatofaccounts.service";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import './../css/autocomplete.css';
import { itemFromArray } from 'tsparticles/Utils';
import { IoIosClose } from 'react-icons/io';

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type:string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized:string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst:string;
  tax_wht_it:string;
  tax_wht_sst:string;
  tax_cgt:string;
  sale_load:string;
  redemption_load:string;
  low_balance_agree: string;
  offer_price:string;
  load_per_unit:string;
  percentage_of_discount:string;
  applicable_offer_price:string;
  sales_tax_collector: string;
  volume:string;
  par_value:string;
  announcement_date: string; 
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend:string;
  tax_on_bonus:string;
  tax_on_right:string;
  zakat_amount:string;
  ledgers:string;
}

const UnitManagementTransactions = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [headLabel, setHeadLabel] = React.useState('');
  const tx = sessionStorage.getItem('rejectedTxName') || '';
  const amcEdit = sessionStorage.getItem('rejectedAmc') || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState('');
  const [accLoading, setAccLoading] = useState(false);
  const [unitHolderData, setUnitHolderData] = useState<any>([]);
  const paymentType = sessionStorage.getItem('payment_type') || '';

  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const [counterFundAccount, setCounterFundAccount] = useState<any>([]);
  const amcName = sessionStorage.getItem('amc_name') || '';
  let [sstPercentage, setSSTPercentage]=useState('');
  let [whtITPercentage, setWHTITPercentage]=useState('');
  let [sstWHTPercentage, setSSTWHTPercentage]=useState('');

  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [allCounterFundsAccounts, setAllCounterFundsAccounts] = useState<any>(
    []
  ); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data


  const [unitHolderFieldReadOnly, setUnitHolderFieldReadOnly] = useState(false);
  const [loadBtnEnable, setLoadBtnEnable] = useState(false);

  const [viewModalAddNewHolder, setViewModalAddNewUnitHolder] = useState(false);

  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  const [propsState, setPropsState] = useState({
    amc_code:'',
    folioNo:'',
    unitholder:'',
    account_title:'',
    account_no:'',
    bank:'',
    branch:''
  })

  const loadDailyNav = async (fund: string, nav_date: string) => {
    try {
      const amcResponse = await getDailyNavByDate(email, fund, nav_date);
      return amcResponse.data.data;
    } catch (error) {}
  }

  const decimalValueNav = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === 'Fund') {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
       // sort data
       let sortedData:any=[]; 
       sortedData = response.data.data.sort(function(a,b){
         let x = a.nature.toLowerCase();
         let y = b.nature.toLowerCase();
         if(x>y){return 1;}
         if(x<y){return -1;}
         return 0;
       });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if(val.nature==='Special Managed Account'){
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
            value: val.symbol_code,
          };
        }else{
        return {
          ...val,
          label: `${val.fund_name} (${val.symbol_code})`,
          value: val.symbol_code,
        };
      }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) {}
   
  };

  const getUnitHoldersByAmcFolioNo=async(email, amc_code, foliono)=>{
    try {
      setUnitHolderFieldReadOnly(true);
      const unitHolderResponse = await getUnitHoldersByAmcAndFolioNo(email, amc_code, foliono);
      let dataunit= unitHolderResponse.data.data;
      if(dataunit.length>0){
      setUnitHolderData(dataunit);
       
      if(headLabel==='saleofunit' || headLabel==='redemptionofunits'){
      await setCounterFieldsData('UNITHOLDER_'+foliono, unitHolderResponse.data.data);
      }else if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
        await setFolioNoData('UNITHOLDER_'+foliono, unitHolderResponse.data.data);
      }
    }else{
       // clear the fields
       if(headLabel==='saleofunit' || headLabel==='redemptionofunints'){
        setFields({
          ...fields,
          counter_account_title:'',
          counter_account_number:'',
          counter_bank:'',
          counter_branch:'',
          folio_no:'',
          unitholder_name:'',
          current_holding:''
        })
      }else if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
      setFields({
        ...fields,
        counter_account_title:'',
        counter_account_number:'',
        counter_bank:'',
        counter_branch:'',
        unitholder_name:'',
        current_holding:''
      })
    }
      toast.error('Invalid folio no. ('+foliono.replaceAll(amc_code.toString()+'-','')+')')
    }
    } catch (error:any) {
      if(error.response!==undefined){
        toast.error(error.response.data.message)
      }
    }
    setUnitHolderFieldReadOnly(false);
  }

  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item:any) => {
      if (item.symbol_code === fund_code) {
        fields['nav'] = item.nav;
        fields['face_value']=item.face_value;
           
        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== '' &&
          item.stock_market !== '[]' &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };

  // Function to get aLL Accounts of selected Fund from Instruction Section
  const [counterFundLoading, setCounterFundLoading] = useState(false);

  const getCounterFundAccountByFundName = async (fund_code: string) => {
    setCounterFundLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // label: `${val.account_no} (${val.account_code})`,
          label: `${val.bank_name}-${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterFundsAccounts(temp);
    } catch (error) {}
    setCounterFundLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem('rejectedFlag') || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);
  const [unitsReadOnly, setUnitsReadOnly] = useState(false);
  const [discount_applicable_ReadOnly, setDiscount_Applicable_ReadOnly] = useState(false);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields['txnr_type'] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === 'Other') {
      fields['counter_account_type'] = 'Other';
      setFields(fields);
    }
    if(props.match.params.type==='debtmarketinvestment' || props.match.params.type==='equityinvestment'){
      setGrossAmountReadOnly(true);
      setNetAmountReadOnly(true);
    }
  }, [props.match.params.type]);

  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates=moment(date).format('YYYY-MM-DD');
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: '',
    amc_code: loggedInUser.amc_code,
    dividend_date: '',
    dividend_rate: '',
    fund_code: '',
    reinvest: 'false',
    instruction_date: moment(date).format('YYYY-MM-DD'),
    execution_date: moment(date).format('YYYY-MM-DD'),
    executed_date: moment(date).format('YYYY-MM-DD'),
    fund_account: '',
    account_title: '',
    account_no: '',
    bank: '',
    branch: '',
    counter_account_type: '',
    counter_type:'',
    counter_account_title: '',
    counter_account_number: '',
    counter_bank: '',
    counter_branch: '',
    mode_of_payment: '',
    payment_type: '',
    instrument_no: '',
    instrument_type: '',
    instrument_date: moment(date).format('YYYY-MM-DD'),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format('YYYY-MM-DD'),
    realized:'true',
    gross_amount: '',
    net_amount: '',
    dr_amount: '',
    period: '',
    cr_amount: '',
    balance: '',
    folio_no: '',
    unitholder_name: '',
    units: '',
    nav: '',
    sale_date: moment(date).format('YYYY-MM-DD'),
    current_holding: '0',
    total_holding: '',
    symbol: '',
    dividend_percentage: '',
    credit_date: '',
    maturity_type: '',
    security_type: '',
    issue_date: moment(date).format('YYYY-MM-DD'),
    conversion_date: moment(date).format('YYYY-MM-DD'),
    associated_transaction: '',
    maturity_date: moment(date).format('YYYY-MM-DD'),
    coupon_rate: '',
    price: '',
    face_value: '',
    money_market_face_value: '',
    detail: '',
    type: '',
    redemption_date: moment(date).format('YYYY-MM-DD'),
    remain_holding: '0',
    settlement_by: '',
    tax_type: '',
    settlement_date: moment(date).format('YYYY-MM-DD'),
    total_charges: '0',
    txn_charges: '',
    sale_txn_no: '',
    return_date: '',
    txn_id: '',
    last_coupon_date: moment(date).format('YYYY-MM-DD'),
    next_coupon_date: moment(date).format('YYYY-MM-DD'),
    yield: '',
    tenor: '',
    fund_ips_account: '',
    counter_party_ips_account: '',
    system_tax: '',
    primary_dealer:'',
    broker_commission:'',
    tax_sst:'',
    tax_wht_it:'',
    tax_wht_sst:'',
    tax_cgt:'0',
    sale_load:'',
    redemption_load:'0',
    low_balance_agree:'',
    offer_price:'',
    load_per_unit:'',
    percentage_of_discount:'0',
    applicable_offer_price:'',
    sales_tax_collector:'SRB', // default value SRB
    volume:'',
    par_value:'',
    announcement_date:'',     // Dividend on Investment
    bonus_percentage:'',
    bonus_credit_date:'',
    bonus_gross_volume:'',
    tax_it_bonus_amount:'',
    net_bonus_volume:'',
    right_share_percentage:'',
    right_share_credit_date:'',
    right_share_gross_volume:'',
    tax_it_right_share_amount:'',
    net_right_share_volume:'',
    tax_on_dvidend:'',
    tax_on_bonus:'',
    tax_on_right:'',
    zakat_amount:'0',
    ledgers:''
  });

   // for legders states
  //  const [ledgerAccounts, setLegderAccounts] = useState<any>([{
  //   amc_code:amc_code,
  //   fund_code:fields['fund_code'],
  // }]);

  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [ledgeroptions, setLedgerOptions] = useState<any>([]);

  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [ledgerAccountCode, setLegderAccountCode] = useState('');
  const [ledgerAccountName, setLegderAccountName] = useState('');

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{
    amc_code:amc_code,
    fund_code:fields['fund_code'],
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    child_family_code:'',
    child_family_name:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'',
    debit_amount:'',
    description:'',
    entered_by:'',
    evidence:'',
    family_code:'',
    family_name:'',
    gl_code:'',
    gl_name:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    main_family_code:'',
    main_family_name:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    sub_family_code:'',
    sub_family_name:'',
  }]);
  
  const [totalDebit, setTotalDebit] = useState('0');
  const [totalCredit, setTotalCredit] = useState('0');
  const [balance, setBalance] = useState('0');

  const [selectedLedgerCode, setSelectedLedgerCode] = useState('');
  const [selectedInputLedgerCode, setSelectedInputLedgerCode] = useState('');
  const [selectedLedgerName, setSelectedLedgerName] = useState('');
  const [ledgerType, setLedgerType] = useState('');
  const [ledgerAmount, setLedgerAmount] = useState('');
  const [ledgerDescription, setLedgerDescription] = useState('');
  const [ledgerInvoice, setLedgerInvoice] = useState('');
  const [ledgerAmountType, setLedgerAmountType] = useState('');

  //code for edit transaction
  let [count, setCount] = useState(0);

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem('rejectedTxObj') || ' ');
      getFundByAMcCode(obj.amc_code);
      fields['payment_type'] = obj.payment_type || '';
      fields['txnr_type'] = obj.txnr_type || '';
      fields['amc_code'] = obj.amc_code || '';
      getAccountByFundName(obj.fund_code);
      fields['fund_code'] = obj.fund_code || '';
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields['instruction_date'] = obj.instruction_date || '';
      fields['execution_date'] = obj.execution_date || '';
      fields['executed_date'] = obj.executed_date || '';
      fields['fund_account'] = obj.fund_account || '';
      fields['dividend_date'] =
        obj.dividend_date || '';
      fields['dividend_rate'] = obj.dividend_rate || '';
      fields['reinvest'] = obj.reinvest || '';
      fields['period'] = obj.period || '';
      fields['account_title'] = obj.account_title || '';
      fields['account_no'] = obj.account_number || '';
      fields['bank'] = obj.bank || '';
      fields['branch'] = obj.branch || '';
      fields['counter_account_type'] = obj.counter_account_type || '';
      fields['counter_type'] = obj.counter_type || '';
      fields['counter_account_title'] = obj.counter_account_title || '';
      fields['counter_account_number'] = obj.counter_account_number || '';
      fields['counter_bank'] = obj.counter_bank || '';
      fields['counter_branch'] = obj.counter_branch || '';
      fields['mode_of_payment'] = obj.mode_of_payment || '';
      fields['instrument_no'] = obj.instrument_no || '';
      fields['instrument_type'] = obj.instrument_type || '';
      fields['instrument_date'] = obj.instrument_date || '';
      fields['realized_date'] = obj.realized_date || '';
      fields['realized'] = obj.realized || '';
      fields['gross_amount'] = obj.gross_amount.toString() || '';
      fields['net_amount'] = obj.net_amount.toString() || '';
      fields['dr_amount'] = '0';
      fields['cr_amount'] = '0';
      fields['balance'] = obj.balance.toString() || '';
      fields['folio_no'] = obj.folio_no || '';
      fields['unitholder_name'] = obj.unitholder_name || '';
      fields['units'] = obj.units.toString() || '';
      fields['nav'] = obj.nav.toString() || '';
      fields['sale_date'] = obj.sale_date || '';
      fields['current_holding'] = obj.current_holding.toString() || '';
      fields['total_holding'] = obj.total_holding.toString() || '';
      fields['symbol'] = obj.symbol || '';
      fields['dividend_percentage'] = obj.dividend_percentage.toString() || '';
      fields['credit_date'] = obj.credit_date || '';
      fields['maturity_type'] = obj.maturity_type || '';
      fields['security_type'] = obj.security_type || '';
      fields['issue_date'] = obj.issue_date || '';
      fields['conversion_date'] = obj.conversion_date || '';
      fields['associated_transaction'] = obj.associated_transaction || '';
      fields['maturity_date'] = obj.maturity_date || '';
      fields['coupon_rate'] = obj.coupon_rate || '';
      fields['price'] = obj.price.toString() || '';
      fields['face_value'] = obj.face_value.toString() || '';
      fields['money_market_face_value'] = obj.money_market_face_value.toString() || '';
      fields['detail'] = obj.detail || '';
      fields['type'] = obj.type || '';
     
      fields['redemption_date'] = obj.redemption_date || '';
      fields['remain_holding'] = obj.remain_holding.toString() || '';
      fields['settlement_by'] = obj.settlement_by || '';
      fields['tax_type'] = obj.tax_type || '';
      fields['settlement_date'] = obj.settlement_date || '';
      fields['total_charges'] = obj.total_charges.toString() || '';
      fields['sale_txn_no'] = obj.sale_txn_no || '';
      fields['return_date'] = obj.return_date || '';
      fields['trx_id'] = obj.txn_id || '';
      fields['primary_dealer'] = obj.primary_dealer;
      fields['yield'] = obj.yield || '';

      if(headLabel==='' || headLabel===''){
        fields['sale_load']=obj.sale_load || '';
      }


      if(headLabel==='' || headLabel===''){
        fields['redemption_load']=obj.redemption_load || '';
        fields['tax_cgt']=obj.tax_cgt || '';
      }

      fields['zakat_amount'] = obj.zakat_amount;
      fields['txn_status'] = 'CREATED';
        // Dividend On Investment
        fields['volume'] = obj.volume;
        fields['par_value'] = obj.par_value;
        fields['announcement_date '] = obj.announcement_date ;
        fields['bonus_percentage'] = obj.bonus_percentage;
        fields['bonus_credit_date'] = obj.bonus_credit_date;
        fields['bonus_gross_volume'] = obj.bonus_gross_volume;
        fields['tax_it_bonus_amount'] = obj.tax_it_bonus_amount;
        fields['net_bonus_volume'] = obj.net_bonus_volume;
        fields['right_share_percentage'] = obj.right_share_percentage;
        fields['right_share_credit_date'] = obj.right_share_credit_date;
        fields['right_share_gross_volume'] = obj.right_share_gross_volume;
        fields['tax_it_right_share_amount'] = obj.tax_it_right_share_amount;
        fields['net_right_share_volume'] = obj.net_right_share_volume;
        fields['tax_on_dvidend'] = obj.tax_on_dvidend;
        fields['tax_on_bonus'] = obj.tax_on_bonus;
        fields['tax_on_right'] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields['fund_ips_account'] = obj.fund_ips_account || '';
      fields['counter_party_ips_account'] = obj.counter_party_ips_account || '';
      fields['low_balance_agree']=''
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  //

  const getTaxRate=()=>{
    let taxFields = getTaxObj(props.match.params.type);
    setSSTPercentage(taxFields.tax_rate);
    setWHTITPercentage(taxFields.wht_it_rate);
    setSSTWHTPercentage(taxFields.wht_sst_rate);
  };

  //end
  React.useEffect(() => {
    getTaxRate();
    if (!flag) {
      fields['payment_type'] = paymentType;
      fields['email'] = email;
      fields['amc_code'] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);
  
      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {
       
      }

      try{
        const response = await getChartOfAccounts(email);
        if(response.data.status==200){
         let ledgersacc = getLedgerAccounts(response.data.data.main_family[0].family[3].sub_family);
         setLegderAccounts(ledgersacc);
         let allLedgers = await getAllLedgers(response.data.data.main_family);
         setAllLedgers(allLedgers);
         await ledgerDropDown(allLedgers);
      }else{
        toast.error(response.data.message);
      }
      }catch(error){

      }

      // get all transaction ledgers
       try{
         const response = await getTransactionsLedgers(email);
         if(response.data.status==200){
           let unitManagement = response.data.data.txn_catagory[0].unitManagementTxn;
            let txnLegders =  await getLedgersByTxn(unitManagement, props.match.params.type);
            console.log('txnLegders',txnLegders)
            setTransactionLedgers(txnLegders);
            // console.log('txnLegders',transactionLedgers)
         }else{
            toast.error(response.data.message);
         }
       }catch(error){

       }

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: 'Other',
          label: 'Other',
        });
        setAmcdataCounter(temp);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }
      }
     
      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace('BANK_','')} (${val.bank_name})`,
            value: val.bank_code.replace('BANK_',''),
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(temp);
      } catch (error) {}
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) {}
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) {}

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) {}

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);


  const ledgerDropDown = (allLedgerData)=>{
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.name}`,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.bank}`,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
          },
        ]);
      }
    });
  }

  const renderFundAccounts = () => {
    return (
      ledgerAccounts && ledgerAccounts.map((item: any, index: number) => {
      //   if(item.plsName==='PLS ACCOUNTS'){
      //   return (
      //     <optgroup style={{
      //       color:'black'
      //     }} label="PLS ACCOUNTS">
      //     <option key={index} value={item.code}>
      //       {item.account_title}
      //     </option>
      //     </optgroup>
      //   );
      // }else{
      //   return (
      //     <optgroup style={{
      //       color:'black'
      //     }}  label="CURRENT ACCOUNTS">
      //     <option key={index} value={item.code}>
      //       {item.account_title}
      //     </option>
      //     </optgroup>
      //   );

      // }
      return (
      <option key={index} value={item.code}>
            {item.account_title}
          </option>
      );
      })
    );
  };

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if(item.nature==='Special Managed Account'){
        return (
          <>
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code}) - {'SMA'}
          </option>
          </>
        );
      }else{
      return (
        <option key={index} value={item.symbol_code}>
         {item.fund_name} ({item.symbol_code}) 
        </option>
      );
    }
    });
  };

  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === 'Other') {
      setFields({
        ...fields,
        counter_branch: '',
      });
      setInputType(true);
    }
  };

  // handle data for Fund Account Dropdown selection
  const setFundDataCounter = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      accountNoData.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['counter_account_type'] = item.account_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch_name;
          setFields(fields);
        }
      });
    }
  };

  const [branchInputType, setBranchInputType] = useState(false);
  const onOtherBranchSelection = (val) => {
    if (val === 'Other') {
      setFields({
        ...fields,
        branch: '',
      });
      setBranchInputType(true);
    }
  };
  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState('');

  const createLegders =()=>{
    if(headLabel=='saleofunit' || headLabel=='conversionin'){
      let finalLedgers = transactionLedgers.map((item:any, index:number)=>{
        item.amc_code=amc_code;
        item.fund_code=fields['fund_code'];
        item.account_number=fields['account_no'];
        item.account_title=fields['account_title'];
        item.bank_code=fields['bank'];
        item.branch=fields['branch'];
        item.balance='';
        // item.counter_account_number=fields['counter_account_number'];
        // item.counter_account_title=fields['counter_account_title'];
        // item.counter_bank=fields['counter_bank'];
        // item.counter_branch=fields['counter_branch'];
        item.counter_account_number='';
        item.counter_account_title='';
        item.counter_bank='';
        item.counter_branch='';

        if(item.amount_type!='Other')
        item.description=item.amount_type+' '+fields['detail']+' '+fields['folio_no'];

        item.instruction_date=fields['instruction_date'];
        item.instrument_by='';
        item.instrument_date=fields['instrument_date'];
        item.instrument_no=fields['instrument_no'];
        item.instrument_type=fields['instrument_type'];
        item.realized=fields['realized'];
        item.realized_by='';
        item.realized_date=fields['realized_date'];
        item.entered_by='';
        item.evidence='';
        item.period='2021-2022';
        return item;
      });
       console.log('finalLedgers',finalLedgers)
      return finalLedgers;
    }

    if(headLabel=='redemptionofunits' || headLabel=='conversionout'){
      let finalLedgers = transactionLedgers.map((item:any, index:number)=>{
        item.amc_code=amc_code;
        item.fund_code=fields['fund_code'];
        item.account_number=fields['account_no'];
        item.account_title=fields['account_title'];
        item.bank_code=fields['bank'];
        item.branch=fields['branch'];
        item.balance='';
        // item.counter_account_number=fields['counter_account_number'];
        // item.counter_account_title=fields['counter_account_title'];
        // item.counter_bank=fields['counter_bank'];
        // item.counter_branch=fields['counter_branch'];
        item.counter_account_number='';
        item.counter_account_title='';
        item.counter_bank='';
        item.counter_branch='';

        if(item.amount_type!='Other')
        item.description=item.amount_type+' '+fields['detail']+' '+fields['folio_no'];

        item.instruction_date=fields['instruction_date'];
        item.instrument_by='';
        item.instrument_date=fields['instrument_date'];
        item.instrument_no=fields['instrument_no'];
        item.instrument_type=fields['instrument_type'];
        item.realized=fields['realized'];
        item.realized_by='';
        item.realized_date=fields['realized_date'];
        item.entered_by='';
        item.evidence='';
        item.period='2021-2022';
        return item;
      });
       console.log('finalLedgers',finalLedgers)
      return finalLedgers;

      // return transactionLedgers;
    }
    // console.log('after',transactionLedgers)

  }

  //Create Transaction Api Call
  const createTransaction = async () => {
    // createLegders();
    if (!chechFieldDisplay(headLabel, 'nav')) {
      fields['nav'] = '';
    }
    if (headLabel === 'unitconversion') {
      let conversionAmount = +fields['net_amount'] / +unitNav;
      fields['total_holding'] = conversionAmount.toFixed(2);
    } else {
      fields['counter_type'] = counterType!==''? counterType: counterAccounter;
    }

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (headLabel === 'fundtransfer' && fields['counter_account_type'] === fields['fund_account']) {
        toast.error('Both account should be not same');
      } else {
        setLoading(true);
        // if (flag) {
        //   if(headLabel==='saleofunit' || headLabel==='redemptionofunits'){
        //     fields['counter_account_type']=fields['counter_account_type'].replaceAll(fields['amc_code']+'-','')
        //     fields['counter_account_type']=fields['amc_code']+'-'+fields['counter_account_type'];
        //   }
        //   if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
        //     fields['folio_no']=fields['folio_no'].replaceAll(fields['amc_code']+'-','');
        //     fields['folio_no']=fields['amc_code']+'-'+fields['folio_no'];
        //   }
        //   if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0 ){
        //     toast.error("Amounts or units or remaining holding are negative");
        //   }else if((headLabel==='redemptionofunits' && +fields['units']===0) || (headLabel==='conversionout' && +fields['units']===0) || (headLabel==='saleofunit' && +fields['units']===0)
        //   || (headLabel==='conversionin' && +fields['units']===0)){
        //     toast.error(" Units cannot be zero.");
        //   }
        //   else{
        //   try {
        //     const response = await EditTransaction(fields); // for Edit existed transaction
        //     if(response.data.status===400){
        //       setViewBankBalanceModal(true);
        //       toast.error(response.data.message);
        //     }else if(response.data.status===417){
        //       toast.warning(response.data.message);
        //     }else{
        //       setUnderStand(false);
        //       setViewBankBalanceModal(false);
        //       toast.success(response.data.message);
        //       setTimeout(() => {
        //         history.replace(`/admin/create-transaction`);
        //       }, 3000);
        //   }
        //   } catch (error:any) {
        //     setLoading(false);
        //     if(error.response!==undefined){
        //       toast.error(error.response.data.message);
        //       }else{
        //         toast.error('Something went wrong!');
        //       }
        //   }
        // }
        // } else {
          if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0){
            toast.error("Amounts or units or remaining holding are negative");
          }else if((headLabel==='redemptionofunits' && fields['units']==='0') || (headLabel==='conversionout' && fields['units']==='0' ) || (headLabel==='saleofunit' && (fields['units']==='0' || fields['units']==='0.0000'))
          || (headLabel==='conversionin' && (fields['units']==='0' || fields['units']==='0.0000'))){
            toast.error(" Units cannot be zero.");
          }
          else{
            if(headLabel==='saleofunit' || headLabel==='redemptionofunits'){
              fields['counter_account_type']=fields['counter_account_type'].replaceAll(fields['amc_code']+'-','');
              fields['counter_account_type']=fields['amc_code']+'-'+fields['counter_account_type'];
            }
            if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
              fields['folio_no']=fields['folio_no'].replaceAll(fields['amc_code']+'-','');
              fields['folio_no']=fields['amc_code']+'-'+fields['folio_no'];
            }
            if(+balance!=0){
              toast.error(`Balance should be 0, Other wise you can't create transaction`);
              setLoading(false);
              return;
            }
          let finalLedgers = createLegders();
          fields.ledgers=JSON.stringify(finalLedgers);
          console.log('fields',fields)
          try {
            const response = await addMegaTransaction(fields); // for add new transaction
            if(response.data.status===400){
              setViewBankBalanceModal(true);
              toast.warning(response.data.message);
            }else if(response.data.status===417){
              toast.warning(response.data.message);
            }else{
              setUnderStand(false);
              setViewBankBalanceModal(false);
            sessionStorage.setItem('last_id', response.data.txn_id);
            toast.success(`${response.data.message} - ${response.data.txn_id}`)
            // toast.success(response.data.message);
            setTimeout(() => {
              history.replace(`/admin/create-transaction`);
            }, 3000);
          }
          } catch (error:any) {
            setLoading(false);
            if(error.response!==undefined){
            toast.error(error.response.data.message);
            }else{
              toast.error('Something went wrong!');
            }
          }
        }
        // }
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  };

  const [accTilteName, setAccTiltleName] = useState('');
  const [bankBalance, setBankBalance]= useState('');
  // handle data for Fund Account Dropdown selection
  // const setFundAccountFieldsData = (selected) => {
  //   if (selected === 'Other') {
  //     //incase if other option is selected then user entrer data manually
  //     setAccTiltleName(selected);
  //     fields['account_title'] = '';
  //     fields['account_no'] = '';
  //     fields['bank'] = '';
  //     fields['branch'] = '';
  //     fields['fund_account'] = 'other';
  //     setBankBalance("");
  //     setFields(fields);
  //   } else {
  //     accountNoData.map((item, index) => {
  //       if (item.account_code === selected) {
  //         getBranchByName(item.bank_name);
  //         setAccTiltleName(item.account_title);
  //         fields['fund_account'] = item.account_code;
  //         fields['account_title'] = item.account_title;
  //         fields['account_no'] = item.account_no;
  //         fields['bank'] = item.bank_name;
  //         fields['branch'] = item.branch_name;
  //         setBankBalance(item.balance_amount);
  //         setFields(fields);
  //       }
  //     });
  //   }
  // };

  const setFundAccountFieldsData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['account_title'] = '';
      fields['account_no'] = '';
      fields['bank'] = '';
      fields['branch'] = '';
      fields['fund_account'] = 'other';
      setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          // fields['fund_account'] = item.account_code;
          // fields['account_title'] = item.account_title;
          // fields['account_no'] = item.account_no;
          // fields['bank'] = item.bank;
          // fields['branch'] = item.branch;
          // setFields(fields);
          setFields({
            ...fields,
          fund_account:selected,
          account_title: item.account_title,
          account_no: item.account_no,
          bank: item.bank,
          branch: item.branch
        })
        // transactionLedgers.map((items:any)=>{
        //   if((props.match.params.type==='saleofunit' || props.match.params.type=='conversionin') && items.type=='Debit'){
        //     items.gl_code=+item.code;
        //     items.gl_name=item.bank.toString();
    
        //     items.sub_family_code=+item.plsCode;
        //     items.sub_family_name=item.plsName.toString();
        //   }

        //   if((props.match.params.type==='redemptionofunits' || props.match.params.type=='conversionout') && items.type=='Credit'){
        //     items.gl_code=+item.code;
        //     items.gl_name=item.bank.toString();
    
        //     items.sub_family_code=+item.plsCode;
        //     items.sub_family_name=item.plsName.toString();
        //   }
        // })
        transactionLedgers[0].gl_code=+item.code;
        transactionLedgers[0].gl_name=item.bank.toString();

        transactionLedgers[0].sub_family_code=+item.plsCode;
        transactionLedgers[0].sub_family_name=item.plsName.toString();
        // setLegderAccountCode(item.code.toString())
        // setLegderAccountName(item.bank.toString())
        }
      });
    }
  };


  // handle data for Fund Account Dropdown selection for unit conversion
  const setFundAccountDataCounter = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      allCounterFundsAccounts.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['counter_account_type'] = item.account_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch_name;
          setFields(fields);
        }
      });
    }
  };


  const setCounterFieldsData = (selected, unitHolderdata) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['unitholder_name'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      unitHolderdata.map((item, index) => {
        //map from unitHolder data
        if (item.folio_no === selected) {
          let code =selected.replace('UNITHOLDER_','').split('-')[0];
          setAccTiltleName(item.folio_no);
          getCounterBranchByName(item.bank_name);
          // fields['counter_account_type'] = item.folio_no.replace('UNITHOLDER_','');
          fields['counter_account_type'] = item.folio_no.replace('UNITHOLDER_'+code+'-'.toString(),'');
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch_name;
          fields['unitholder_name'] = item.name;
          // fields['current_holding'] = item.balance_unit;
          fields['folio_no'] = item.folio_no.replace('UNITHOLDER_','');
          setFields(fields);
          if (item.balance_unit !== '' && item.balance_unit !== '[]' && item.balance_unit !== undefined) {
            let unitBlance = JSON.parse(item.balance_unit);
            if (unitBlance && typeof unitBlance !== 'number') {
              const temp = unitBlance.filter((element) => {
                if (element.fund_code.trim() === fields['fund_code']) {
                  return element;
                }
              });

              if (temp.length > 0) {
                fields['current_holding'] = temp[0].fund_unit==='NaN' ? '0' : parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
              } else {
                if (headLabel !== 'funddividendpayout') {
                  // toast.error('No Units of selected Fund')
                }
                fields['current_holding'] = '0.00';
                setAccTiltleName(selected);
              }
            }
          } else {
            //empty
            fields['current_holding'] = '0.00';
            if (headLabel !== 'funddividendpayout') {
              // toast.error('No Units of selected Fund');
            }
            setAccTiltleName(selected);
          }
        }
      });
      setFields(fields);
    }
  };

   const setFolioNoData = (selected, dataUnitHolder) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== 'Other') {
      // if user select any account from dropdown then data fillout
      dataUnitHolder.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            fields['unitholder_name'] = item.name;
            fields['folio_no'] = item.folio_no.replace('UNITHOLDER_','');
            if (item.balance_unit !== '' && item.balance_unit !== '[]' && item.balance_unit !== undefined) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== 'number') {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === fields['fund_code']) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(temp[0].fund_unit.toString());
                  fields['current_holding'] = parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
                  setFields(fields);
                } else {
                  toast.error('Zero Units of selected Fund', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  fields['current_holding'] = '0.00';
                  setFields(fields);
                }
              }
            } else {
              //empty
              toast.error('Zero Units of selected Fund', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              fields['current_holding'] = '0.00';
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      fields['current_holding'] = '0.00';
      setAccTiltleName(`${count}-${selected}`);
    }
  };
  
  
  const clearFundAccountFields = () => {
    setAccTiltleName('Unset');
    fields['fund_account'] = '';
    fields['account_title'] = '';
    fields['account_no'] = '';
    fields['bank'] = '';
    fields['branch'] = '';
    fields['nav'] = '';
    setFields(fields);
  };

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + 'changes');
          fields['counter_account_type'] = item.company_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.iban;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch;
          fields['sales_tax_collector'] = item.tax_collected;
          setFields(fields);
          
        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      amcdata.map((item, index) => {
        if (item.account_no === selected) {
          setAccTiltleName(item.account_no + 'changes');
          getCounterBranchByName(item.bank);
          fields['counter_account_type'] = item.account_no;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank;
          fields['counter_branch'] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields['current_holding'] + 'change');
    fields['counter_account_type'] = '';
    fields['counter_account_title'] = '';
    fields['counter_account_number'] = '';
    fields['counter_bank'] = '';
    fields['counter_branch'] = '';
    fields['current_holding'] = '';
    fields['folio_no'] = '';
    setFields(fields);
  };

  //Select data if Counter Account is Fund account
  const setFundData = (selected) => {
    counterFundAccount.map((item, index) => {
      if (item.account_no === selected) {
        setAccTiltleName(item.bank_name + 'changes');
        fields['counter_account_type'] = item.account_no;
        fields['counter_account_title'] = item.account_title;
        fields['counter_account_number'] = item.account_no;
        fields['counter_bank'] = item.bank_name;
        fields['counter_branch'] = item.branch_name;
        fields['current_holding'] = parseFloat(item.balance_amount).toFixed(4);
        setFields(fields);
      }
    });
  };

const [grossAmountInWords, setGrossAmountInWords]=useState('');
const [netAmountInWords, setNetAmountInWords]=useState('');
const [netAmountSeparator, setNetAmountSeparator]=useState('');
const [grossAmountSeparator, setGrossAmountSeparator]=useState('');
const [unitsSeparator, setUnitsSeparator]=useState('');
const [unitsInWords, setUnitsInWord]=useState('');

const setTotalRemainHolding=(amount, type)=>{
  if(type==="NetAmount"){
    if(headLabel==="saleofunit" || headLabel==="conversionin"){                         
    if(amount.length===0){
      setFields({
        ...fields,
        net_amount: amount,
      });
    }else{     
      setFields({
        ...fields,
        net_amount: amount,
      });
      if(headLabel=='saleofunit' || headLabel=='conversionin'){
      // // setTotalDebit(amount);
      // let totalcredit = (+fields['sale_load'] + +amount).toString()
      // setTotalCredit(totalcredit);
      // let balance=(+totalDebit - +totalcredit).toString()
      // setBalance(balance)
      let totaldebit=0, totalcredit=0;
      transactionLedgers.map((item:any)=>{
        if(item.amount_type=='Net Amount'){
          item.debit_amount='0';
          item.credit_amount=amount;
        }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
          }
         if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
         }
      })
      setTotalDebit(totaldebit.toFixed(2).toString());
      setTotalCredit(totalcredit.toFixed(2).toString());
      setBalance((+totaldebit - +totalcredit).toFixed(2).toString());

      // transactionLedgers[2].debit_amount='0';
      // transactionLedgers[2].credit_amount=amount;
      }
    }
  }else{
    if(amount.length===0){
    setFields({
      ...fields,
      net_amount: amount,
    });
    }else{
    setFields({
      ...fields,
      net_amount: amount,
    });
    if(headLabel=='redemptionofunits' || headLabel=='conversionout'){
      // // setTotalDebit(fields['gross_amount']);
      // let totalcredit = (+fields['redemption_load'] + +fields['tax_cgt'] + +fields['zakat_amount'] + +amount).toString()
      // setTotalCredit(totalcredit);
      // let balance=(+totalDebit - +totalcredit).toString();
      // setBalance(balance);
      let totaldebit=0, totalcredit=0;
      transactionLedgers.map((item:any)=>{
        if(item.amount_type=='Net Amount'){
          item.debit_amount='0';
          item.credit_amount=amount.toString();
        }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
          }
         if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
         }
         
      })
      setTotalDebit(totaldebit.toFixed(2).toString());
      setTotalCredit(totalcredit.toFixed(2).toString());
      setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
      }
    }
    }
  }else if(type==="GrossAmount"){
    let unit=parseFloat(fields['applicable_offer_price'])<=0?0:parseFloat(amount)/parseFloat(fields['applicable_offer_price']);
    let a=unit.toFixed(4).toString();
      if(headLabel==="saleofunit" || headLabel==="conversionin"){ 
        let saleload =(parseFloat(fields['load_per_unit'])*parseFloat(a)).toFixed(2);
        let netamount = (parseFloat(amount) - parseFloat(saleload.replace('','0'))).toFixed(2);
        let remainingholding=parseFloat(fields['current_holding']) + parseFloat(a)
        let remainholding=remainingholding.toFixed(4).toString();                         
      if(amount.length===0){
        setFields({
          ...fields,
          gross_amount:amount,
          net_amount: amount,
          sale_load: '0',
          units:'0.0000',
          total_holding:fields['current_holding']
        });
        setNetAmountSeparator('');
        setNetAmountInWords('');
        setGrossAmountSeparator('');
        setGrossAmountInWords('');
        setUnitsSeparator('');
        setUnitsInWord('');
        if(headLabel=='saleofunit' || headLabel=='conversionin'){
        setTotalDebit('0');
        setTotalCredit('0');
        setBalance('0');
        }
      }else{
        setFields({
          ...fields,
          gross_amount:amount,
          net_amount: netamount,
          sale_load:saleload,
          total_charges: '0',
          units:a,
          total_holding:remainholding
        });
        let inwordUnits = inWords(a);
          setUnitsInWord(inwordUnits);
          let inwordNetAmount = inWords(netamount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          let unitSeparator=numberWithCommas(a);
          setUnitsSeparator(unitSeparator);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator= numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator= numberWithCommas(netamount);
          setNetAmountSeparator(netAmountSeparator);

          // accounting
          if(headLabel=='saleofunit' || headLabel=='conversionin'){
            // setTotalDebit(amount);
            // let totalcredit = (+saleload + +netamount).toString()
            // setTotalCredit(totalcredit);
            // setBalance((+amount - +totalcredit).toString())

            let totaldebit=0, totalcredit=0;
            transactionLedgers.map((item:any)=>{
              if(item.amount_type=='Gross Amount'){
                item.debit_amount=amount;
                item.credit_amount='0';
              }
              if(item.amount_type=='Sale load'){
                item.debit_amount='0';
                item.credit_amount=saleload;
              }
              if(item.amount_type=='Net Amount'){
                item.debit_amount='0';
                item.credit_amount=netamount;
              }
              if(item.type=='Debit'){
                totaldebit+= +item.debit_amount;
                }
               if(item.type=='Credit'){
                totalcredit+= +item.credit_amount;
               }
            })
            setTotalDebit(totaldebit.toFixed(2).toString());
            setTotalCredit(totalcredit.toFixed(2).toString());
            setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
          }
      }
    }else if(headLabel==="conversionout" || headLabel==="redemptionofunits" || headLabel==="unitconversion"){
    let redemptionload= (parseFloat(fields['load_per_unit']) * parseFloat(a)).toFixed(2);
    let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
    let remainholding=remainingholding.toFixed(4).toString();  
    let net_amount= (amount-parseFloat(redemptionload.replace('','0'))-parseFloat(fields['tax_cgt'].replace('','0')) - parseFloat(fields['zakat_amount'] || '0')).toFixed(2);                                              
  if(amount.length===0){
    setFields({
      ...fields,
      gross_amount:amount,
      net_amount: amount,
      redemption_load:'0',
      units:'0.0000',
      remain_holding:fields['current_holding']
    });
      setNetAmountSeparator('');
    setNetAmountInWords('');
    setGrossAmountSeparator('');
    setGrossAmountInWords('');
    setUnitsSeparator('');
    if(headLabel=='redemptionofunits' || headLabel=='conversionout'){

      let totaldebit=0, totalcredit=0;
      transactionLedgers.map((item:any)=>{
        
        if(item.amount_type=='Gross Amount'){
          item.debit_amount='0';
          item.credit_amount='0';
        }
        if(item.amount_type=='Redemption Load'){
          item.debit_amount='0';
          item.credit_amount='0';
        }
        if(item.amount_type=='CGT'){
          item.debit_amount='0';
          item.credit_amount=fields['tax_cgt'];
        }
        if(item.amount_type=='Zakat'){
          item.debit_amount='0';
          item.credit_amount=fields['zakat_amount'];
        }
        if(item.amount_type=='Net Amount'){
          item.debit_amount='0';
          item.credit_amount='0';
        }
        if(item.type=='Debit'){
          totaldebit+= +item.debit_amount;
        }
        if(item.type=='Credit'){
          totalcredit+= +item.credit_amount;
        }
      })
      setTotalDebit(totaldebit.toFixed(2).toString());
      setTotalCredit(totalcredit.toFixed(2).toString());
      setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
      }
  }else{
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: net_amount,
    redemption_load:redemptionload,
    total_charges: '0',
    units:a,
    remain_holding:remainholding
  });
  if(headLabel=='redemptionofunits' || headLabel=='conversionout'){
    // setTotalDebit(amount);
    // let totalcredit = (+redemptionload + +fields['tax_cgt'] + +fields['zakat_amount'] + +net_amount).toFixed(2).toString()
    // setTotalCredit(totalcredit);
    // let balance=(+amount - +totalcredit).toFixed(2).toString()
    // setBalance(balance)


    let totaldebit=0, totalcredit=0;
    transactionLedgers.map((item:any)=>{

      if(item.amount_type=='Gross Amount'){
        item.debit_amount=amount;
        item.credit_amount='0';
      }
      if(item.amount_type=='Redemption Load'){
        item.debit_amount='0';
        item.credit_amount=redemptionload;
      }
      if(item.amount_type=='CGT'){
        item.debit_amount='0';
        item.credit_amount=fields['tax_cgt'];
      }
      if(item.amount_type=='Zakat'){
        item.debit_amount='0';
        item.credit_amount=fields['zakat_amount'];
      }
      if(item.amount_type=='Net Amount'){
        item.debit_amount='0';
        item.credit_amount=net_amount;
      }

      if(item.type=='Debit'){
        totaldebit+= +item.debit_amount;
      }
      if(item.type=='Credit'){
        totalcredit+= +item.credit_amount;
      }
    })

    console.log(totalcredit)
    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());

    }
  let inwordNetAmount = inWords(net_amount);
  setNetAmountInWords(inwordNetAmount);
  let inwordGrossAmount = inWords(amount);
  let unitSeparator=numberWithCommas(a);
  setUnitsSeparator(unitSeparator);
  setGrossAmountInWords(inwordGrossAmount);
  let grossAmountSeparator= numberWithCommas(amount);
  setGrossAmountSeparator(grossAmountSeparator);
  let netAmountSeparator= numberWithCommas(net_amount);
  setNetAmountSeparator(netAmountSeparator);
  }
  }else{
  if(amount.length===0){
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: amount,
  });
  setNetAmountSeparator('');
  setNetAmountInWords('');
  setGrossAmountSeparator('');
  setGrossAmountInWords('');
  }else{
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: amount,
    total_charges: '0',
  });
    let inwordNetAmount = inWords(amount);
    setNetAmountInWords(inwordNetAmount);
    let inwordGrossAmount = inWords(amount);
    setGrossAmountInWords(inwordGrossAmount);
    let grossAmountSeparator= numberWithCommas(amount);
    setGrossAmountSeparator(grossAmountSeparator);
    let netAmountSeparator= numberWithCommas(amount);
    setNetAmountSeparator(netAmountSeparator);
  }
  }
  }
};

  //set Current holding

  const setCurentHolding = (unit_value) => {
    //case to handle
    let total_Hold,
      gross_am = 0;
      let netamount=0;
    if (fields.payment_type === 'Receipt') {
      total_Hold = +fields['current_holding'] + +unit_value;
      gross_am = +unit_value * +fields['nav'];
      if (chechFieldDisplay(headLabel, 'total_holding')) {
        fields['total_holding'] = parseFloat(total_Hold).toFixed(4);
      }
      if (chechFieldDisplay(headLabel, 'nav')) {
        fields['gross_amount'] = gross_am.toFixed(2);
        fields['net_amount'] = gross_am.toFixed(2);
      }
      setAccTiltleName(
        (
          +fields['gross_amount'] +
          +fields['total_holding'] * +unit_value
        ).toString()
      );
    } else if (fields.payment_type === 'Payment' || headLabel === 'unitconversion') {
      total_Hold = +fields['current_holding'] - +unit_value;
      gross_am = +unit_value * +fields['nav'];
      let red_load = unit_value * (+fields['nav']- +fields['offer_price']) * (1 - (+fields['percentage_of_discount']/100));
      netamount= gross_am -red_load || 0 - parseFloat(fields['tax_cgt'].replace('','0')) - parseFloat(fields['zakat_amount'].replace('','0'));

      if (chechFieldDisplay(headLabel, 'remain_holding')) {
        fields['remain_holding'] = parseFloat(total_Hold).toFixed(4);
      }
      if (chechFieldDisplay(headLabel, 'nav')) {
        fields['gross_amount'] = gross_am.toFixed(2);
        fields['redemption_load'] = red_load.toFixed(2);
        fields['net_amount'] = netamount.toFixed(2);

        if(unit_value!==''){
          let a = inWords(fields['gross_amount']);
          let b = inWords(fields['net_amount']);
          // let c = inWords(unit_value);
          setGrossAmountInWords(a);
          setNetAmountInWords(b);
          // setUnitsInWord(c);
          //// separator
          let unitSepa=numberWithCommas(unit_value);
          let grossAmountSepa= numberWithCommas(fields['gross_amount']);
          let netAmountSepa= numberWithCommas(fields['net_amount']);
          setUnitsSeparator(unitSepa);
          setGrossAmountSeparator(grossAmountSepa);
          setNetAmountSeparator(netAmountSepa);
          if(headLabel=='redemptionofunits' || headLabel=='conversionout'){

            let totaldebit=0, totalcredit=0;
            transactionLedgers.map((item:any)=>{
              if(item.amount_type=='Gross Amount'){
                item.debit_amount=gross_am.toFixed(2);
                item.credit_amount='0';
              }
              if(item.amount_type=='Redemption Load'){
                item.debit_amount='0';
                item.credit_amount=red_load.toFixed(2);
              }
              if(item.amount_type=='CGT'){
                item.debit_amount='0';
                item.credit_amount=fields['tax_cgt'];
              }
              if(item.amount_type=='Zakat'){
                item.debit_amount='0';
                item.credit_amount=fields['zakat_amount'];
              }
              if(item.amount_type=='Net Amount'){
                item.debit_amount='0';
                item.credit_amount=netamount.toFixed(2);
              }
              if(item.type=='Debit'){
                totaldebit+= +item.debit_amount;
              }
              if(item.type=='Credit'){
                totalcredit+= +item.credit_amount;
              }
            })
            setTotalDebit(totaldebit.toFixed(2).toString());
            setTotalCredit(totalcredit.toFixed(2).toString());
            setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
            
            }
        }else{
          setGrossAmountInWords('');
          setNetAmountInWords('');
          setGrossAmountSeparator('');
          setNetAmountSeparator('');
          setUnitsSeparator('');
          //setUnitsInWord('');
        }

      }
      setAccTiltleName(
        (
          +fields['total_holding'] / +fields['gross_amount'] +
          +unit_value
        ).toString()
      );
    } else {
    }
  };

  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === '') || (value.substring(0,1)==='-')) {
      return 'required-border';
    } else {
      return '';
    }
  };
  //

  const [viewBankBalanceModal, setViewBankBalanceModal]= useState(false);
  const [underStand, setUnderStand] = useState(false)
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
             <div className="modal-header ">
              <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setUnderStand(false);
                    setViewBankBalanceModal(false); 
                  }}
              >
                  <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
          </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <div></div> Balance
                            <input type="number"
                            value={bankBalance || '0'}
                            readOnly
                            className="form-control w-100" />
                               
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            Detail*
                            <input
                              type="text"
                              defaultValue={fields['detail']}
                              className={
                                'form-control w-100 '
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  detail: e.target.value,
                                });
                              }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                    <div className="form-inline">
                        <div className="form-group mr-2">
                            <input
                              type="checkbox"
                              // defaultValue={underStand}
                              // className={
                              //   'form-control w-100 '
                              // }
                              onChange={(e) => {
                               setUnderStand(!underStand)
                              }}
                            />
                        </div>
                        <span>I understand</span>
                    </div>
                    </div>
                    </div>
                    {underStand===true ? (
                      <>
                    <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: 'Yes',
                          });
                          createTransaction();
                          
                        }}>
                            {Loading ? (
                        <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                      </>
                      ) : (
                        <span>Proceed</span>
                      )}
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-info btn-block" onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}>
                           <span>Cancel</span>
                        </button>
                    </div>
                       </div>
                       </>
                       ):(
                         ''
                       )}
                {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  let [currentHolding, setCurrentHolding] = useState('');

  const setFolioNoDataForStep2 = (selected, funCode) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== 'Other') {
      // if user select any account from dropdown then data fillout
      unitHolderData.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            // fields['unitholder_name'] = item.name;
            setFields({
              ...fields,
              unitholder_name:item.name
            })
            if (
              item.balance_unit !== '' &&
              item.balance_unit !== '[]' &&
              item.balance_unit !== undefined
            ) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== 'number') {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === funCode) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(temp[0].fund_unit.toString().trim().toString());
                  setFields({
                    ...fields,
                    current_holding: parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4)
                  })
                  currentHolding=parseFloat(temp[0].fund_unit.toString().trim()).toFixed(4);
                  setCurrentHolding(currentHolding);
                } else {
                  toast.error('Zero Units of selected Fund', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  setFields({
                    ...fields,
                    current_holding: '0.00'
                  })
                  // setCurrentHolding('0.00');
                  currentHolding='0.00';
                  setCurrentHolding(currentHolding);
                }
              }
            } else {
              //empty
              toast.error('Zero Units of selected Fund', {
                position: 'top-right',
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              // fields['current_holding'] = '0.00';
              setFields({
                ...fields,
                current_holding: '0.00'
              })
              currentHolding='0.00';
              setCurrentHolding(currentHolding);
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      // fields['current_holding'] = '0.00';
      setFields({
        ...fields,
        current_holding: '0.00'
      })
      currentHolding='0.00';
      setCurrentHolding(currentHolding);
      setAccTiltleName(`${count}-${selected}`);
    }
  };

  const [finishFlag, setFinishFlag] = useState(false);

  const Next = async()=>{
    const isValid =  await validate(); // check validation for Fields Data
    if (isValid) {
      setFields({
        ...fields,
        txnr_type:'conversionout'
      })
      fields['txnr_type']='conversionout';
      setFields(fields);
      if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0){
        toast.error("Amounts or units or remain holding are negative");
      }else if(fields['txnr_type']==='conversionout' && +fields['units']===0)
      {
        toast.error(" Units cannot be zero.")
      }else{
      sessionStorage.setItem('Txn1', JSON.stringify(fields));
      setFinishFlag(true);
      history.replace(`/admin/beta-transaction/conversionin`);
    }
    }else{
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  }

  React.useEffect(()=>{
    if(finishFlag){
    let data:string = sessionStorage.getItem('Txn1') || '';
    let prevTxnData = JSON.parse(data);
    // for conversion of units 2nd step
    setFolioNoDataForStep2(fields['folio_no'],prevTxnData.counter_type);
    setFields({
      ...fields,
      fund_code:prevTxnData.counter_type,
      fund_account:prevTxnData.counter_account_type,
      account_title:prevTxnData.counter_account_title,
      account_no:prevTxnData.counter_account_number,
      bank:prevTxnData.counter_bank,
      branch:prevTxnData.counter_branch,
      counter_type:prevTxnData.fund_code, 
      counter_account_type:prevTxnData.fund_account, 
      counter_account_title:prevTxnData.account_title, 
      counter_account_number:prevTxnData.account_no, 
      counter_bank:prevTxnData.bank, 
      counter_branch:prevTxnData.branch, 
      conversion_date:prevTxnData.redemption_date,
      redemption_date:'', 
      units: '',
      nav:'',
      percentage_of_discount:'',
      load_per_unit:'',
      offer_price:'',
      applicable_offer_price:'',
      current_holding: currentHolding,
      total_holding: '',
      redemption_load: '',
      tax_cgt: '',
      zakat_amount: '',
      gross_amount: prevTxnData.net_amount,
      net_amount:'',
      txnr_type:'conversionin',
      payment_type:'Receipt'
    })
    setUnitsSeparator('');
    setGrossAmountSeparator('');
    setGrossAmountInWords('');
    setNetAmountSeparator('');
    setNetAmountInWords('');
  }
  },[finishFlag])

  const Finish = async()=>{
    setLoading(true);
    const isValid =  await validate(); // check validation for Fields Data
    if (isValid) {
      if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0){
        toast.error("Amounts or units or remain holding are negative");
      }else if(fields['txnr_type']==='conversionin' && +fields['units']===0)
      {
        toast.error("Units cannot be zero.")
      }else{
      let data:string = sessionStorage.getItem('Txn1') || '';
      let txnsData:any=[];
      txnsData.push(JSON.parse(data));

      txnsData[0].folio_no = txnsData[0].folio_no.replaceAll(txnsData[0].amc_code+'-','')
      txnsData[0].folio_no = txnsData[0].amc_code+'-'+txnsData[0].folio_no;

      fields['folio_no'] = fields['folio_no'].replaceAll(fields['amc_code']+'-','')
      fields['folio_no'] = fields['amc_code']+'-'+fields['folio_no'];
      txnsData.push(fields);
      const response = await addConversionOfUnitsTransaction(email,txnsData)
      toast.success(response.data.message)
      sessionStorage.removeItem('Txn1');
      setTimeout(() => {
        history.replace(`/admin/transaction-types`);
      }, 3000);
    }
    }else{
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
    setLoading(false);
  }

  React.useEffect(()=>{
    if(headLabel==='saleofunit'){
    setFields({
      ...fields,
      counter_account_type:propsState.folioNo.replaceAll(propsState.amc_code+'-',''),
      folio_no:propsState.folioNo,
      unitholder_name: propsState.unitholder,
      counter_account_title: propsState.account_title,
      counter_account_number:propsState.account_no,
      counter_bank:propsState.bank,
      counter_branch:propsState.branch
    })
  }else if(headLabel==='conversionin'){
    setFields({
      ...fields,
      // counter_account_type:'Other',
      folio_no:propsState.folioNo.replaceAll(propsState.amc_code+'-',''),
      unitholder_name: propsState.unitholder,
      // counter_account_title: propsState.account_title,
      // counter_account_number:propsState.account_no,
      // counter_bank:propsState.bank,
      // counter_branch:propsState.branch
    })
  }
    setViewModalAddNewUnitHolder(false);
  },[propsState])

  const renderModalAddNewUnitHolder = () =>{
    switch (viewModalAddNewHolder) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewModalAddNewUnitHolder(false);
            }}
          >
             <div className="modal-header ">
              <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setViewModalAddNewUnitHolder(false);
                  }}
              >
                  <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add UnitHolder</h6>
          </div>
            <div className="modal-body">
                <>
                    <AddUnitHolder setPropState={setPropsState} />
                </>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  }

  const getLedgesDetailByLedgerCode = async(ledgerCode)=>{
    try{
      const response = await getLedgerInfoByCode(email,ledgerCode)
      return response.data.data;

    }catch(error:any){

    }
  }

  const [viewModalForLedgers, setViewModalForLedgers] = useState(false);
  // add more ledgers
  const renderModalForAddMoreLedgers = () => {
    switch (viewModalForLedgers) {
      case true:
        return (
            <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { 
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
             }}
        > 
        <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewModalForLedgers(false);
                clearFields();
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add New Ledger</h6>
          </div>

            <div className="modal-body">
                <div className="  m-3">

                <Row> 
                  <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Ledgers</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                        <Col>
                        <div className="form-group " data-tip="Select Ledger">
                  {ledgeroptions.length > 0 ? (
                    <Autocomplete
                      className="mt-2 border-bottom autocomplete border-gray"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgeroptions}
                      style={{ width: "100%" }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{ background: "#1E1E2F", color: "white" }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={selectedLedgerCode}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedLedgerCode(newValue);
                        // console.log('onChange',event);
                      }}
                      inputValue={selectedInputLedgerCode}
                      onInputChange={(event, newInputValue) => {
                        setSelectedInputLedgerCode(newInputValue);
                        // console.log('onInputChange',event);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <div className="form-control w-100">
                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                    </div>
                  )}
                </div>
                          </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Amount Type
                              <select
                                className="form-control w-100"
                                value={ledgerAmountType}
                                onChange={(e) => {
                                   setLedgerAmountType(e.target.value);
                                   if(e.target.value=='Gross Amount'){
                                     setLedgerAmount(fields['gross_amount'].toString())
                                   }
                                   if(e.target.value=='Sale load'){
                                    setLedgerAmount(fields['sale_load'].toString())
                                  }
                                  if(e.target.value=='CGT'){
                                    setLedgerAmount(fields['tax_cgt'].toString())
                                  }
                                  if(e.target.value=='Redemption load'){
                                    setLedgerAmount(fields['redemption_load'])
                                  }
                                  if(e.target.value=='Zakat'){
                                    setLedgerAmount(fields['zakat_amount'].toString())
                                  }
                                  if(e.target.value=='Net Amount'){
                                    setLedgerAmount(fields['net_amount'].toString())
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Amount Type
                            </option>
                              <option value="Gross Amount">Gross Amount</option>
                              <option value="Sale load">Sale load</option>
                              {(headLabel==='redemptionofunits' || headLabel==='conversionout') && (
                                <>
                                <option value="Redemption load">Redemption load</option>
                                <option value="CGT">CGT</option>
                                <option value="Zakat">Zakat</option>
                                </>
                              )}
                              <option value="Net Amount">Net Amount</option>
                              <option value="Other">Other</option>
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Type
                              <select
                                className="form-control w-100"
                                value={ledgerType}
                                onChange={(e) => {
                                   setLedgerType(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                              <option value="Debit">Debit</option>
                              <option value="Credit">Credit</option>
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                          <div className="form-group">
                          Amount
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerAmount}
                            onChange={(e) => {
                             setLedgerAmount(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>

                  <Row>
                        <Col>
                          <div className="form-group">
                          Description
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerDescription}
                            onChange={(e) => {
                             setLedgerDescription(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>
                  <Row>
                        <Col>
                          <div className="form-group">
                          Invoice
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerInvoice}
                            onChange={(e) => {
                             setLedgerInvoice(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>

                      </div>
                    </div>
                    </Col>

                    </Row>

                    <div className=" ">

                    <div className=" mt-3">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => {
                          if(selectedInputLedgerCode==''){
                            toast.error('Select Ledger');
                            return;
                          }
                          if(ledgerAmount==''){
                            toast.error('Enter Amount');
                            return;
                          }
                          if(ledgerType==''){
                            toast.error('Select Type');
                            return;
                          }
                          addLedgersInArray();
                          setViewModalForLedgers(false);
                        }}  >Add</button>
                    </div>
                </div>
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };

  const addLedgersInArray=async()=>{
    const ledgerInfo = await getLedgesDetailByLedgerCode(selectedInputLedgerCode.split("-")[0].trim());

    // if(ledgerType=='Debit'){
    //   let totaldebit =(+totalDebit + +ledgerAmount).toString()
    //   let totalcredit =(+fields['sale_load'] + +fields['net_amount'] + 0).toString();
    //   setTotalDebit(totaldebit);
    //   setTotalCredit(totalcredit);
    //   setBalance((+totaldebit - +totalcredit).toString())
    // }else{
    //   let totaldebit =(+totalDebit).toString()
    //   let totalcredit =(+fields['sale_load'] + +fields['net_amount'] + +ledgerAmount).toString();
    //   setTotalDebit(totaldebit);
    //   setTotalCredit(totalcredit);
    //   setBalance((+totaldebit - +totalcredit).toString())
    // }
    let newLedger = {
      amc_code:amc_code,
      fund_code:fields['fund_code'],
      account_number:fields['account_no'],
      account_title:fields['account_title'],
      bank_code:fields['bank'],
      branch:fields['branch'],
      child_family_code:ledgerInfo.child_family_code,
      child_family_name:ledgerInfo.child_family_name,
      counter_account_number:fields['counter_account_number'],
      counter_account_title:fields['counter_account_title'],
      counter_bank:fields['bank'],
      counter_branch:fields['branch'],
      credit_amount:ledgerType=='Credit' ? ledgerAmount : '0',
      debit_amount:ledgerType=='Debit' ? ledgerAmount : '0',
      description:ledgerDescription,
      entered_by:'',
      evidence:'',
      family_code:ledgerInfo.family_code,
      family_name:ledgerInfo.family_name,
      gl_code:+(selectedInputLedgerCode.split("-")[0].replace(/\s+/g, "")),
      gl_name:selectedInputLedgerCode.split("-")[1],
      instruction_date:fields['instruction_date'],
      instrument_by:'',
      instrument_date:fields['instrument_date'],
      instrument_no:fields['instrument_no'],
      instrument_type:fields['instrument_type'],
      main_family_code:ledgerInfo.main_family_code,
      main_family_name:ledgerInfo.main_family_name,
      realized:'',
      realized_by:'',
      realized_date:'',
      sub_family_code:ledgerInfo.sub_family_code,
      sub_family_name:ledgerInfo.sub_family_name,
      amount_type:ledgerAmountType,
      type:ledgerType
    }
    setTransactionLedgers([...transactionLedgers, newLedger]);
    let totaldebit=0, totalcredit=0;
    let data =[...transactionLedgers, newLedger]
    data.map((item:any)=>{
      if(item.type=='Debit'){
        totaldebit+= +item.debit_amount;
        }
       if(item.type=='Credit'){
        totalcredit+= +item.credit_amount;
       }
    });
    console.log('totaldebit',totaldebit)
    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
    console.log([...transactionLedgers, newLedger])

    clearFields();
  }

  const clearFields=()=>{
    setSelectedLedgerCode('');
    setSelectedInputLedgerCode('');
    setSelectedLedgerName('');
    setLedgerType('');
    setLedgerAmount('');
    setLedgerDescription('');
    setLedgerInvoice('');
  }


  return (
    <>
     <div className="content">
      <Row>
       <Col md="12">
      <Container fluid>
        <ToastContainer />
        <div className="">
        <div className="input-holder"> 
                  <div className="row">
                    <h4 className=" card-title">
                        <i className="fa fa-angle-left mr-2 ml-3 mt-1 pointer" onClick={() => {
                            history.replace('/admin/create-transaction')
                        }}></i>
                        {CapTxnType(headLabel)} Transaction</h4> 
                    </div>  

              {headLabel==='unitconversion' && (
              <span>Step<span className='text-primary'> 1</span> of 2</span>
              )}
              {finishFlag===true && (
              <span>Step<span className='text-primary'> 2</span> of 2</span>
              )}
                <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm mr-3" replace>
                     <i className="fa fa-upload mr-2"></i> Bulk Upload
               </Link> 

              </div>
          <div className=" ">
            
            <Row >
              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Instruction</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      <div
                        className="form-group "
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div>
                        ) : (
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['fund_code'])
                            }
                            value={fields['fund_code']}
                            disabled={finishFlag}
                            onChange={(e) => {
                              fields['fund_code'] = e.target.value;
                              setFields(fields);
                              
                              clearFundAccountFields();
                              getAccountByFundName(e.target.value);
                              if (headLabel === 'unitconversion') {
                                let filterToAcc = allFunds.filter(
                                  (acc) => acc.symbol_code !== e.target.value
                                );
                                let temp = filterToAcc.map((val) => {
                                  if(val.nature==='Special Managed Account'){
                                    return {
                                      ...val,
                                      label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
                                      value: val.symbol_code,
                                    };
                                  }else{
                                  return {
                                    ...val,
                                    label: `${val.fund_name} (${val.symbol_code})`,
                                    value: val.symbol_code,
                                  };
                                }
                                });
                                setAllCounterFunds(temp);
                              }
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {fundCheck ? 'No Fund Found' : 'Select Fund'}
                            </option>
                            {renderFundsDropdown()}
                          </select>
                        )}
                      </div>
                      <div className="form-group">
                        <div>Instruction Date</div>
                        <input
                          type="date"
                          value={fields['instruction_date']}
                          readOnly
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['instruction_date'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              instruction_date: e.target.value,
                              //realized_date: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {/* <div>Execution Date</div> */}
                        <div>Settlement Date</div>
                        <input
                          type="date"
                          value={fields['execution_date']}
                          min={disableDates}
                          readOnly={finishFlag}
                          className={'form-control w-100' + ErrorBorder(fields['execution_date'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              execution_date: e.target.value,
                              settlement_date: e.target.value
                            });
                          }}
                        />
                      </div>
                      
                      <div className="form-group">
                        <div>Mode of Payment*</div>
                        <select
                          value={fields['mode_of_payment']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['mode_of_payment'])
                          }
                          disabled={finishFlag}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              mode_of_payment: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Payment
                          </option>
                          {renderModeOfPayments()}
                        </select>
                      </div>
                      
                      <div className="form-group">
                        <div>Instrument Type</div>
                        <select
                          className={'form-control w-100 '}
                          value={fields['instrument_type']}
                          disabled={finishFlag}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              instrument_type: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Type
                          </option>
                          {renderiTypeDataDropdown()}
                        </select>
                      </div>
                      {fields.payment_type === 'Receipt' && (
                        <>
                          <div className="form-group">
                            <div>Instrument No./Reference No</div>
                            <input
                              defaultValue={fields['instrument_no']}
                              type="text"
                              className={'form-control w-100 '}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  instrument_no: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <div>Instrument Date / Deposit Date</div>
                            <input
                              type="date"
                              defaultValue={fields['instrument_date']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['instrument_date'])
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  instrument_date: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                </div>

                {fields['payment_type']=='Payment' && (
                <div className="card">
                  <div className="card-header">
                    {/* condition to for label selection */}
                    <h4 className="card-title"> Beneficiary Account
                    </h4>
                  </div>
                  <div className="card-body">
                    <div>
                      {/* start of fund selection in the case of Unit Conversion Transaction */}
                      {headLabel === 'unitconversion' && finishFlag===false && (
                        <div className="form-group w-100 d-flex">
                          <div
                            className="w-50 flex"
                            data-tip="First Select Amc"
                          >
                            <div>Fund Name*</div>
                            <ReactTooltip
                              textColor="white"
                              backgroundColor="black"
                              effect="float"
                            />
                            {accFundLoading ? (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : fields['amc_code'].trim() === '' ? (
                              <div className="form-control    w-100">
                                First Select AMC
                              </div>
                            ) : (
                              <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  getCounterFundAccountByFundName(e.value);
                                  allCounterFunds.map((item) => {
                                    if (item.symbol_code === e.value) {
                                      setUnitNav(item.nav);
                                    }
                                  });
                                  fields['counter_type'] = e.value;
                                }}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={allCounterFunds}
                              />
                            )}
                          </div>
                          <div className="w-50 flex">
                            <div>Fund Account</div>
                            {counterFundLoading ? (
                              <div className="form-control w-100">
                                 <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                              <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_account_type: e.value,
                                  });
                                  setFundAccountDataCounter(e.value);
                                }}
                                value={allCounterFundsAccounts.filter(
                                  (option) =>
                                    option.value ===
                                    fields['counter_account_type']
                                )}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={allCounterFundsAccounts}
                              />
                            )}
                          </div>
                        </div>
                      )}

                    {finishFlag===true && (
                          <div className="form-group w-100 d-flex">
                          
                            <div
                              className="w-50 flex" 
                            >
                              <div>Fund Name</div>
                                <input
                                type="text"
                                className="form-control"
                                value={fields['counter_type']}
                                readOnly={finishFlag}
                                /> 
                            </div>
                            <div className="w-50 flex">
                              <div>Fund Account</div>
                                <input
                                type="text"
                                className="form-control"
                                value={fields['counter_account_type']}
                                readOnly={finishFlag}
                                />
                            </div>
                          </div>
                        )}

                      {/* End of fund selection in the case of Unit Conversion Transaction */}
                      {headLabel !== 'unitconversion' && (
                        <div className="form-group">
                          <ReactTooltip
                            textColor="black"
                            backgroundColor="white"
                            effect="float"
                          />

                          {finishFlag===false && ( // conversion of unit is selected and click on next button then is title will not show.
                          <div className="text-capitalize">
                            {counterAccounter === 'Unit Holder'
                              ? ''
                              : counterAccounter}
                          </div>
                          )}

                          {counterAccounter === 'Unit Holder' ? (
                            //For Unit Holder Counter Account
                          //   fields['fund_code'].trim() === '' ? (
                          //     <div className="form-control w-100">
                          //       Kindly First Select Fund
                          //     </div>
                          //   ) : (
                          //     <>
                          //     <Row>
                          //         <Col md="9">
                          //     <ReactTooltip
                          //       textColor="white"
                          //       backgroundColor="black"
                          //       effect="float"
                          //     />
                          //           <div className="form-group">
                          //           <div></div>
                          //           <input
                          //             type="text"
                          //             value={fields['counter_account_type']}
                          //             placeholder='Enter Folio No.'
                          //             data-tip="Press Enter key to load data."
                          //             className={
                          //               'form-control w-100 ' +
                          //               ErrorBorder(fields['counter_account_type'])
                          //             }
                          //             readOnly={unitHolderFieldReadOnly}
                          //             onChange={(e) => {
                          //               setFields({
                          //                 ...fields,
                          //                 counter_account_type: e.target.value,
                          //               });
                          //             }}
                          //             onKeyPress={(e)=>{
                          //               if(e.key === 'Enter' && unitHolderFieldReadOnly===false){
                          //                 getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code']+'-'+fields['counter_account_type']);
                          //               }
                          //             }}
                          //           />
                          //         </div>
                          //         </Col>
                          //     <Col md="3">
                          //     <div></div>
                          //       <button className="btn btn-default btn-sm mt-a"
                          //       disabled={Boolean(unitHolderFieldReadOnly)}
                          //       onClick={() => {
                          //         getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code']+'-'+fields['counter_account_type']);
                          //       }}>
                          //       {unitHolderFieldReadOnly ? (
                          //       <>
                          //         <span
                          //           className="spinner-border login-txt spinner-border-sm"
                          //           role="status"
                          //           aria-hidden="true"
                          //         ></span>

                          //         <i className="fa fa-search"></i>
                          //       </>
                          //     ) : (
                          //       <i className="fa fa-search"></i>
                          //     )}
                          //     </button>
                          //   </Col>
                          // </Row>
                          // </>
                          //   )
                          ''
                          ) : counterAccounter === 'Fund' ? (
                            //For fund
                            <div data-tip="First Select Fund Name">
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accLoading ? (
                                <div className="form-control w-100">
                                   <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                                </div>
                              ) : fields['fund_code'].trim() === '' ? (
                                <div className="form-control    w-100">
                                  First Select Fund
                                </div>
                              ) : (
                                <Select
                                className="react-select info"
                                classNamePrefix={"react-select "+ ErrorBorder(fields['counter_account_type'])}
                                  label={'Select'}
                                  isDisabled={false}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      counter_account_type: e.value,
                                    });
                                    setFundDataCounter(e.value);
                                  }}
                                  value={toAccountData.filter(
                                    (option) =>
                                      option.value ===
                                      fields['counter_account_type']
                                  )}
                                  isClearable={false}
                                  isSearchable={true}
                                  name="color"
                                  options={toAccountData}
                                />
                              )}
                            </div>
                          ) : //For Other Counter Amc
                          counterAccounter === 'amc' ? (
                            accFundLoading ? (
                              <div className="form-control w-100">
                                 <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                              <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  let taxCollector='';
                                  if(e.value==='Other'){
                                    taxCollector='Other'
                                  }else{
                                 let amcTaxCollector = amcdataCounter.filter((item) =>
                                  item.value ===e.value)

                                  taxCollector=amcTaxCollector[0].tax_collector;
                                  }
                                  
                                  fields['counter_account_type'] = e.value;
                                  fields['sales_tax_collector'] = taxCollector;
                                  setFields(fields);
                                  // setFields({
                                  //   ...fields,
                                  //   counter_account_type: e.value,
                                  // });
                                  setAmcAccountData(e.value);
                                }}
                                value={amcdataCounter.filter(
                                  (option) =>
                                    option.value ===
                                    fields['counter_account_type']
                                )}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={amcdataCounter}
                              />
                            )
                          ) : //For Other Counter Accounts

                          counterAccounter === 'Other' ? (
                            <input
                              type="text"
                              value={fields['counter_account_type']}
                              className="form-control w-100"
                              readOnly
                            />
                          ) : (
                            //For Other Brokers
                            finishFlag===false && ( // check the conversion of unit next button 
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select'}
                              isDisabled={false}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  counter_account_type: e.value,
                                });
                                setBrokerData(e.value);
                              }}
                              value={borkerByTypeData.filter(
                                (option) =>
                                  option.value ===
                                  fields['counter_account_type']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={borkerByTypeData}
                            />
                            )
                          )}
                        </div>
                      )}
                      <div className="form-group">
                        <div>Account Title</div>
                        <input
                          type="text"
                          value={fields['counter_account_title']}
                          className={'form-control w-100 '+ErrorBorder(fields['counter_account_title'])}
                          readOnly={finishFlag}
                          onChange={(e) => {
                            if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                              {
                                counterType='Other';
                                setCounterType(counterType);
                                setFields({
                                  ...fields,
                                  counter_account_title: e.target.value || '',
                                });
                              }
                            // setFields({
                            //   ...fields,
                            //   counter_account_title: e.target.value,
                            // });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div>Account Number</div>
                        <input
                          type="text"
                          value={fields['counter_account_number']}
                          className={'form-control w-100 '}
                          readOnly={finishFlag}
                          onChange={(e) => {
                            if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                            {
                              counterType='Other';
                              setCounterType(counterType);
                              setFields({
                              ...fields,
                              counter_account_number: e.target.value,
                              });
                            }
                            // setFields({
                            //   ...fields,
                            //   counter_account_number: e.target.value,
                            // });
                          }}
                        />
                      </div>
                      <div className="form-group w-100 d-flex">
                        <div className="w-50 flex mr-2">
                          <div>Bank</div>
                          {/* <div className="form-group">
                          <input className="form-control"
                              value={fields['counter_bank']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_bank: e.target.value,
                                  });
                                }} />
                                </div> */}
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={'Select Bank'}
                            // isDisabled={false}
                            isDisabled={finishFlag}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                counter_bank: e.value,
                              });
                              getCounterBranchByName(e.value);
                            }}
                            value={bankNameData.filter(
                              (option) =>
                                option.value === fields['counter_bank']
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={bankNameData}
                          />
                        </div>

                        {finishFlag===true ? (
                            <div className="w-50 flex">
                            <div>Branch</div>
                            <div className="d-flex">
                                <input
                                  type="text"
                                  value={fields['counter_branch']}
                                  className="form-control w-100 "
                                  readOnly={finishFlag}
                                />
                                </div>
                                </div>
                          ):(
                        <div className="w-50 flex mr-2">
                          <div>Branch</div>
                          <div className="form-group">
                          {/* <input className="form-control"
                              value={fields['counter_branch']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }} /> */}
                                
                          {inputType ? (
                            <div className="d-flex">
                              <input
                                type="text"
                                value={fields['counter_branch']}
                                className="form-control w-100 "
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                }}
                              />
                              <RiArrowGoForwardFill
                                onClick={() => {
                                  setInputType(false);
                                  setFields({
                                    ...fields,
                                    counter_branch: '',
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select Branch'}
                              // isDisabled={false}
                              isDisabled={finishFlag}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  counter_branch: e.branch_name,
                                });
                                onOtherSelection(e.value);
                              }}
                              value={allCounterbranchesData.filter(
                                (option) =>
                                  option.value === fields['counter_branch']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={allCounterbranchesData}
                            />
                          )}
                        </div>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                )}            
              </Col>
              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transaction Details
                         {(headLabel==='saleofunit' || headLabel==='conversionin') && (
                          // <>
                            <button className='btn btn-default btn-sm float-right'
                            title="Add New Unit-Holder"
                            onClick={()=>{
                              if(fields['amc_code']!==''){
                              setViewModalAddNewUnitHolder(true);
                              }else{
                                toast.error('First select AMC');
                              }
                              }}>
                            <i className="fa fa-plus"></i>
                          </button>
                          // </>
                        
                        )}</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      {(headLabel === 'unitconversion' ||
                       // headLabel === 'redemptionofunits' ||
                        headLabel === 'conversionin' ||
                        headLabel === 'conversionout' || headLabel==='saleofunit' || headLabel==='redemptionofunits'
                         ) && (
                          <>
                          <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                          <Row>
                              <Col md="9">
                            <div className="form-group">
                            <div>Folio Number</div>
                            <input
                                  type="text"
                                  placeholder='Enter Folio No.'
                                  data-tip="Press Enter key to load current holding."
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['folio_no'])
                                  }
                                  
                                  // value={fields['folio_no']}
                                  value={fields['folio_no']}
                                  readOnly={unitHolderFieldReadOnly}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      folio_no: e.target.value,
                                    });
                                  }}
                                  onKeyPress={(e)=>{
                                    if(e.key === 'Enter' && unitHolderFieldReadOnly===false){
                                      if (fields['fund_code']) {
                                        fields['folio_no'] = fields['folio_no'].replaceAll(fields['amc_code']+'-','');
                                        getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code']+'-'+fields['folio_no']);
                                      }else{
                                        toast.error('Kindly First select Fund');
                                      }
                                    }
                                  }}
                                />
                              </div>
                              </Col>
                              <Col md="3">
                              <div className="form-group">
                              <div></div>
                             <button className="btn btn-default btn-sm mt-4"
                              disabled={Boolean(unitHolderFieldReadOnly)}
                              onClick={() => {
                                if (fields['fund_code']) {
                                  fields['folio_no'] = fields['folio_no'].replaceAll(fields['amc_code']+'-','');
                                  getUnitHoldersByAmcFolioNo(email, fields['amc_code'], fields['amc_code']+'-'+fields['folio_no']);
                                }else{
                                    toast.error('Kindly First select Fund');
                                }
                              }}>
                              {unitHolderFieldReadOnly ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  {/* <span className="login-txt"> Search</span> */}
                                  <i className="fa fa-search"> </i>
                                </>
                              ) : (
                                // <span>Search</span>
                                <i className="fa fa-search"> </i>
                              )}
                              </button>
                              </div>
                              </Col>
                              </Row>

                              <div className="form-group">
                            <div>UnitHolder Name</div>
                                <input
                                  type="text"
                                  value={fields['unitholder_name']}
                                  placeholder='UnitHolder Name'
                                  className={
                                    'form-control w-100 ' +
                                    ErrorBorder(fields['unitholder_name'])
                                  }
                                  readOnly
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      unitholder_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              </>
                      )}
                      
                      {chechFieldDisplay(headLabel, 'sale_date') && (
                         <Row>
                         <Col md="6">
                        <div className="form-group">
                          <div>Sale Date</div>
                          <input
                            type="date"
                            defaultValue={fields['sale_date']}
                            max={disableDates}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['sale_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                sale_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        </Col>
                        <Col md="4">
                              <button className="btn btn-default mt-3"
                              disabled={Boolean(loadBtnEnable)}
                              onClick={async() => {
                                try{
                                  setLoadBtnEnable(true);
                               await loadDailyNav(fields['fund_code'], fields['sale_date']).then(res => {
                                  let newNav = 0;
                                  if (res.length>0) { 
                                    newNav = decimalValueNav(res[0].Record.nav, 4);
                                    let offerprice= res[0].Record.offer_price;
                                    let loadperunit= decimalValueNav(offerprice-newNav, 4);
                                  
                                  setFields({
                                    ...fields,
                                    nav: newNav.toString(),
                                    offer_price:offerprice.toString(),
                                    load_per_unit: loadperunit.toString(),
                                    applicable_offer_price:offerprice,
                                    percentage_of_discount:'0'
                                  });
                                }else{
                                  setFields({
                                    ...fields,
                                    nav: '0',
                                    offer_price:'0',
                                    load_per_unit: '0',
                                    applicable_offer_price:'0'
                                  });
                                }
                                setLoadBtnEnable(false);
                                });
                              }catch(error:any){
                                setLoadBtnEnable(false);
                                if(error.response!==undefined){
                                  toast.error(error.response.data.message);
                                }else{
                                  toast.error('Request Failed!');
                                }
                              }
                              setLoadBtnEnable(false);
                              }}
                              >Load</button>
                            </Col>
                          </Row>
                      )}
                      {chechFieldDisplay(headLabel, 'redemption_date') && (
                         <Row>
                         <Col md="6">
                        <div className="form-group">
                          <div>Redemption Date</div>
                          <input
                            type="date"
                            defaultValue={fields['redemption_date']}
                            max={disableDates}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['redemption_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                redemption_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        </Col>
                        <Col md="4">
                             <button className="btn btn-default mt-3"
                             disabled={Boolean(loadBtnEnable)}
                              onClick={async() => {
                                try{
                                  setLoadBtnEnable(true);
                                await loadDailyNav(fields['fund_code'], fields['redemption_date']).then(res => {
                                  let newNav = 0, loadperunit=0;
                                  if (res && res[0]) {
                                    newNav = decimalValueNav(res[0].Record.nav, 4);
                                    let redemptionprice= res[0].Record.redemption_price;
                                    loadperunit=decimalValueNav(newNav-redemptionprice,4);
                                  setFields({
                                    ...fields,
                                    nav: newNav.toString(),
                                    offer_price:redemptionprice.toString(),
                                    load_per_unit: loadperunit.toString(),
                                    applicable_offer_price:redemptionprice,
                                    percentage_of_discount:'0'
                                  });
                                }else{
                                  setFields({
                                    ...fields,
                                    nav: '0',
                                    offer_price:'0',
                                    load_per_unit: '0',
                                    applicable_offer_price:'0'
                                  });
                                }
                                setLoadBtnEnable(false);
                                });
                              }catch(error:any){
                                setLoadBtnEnable(false);
                                if(error.response!==undefined){
                                  toast.error(error.response.data.message);
                                }else{
                                  toast.error('Request Failed!');
                                }
                              }
                              setLoadBtnEnable(false);
                                //loadDailyNav(fields['fund_name'], fields['sale_date'])
                              }}
                              >Load
                              </button>
                              </Col>  
                          </Row>
                      )}

                    {chechFieldDisplay(headLabel, 'conversion_date') && (
                        <Row>
                        <Col md="6">
                        <div className="form-group">
                          <div>Conversion Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['conversion_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['conversion_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                conversion_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        </Col>
                        <Col md="4">
                             <button className="btn btn-default mt-3"
                             disabled={Boolean(loadBtnEnable)}
                              onClick={async() => {
                                try{
                                  setLoadBtnEnable(true);
                                if(finishFlag===true){
                                 await loadDailyNav(fields['fund_code'], fields['conversion_date']).then(res => {
                                    let newNav = 0;
                                    if (res && res[0]) {
                                      newNav = decimalValueNav(res[0].Record.nav, 4);
                                      let offerprice= res[0].Record.offer_price;
                                      let loadperunit= decimalValueNav(offerprice-newNav,4);
  
                                      let unit=(parseFloat(fields['gross_amount'])/parseFloat(offerprice)).toFixed(4);
                                      let saleload =(parseFloat(loadperunit)*parseFloat(unit)).toFixed(2);
                                      let netamount = (parseFloat(fields['gross_amount']) - parseFloat(saleload.replace('','0'))).toFixed(2);
                                      let remainingholding=(parseFloat(fields['current_holding']) + parseFloat(unit)).toFixed(4);
                                      // let applicable_offer_price= +newNav + parseFloat(loadperunit);
                                    
                                    setFields({
                                      ...fields,
                                      nav: newNav.toString(),
                                      offer_price:offerprice.toString(),
                                      load_per_unit: loadperunit.toString(),
                                      applicable_offer_price: offerprice.toString(),
                                      units:unit,
                                      sale_load:saleload || '0',
                                      net_amount:netamount,
                                      total_holding:remainingholding,
                                      percentage_of_discount:'0'
                                    });
  
                                    let inwordUnits = inWords(unit);
                                    setUnitsInWord(inwordUnits);
                                    let inwordNetAmount = inWords(netamount);
                                    setNetAmountInWords(inwordNetAmount);
                                    let inwordGrossAmount = inWords(fields['gross_amount']);
                                    let unitSeparator=numberWithCommas(unit);
                                    setUnitsSeparator(unitSeparator);
                                    setGrossAmountInWords(inwordGrossAmount);
                                    let grossAmountSeparator= numberWithCommas(fields['gross_amount']);
                                    setGrossAmountSeparator(grossAmountSeparator);
                                    let netAmountSeparator= numberWithCommas(netamount);
                                    setNetAmountSeparator(netAmountSeparator);
  
                                    }else{
                                      setFields({
                                        ...fields,
                                        nav: '0',
                                        offer_price:'0',
                                        load_per_unit: '0',
                                        applicable_offer_price:'0'
                                      });
                                    }
                                  });
                                }else{
                                await loadDailyNav(fields['fund_code'], fields['conversion_date']).then(res => {
                                  let newNav = 0;
                                  if (res && res[0]) {
                                    newNav = decimalValueNav(res[0].Record.nav, 4);
                                    let offerprice= res[0].Record.offer_price;
                                    let loadperunit= decimalValueNav(offerprice-newNav,4);
                                  
                                  setFields({
                                    ...fields,
                                    nav: newNav.toString(),
                                    offer_price:offerprice.toString(),
                                    load_per_unit: loadperunit.toString(),
                                    applicable_offer_price: offerprice.toString(),
                                    percentage_of_discount:'0'
                                  });

                                  }else{
                                    setFields({
                                      ...fields,
                                      nav: '0',
                                      offer_price:'0',
                                      load_per_unit: '0',
                                      applicable_offer_price:'0'
                                    });
                                  }
                                });
                              }
                              setLoadBtnEnable(false);
                            }catch(error:any){
                              setLoadBtnEnable(false);
                              if(error.response!==undefined){
                                toast.error(error.response.data.message);
                              }else{
                                toast.error('Request Failed!');
                              }
                            }
                            setLoadBtnEnable(false);
                              }}
                              >Load
                              </button>
                            </Col>
                          </Row>
                      )}

                      <div className="row">
                      {chechFieldDisplay(headLabel, 'nav') && (
                        <div className="form-group col-md-6">
                          <div>NAV Price*</div>
                          <input
                            type="number"
                            value={fields['nav']}
                            className={'form-control w-100' + ErrorBorder(fields['nav'])
                            }
                            readOnly
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 4);
                               setFields({
                                  ...fields,
                                  nav: value
                                });
                           
                            }}
                          />
                        </div>
                      )}

                      {chechFieldDisplay(headLabel, 'offer_price') && (
                          <div className="form-group col-md-6">
                            <div>{(headLabel==='saleofunit' || headLabel==='conversionin') ? 'Sale Price' : 'Redemption Price'}</div>
                            <input
                              type="number"
                              value={fields['offer_price']}
                              className={
                                'form-control w-100 ' + ErrorBorder(fields['offer_price'])
                              }
                              readOnly
                              onChange={(e) => {
                                let value = decimalValue(e.target.value, 4);
                                setFields({
                                  ...fields,
                                  offer_price: value
                                });
                              }}
                            />
                          </div>
                        )}
                  </div>

                  <div className="row">

                      {chechFieldDisplay(headLabel, 'percentage_of_discount') && (
                          <div className="form-group col-md-6">
                            <div>% Discount on Load</div>
                            <input
                              type="number"
                              value={fields['percentage_of_discount'] || 0}
                              className={
                                'form-control w-100 ' + ErrorBorder(fields['percentage_of_discount'])
                              }
                              readOnly={discount_applicable_ReadOnly}
                              onChange={(e) => {
                                let value = e.target.value;
                                if(headLabel==='saleofunit' || headLabel==='conversionin'){
                                if(value.length<4){
                                  let dicountedLoad= (parseFloat(fields['offer_price'])-parseFloat(fields['nav']))* (1-parseFloat(value)/100);
                                  let applicableaOfferPrice= parseFloat(fields['nav'])+dicountedLoad;

                                setFields({
                                  ...fields,
                                  percentage_of_discount: value,
                                  load_per_unit:dicountedLoad.toFixed(4).toString(),
                                  applicable_offer_price: applicableaOfferPrice.toFixed(4)
                                });
                              }
                            }else if(headLabel==='conversionout' || headLabel==='redemptionofunits' || headLabel==='unitconversion'){
                              if(value.length<4){
                                let dicountedLoad= (parseFloat(fields['nav'])-parseFloat(fields['offer_price']))* (1-parseFloat(value)/100);
                                let applicableaOfferPrice= parseFloat(fields['nav'])-dicountedLoad;

                              setFields({
                                ...fields,
                                percentage_of_discount: value,
                                load_per_unit:dicountedLoad.toFixed(4).toString(),
                                applicable_offer_price: applicableaOfferPrice.toFixed(4)
                              });
                            }
                            }
                              }}
                              
                            />
                          </div>
                        )}
  
                      {chechFieldDisplay(headLabel, 'load_per_unit') && (
                          <div className="form-group col-md-6">
                            <div>Applicable Unit Load</div>
                            <input
                              type="number"
                              value={fields['load_per_unit']}
                              className={
                                'form-control w-100 ' + ErrorBorder(fields['load_per_unit'])
                              }
                              readOnly
                              onChange={(e) => {
                                let value = decimalValue(e.target.value, 4);
                                setFields({
                                  ...fields,
                                  load_per_unit: value
                                });
                              }}
                            />
                          </div>
                        )}
                       </div>

                       {chechFieldDisplay(headLabel, 'applicable_offer_price') && (
                          <div className="form-group">
                            <div>Applicable Price</div>
                            <input
                              type="number"
                              value={fields['applicable_offer_price']}
                              className={
                                'form-control w-100 ' + ErrorBorder(fields['applicable_offer_price'])
                              }
                              onChange={(e) => {
                                let value = e.target.value;
                                // if(value.length<3){
                                //   let percentagevalue= parseInt(value)/100;
                                //   let applicableaOfferPrice= parseInt(fields['offer_price'])-(parseInt(fields['load_per_unit'])*percentagevalue);
                                setFields({
                                  ...fields,
                                  applicable_offer_price: decimalValue(value,4)
                                });
                             // }
                              }}
                              
                            />
                          </div>
                        )}


                {chechFieldDisplay(headLabel, 'units') && (
                        <div className="form-group">
                         <div> Units{' '}
                          {headLabel === 'redemptionofunits'
                            ? 'Redeemed'
                            : headLabel === 'saleofunit' ||
                              headLabel === 'conversionin'
                            ? 'Sold*'
                            : headLabel === 'salereturn'
                            ? 'Returned'
                            : '*'} {unitsSeparator===''? '' : <span className="float-right text-primary">{unitsSeparator}</span>} </div>
                          <input
                            type="number"
                            // value={fields['units']}
                            className={'form-control w-100 ' + ErrorBorder(fields['units'])}
                            value={fields['units']}
                            readOnly={unitsReadOnly}
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 4);
                              if (fields.payment_type === 'Payment' || headLabel === 'unitconversion') {
                                if (+e.target.value <=+fields['current_holding']) {
                                  setCurentHolding(value);
                                  setFields({
                                    ...fields,
                                    units: value,
                                  });
                                  value==='' ?
                                  setGrossAmountReadOnly(false)
                                  : setGrossAmountReadOnly(true)
                                  value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                                }
                              } else {
                                let gamount=(parseFloat(fields['applicable_offer_price']))*(parseFloat(value));
                                  let gross_amount=gamount.toFixed(2).toString();
                                  let saleload= (parseFloat(value) * parseFloat(fields['load_per_unit'])).toFixed(2);
                                  let netamount=(gamount-parseFloat(saleload.toString().replace('','0'))).toFixed(2).toString();
                                  let totalholding = (parseFloat(fields['current_holding']) + parseFloat(value)).toFixed(4);
                                 
                                  if(gross_amount.split('.')[0].length<=12){
                                  setFields({
                                    ...fields,
                                    units: value,
                                    total_holding: totalholding,
                                    gross_amount:gross_amount,
                                    sale_load: saleload.toString(),
                                    net_amount:netamount
                                  });
                                }
                                value==='' ?
                                setGrossAmountReadOnly(false)
                                : setGrossAmountReadOnly(true)
                                value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                                if(value!==''){
                                let a = inWords(gross_amount);
                                let b = inWords(netamount);
                                //let c = inWords(value);

                                //setUnitsInWord(c);
                                setGrossAmountInWords(a);
                                setNetAmountInWords(b);
                                //// separator
                                let unitSepa=numberWithCommas(value);
                                let grossAmountSepa= numberWithCommas(gross_amount);
                                let netAmountSepa= numberWithCommas(netamount);
                                setUnitsSeparator(unitSepa);
                                setGrossAmountSeparator(grossAmountSepa);
                                setNetAmountSeparator(netAmountSepa);
                              }else{
                                setGrossAmountInWords('');
                                setNetAmountInWords('');
                                setGrossAmountSeparator('');
                                setNetAmountSeparator('');
                                setUnitsSeparator('');
                              }

                              if(headLabel=='saleofunit' || headLabel=='conversionin'){

                                let totaldebit=0, totalcredit=0;
                                transactionLedgers.map((item:any)=>{
                                  if(item.amount_type=='Gross Amount'){
                                    item.debit_amount=gross_amount;
                                    item.credit_amount='0';
                                  }
                                  if(item.amount_type=='Sale load'){
                                    item.debit_amount='0';
                                    item.credit_amount=saleload.toString();
                                  }
                                  if(item.amount_type=='Net Amount'){
                                    item.debit_amount='0';
                                    item.credit_amount=netamount.toString();
                                  }
                                  if(item.type=='Debit'){
                                    totaldebit+= +item.debit_amount;
                                    }
                                   if(item.type=='Credit'){
                                    totalcredit+= +item.credit_amount;
                                   }
                                })

                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }
                            }
                             
                            }}
                          />

                          {/* <NumberFormat
                          thousandSeparator={true}
                          inputMode="numeric"
                          className={'form-control w-100 text-right ' + ErrorBorder(fields['units'])}
                          value={fields['units']}
                          disabled={unitsReadOnly}
                          onValueChange={(e) => {
                            let value = decimalValue(e.value, 4);
                            console.log('value',value)
                            if (fields.payment_type === 'Payment' || headLabel === 'unitconversion') {
                              if (+e.value <=+fields['current_holding']) {
                                setCurentHolding(value);
                                setFields({
                                  ...fields,
                                  units: value,
                                });
                                value==='' ?
                                setGrossAmountReadOnly(false)
                                : setGrossAmountReadOnly(true)
                                value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                              }
                            } else {
                              let gamount=(parseFloat(fields['applicable_offer_price']))*(parseFloat(value));
                                let gross_amount=gamount.toFixed(2).toString();
                                let saleload= (parseFloat(value) * parseFloat(fields['load_per_unit'])).toFixed(2);
                                let netamount=(gamount-parseFloat(saleload.toString().replace('','0'))).toFixed(2).toString();
                                let totalholding = (parseFloat(fields['current_holding']) + parseFloat(value)).toFixed(4);
                               
                                if(gross_amount.split('.')[0].length<=12){
                                setFields({
                                  ...fields,
                                  units: value,
                                  total_holding: totalholding,
                                  gross_amount:gross_amount,
                                  sale_load: saleload.toString(),
                                  net_amount:netamount
                                });
                              }
                              value==='' ?
                              setGrossAmountReadOnly(false)
                              : setGrossAmountReadOnly(true)
                              value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);
                              if(value!==''){
                              let a = inWords(gross_amount);
                              let b = inWords(netamount);
                              //let c = inWords(value);

                              //setUnitsInWord(c);
                              setGrossAmountInWords(a);
                              setNetAmountInWords(b);
                              //// separator
                              let unitSepa=numberWithCommas(value);
                              let grossAmountSepa= numberWithCommas(gross_amount);
                              let netAmountSepa= numberWithCommas(netamount);
                              setUnitsSeparator(unitSepa);
                              setGrossAmountSeparator(grossAmountSepa);
                              setNetAmountSeparator(netAmountSepa);
                            }else{
                              setGrossAmountInWords('');
                              setNetAmountInWords('');
                              setGrossAmountSeparator('');
                              setNetAmountSeparator('');
                              setUnitsSeparator('');
                            }

                            if(headLabel=='saleofunit' || headLabel=='conversionin'){
                              // setTotalDebit(gross_amount);
                              // let totalcredit = (+saleload + +netamount).toFixed(2).toString()
                              // setTotalCredit(totalcredit);
                              // let balance=(+gross_amount - +totalcredit).toString()
                              // setBalance(balance)

                              let totaldebit=0, totalcredit=0;
                              transactionLedgers.map((item:any)=>{
                                if(item.amount_type=='Gross Amount'){
                                  item.debit_amount=gross_amount;
                                  item.credit_amount='0';
                                }
                                if(item.amount_type=='Sale load'){
                                  item.debit_amount='0';
                                  item.credit_amount=saleload.toString();
                                }
                                if(item.amount_type=='Net Amount'){
                                  item.debit_amount='0';
                                  item.credit_amount=netamount.toString();
                                }
                                if(item.type=='Debit'){
                                  totaldebit+= +item.debit_amount;
                                  }
                                 if(item.type=='Credit'){
                                  totalcredit+= +item.credit_amount;
                                 }
                              })

                              setTotalDebit(totaldebit.toString());
                              setTotalCredit(totalcredit.toString());
                              setBalance((+totaldebit - +totalcredit).toString());
                            }
                          }
                        }}
                       />  */}

                          {/* <small className="truncate" title={unitsInWords}>{unitsInWords==='' ? '' : (unitsInWords)}</small> */}
                        </div>
                      )}

                  <div className="row">
                  {chechFieldDisplay(headLabel, 'current_holding') && (
                        <div className="form-group col-md-6">
                          <div>Current Holding*</div>
                          <input
                            type="number"
                            value={fields['current_holding'] }
                            disabled
                            className={
                              'form-control w-100' 
                              +
                              ErrorBorder(fields['current_holding'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value , 4  ) ;
                              setFields({
                                ...fields,
                                current_holding: value,
                              });
                            }}
                          />
                        </div>
                      )}

                    {chechFieldDisplay(headLabel, 'total_holding') && (
                        <div className="form-group col-md-6">
                          <div>Total Holding*</div>
                          <input
                            type="number"
                            value={fields['total_holding']}
                            disabled
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['total_holding'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 4);
                              setFields({
                                ...fields,
                                total_holding: value,
                              });
                            }}
                          />
                        </div>
                      )}

                  {chechFieldDisplay(headLabel, 'remain_holding') && (
                        <div className="form-group col-md-6">
                          Remaining Holding*
                          <input
                            type="number"
                            value={fields['remain_holding']}
                            disabled
                            className={'form-control w-100 ' + ErrorBorder(fields['remain_holding'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                remain_holding: value,
                              });
                            }}
                          />
                        </div>
                      )}

                    </div>
                    
                      
                      {chechFieldDisplay(
                        headLabel,
                        'associated_transaction'
                      ) && (
                        <div className="form-group">
                          <div>Associated Txn No*</div>
                          <input
                            type="text"
                            defaultValue={fields['associated_transaction']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['associated_transaction'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                associated_transaction: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                   



                      {chechFieldDisplay(headLabel, 'face_value') && (
                        <div className="form-group">
                          <div>Face Value*</div>
                          <input
                            type="number"
                            value={fields['face_value']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['face_value'])
                            }
                            readOnly
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                face_value: value,
                              });
                            }}
                          />
                        </div>
                      )}


                      {chechFieldDisplay(headLabel, 'detail') && (
                        <div className="form-group">
                          <div>Detail*</div>
                          <input
                            type="text"
                            defaultValue={fields['detail']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['detail'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                detail: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}

                      {chechFieldDisplay(headLabel, 'tax_type') && (
                        <div className="form-group mt-2">
                          <div>Tax Type*</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['tax_type'])
                            }
                            defaultValue={fields['tax_type']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                tax_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderTaxTypeDropdown()}
                          </select>
                        </div>
                      )}
                        
                    </div>
                  </div>
                </div>
              </Col>

              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Amount</h4>
                  </div>
                  <div className="card-body">
                    <div>

                    <div className="form-group"
                      >
                        <div>Fund Account*</div>
                        {accLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div>
                        ) : fields['fund_code'].trim() === '' ? (
                          <div className="form-control    w-100">
                            Kindly First Select Fund
                          </div>
                        ) : (
                          // <Select
                          // className="react-select info"
                          // classNamePrefix="react-select"
                          //   label={'Select'}
                          //   isDisabled={false}
                          //   onChange={(e) => {
                          //     fields['fund_account']=e.value;
                          //     setFields(fields);
                          //     setFundAccountFieldsData(e.value);
                          //   }}
                          //   value={ledgerAccounts.filter(
                          //     (option) =>
                          //       option.value === fields['fund_account']
                          //   )}
                          //   isClearable={false}
                          //   isSearchable={true}
                          //   name="color"
                          //   options={ledgerAccounts}
                          // />
                          <select className='form-control' value={fields['fund_account']} onChange={(e) => {
                                fields['fund_account']=e.target.value;
                                setFields(fields);
                                setFundAccountFieldsData(e.target.value);
                              }}>
                            <option value="" defaultChecked hidden>
                            {' '}
                            Select Fund Account
                          </option>
                            {renderFundAccounts()}
                          </select>
                        )}
                      </div>

                      <div className="form-group">
                        <div>Gross Amount*  {grossAmountSeparator===''? '' : <span className="float-right text-primary">{grossAmountSeparator}</span>}</div> 
                        <input
                          type="number"
                          value={fields['gross_amount']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['gross_amount'])
                          }
                          readOnly={grossAmountReadOnly}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            if(value.split('.')[0].length<=12){
                            setTotalRemainHolding(value,"GrossAmount");  
                            value==='' ? setUnitsReadOnly(false) : setUnitsReadOnly(true);
                            value==='' ? setDiscount_Applicable_ReadOnly(false) : setDiscount_Applicable_ReadOnly(true);           
                            }               
                          }}
                        />
                        <small className="truncate" title={grossAmountInWords}>{grossAmountInWords==='' ? '' : (grossAmountInWords)}</small>
                      </div> 

                      {chechFieldDisplay(headLabel, 'sale_load') && (
                        <div className="form-group">
                        <div>Sale Load</div>
                        <input
                          type="number"
                          value={fields['sale_load']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['sale_load'])
                          }
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                              let netamount=(parseFloat(fields['gross_amount'].replace('','0'))-(parseFloat(value.replace('','0')))).toFixed(2);
                              // let unit=parseFloat(fields['net_amount'])/parseFloat(fields['nav']);
                              // let unit=netamount/parseFloat(fields['nav']);
                              // let a=unit.toFixed(4).toString();
                              // let remainingholding=parseFloat(fields['current_holding']) + parseFloat(a)
                              // let remainholding=remainingholding.toFixed(4).toString();
                              setFields({
                                ...fields,
                                sale_load:value,
                                net_amount:netamount
                                // units:a,
                                // total_holding:remainholding
                              })
                           
                                let netamountWords = inWords(netamount.toString());
                                setNetAmountInWords(netamountWords);

                                let netAmountSeparator= numberWithCommas(netamount.toString());
                                setNetAmountSeparator(netAmountSeparator);

                               // setTotalDebit(amount);
                               if(headLabel=='saleofunit' || headLabel=='conversionin'){
                                // let totalcredit = (+value + +netamount).toString();
                                // setTotalCredit(totalcredit);
                                // let balance=(+totalDebit - +totalcredit).toFixed(2).toString();
                                // setBalance(balance)

                                let totaldebit=0, totalcredit=0;
                                transactionLedgers.map((item:any)=>{
                                  if(item.amount_type=='Sale load'){
                                    item.debit_amount='0';
                                    item.credit_amount=value;
                                  }
                                  if(item.amount_type=='Net Amount'){
                                    item.debit_amount='0';
                                    item.credit_amount=netamount;
                                  }
                                  if(item.type=='Debit'){
                                    totaldebit+= +item.debit_amount;
                                    }
                                   if(item.type=='Credit'){
                                    totalcredit+= +item.credit_amount;
                                   }
                                });

                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                               }
                          }}
                          />
                          </div>
                      )}
                      {chechFieldDisplay(headLabel, 'redemption_load') && (
                        <div className="form-group">
                        <div>Redemption Load</div>
                        <input
                          type="number"
                          value={fields['redemption_load']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['redemption_load'])
                          }
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                                let netamount=(parseFloat(fields['gross_amount'])-(parseFloat(fields['tax_cgt'].replace('','0')))-(parseFloat(value.replace('','0')))- (parseFloat(fields['zakat_amount'] || '0'))).toFixed(2);
                                // let unit=parseFloat(netamount)/parseFloat(fields['nav']);
                                // let a=unit.toFixed(4).toString();

                                // let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
                                // let remainholding=remainingholding.toFixed(4).toString(); 
                                setFields({
                                  ...fields,
                                  redemption_load:value,
                                  net_amount:netamount.toString(),
                                  // units:a,
                                  // remain_holding:remainholding
                                }) 
                                let netamountWords = inWords(netamount.toString());
                                setNetAmountInWords(netamountWords);

                              let netAmountSeparator= numberWithCommas(netamount.toString());
                              setNetAmountSeparator(netAmountSeparator);
                              if(headLabel=='redemptionofunits' || headLabel=='conversionout'){
                                // // setTotalDebit(fields['gross_amount']);
                                // let totalcredit = (+value + +fields['tax_cgt'] + +netamount).toString()
                                // setTotalCredit(totalcredit);
                                // let balance=(+totalDebit - +totalcredit).toString()
                                // setBalance(balance)


                            let totaldebit=0, totalcredit=0;
                                transactionLedgers.map((item:any)=>{
                                 
                                  if(item.amount_type=='Redemption Load'){
                                    item.debit_amount='0';
                                    item.credit_amount=value;
                                  }
                                  if(item.amount_type=='CGT'){
                                    item.debit_amount='0';
                                    item.credit_amount=fields['tax_cgt'];
                                  }
                                  if(item.amount_type=='Zakat'){
                                    item.debit_amount='0';
                                    item.credit_amount=fields['zakat_amount'];
                                  }
                                  if(item.amount_type=='Net Amount'){
                                    item.debit_amount='0';
                                    item.credit_amount=netamount.toString();
                                  }
                                  if(item.type=='Debit'){
                                    totaldebit+= +item.debit_amount;
                                  }
                                  if(item.type=='Credit'){
                                    totalcredit+= +item.credit_amount;
                                  }
                                })
                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                                }
                          }}
                          />
                          </div>
                      )}
                      {chechFieldDisplay(headLabel, 'tax_cgt') && (
                        <div className="form-group">
                        <div>CGT</div>
                        <input
                          type="number"
                          value={fields['tax_cgt']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['tax_cgt'])
                          }
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                                let netamount=(parseFloat(fields['gross_amount'])-(parseFloat(fields['redemption_load'].replace('','0')))-(parseFloat(value.replace('','0')))-(parseFloat(fields['zakat_amount'] || '0'))).toFixed(2);
                                // let unit=parseFloat(fields['net_amount'])/parseFloat(fields['nav']);
                                // let unit=parseFloat(netamount)/parseFloat(fields['nav']);
                                // let a=unit.toFixed(4).toString();

                                // let remainingholding=parseFloat(fields['current_holding']) - parseFloat(a)
                                // let remainholding=remainingholding.toFixed(4).toString(); 
                                setFields({
                                  ...fields,
                                  tax_cgt:value,
                                  net_amount:netamount.toString(),
                                  // units:a,
                                  // remain_holding:remainholding
                                })
                                let netamountWords = inWords(netamount.toString());
                                setNetAmountInWords(netamountWords);

                              let netAmountSeparator= numberWithCommas(netamount.toString());
                              setNetAmountSeparator(netAmountSeparator);
                              if(headLabel=='redemptionofunits' || headLabel=='conversionout'){
                                // // setTotalDebit(fields['gross_amount']);
                                // let totalcredit = (+fields['redemption_load'] + +value + +fields['zakat_amount'] + +netamount).toString()
                                // setTotalCredit(totalcredit);
                                // let balance=(+fields['gross_amount'] - +totalcredit).toString()
                                // setBalance(balance)
                                let totaldebit=0, totalcredit=0;
                                transactionLedgers.map((item:any)=>{
                                  if(item.amount_type=='CGT'){
                                    item.debit_amount='0';
                                    item.credit_amount=value;
                                  }
                                  if(item.amount_type=='Zakat'){
                                    item.debit_amount='0';
                                    item.credit_amount=fields['zakat_amount'];
                                  }
                                  if(item.amount_type=='Net Amount'){
                                    item.debit_amount='0';
                                    item.credit_amount=netamount.toString();
                                  }
                                  if(item.type=='Debit'){
                                    totaldebit+= +item.debit_amount;
                                    }
                                   if(item.type=='Credit'){
                                    totalcredit+= +item.credit_amount;
                                   }
                                })
                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                                }
                          }}
                          />
                          </div>
                      )}

                    {(headLabel==='redemptionofunits' || headLabel==='conversionout' || headLabel==='unitconversion') && (
                      <div className="form-group">
                          <div>Zakat</div>
                          <input
                            type="number"
                            value={fields['zakat_amount']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['zakat_amount'])
                            }
                            onChange={(e) => {
                              let value=decimalValue(e.target.value,2);
                              let netamount = (parseFloat(fields['gross_amount']) - parseFloat(fields['redemption_load'] || '0')- parseFloat(fields['tax_cgt'] || '0') - parseFloat(value)).toFixed(2);
                              setFields({
                                ...fields,
                                zakat_amount: value,
                                net_amount: netamount,
                              });
                              let netamountWords = inWords(netamount.toString());
                              setNetAmountInWords(netamountWords);

                              let netAmountSeparator= numberWithCommas(netamount.toString());
                              setNetAmountSeparator(netAmountSeparator);

                            if(headLabel=='redemptionofunits' || headLabel=='conversionout'){
                              // // setTotalDebit(fields['gross_amount']);
                              // let totalcredit = (+fields['redemption_load'] + +fields['tax_cgt'] + +value + +netamount).toString()
                              // setTotalCredit(totalcredit);
                              // let balance=(+totalDebit - +totalcredit).toFixed(2).toString()
                              // setBalance(balance)

                              let totaldebit=0, totalcredit=0;
                              transactionLedgers.map((item:any)=>{
                                if(item.amount_type=='Zakat'){
                                  item.debit_amount='0';
                                  item.credit_amount=value;
                                }
                                if(item.amount_type=='Net Amount'){
                                  item.debit_amount='0';
                                  item.credit_amount=netamount.toString();
                                }
                                if(item.type=='Debit'){
                                  totaldebit+= +item.debit_amount;
                                  }
                                 if(item.type=='Credit'){
                                  totalcredit+= +item.credit_amount;
                                 }
                              })
                              setTotalDebit(totaldebit.toFixed(2).toString());
                              setTotalCredit(totalcredit.toFixed(2).toString());
                              setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }
                            }}
                          />
                        </div>
                        )}              


                      <div className="form-group">
                        {/* <div className="d-flex"> */}
                        <div>Net Amount* {netAmountSeparator===''? '' : <span className="float-right text-primary">{netAmountSeparator}</span>}</div>
 
                        <input
                          type="number"
                          value={fields['net_amount']}
                          className={
                            'form-control w-100 ' + ErrorBorder(fields['net_amount'])
                          }
                          readOnly={netAmountReadOnly}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            if(value.split('.')[0].length<=12){
                            setTotalRemainHolding(value,"NetAmount");
                            if(value!==''){
                              let a = inWords(value);
                              setNetAmountInWords(a);
  
                               //// separator
                               let netAmountSeparator= numberWithCommas(value);
                               setNetAmountSeparator(netAmountSeparator);
                              }else{
                                setNetAmountInWords('');
                                setNetAmountSeparator('');
                              }
                            }
                          }}
                        />
                      <small className="truncate" title={netAmountInWords}>{netAmountInWords==='' ? '' : (netAmountInWords)}</small>
                      </div>

                      <div className="form-group">
                          <div> Payment Type </div>
                          <div  className="form-control" >
                            {fields['payment_type']}
                          </div>
                        </div>

                    </div>
                  </div>
                </div>

              </Col>
         
            </Row>

            <Row>
              <Col>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title text-primary">Ledgers
                  <button className="btn-round btn-icon btn btn-success float-right" onClick={() => {
                              setViewModalForLedgers(!viewModalForLedgers);
                          }}> <i className="fa fa-plus"></i> </button>
                  </h4>
                </div>
                <div className="card-body">
                <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Ledger</th>
                          <th className="text-right">Debit Amount</th>
                          <th className="text-right">Credit Amount</th>
                          <th>Type</th>
                          <th>Ledger Family</th>
                          <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                        {transactionLedgers.map((item:any, index:number)=>{
                          // console.log('item=>',item)
                          return(
                            <tr>
                            <td>
                              <label>{item.gl_name} <br/>
                              {item.gl_code=='' ? '' : <small className='text-muted'>({item.gl_code})</small>}
                              </label></td>
                            <td className="text-right">{numberWithCommas(item.debit_amount)}</td>
                            <td className="text-right">{numberWithCommas(item.credit_amount)}</td>
                            <td>{item.type}</td>
                            <td>{item.main_family_name}</td>
                            <td>
                            <IoIosClose
                              style={{ fontSize: '25px', color: 'red' }}
                              onClick={() => {
                                let array = [...transactionLedgers];
                                array.splice(index, 1);
                                setTransactionLedgers(array);
                                let totaldebit=0, totalcredit=0;
                                array.map((items, index) => {
                                  if(items.type=='Debit'){
                                    totaldebit+= +items.debit_amount;
                                    }
                                  if(items.type=='Credit'){
                                    totalcredit+= +items.credit_amount;
                                  }
                                });
                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }}
                            />
                            </td>
                          </tr>
                          )
                        })}

                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total Debit: <span className='text-primary'>{numberWithCommas(totalDebit)}</span></td>
                          <td>Total Credit: <span className='text-primary'>{numberWithCommas(totalCredit)}</span></td>
                          <td>Balance: <span className='text-primary'>{numberWithCommas(balance)}</span></td>
                        </tr>
                      </tfoot>

                    </table>
                  </div>
                </div>
              </div>
              </Col>
            </Row>

          {(headLabel!=='unitconversion' && finishFlag==false) && (
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    createTransaction();
                  }}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>{tx === 'cashdividend' ? 'Update' : 'Create'}</span>
                  )}
                </button>
              </div>
            </div>
            )}

          {headLabel==='unitconversion' && (
              <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    // createTransaction();
                    Next();
                  }}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>{flag ? 'Update' : 'Next'}</span>
                  )}
                </button>
              </div>
            </div>
            )}
             {finishFlag==true && (
              <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    // createTransaction();
                    Finish();
                  }}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>{flag ? 'Update' : 'Finish'}</span>
                  )}
                </button>
              </div>
            </div>
            )}

          </div>
          {renderModalForBankBalance()}
          {renderModalAddNewUnitHolder()}
          {renderModalForAddMoreLedgers()}
        </div>
        <div style={{ display: 'none' }}>{accTilteName}</div>
      </Container>
      </Col>
      </Row>
      </div>
    </>
  );
};

export default UnitManagementTransactions;

