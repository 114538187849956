import React from 'react';
// import Header from './../../components/Header';
import { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { Container, Col, Row } from 'reactstrap';
import {
  updateUnitHolderStatus,
  getUnitHoldersByAmcAndFolioNo
} from './../../stores/services/unit-holder.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { BsTable } from 'react-icons/bs';	
import { useHistory } from 'react-router-dom';
const csv = require('csvtojson/v2');


const ClientList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amcCode= sessionStorage.getItem('amc_code') || '';
  const [isSearchActive, setSearchActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

    React.useEffect(() => {
    sessionStorage.removeItem('taxObj');
    searchUnitHolderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [clientStatus, setClientStatus] = useState('');
  const [code, setCode] = useState('');
  const [clientLoading, setClientLoading] = useState(false);
 
  const [resLoading, setResLoading] = useState(false);


  const updateStatus = async () => {
    setClientLoading(true);
    let status = '';
    if (clientStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateUnitHolderStatus(email, code, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      searchUnitHolderData();
    } catch (error) {}
    setClientLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  const searchUnitHolderData=async()=>{
    let flag=true;
    let search=amcCode+'-'+searchValue;
    if(searchValue===''){
      flag=false;
      search='';
    }
    setResLoading(true);
    setLoading(flag);
      try {
        const response = await getUnitHoldersByAmcAndFolioNo(email, amcCode, search);
        if(response.data.status===200){
        setData(response.data.data)
        setSearchActive(true);
        }else{
          toast.error(response.data.message)
        }
      } catch (error:any) {
        setLoading(false);
        setResLoading(false);
        if(error.response!==undefined){
          toast.error(error.response.data.message[0]);
        }else{
          toast.error('Request Failed!')
        }

      }
      setLoading(false);
      setResLoading(false);
    }

  const renderUpdateBankStatusPopup = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal60w"	
            show={true}	
          >	
        <div className="modal-header justify-content-center">	
          <button aria-hidden="true" className="close"  onClick={() => {	
                          setStatusSetPopup(false);	
                        }} data-dismiss="modal" type="button">	
            <i className="tim-icons icon-simple-remove"></i></button>	
            <h6 className="title title-up">Action</h6>	
            </div>	
            <div className="modal-body">	
              <div className="text-center">	
                <Row>	
                  <div className="mx-auto">	
                    {clientStatus === 'active' ? (	
                      <img src="assets/ban.svg" alt="" width="70" />	
                    ) : (	
                      <img src="assets/check.svg" alt="" width="70" />	
                    )}	
                    <h1 className="pb-3">Are You Sure?</h1>	
                    Are you sure you want to{' '}	
                    {clientStatus === 'active' ? 'Deactivate' : 'Activate'} this	
                    Client?	
                  </div>	
                </Row>	
                <Row className="mt-3">	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-primary "	
                        disabled={Boolean(clientLoading)}	
                        onClick={() => {	
                          updateStatus();	
                        }}	
                      >	
                        {clientLoading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Yes'	
                        )}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderData = () => {	
    return res.map((items: any, index: any) => {	
      return (	
        <tr key={index}>		
          <td>{items.folio_no.replace("UNITHOLDER_","")}</td>
          <td>{items.name}</td>		
          <td>{items.cnic}</td>		
          <td>{items.city}</td>
          <td>{items.email}</td>	
          <td>{moment(items.created_at).format('DD-MM-YYYY')}</td>
          <td className="captilize">{items.status}</td>	
          <td className="pointer">	
            <div	
              className="mr-3"	
              // onClick={() => {	
              //   setStatusSetPopup(true);	
              //   setClientStatus(items.status);	
              //   setCode(items.folio_no);	
              // }}	
            >	
              {/* {items.status !== 'active' ? (	
               <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                 <i className="tim-icons icon-simple-remove"> </i>
              </button>
              ) : (	
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                    <i className="fa fa-check"></i>
                </button>
              )}  */}

               <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"  onClick={() => {
                      sessionStorage.setItem('clientObj', JSON.stringify(items));
                      history.replace('/admin/view-client');
                    }}>
                    <i className="fa fa-eye"></i>
              </button>

                 <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {
                      sessionStorage.setItem('clientObj', JSON.stringify(items));
                      history.replace(`/admin/edit-client`);
                    }}>
                    <i className="tim-icons icon-pencil"></i>
                 </button>
 
                </div>
	
          </td>	
        </tr>	
      );	
    });	
  };	
  return (	
    <>
      <div className="content">
        <Row>
        <Col md="12">
    <Container fluid>
      <ToastContainer limit={1} />
      {/* <Header /> */}
      <div className="card">
        <div className="d-flex align-items-center"></div>
          <div className="">
            <div className="card-header">
              <h4 className="card-title">Client Listing
              <button	
                className="btn btn-primary btn-sm mt-a float-right"	
                onClick={() => {	
                  history.replace('/admin/setup-clients');	
                }}	
              >	
               <i className="fa fa-plus mr-2"></i> Add New	
              </button></h4>
            </div>
            <div className="card-body">
     
                <Row>
                    <Col md="4">
                      <ReactTooltip	
                        textColor="white"	
                        backgroundColor="black"	
                        effect="float"	
                       />	
                      <div className="form-group">
                      <input	
                        type="search"	
                        id="myInput"	
                        placeholder="Enter Folio Number"	
                        data-tip="Folio No"	
                        className="form-control"	
                        value={searchValue}	
                        onChange={(e) => {	
                          setSearchValue(e.target.value);	
                          // myFunction(e);	
                          // handleSearchValue(e);	
                        }}	
                      />	
                    </div>
                    </Col>
                    <Col md="4">
                    <div className="">
                    <button className="btn btn-primary mr-4"
                    disabled={Boolean(loading)}
                    onClick={searchUnitHolderData}>	
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Searching...</span>
                      </>
                    ) : (
                      <span> <i className="fa fa-search"></i>Search</span>
                    )}
                  </button>	

                </div>
                </Col>
                </Row>
      

                {resLoading ? (
                  <div className="d-flex justify-content-center my-5">	
                  <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
                  </div>
                ):(
                res.length>0 ? (
                  <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Folio No</th>
                    <th>Name</th>
                    <th>CNIC</th>
                    <th>City</th>
                    <th>Email</th>
                    <th>Registration Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </table>
                  </div>
                ) : (
                  <div className="table-empty">	
                   <BsTable className="icon" />	
                   <p className="text-center empty-text">No record found</p>	
                  </div>	
                 )
                 )}
          </div>
          </div>
        {renderUpdateBankStatusPopup()}
      </div>
    </Container>

</Col>
</Row>
    </div>
  </>
  );	
};	
export default ClientList;