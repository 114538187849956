import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../assets/img/dbg.svg';


export default function HomePage() {

  return (
    <div className="content">
        <div className='center'
        style={{
            backgroundImage: `url(${Image})`,
            height:'80vh',
            // backgroundRepeat:'space'
            backgroundSize:'cover'
        }}>
      <img
        className="opacity-img center"
        alt=""
        //  src={require("../../assets/img/MACS-Logo.svg").default}  width={"800px"}
         src={require("../assets/vertices.svg").default}  width={"400px"}
         />
         </div>
</div>
  );
}