import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


const ViewCheckList = () => {
  const history = useHistory();
  const [serialNo, setSerialNo] = useState('');
  const [title, setTitle] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [role, setRole] = useState('');

  const [code, setCode] = useState('');
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('checklistObj') || '');
    setSerialNo(obj.serial_no);
    setTitle(obj.message);
    setRole(obj.role);
    setCode(obj.code);
  }, []);


  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
            <div className="card-header">
              <div className="title-row">
                <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    sessionStorage.removeItem('checklistObj');
                      history.replace('/admin/check-list')
                  }}></i>View - Check List</h4> 
                 <Link
                    to="/admin/check-list"
                    className="btn btn-primary btn-sm ml-auto"
                    replace
                  >
                    <i className="fa fa-eye"></i>&nbsp;View All
                  </Link>
                </div>
            </div>
            <div className="card-body">
              
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        className="form-control"
                        value={title}
                      />
                    </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label>Role</label>
                      <input
                        className="form-control w-100"
                        value={role}
                      />
                    </div>
                </Col>
              </Row>
              
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label>Serial.# / Sequence # (Number)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Serial.# / Sequence # (Number)"
                        value={serialNo}
                      />
                    </div>
                </Col>
              </Row>
              <div className="d-flex mt-3">
                <div className="form-group">
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      sessionStorage.removeItem('checklistObj');
                      history.replace('/admin/check-list');
                    }}
                  >
                    <span>Cancel</span>
                  </button>

                </div>
              </div>
            </div>
          </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewCheckList;
