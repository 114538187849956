import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Header from "../../components/Header";
import Footer from "../../components/Footer/Footer.js";
import Sidebar from "../../components/Sidebar/Sidebar.js";
// import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
import FixedPlugin from "../../components/FixedPlugin.tsx";
import logo from "../../assets/vertices.svg";

import TransactionTxnFields from "../../containers/single-beta-txn";
import UnitManagementTransactions from "../../containers/unit-management-txn";
import FundManagementTransactions from "../../containers/fund-management-txn";
import FeePaymentTxns from "../../containers/fee-payment-txn";
import InvestmentTransactions from "../../containers/investment-txn";

import PendingTransactions from "../../containers/pending-transactions";
import TransactionListing from "../../containers/transaction-listing";
import ConcernedOfficer from "../../containers/officer-listing";
import AuthorizerListing from "../../containers/authorizer-listing";
import Dashboard from "../../containers/DashboardPage";
import BetaTxnType from "../../containers/beta-txn-type";
import RejectedTransaction from "../../containers/rejected-transactions";
import NAV from "../../containers/nav";
// import PendingTransactions from "../../containers/pending-transactions";
import ComplianceSignatoryTransactions from "../../containers/comp-sign-listing";
import SignatoryA from "../../containers/signatory-a";
import NotificationScreen from "../../containers/notification-screen";
import UserSetting from "../../containers/user-setting";
import FundDividendPayout from "../../containers/fund-dividend-payout-txn";
import BulkUplaod from "../../containers/bulk-upload";

// reports
import BankStatement from "../../containers/reports/bank-statement";
import RecieptPaymentRegisterReport from "../../containers/reports/reciept-payment-register-report";
import UnitHolderStatement from "../../containers/reports/unit-holder-statement";
import TransactionReportScreen from "../../containers/reports/transaction-report";
import GeneralLegderReport from "../../containers/reports/general-legder";

import FundList from "../../containers/setup/fund/fund-listing";
import SetupFunds from "../../containers/setup/fund/setup-funds";
import EditFunds from "../../containers/setup/fund/edit-fund";
import ViewFund from "../../containers/setup/fund/view-fund";

import AccountList from "../../containers/setup/account/account-listing";
import SetupAccount from "../../containers/setup/account/setup-account";
import EditAccountComponent from "../../containers/setup/account/edit-account";
import ViewAccountComponent from "../../containers/setup/account/view-account";

import BrokerList from "../../containers/setup/broker/broker-listing";
import BrokerComponent from "../../containers/setup/broker/broker";
import EditBroker from "../../containers/setup/broker/edit-broker";
import ViewBroker from "../../containers/setup/broker/view-broker";

import SecurityList from "../../containers/setup/security/security-listing";
import SetupSecurity from "../../containers/setup/security/setup-security";
import EditSecurity from "../../containers/setup/security/edit-security";
import ViewSecurity from "../../containers/setup/security/view-security";
import SecurityTransactionFilter from "../../containers/setup/security/security-filter";

import SetupEquitySecurity from "../../containers/setup/security/setup-equity-security";
import EditEquitySecurity from "../../containers/setup/security/edit-equity-security";
import ViewEquitySecurity from "../../containers/setup/security/view-equity-security";

import BankList from "../../containers/setup/bank/bank-listing";
import SetupBank from "../../containers/setup/bank/setup-bank";
import EditBank from "../../containers/setup/bank/edit-bank";
import ViewBank from "../../containers/setup/bank/view-bank";

import BranchList from "../../containers/setup/branch/branch-listing";
import SetupBranch from "../../containers/setup/branch/setup-branches";
import EditBranch from "../../containers/setup/branch/edit-branch";
import ViewBranch from "../../containers/setup/branch/view-branch";

import CheckList from "../../containers/setup/checklist/checklist-listing";
import SetupCheckList from "../../containers/setup/checklist/setup-checklist";
import EditCheckList from "../../containers/setup/checklist/edit-checklist";
import ViewCheckList from "../../containers/setup/checklist/view-checklist";

import ClientList from "../../containers/unit-holder/client-listing";
import SetupClients from "../../containers/unit-holder/setup-clients";
import EditClient from "../../containers/unit-holder/edit-client";
import ViewClient from "../../containers/unit-holder/view-client";

import ChartsOfAccountsConfig from "../../containers/setup/charts-of-accounts/charts-of-accounts-config";

import HomePage from "../../components/home-page";

import Vouchers from "../../containers/vouchers";
import TrialBalanceStatement from "../../containers/reports/trial-balance";
import DailyBankProfit from "../../containers/setup/daily-bank-profit-form/daily-bank-profit-form";
import DailyExpenses from "../../containers/setup/daily-expenses/daily-expenses";

var ps;

const Admin = (props) => {
  const history = useHistory();
  const [activeColor, setActiveColor] = React.useState("blue");
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const mainPanelRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);
  const [features, setFeatures] = React.useState([]);
  let [nodes, setNodes] = React.useState([]);
  let [routes, setRoutes] = React.useState([]);
  let fullFeatures = [];

  const data = JSON.parse(sessionStorage.getItem("features")) || "";

  document.addEventListener(
    "DOMContentLoaded",
    function () {
      onLoad();
    },
    false
  );
  async function onLoad() {
    try {
      let isvalid = true;
      const path =
        window.location.pathname == "/admin/dashboard"
          ? "/admin/dashboard"
          : window.location.pathname == "/admin/details/compliance"
          ? "/admin/compliance"
          : window.location.pathname == "/admin/details/authorizer-a"
          ? "/admin/signatory-a"
          : window.location.pathname == "/admin/details/authorizer-b"
          ? "/admin/signatory-b"
          : window.location.pathname ==
            "/admin/unit-management-transaction/saleofunit"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/transaction-types"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/unit-management-transaction/unitconversion"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/unit-management-transaction/conversionin"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/unit-management-transaction/conversionout"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/unit-management-transaction/redemptionofunits"
          ? "/admin/create-transaction"
          : window.location.pathname === "/admin/home"
          ? "/admin/dashboard"
          : window.location.pathname == "/admin/bulk-upload"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fund-management-transaction/fundtransfer"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/outflow"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/beta-transaction/inflow"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/debtmarketinvestment"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/termdeposit"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/bankprofit"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/equityinvestment"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/cashdividend"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/cashmargin"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/investment-transaction/marginreturn"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/advisoryfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/auditorsfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/bankcharges"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/managementfee"
          ? "/admin/post-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/trusteefee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/custodyfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/shariahadvisoryfee"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/fee-payment-transaction/cdcfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/listingfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/printingfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/professionalfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/ratingfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/taxpayment"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/fee-payment-transaction/secpfee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/brokagefee"
          ? "/admin/create-transaction"
          : window.location.pathname ==
            "/admin/fee-payment-transaction/otherpayment"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/fund-dividend-payout"
          ? "/admin/create-transaction"
          : window.location.pathname == "/admin/setup-clients"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/view-client"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/edit-client"
          ? "/admin/unit-holders"
          : window.location.pathname == "/admin/add-fund"
          ? "/admin/funds"
          : window.location.pathname == "/admin/view-fund"
          ? "/admin/funds"
          : window.location.pathname == "/admin/edit-fund"
          ? "/admin/funds"
          : window.location.pathname == "/admin/setup-accounts"
          ? "/admin/bank-accounts"
          : window.location.pathname == "/admin/edit-account"
          ? "/admin/bank-accounts"
          : window.location.pathname == "/admin/view-account"
          ? "/admin/bank-accounts"
          : window.location.pathname == "/admin/setup-brokers"
          ? "/admin/associated-beneficiaries"
          : window.location.pathname == "/admin/edit-broker"
          ? "/admin/associated-beneficiaries"
          : window.location.pathname == "/admin/view-broker"
          ? "/admin/associated-beneficiaries"
          : window.location.pathname == "/admin/setup-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/view-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/edit-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/setup-equity-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/edit-equity-security"
          ? "/admin/securities"
          : window.location.pathname == "/admin/security-transactions"
          ? "/admin/securities"
          : window.location.pathname == "/admin/setup-banks"
          ? "/admin/banks"
          : window.location.pathname == "/admin/edit-bank"
          ? "/admin/banks"
          : window.location.pathname == "/admin/view-bank"
          ? "/admin/banks"
          : window.location.pathname == "/admin/setup-branches"
          ? "/admin/bank-branches"
          : window.location.pathname == "/admin/edit-branch"
          ? "/admin/bank-branches"
          : window.location.pathname == "/admin/view-branch"
          ? "/admin/bank-branches"
          : window.location.pathname == "/admin/setup-checklist"
          ? "/admin/check-list"
          : window.location.pathname == "/admin/edit-checklist"
          ? "/admin/check-list"
          : window.location.pathname == "/admin/view-checklist"
          ? "/admin/check-list"
          : window.location.pathname;

      const arr = routes;

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].views !== undefined) {
          const views = arr[i].views;
          for (var a = 0; a < views.length; a++) {
            if (path == "/admin" + views[a].path) {
              //
              isvalid = true;
              return;
            } else {
              isvalid = false;
            }
          }
        } else if (path == "/admin" + arr[i].path) {
          isvalid = true;
          return;
        }
      }
      if (isvalid === false) {
        if (sessionStorage.getItem("isAuthenticated") === "true") {
          history.replace("/admin/view");
          alert("You are not authorized!");
        } else {
          history.replace("/login");
        }
        // toast.error("You are not authorized!");
      }
    } catch (e) {
      alert(e.message);
    }
  }

  // let routes = [];
  // data.map((item, i) => {
  //   let obj = {
  //     path: `/${item.feature.replace(/\s+/g, "-").toLowerCase()}`,
  //     name: item.feature,
  //     rtlName: "لوحة القيادة",
  //     icon: "tim-icons icon-chart-pie-36",
  //     component: Dashboard,
  //     layout: "/admin",
  //   };
  //   routes.push(obj);
  // });

  const handleSetAllRoutes = (data) => {
    let newRoute = [];
    data.map((item, i) => {
      if (item.submenu) {
        if (item.enabled) {
          let obj = {
            collapse: true,
            name: item.label,
            rtlName: `${i}خرائط`,
            icon: "tim-icons icon-single-02 " + item.label.replace(" ", ""),
            state: item.label,
          };
          let subMenuArray = [];
          item.submenu.map((submenu, j) => {
            if (submenu.enabled) {
              let comp =
                submenu.label == "Create Transaction"
                  ? BetaTxnType
                  : submenu.label == "Transaction Status"
                  ? PendingTransactions
                  : submenu.label == "Rejected Transactions"
                  ? RejectedTransaction
                  : submenu.label == "Transaction Listing"
                  ? TransactionListing
                  : submenu.label == "Authorizer"
                  ? ComplianceSignatoryTransactions
                  : // : submenu.label == "Authorizer-A"
                  // ? ComplianceSignatoryTransactions
                  // : submenu.label == "Authorizer-B"
                  // ? ComplianceSignatoryTransactions
                  submenu.label == "Concerned Officers"
                  ? ConcernedOfficer
                  : submenu.label === "Transaction Creators"
                  ? AuthorizerListing
                  : submenu.label == "NAV Update"
                  ? NAV
                  : submenu.label == "Trial balance statement"
                  ? TrialBalanceStatement
                  : submenu.label == "General Legder Report"
                  ? GeneralLegderReport
                  : submenu.label == "Transaction Report"
                  ? TransactionReportScreen
                  : submenu.label === "Unit Holder Statement"
                  ? UnitHolderStatement
                  : submenu.label === "Bank Statements"
                  ? BankStatement
                  : submenu.label === "Receipt Payment Register Report"
                  ? RecieptPaymentRegisterReport
                  : submenu.label == "Unit Holders"
                  ? ClientList
                  : submenu.label == "Funds"
                  ? FundList
                  : submenu.label == "Bank Accounts"
                  ? AccountList
                  : submenu.label == "Associated Beneficiaries"
                  ? BrokerList
                  : submenu.label == "Securities"
                  ? SecurityList
                  : submenu.label == "Banks"
                  ? BankList
                  : submenu.label == "Bank Branches"
                  ? BranchList
                  : submenu.label == "Check List"
                  ? CheckList
                  : submenu.label == "Daily Bank Profit"
                  ? DailyBankProfit
                  : submenu.label == "Daily Expenses"
                  ? DailyExpenses
                  : submenu.label == "Charts of Accounts"
                  ? ChartsOfAccountsConfig
                  : Dashboard;
              var matches = submenu.label.match(/\b(\w)/g); // ['J','S','O','N']
              var acronym = matches.join("");
              let subObj = {
                path: `/${submenu.label.replace(/\s+/g, "-").toLowerCase()}`,
                name: submenu.label,
                rtlName: `${j}خرائط`,
                mini: acronym,
                rtlMini: "زم",
                component: comp,
                layout: "/admin",
                isAuthenticated: "true",
              };
              subMenuArray.push(subObj);
            }
          });
          if (subMenuArray.length !== 0) {
            Object.assign(obj, { views: subMenuArray });

            newRoute.push(obj);
          }
        }
      } else {
        if (item.enabled) {
          //   // let obj = {
          //   //   path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
          //   //   name: item.label,
          //   //   rtlName: "لوحة القيادة",
          //   //   icon: "tim-icons icon-chart-pie-36",
          //   //   component: Dashboard,
          //   //   layout: "/admin",
          //   // };

          if (item.label === "Transaction Status") {
            let obj = {
              path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
              name: item.label,
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-bullet-list-67",
              component: PendingTransactions,
              layout: "/admin",
              isAuthenticated: "true",
            };

            newRoute.push(obj);
          }
          if (item.label === "Dashboard") {
            let obj = {
              path: `/${item.label.replace(/\s+/g, "-").toLowerCase()}`,
              name: item.label,
              rtlName: "لوحة القيادة",
              icon: "tim-icons icon-chart-pie-36",
              component: Dashboard,
              layout: "/admin",
              isAuthenticated: "true",
            };

            newRoute.push(obj);
          }
        }
      }
    });

    setRoutes(newRoute);
  };

  const onload1 = async () => {
    let fullData = JSON.parse(sessionStorage.getItem("allFeatures") || "");
    fullData.unshift({
      id: -1,
      feature: "Trustee",
      parent_id: null,
    });
    for (let index = 0; index < fullData.length; index++) {
      if (fullData[index].parent_id === 0) {
        fullData[index].parent_id = -1;
      }
      fullData[index].value = fullData[index].id;
      fullData[index].label = fullData[index].feature;
    }
    var data = fullData;
    fullFeatures.push(...fullData);
    var root;
    const idMapping = data.reduce((acc, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});
    data.forEach((el) => {
      // Handle the root element
      if (el.parent_id === null) {
        root = el;
        return;
      }
      // Use our mapping to locate the parent element in our data array
      const parentEl = data[idMapping[el.parent_id]];
      // Add our current el to its parent's `children` array
      parentEl.children = [...(parentEl.children || []), el];
    });
    setNodes([root]);
  };

  // route
  React.useEffect(() => {
    onload1();
    let template = [];

    const buildDefaultTemplate = () => {
      template = [
        {
          label: "Dashboard",
          enabled: true,
        },

        {
          label: "Transaction Status",
          enabled: true,
        },

        {
          label: "Transaction Creator",
          enabled: true,
          submenu: [
            {
              enabled: true,
              label: "Create Transaction",
            },
            {
              enabled: true,
              label: "Rejected Transactions",
            },
            {
              enabled: true,
              label: "Transaction Listing",
            },
          ],
        },

        {
          label: "Processing",
          enabled: true,
          submenu: [
            {
              enabled: true,
              label: "Authorizer",
            },
            // {
            //   enabled: true,
            //   label: "Authorizer-A",
            // },
            // {
            //   enabled: false,
            //   label: "Authorizer-B",
            // },
          ],
        },

        {
          label: "Daily Tasks",
          enabled: true,
          submenu: [
            {
              enabled: true,
              label: "NAV Update",
            },
          ],
        },

        {
          label: "Reports",
          enabled: true,
          submenu: [
            {
              enabled: true,
              label: "General Legder Report",
            },
            {
              enabled: true,
              label: "Bank Statements",
            },
            {
              enabled: true,
              label: "Transaction Report",
            },
            {
              enabled: true,
              label: "Unit Holder Statement",
            },
            {
              enabled: true,
              label: "Receipt Payment Register Report",
            },
            { enabled: true, label: "Trial balance statement" },
          ],
        },

        {
          label: "Management",
          enabled: true,
          submenu: [
            {
              label: "Charts of Accounts",
              enabled: true,
            },
            {
              label: "Associated Beneficiaries",
              enabled: true,
            },
            {
              label: "Banks",
              enabled: true,
            },
            {
              label: "Bank Branches",
              enabled: true,
            },
            {
              label: "Bank Accounts",
              enabled: true,
            },
            {
              label: "Funds",
              enabled: true,
            },
            {
              label: "Unit Holders",
              enabled: true,
            },
            {
              label: "Securities",
              enabled: true,
            },
            {
              label: "Check List",
              enabled: true,
            },
            {
              label: "Daily Bank Profit",
              enabled: true,
            },
            {
              label: "Daily Expenses",
              enabled: true,
            },
          ],
        },

        {
          label: "Authorized Users",
          enabled: true,
          submenu: [
            {
              label: "Concerned Officers",
              enabled: true,
            },
            {
              label: "Transaction Creators",
              enabled: true,
            },
          ],
        },
      ];
      if (
        sessionStorage.getItem("features") !== "undefined" &&
        sessionStorage.getItem("features") !== null
      ) {
        let featuesList = JSON.parse(sessionStorage.getItem("features"));

        // remove element of array.
        fullFeatures.shift();
        if (
          sessionStorage.getItem("additional_features") !== null &&
          sessionStorage.getItem("additional_features") !== "[]" &&
          sessionStorage.getItem("additional_features").value !== "" &&
          sessionStorage.getItem("additional_features") !== ""
        ) {
          let additionalaccess = JSON.parse(
            sessionStorage.getItem("additional_features") || ""
          );
          featuesList = [...featuesList, ...additionalaccess];
        }

        if (
          sessionStorage.getItem("denied_features") !== null &&
          sessionStorage.getItem("denied_features") !== "[]" &&
          sessionStorage.getItem("denied_features").value !== "" &&
          sessionStorage.getItem("denied_features") !== ""
        ) {
          let deniedaccess = JSON.parse(
            sessionStorage.getItem("denied_features") || ""
          );
          let updatedFeaturesList = featuesList.filter((mainFeature) => {
            if (
              deniedaccess.some((dFeature) => dFeature.id == mainFeature.id)
            ) {
              return false;
            } else {
              return true;
            }
          });
          featuesList = [...updatedFeaturesList];
        }

        let copyArray = [];
        for (let i = 0; i < featuesList.length; i++) {
          for (let j = 0; j < fullFeatures.length; j++) {
            if (fullFeatures[j].children !== undefined) {
              for (let n = 0; n < fullFeatures[j].children.length; n++) {
                if (
                  featuesList[i].label ===
                  fullFeatures[j].children[n]
                    .label /*&& featuesList[i].label!=='AMC'*/
                ) {
                  copyArray.push(fullFeatures[j]);
                }
              }
            }
          }
        }

        let finalFeatures = [...copyArray, ...featuesList];

        let labelArr = [];
        for (let index = 0; index < finalFeatures.length; index++) {
          for (let j_index = 0; j_index < template.length; j_index++) {
            const result = searchTree(
              template[j_index],
              finalFeatures[index].feature
            );
            if (result !== null) {
              labelArr.push(result.label);
              break;
            }
          }
        }

        template.forEach(function iter(a) {
          if (labelArr.includes(a.label)) {
            a.enabled = true;
          }
          Array.isArray(a.children) && a.children.forEach(iter);
        });
      }
      // const menu = Menu.buildFromTemplate(template);
      // Menu.setApplicationMenu(menu);
      handleSetAllRoutes(template);
    };

    const searchTree = (element, matchingTitle) => {
      if (element.label === matchingTitle) {
        element.enabled = true;
        return element;
      } else if (element.submenu != null) {
        var result = null;
        for (let i = 0; result == null && i < element.submenu.length; i++) {
          result = searchTree(element.submenu[i], matchingTitle);
        }
        return result;
      }
      return null;
    };
    buildDefaultTemplate();
  }, []);

  // end route
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    let innerMainPanelRef = mainPanelRef;
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current);
      mainPanelRef.current &&
        mainPanelRef.current.addEventListener("ps-scroll-y", showNavbarButton);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", showNavbarButton);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
        innerMainPanelRef.current &&
          innerMainPanelRef.current.removeEventListener(
            "ps-scroll-y",
            showNavbarButton
          );
      }
      window.removeEventListener("scroll", showNavbarButton);
    };
  }, []);
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            isAuthenticated={prop.isAuthenticated}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleMiniClick = () => {
    // let notifyMessage = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      // notifyMessage += "deactivated...";
    } else {
      setSidebarMini(true);
      // notifyMessage += "activated...";
    }
    // let options = {};
    // options = {
    //   place: "tr",
    //   message: notifyMessage,
    //   type: "primary",
    //   icon: "tim-icons icon-bell-55",
    //   autoDismiss: 7,
    // };
    // notificationAlertRef.current.notificationAlert(options);
    document.body.classList.toggle("sidebar-mini");
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle("nav-open");
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove("nav-open");
  };
  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <button
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        routes={routes}
        activeColor={activeColor}
        logo={{
          outterLink: "https://digitalcustodian.co/",
          text: "Back-Office",
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanelRef} data={activeColor}>
        {/* <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        /> */}
        <Header
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(routes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        <Switch>
          {getRoutes(routes)}

          {/* <Route path="/admin/create-transaction" component={BetaTxnType} /> */}
          <Route path="/admin/home" component={HomePage} />
          <PrivateRoute
            path="/admin/transaction-types"
            component={BetaTxnType}
          />
          <PrivateRoute
            path="/admin/comp-sign-transactions/:type"
            component={ComplianceSignatoryTransactions}
          />
          {/* <Route path="/admin/signatory-a/:type" component={SignatoryA} /> */}
          <PrivateRoute path="/admin/details/:type" component={SignatoryA} />
          <PrivateRoute
            path="/admin/notification-view"
            component={NotificationScreen}
          />
          <PrivateRoute
            path="/admin/beta-transaction/:type"
            component={TransactionTxnFields}
          />
          <PrivateRoute
            path="/admin/unit-management-transaction/:type"
            component={UnitManagementTransactions}
          />
          <PrivateRoute
            path="/admin/fund-management-transaction/:type"
            component={FundManagementTransactions}
          />
          <PrivateRoute
            path="/admin/fee-payment-transaction/:type"
            component={FeePaymentTxns}
          />
          <PrivateRoute
            path="/admin/investment-transaction/:type"
            component={InvestmentTransactions}
          />
          <PrivateRoute
            path="/admin/fund-dividend-payout"
            component={FundDividendPayout}
          />

          <PrivateRoute path="/admin/vouchers/:type" component={Vouchers} />

          <PrivateRoute path="/admin/bulk-upload" component={BulkUplaod} />
          <PrivateRoute path="/admin/dashboard" component={Dashboard} />
          <PrivateRoute path="/admin/user-setting" component={UserSetting} />

          <PrivateRoute path="/admin/funds" component={FundList} />
          <PrivateRoute path="/admin/add-fund" component={SetupFunds} />
          <PrivateRoute path="/admin/edit-fund" component={EditFunds} />
          <PrivateRoute path="/admin/view-fund" component={ViewFund} />

          <PrivateRoute path="/admin/bank-accounts" component={AccountList} />
          <PrivateRoute path="/admin/setup-accounts" component={SetupAccount} />
          <PrivateRoute
            path="/admin/edit-account"
            component={EditAccountComponent}
          />
          <PrivateRoute
            path="/admin/view-account"
            component={ViewAccountComponent}
          />

          <PrivateRoute
            path="/admin/associated-beneficiaries"
            component={BrokerList}
          />
          <PrivateRoute
            path="/admin/setup-brokers"
            component={BrokerComponent}
          />
          <PrivateRoute path="/admin/edit-broker" component={EditBroker} />
          <PrivateRoute path="/admin/view-broker" component={ViewBroker} />

          <PrivateRoute path="/admin/securities" component={SecurityList} />
          <PrivateRoute
            path="/admin/setup-security"
            component={SetupSecurity}
          />
          <PrivateRoute path="/admin/edit-security" component={EditSecurity} />
          <PrivateRoute path="/admin/view-security" component={ViewSecurity} />
          <PrivateRoute
            path="/admin/security-transactions"
            component={SecurityTransactionFilter}
          />

          <PrivateRoute
            path="/admin/setup-equity-security"
            component={SetupEquitySecurity}
          />
          <PrivateRoute
            path="/admin/edit-equity-security"
            component={EditEquitySecurity}
          />
          <PrivateRoute
            path="/admin/view-equity-security"
            component={ViewEquitySecurity}
          />

          <PrivateRoute path="/admin/banks" component={BankList} />
          <PrivateRoute path="/admin/setup-banks" component={SetupBank} />
          <PrivateRoute path="/admin/edit-bank" component={EditBank} />
          <PrivateRoute path="/admin/view-bank" component={ViewBank} />

          <PrivateRoute path="/admin/bank-branches" component={BranchList} />
          <PrivateRoute path="/admin/setup-branches" component={SetupBranch} />
          <PrivateRoute path="/admin/edit-branch" component={EditBranch} />
          <PrivateRoute path="/admin/view-branch" component={ViewBranch} />

          <PrivateRoute path="/admin/check-list" component={CheckList} />

          <PrivateRoute
            path="/admin/setup-checklist"
            component={SetupCheckList}
          />
          <PrivateRoute
            path="/admin/edit-checklist"
            component={EditCheckList}
          />
          <PrivateRoute
            path="/admin/view-checklist"
            component={ViewCheckList}
          />

          <PrivateRoute
            path="/admin/daily-bank-profit"
            component={DailyBankProfit}
          />
          <PrivateRoute
            path="/admin/daily-expenses"
            component={DailyExpenses}
          />
          <PrivateRoute path="/admin/unit-holders" component={ClientList} />
          <PrivateRoute path="/admin/setup-clients" component={SetupClients} />
          <PrivateRoute path="/admin/edit-client" component={EditClient} />
          <PrivateRoute path="/admin/view-client" component={ViewClient} />

          {/* <Redirect from="*" to="/admin/dashboard" /> */}
        </Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
      {/* <FixedPlugin
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleMiniClick={handleMiniClick}
      /> */}
      <FixedPlugin />
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("isAuthenticated") === "true" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

export default Admin;
