import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../../../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getFundByAmc } from "../../../stores/services/funds.service";
import { getChartOfAccounts } from "../../../stores/services/chatofaccounts.service";
import {
  getAllLedgers,
  getLedgerAccounts,
} from "../../../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../../../stores/services/daily-bank-profit-form.service";
import { numberWithCommas } from "../../../utils/customFunction";

const DailyExpenses = () => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);

  const [expenseDescription, setExpenseDescription] = useState<any>("");
  const [quarterlyFee, setQuaterlyFee] = useState<any>(0);
  const [halfYearlyFee, setHalfYearlyFee] = useState<any>(0);
  const [yearlyFee, setYearlyFee] = useState<any>(0);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(email, code);
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const getBankAccounts = async () => {
      try {
        const response = await getChartOfAccounts(email);
        if (response.data.status == 200) {
          let ledgersacc = getLedgerAccounts(
            response.data.data.main_family[0].family[3].sub_family
          );
          setLegderAccounts(ledgersacc);
          let allLedgers = await getAllLedgers(response.data.data.main_family);
          setAllLedgers(allLedgers);
          // await ledgerDropDown(allLedgers);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {}
    };
    getFundByAMcCode(amc_code);
    getBankAccounts();
  }, []);

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const handleAdd = () => {
    if (fund === "") {
      return toast.error("Fund is required");
    }

    if (amc_code === "") {
      return toast.error("Invalid user. Login Again");
    }
    if (expenseDescription === "") {
      return toast.error("Description can't be empty");
    }

    if (parseInt(quarterlyFee) > parseInt(halfYearlyFee)) {
      return toast.error("Half Yearly Fee can't be less then quarterly fee");
    }

    if (parseInt(halfYearlyFee) > parseInt(yearlyFee)) {
      return toast.error("Yearly Fee can't be less then quarterly fee");
    }

    if (tableData.length === 0) {
      setTableData([
        {
          fund_code: fund,
          amc_code: amc_code,
          expense_description: expenseDescription,
          quarterly_fee: quarterlyFee,
          half_yearly_fee: halfYearlyFee,
          yearly_fee: yearlyFee,
        },
      ]);

      setExpenseDescription("");
      setQuaterlyFee(0);
      setHalfYearlyFee(0);
      setYearlyFee(0);
    } else {
      setTableData((prevState) => [
        ...prevState,
        {
          fund_code: fund,
          amc_code: amc_code,
          expense_description: expenseDescription,
          quarterly_fee: quarterlyFee,
          half_yearly_fee: halfYearlyFee,
          yearly_fee: yearlyFee,
        },
      ]);
      setFund("");
      setExpenseDescription("");
      setQuaterlyFee(0);
      setHalfYearlyFee(0);
      setYearlyFee(0);
    }
  };

  return (
    <>
      <ToastContainer limit={1} />

      <div className="mt-4">
        <Row>
          <div className="col-md-1"></div>
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Daily Expenses</h4>
              </div>
              <div className="card-body">
                {/* fund selection */}
                <div className="row">
                  <div
                    className="form-group d-flex col-md-12 justify-content-center align-content-center"
                    data-tip="Select Fund"
                  >
                    <div className="col-md-2 align-self-center">Fund Name*</div>

                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control col-md-6">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <select
                        className={"form-control col-md-6"}
                        value={fund}
                        disabled={false}
                        onChange={(e) => {
                          // setLoading(true);
                          // setTableData([]);
                          setFund(e.target.value);
                          // setLoading(false);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {fundCheck ? "No Fund Found" : "Select Fund"}
                        </option>
                        {renderFundsDropdown()}
                      </select>
                    )}
                  </div>
                </div>

                {/* Expense Description */}
                <div className="row">
                  <div
                    className="form-group d-flex col-md-12 justify-content-center align-content-center"
                    data-tip={
                      !fund ? "Select Fund First" : "Describe the expense"
                    }
                  >
                    <div className="col-md-2 align-self-center">
                      Expense Description*
                    </div>

                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control col-md-6">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <input
                        type="text"
                        placeholder="Printing Fee, 
                        Audit Fee,
                        Rating Fee,
                        CDC,
                        MCBFSL,
                        "
                        className={"form-control col-md-6"}
                        value={expenseDescription}
                        disabled={!fund}
                        onChange={(e) => {
                          setExpenseDescription(e.target.value);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div
                    className="form-group d-flex col-md-12 justify-content-center align-content-center"
                    data-tip={
                      !fund
                        ? "Select Fund First then describe expense"
                        : !expenseDescription
                        ? "Name the Expense in description first"
                        : "Enter Quarterly Fee"
                    }
                  >
                    <div className="col-md-2 align-self-center">
                      Quarterly Fee
                    </div>

                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control col-md-6">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <input
                        type="number"
                        className="form-control col-md-6"
                        value={quarterlyFee}
                        onChange={(e) => setQuaterlyFee(e.target.value)}
                        disabled={!expenseDescription}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div
                    className="form-group d-flex col-md-12 justify-content-center align-content-center"
                    data-tip={
                      !fund
                        ? "Select Fund First then describe expense"
                        : !expenseDescription
                        ? "Name the Expense in description first"
                        : "Enter Half Yearly Fee"
                    }
                  >
                    <div className="col-md-2 align-self-center">
                      Half Yearly Fee
                    </div>

                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control col-md-6">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <input
                        type="number"
                        className="form-control col-md-6"
                        value={halfYearlyFee}
                        onChange={(e) => setHalfYearlyFee(e.target.value)}
                        disabled={!expenseDescription}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div
                    className="form-group d-flex col-md-12 justify-content-center align-content-center"
                    data-tip={
                      !fund
                        ? "Select Fund First then describe expense"
                        : !expenseDescription
                        ? "Name the Expense in description first"
                        : "Enter Yearly Fee"
                    }
                  >
                    <div className="col-md-2 align-self-center">Yearly Fee</div>

                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control col-md-6">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <input
                        type="number"
                        className="form-control col-md-6"
                        value={yearlyFee}
                        onChange={(e) => setYearlyFee(e.target.value)}
                        disabled={!expenseDescription}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div
                    className="form-group d-flex col-md-12 justify-content-center align-content-center"
                    data-tip="Select Fund"
                  >
                    <button
                      className="btn btn-primary"
                      disabled={
                        Boolean(Loading) || !fund || !expenseDescription
                      }
                      onClick={handleAdd}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Add Expense</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <div className="col-md-1"></div>
        </Row>
      </div>

      {Loading ? (
        <Row>
          <div className="col-md-1"></div>
          <Col>
            <div className="form-control w-100">
              <i className="fa fa-spinner fa-spin fa-1x"></i>
            </div>
          </Col>
          <div className="col-md-1"></div>
        </Row>
      ) : tableData.length > 0 ? (
        <Row>
          <div className="col-md-1"></div>
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title text-primary">Expenses</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {/* <td colSpan={4}>Fund Code: &nbsp; &nbsp; {fund}</td> */}
                        <td colSpan={1}>AMC Code: &nbsp; &nbsp; </td>
                        <td colSpan={4}>{amc_code}</td>
                      </tr>
                      <tr>
                        <th>Fund</th>
                        <th>Expense Description</th>
                        <th>Quarterly Fee</th>
                        <th>Half Yearly Fee</th>
                        <th>Yearly Fee</th>
                        {/* <th>Balance</th>
                        <th>Profit%</th>
                        <th>Daily Profit</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.fund_code}</td>
                          <td>{data.expense_description}</td>
                          <td>{data.quarterly_fee}</td>
                          <td>{data.half_yearly_fee}</td>
                          <td>{data.yearly_fee}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(Loading)}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Save Expenses</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <div className="col-md-1"></div>
        </Row>
      ) : null}
    </>
  );
};

export default DailyExpenses;
