import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const ViewBank = () => {
  const history = useHistory();
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [bankNamelError, setBankNameError] = useState('');
  const [bankCodeError, setBankCodeError] = useState('');
  const [Loading, setLoading] = useState(false);
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('bankObj') || '');
    setBankName(obj.bank_name);
    setBankCode(obj.bank_code.replace('BANK_',''));
  }, []);

  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="card">
          <div className="card-header title-row">
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
              history.replace('/admin/banks')
          }}></i>View - Bank</h4>
  
            <Link to="/admin/banks" className="btn btn-primary btn-sm ml-auto" replace>
              <i className="fa fa-eye mr-2"></i>View All
              </Link>
          </div>
          <div className="card-body">
            <Row>
              <Col md="6">
                <div className="form-group">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    placeholder="Bank Name"
                    className={`form-control  w-100 `}
                    value={bankName}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label>Bank Code</label>
                  <div className="disable-input">
                    <input
                      readOnly
                      type="text"
                      placeholder="Bank Code"
                      className={`form-control  w-100 `}
                      value={bankCode}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex ">
            <div className="form-group">

              <button
                className="btn btn-default ml-3"
                onClick={() => {
                  history.replace('/admin/banks');
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewBank;
