import React, { useState } from 'react';	
import {	
  Container,	
  Row,	
  Col,	
} from 'reactstrap';	
import { addSecurity } from '../../../stores/services/security.service';	
import { useHistory, Link } from 'react-router-dom';	
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from "react-number-format";

const ChartsOfAccountsConfig = () => {	
  const history = useHistory();	
  const [mainFamily, setMainFamily] = useState('');
  const [mainFamilyData, setMainFamilyData] = useState<any>([]);

  const [mainFamilyError, setMainFamilyError] = useState('');
  const [getDataLoading, setGetDataLoading] = useState(false);

  const [chartOfAccounts, setChartsOfAccount] = useState<any>([]);

  const [Loading, setLoading] = useState(false);

  const getChartOfAccounts=()=>{
    if (!mainFamily) {	
        setMainFamilyError('Required')	
        return;	
      }	
      setGetDataLoading(true);

      setGetDataLoading(false);
  }

  const saveChartsOfAccounts = async () => {	
    
    setLoading(true)	
  	
    setLoading(false)	
  }	
 
  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        <div className="card ">	
          <div className="">	
            <div className="card-header title-row">	
            <h4 className="card-title">Charts of Accounts</h4>	
            </div>	
            <div className="card-body">	

               <Row>	
            <Col md="6">
                  <div className="form-group">
                    <label>Main Family</label>
                  <div>
                  <div className="">
                      <select 
                      className={`form-control  w-100 ${
                        mainFamilyError ? ' required-border' : ''
                      }`}
                      value={mainFamily}
                      onChange={(e)=>{
                       setMainFamily(e.target.value);
                       setMainFamilyError('');

                      }}>
                      <option value='' hidden>Select Main Family</option>
                      <option value='1'>ASSETS</option>
                      <option value='2'>EQUITY</option>
                      <option value='3'>LIABILITIES</option>
                      <option value='4'>EXPENSES</option>
                      <option value='5'>INCOME</option>
                      </select>
                    </div>
                  
                  </div>
                  </div>
                </Col>
                <Col md="3">
                    <button className='btn btn-primary float-left mt-4'
                    disabled={Boolean(getDataLoading)}
                    onClick={()=>{
                        getChartOfAccounts();
                    }}>
                  {getDataLoading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                  <span className="login-txt"> Loading...</span></> : <span>Load</span>}
                    </button>
                </Col>	
            </Row>
     
            </div>	
            	
          </div>	
        </div>	
      </Container>	
      </Col>
      <Col md="12">
      <Container fluid>	
         <div className="card">
             <div className="card-header">
                 <div className="card-title">Pocessed</div>
             </div>
             <div className="card-body">

             </div>
         </div>
         </Container>	
      </Col>
        </Row>

        <div className="">	
              <button className="btn btn-primary ml-3" onClick={saveChartsOfAccounts} disabled={Boolean(Loading)}>	
                {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>	
                  <span className="login-txt"> Saving...</span></> : <span>Save</span>}	
              </button>	
            </div>	
        </div>
    </>	
  )	
};	
export default ChartsOfAccountsConfig;