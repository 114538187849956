import axios from 'axios';
import Config from '../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param password
 */
const login = async (email: string, password: string) => {
  const url = `${Config.baseUrl}/user/login`;
  const result: any = await axios.post(
    url,
    {
      email,
      password,
      user_type: 'AMC',
    },
    {
      headers: {
        Authorization: sessionStorage.getItem('token') || '',
      },
    }
  );
  return result;
};

/**
 *
 * @param email
 */
const forgotPassword = async (email: string) => {
  const url = `${Config.baseUrl}/user/forgot-password`;
  try {
    const result: any = await axios.post(
      url,
      { email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await forgotPassword(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param otp
 */
const forgotPasswordOtp = async (email: string, otp: string, new_password: string, confirm_new_password: string) => {
  const url = `${Config.baseUrl}/user/verify-otp`;
  try {
    const result: any = await axios.post(url, { email, otp, new_password, confirm_new_password });
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await forgotPasswordOtp(email, otp, new_password, confirm_new_password)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param two_fa_code
 */
const twoFALogin = async (email: string, two_fa_code: string) => {
  const url = `${Config.baseUrl}/user/login/2fa`;
  try {
    const result: any = await axios.post(
      url,
      { email, two_fa_code },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await twoFALogin(email, two_fa_code)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getTwoFACode = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-2fa-code`;
  try {
    const result: any = await axios.post(
      url,
      { email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getTwoFACode(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param two_fa_code
 * @param two_fa_enabled
 */
const enableDisableTwoFA = async (
  email: string,
  two_fa_code: string,
  two_fa_enabled: string
) => {
  const url = `${Config.baseUrl}/user/enable-disable-2fa`;
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        two_fa_code,
        two_fa_enabled,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await enableDisableTwoFA(email, two_fa_code, two_fa_enabled)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 * @param status
 */
const updateUserStatus = async (email: string, status: string) => {
  const url = `${Config.baseUrl}/user/update-status`;
  try {
    const result: any = await axios.post(
      url,
      { email, status },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await updateUserStatus(email, status)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

/**
 *
 * @param email
 */
const getUserInfo = async (email: string) => {
  const url = `${Config.baseUrl}/user/get-user-info`;
  try {
    const result: any = await axios.post(
      url,
      { email },
      {
        headers: {
          Authorization: sessionStorage.getItem('token') || '',
        },
      }
    );
    return result;
  } catch (err: any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if (result.status) {
        return await getUserInfo(email)
      } else {
        throw err;
        // return result
      }
    }else {
      throw err
    }
  }
};

export {
  login,
  forgotPassword,
  twoFALogin,
  getTwoFACode,
  enableDisableTwoFA,
  updateUserStatus,
  getUserInfo,
  forgotPasswordOtp,
};
