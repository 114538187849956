import React from 'react';
import { useState } from 'react';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getAmc } from '../../../stores/services/amc.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { EditAccount } from '../../../stores/services/account.service';
import { getFundByAmc } from '../../../stores/services/funds.service';
import ReactTooltip from 'react-tooltip';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {accountTitle_AccountNumber_Regex} from '../../../utils/customFunction';
import 'react-phone-input-2/lib/style.css';
import { RiGitRepositoryLine } from 'react-icons/ri';
import NumberFormat from "react-number-format";

const EditAccountComponent = () => {
  const history = useHistory();
  const amcCode = sessionStorage.getItem('amc_code') || '';
  //hooks for getting all inputs from user
  const [bankname, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [amcName, setAmcName] = useState('');
  const [city, setCity] = useState('');
  const [fund, setFund] = useState('');
  const [currency, setCurrency] = useState('PKR');
  const [txNature, setTxNature] = useState('');
  const [iban, setIban] = useState('');
  const [opHeadEmail, setHeadEmail] = useState('');
  const [accType, setAccType] = useState('');
  const [accPurpose, setAccPurpose] = useState('');
  const [amount, setAmount] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [smaCheck, setSmaCheck] = useState<boolean>(false);
  const [acc_code, setAccCode] = useState('');
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('accountObj') || '');
    setBranchHeadEmail(obj.bm_email);
    setFolioNo(obj.folio_no);
    setAccCode(obj.account_code);
    setBankName(obj.bank_name);
    setBranchName(obj.branch_name);
    setAmcName(obj.amc_code);
    getfundByAmcCode(obj.amc_code);
    getBranchByName(obj.bank_code);
    if (obj.sma === 'true') {
      setSmaCheck(true);
    } else {
      setSmaCheck(false);
    }
    // setSmaCheck(true);
    setFund(obj.fund_code);
    setCurrency(obj.currency);
    setBranchHeadMobile(obj.bm_phone);
    setTxNature(obj.nature_of_account);
    setIban(obj.account_no);
    setHeadPhone(obj.operation_head_phone);
    setAccType(obj.account_title);
    setAccPurpose(obj.product_purpose);
    setHeadEmail(obj.operation_head_email);
    setAmount(obj.balance_amount);
    setCity(obj.bank_city);
    // const fetchAmcFirst = async () => {
    //   try {
    //     const amcResponse = await getAmc(email);
    //     setAmcdata(amcResponse.data.data);
    //   } catch (error) {}
    // };
    // fetchAmcFirst();
    // setAmount(obj.)
  }, []);

  const getfundByAmcCode = async (code: string) => {
    setFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      setAllFunds(response.data.data);
    } catch (error) {}
    setFundLoading(false);
  };
  //error getting hooks
  
  const [bankNameError, setBankNameError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [amcNameError, setAmcError] = useState('');
  const [cityError, setCityError] = useState('');
  const [fundError, setFundError] = useState('');
  const [currencyError, setCurrencyError] = useState('');
  const [txNatureError, setTxNatureError] = useState('');
  const [ibanError, setIbanError] = useState('');
  const [accTypeError, setAccTypeError] = useState('');
  const [accPurposeError, setAccPurposeError] = useState('');
  const [amountError, setAmountError] = useState('');

  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };
  const [headEmailError, setHeadEmailError] = useState('');

  const [folioNoError, setFolioNoError] = useState('');
  const [headPhone, setHeadPhone] = useState('');
  const [branchHeadEmail, setBranchHeadEmail] = useState('');
  const [branchHeadMobile, setBranchHeadMobile] = useState('');
  const [folioNo, setFolioNo] = useState('');
  const [headPhoneError, setHeadPhoneError] = useState('');
  const [branchHeadEmailError, setBranchHeadEmailError] = useState('');
  const [branchHeadMobileError, setBranchHeadMobileError] = useState('');
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);

  React.useEffect(() => {
    const fetchAmc = async () => {
      amcdata.length = 0;
      setAmcdata(amcdata);
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all Amc for dropdown
      // try {
      //   const amcResponse = await getAmc(email);
      //   setAmcdata(amcResponse.data.data);
      // } catch (error) {}
      //get all banks list for dropdown
      try {
        const response = await getAllBanks(email);
        setBankNameData(response.data.data);
        // try {
        //   setAllBranchesData(response.data.data);
        //   setBankName(bankname)
        // } catch (error) { }
      } catch (error) {}

      //get tx nature data for dropdown
    };
    fetchAmc();
  }, []);


  //render dropdown for bank name data
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace('BANK_','')}>
          {item.bank_code.replace('BANK_','')}-{item.bank_name}
        </option>
      );
    });
  };
  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };
  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name}-{item.symbol_code}
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data
  const validate = () => {
    let bankErr,
      branchErr,
      ibanErr = '';
    bankname.trim() === '' ? (bankErr = 'Required') : (bankErr = '');
    branchName.trim() === '' ? (branchErr = 'Required') : (branchErr = '');
    iban.trim() === '' ? (ibanErr = 'Required') : (ibanErr = '');

    if (bankErr || branchErr || ibanErr) {
      setBankNameError(bankErr);
      setBranchNameError(branchErr);
      setIbanError(ibanErr);
      return false;
    } else {
      return true;
    }
  };
  const EditAccountProcess = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await EditAccount(
          email,
          amcName,
          bankname,
          branchName,
          iban,
          fund,
          accType,
          txNature,
          currency,
          amount,
          opHeadEmail,
          smaCheck,
          headPhone,
          branchHeadEmail,
          branchHeadMobile,
          folioNo,
          acc_code,
          city
        );
        if(response.data.status===200){
        setBankName('');
        setBranchName('');
        setAmcName('');
        setFund('');
        setAmount('');
        setCurrency('PKR');
        setTxNature('');
        setIban('');
        setAccType('');
        setAccPurpose('');
        sessionStorage.removeItem('accountObj');
        toast.success(response.data.message);
        setTimeout(() => {
          history.replace(`/admin/bank-accounts`);
        }, 2000);
      }else{
        toast.error(response.data.message);
      }
      } catch (error:any) {
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
    
<div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />
        <div className="input-holder">
            <h4 className="card-title">
            <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      sessionStorage.removeItem('accountObj');
                      history.replace('/admin/bank-accounts')
                  }}></i>Edit - Bank Account</h4>
            <Link
                  to="/admin/bank-accounts"
                  className="btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link> 
        </div>

        <Row> 
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Fund Detail
                </h4>
              </div>
              <div className="card-body">
                  {/* <div className="form-group  ">
                    <label>AMC Name</label>
                    <div className="  disable">
                      <input
                        type="text"
                        className="form-control "
                        style={{ opacity: '0.6' }}
                        value={amcName}
                        readOnly
                      />
                    </div>
                  </div> */}

                  <div className="form-group  ">
                    <label>
                      Fund Selection <span className="color-amber">*</span>
                    </label>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    <div  data-tip="First Select Amc">
                      {fundLoading ? (
                        <div className="form-group">
                          <div className="ml-2 form-control" >Fund Loading
                                    <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div> 
                      </div>
                      ) : (
                        <select
                          className={`form-control  w-100 ${
                            fundError ? 'required-border' : ''
                          }`}
                          value={fund}
                          onChange={(e) => {
                            setFundError('');
                            setFund(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Fund
                          </option>
                          {renderFundsDropdown()}
                        </select>
                      )}
                      {/* {fundError ? <p className="error-labels error-message">{fundError}</p> : ''} */}
                    </div>
                  </div>


              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Bank Detail
                </h4>
              </div>
              <div className="card-body">
                  <div className="form-group  ">
                    <label>Bank Name</label>
                    <div>
                      <select
                        className={`form-control  w-100 ${
                          bankNameError ? 'required-border' : ''
                        }`}
                        value={bankname}
                        onChange={(e) => {
                          setBankNameError('');
                          setBankName(e.target.value);
                          getBranchByName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Bank
                        </option>
                        {renderBankNameDropdown()}
                      </select>
                      {/* {bankNameError ? <p className="error-labels error-message2">{bankNameError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="row">
  <div className="col-md-6">
                  <div className="form-group  ">
                    <label>Branch Name</label>
                        <input type="text" className="form-control"
                        value={branchName}
                        onChange={(e) => {
                          setBankNameError('');
                          setBranchName(e.target.value);
                        }} />
                    {/* <div>
                      <select
                        className={`form-control  w-100 ${
                          branchNameError ? 'required-border' : ''
                        }`}
                        value={branchName}
                        onChange={(e) => {
                          setBankNameError('');
                          setBranchName(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {branchCheckFlag
                            ? 'No Branch Found'
                            : 'Select Branch Name'}
                        </option>
                        {renderBranchsDropdown()}
                      </select>
                     </div> */}
                  </div>

                  </div>

< div className="col-md-6">
<div className="form-group">	
    <label>City <span className="color-amber">*</span></label>	
      <input type="text" className="form-control"
      value={city}	
      onChange={(e) => {	
        setCityError('');	
        setCity(e.target.value);	
      }}
      />
      </div>
</div>
</div>

              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Special Managed Account
                </h4>
              </div>
              <div className="card-body">
                <FormGroup check className="mt-4">	
                    <Label check>	
                      <Input type="checkbox"	
                        checked={smaCheck}	
                        onChange={(e) => {	
                          setSmaCheck(!smaCheck);	
                        }} />	
                      <span className="form-check-sign" />	
                      SMA (Optional)	
                    </Label>	
                  </FormGroup> 	

                  {smaCheck && ( 
                    <div className="form-group mt-4">
                      <label>Main Folio No</label>
                      <div   >
                        <input
                          type="text"
                          className="form-control"
                          value={folioNo}
                          onChange={(e) => {
                            setFolioNo(e.target.value);
                            setFolioNoError('');
                          }}
                        />
                        {/* {folioNoError ? <p className="error-labels error-message">{folioNoError}</p> : ''} */}
                      </div>
                    </div> 
                )}

              </div>
            </div>
          </Col>
        </Row>

        <Row> 
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Contact Detail
                </h4>
              </div>
              <div className="card-body">
                 <div className="form-group  ">
                    <label>Operation Manager Email</label>
                    <div   >
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          headEmailError ? 'required-border' : ''
                        }`}
                        value={opHeadEmail}
                        onChange={(e) => {
                          setHeadEmail(e.target.value);
                          setHeadEmailError('');
                        }}
                      />
                      {/* {headEmailError ? <p className="error-labels error-message2">{headEmailError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Operation Manager Phone</label>
                    <div   >
                      {/* <input
                        type="text"
                        className={`form-control  w-100 ${
                          headPhoneError ? 'required-border' : ''
                        }`}
                        value={headPhone}
                        onChange={(e) => {
                          setHeadPhone(e.target.value);
                          setHeadPhoneError('');
                        }}
                      /> */}
                      <PhoneInput
                      specialLabel=""
                       inputClass={`form-control  w-100 ${
                        headPhoneError ? 'required-border' : ''
                      }`}
                        country={'pk'}
                        placeholder="+92 123-1234567"
                        value={headPhone}	
                        onChange={(e) => {		
                          setHeadPhone(e);	
                      setHeadPhoneError('');			
                        }}		
                      />
                      {/* {headPhoneError ? <p className="error-labels error-message">{headPhoneError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Branch Manager Phone</label>
                    <div   >
                      {/* <input
                        type="number"
                        className={`form-control  w-100 ${
                          branchHeadMobileError ? 'required-border' : ''
                        }`}
                        value={branchHeadMobile}
                        onChange={(e) => {
                          setBranchHeadMobile(e.target.value);
                          setBranchHeadMobileError('');
                        }}
                      /> */}
                      <PhoneInput
                      specialLabel=""
                       inputClass={`form-control  w-100 ${branchHeadMobileError ? 'required-border' : ''	
                      }`}
                        country={'pk'}
                        placeholder="+92 123-1234567"
                        value={branchHeadMobile}
                        onChange={(e) => {		
                          setBranchHeadMobile(e);	
                          setBranchHeadMobileError('');			
                        }}		
                      />
                      {/* {branchHeadMobileError ? <p className="error-labels error-message">{branchHeadMobileError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Branch Manager Email</label>
                    <div   >
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          branchHeadEmailError ? 'required-border' : ''
                        }`}
                        value={branchHeadEmail}
                        onChange={(e) => {
                          setBranchHeadEmail(e.target.value);
                          setBranchHeadEmailError('');
                        }}
                      />
                      {/* {branchHeadEmailError ? <p className="error-labels error-message2">{headEmailError}</p> : ''} */}
                    </div>
                  </div> 
              </div>
            </div>
          </Col>

          
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                Account Detail
                </h4>
              </div>
              <div className="card-body">
                 <div className="form-group  ">
                    <label>Bank Account Title</label>
                    <div>
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          accTypeError ? 'required-border' : ''
                        }`}
                        value={accType}
                        onChange={(e) => {
                        if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                        {	
                          setAccType(e.target.value);
                          setAccTypeError('');
                        }
                        }}
                      />
                      {/* {accTypeError ? <p className="error-labels error-message2">{accTypeError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>IBAN / Account No</label>
                    <div>
                      <input
                        type="text"
                        className={`form-control  w-100 ${
                          ibanError ? 'required-border' : ''
                        }`}
                        value={iban}
                        // readOnly
                        onChange={(e) => {
                        if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                        {	
                          setIban(e.target.value);
                          setIbanError('');
                        }
                        }}
                      />
                      {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Nature of Account</label>
                    <div>
                      <select
                        className={`form-control  w-100 ${
                          txNatureError ? 'required-border' : ''
                        }`}
                        value={txNature}
                        onChange={(e) => {
                          setTxNatureError('');
                          setTxNature(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Nature
                        </option>
                        <option value="Current">Current</option>
                        <option value="Saving">Saving</option>
                        <option value="Divident">Divident</option>
                        <option value="TDR">TDR</option>
                      </select>
                      {/* {txNatureError ? <p className="error-labels error-message2">{txNatureError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Currency</label>
                    <div>
                      <select
                        className={`form-control  w-100 ${
                          currencyError ? 'required-border' : ''
                        }`}
                        value={currency}
                        onChange={(e) => {
                          setCurrencyError('');
                          setCurrency(e.target.value);
                        }}
                      >
                        <option value="PKR" defaultChecked>
                          PKR
                        </option>
                        <option value="USD">USD</option>
                      </select>
                      {/* {currencyError ? <p className="error-labels error-message">{currencyError}</p> : ''} */}
                    </div>
                  </div>

                  <div className="form-group  ">
                    <label>Amount</label>
                    <div className="  disable-input">
                    <NumberFormat
                        thousandSeparator={true}
                        inputMode="numeric"
                      readOnly
                        className={`form-control  w-100 text-right ${
                          amountError ? 'required-border' : ''
                        }`}
                        value={amount}
                        onValueChange={(e) => {
                          setAmount(e.value);
                          setAmountError('');
                        }}
                      />
                      {/* {amountError ? <p className="error-labels error-message2">{amountError}</p> : ''} */}
                    </div>
                  </div>



              </div>
            </div>
          </Col>
           
        </Row>
 
          
        
            <div className="d-flex "> 
              <div className="">
                <button
                  className="btn btn-primary mr-2"
                  onClick={EditAccountProcess}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>

              <div className=" ">
                <button
                  className="btn btn-default"
                  onClick={() => {
                    sessionStorage.removeItem('accountObj');
                    history.replace('/admin/bank-accounts');
                  }}
                >
                  Cancel
                </button>
              </div>

            </div>
          
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default EditAccountComponent;
