import axios from "axios";
import Config from "../../config";

// export const getTrialBalanceReportData = async (
//   fund_code,
//   start_date,
//   end_date
// ) => {
//   const email = sessionStorage.getItem("email");
//   const token = sessionStorage.getItem("token");
//   const url = `${Config.baseUrl}/transaction/get-ledger-trial-balance?email=${email}&fund_code=${fund_code}&start_date=${start_date}&end_date=${end_date}`;
//   try {
//     const result: any = await axios.get(url, {
//       headers: { Authorization: token },
//     });
//     return result;
//   } catch (error: any) {
//     console.log(error);
//   }
// };

// export const getTrialBalanceReportData = async (
//   fund_code,
//   start_date,
//   end_date
// ) => {
//   const email = sessionStorage.getItem("email");
//   const token = sessionStorage.getItem("token");
//   const url = `${Config.baseUrl}/transaction/get-ledger-by-fund-code-and-date-range?email=${email}&fund_code=${fund_code}&start_date=${start_date}&end_date=${end_date}`;
//   try {
//     const result: any = await axios.get(url, {
//       headers: { Authorization: token },
//     });
//     return result;
//   } catch (error: any) {
//     console.log(error);
//   }
// };

export const getTrialBalanceReportData = async (
  fund_code,
  start_date,
  end_date
) => {
  const email = sessionStorage.getItem("email");
  const token = sessionStorage.getItem("token");
  const url = `${Config.baseUrl}/transaction/get-ledger-trial-balance?email=${email}&start_date=${start_date}&end_date=${end_date}&fund_code=${fund_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: { Authorization: token },
    });
    return result;
  } catch (error: any) {
    console.log(error);
  }
};
