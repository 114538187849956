import React from "react";
import { useState } from "react";
import { searchAPI } from "./../stores/services/amc.service";
import { Modal } from "react-bootstrap";
import { Container, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  getFundByAmc,
  getInstrumentType,
  getDailyNavByDate,
} from "./../stores/services/funds.service";
import { getModeOfPayments } from "./../stores/services/transactions.service";
import { getAccountByFund } from "./../stores/services/account.service";
import { getUnitHoldersByAmcAndFolioNo } from "./../stores/services/unit-holder.service";
import { getAllBanks } from "./../stores/services/bank.service";
import { getBranchByBankName } from "./../stores/services/branch.service";
import { RiArrowGoForwardFill } from "react-icons/ri";
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from "./field";
import {
  addMegaTransaction,
  EditTransaction,
  getFlow,
  addConversionOfUnitsTransaction,
} from "./../stores/services/beta-transaction.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { inWords } from "./../stores/services/template.service";
import { getBrokerByType } from "./../stores/services/broker.service";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  numberWithCommas,
  accountTitle_AccountNumber_Regex,
  getLedgerAccounts,
  getLedgersByTxn,
} from "./../utils/customFunction";
import { Link } from "react-router-dom";

import AddUnitHolder from "./add-new-unitholder-txn";
import NumberFormat from "react-number-format";

import {
  getChartOfAccounts,
  getTransactionsLedgers,
} from "./../stores/services/chatofaccounts.service";

import { getAllLedgers } from "../utils/customFunction";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./voucher.css";

import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type: string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized: string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst: string;
  tax_wht_it: string;
  tax_wht_sst: string;
  tax_cgt: string;
  sale_load: string;
  redemption_load: string;
  low_balance_agree: string;
  offer_price: string;
  load_per_unit: string;
  percentage_of_discount: string;
  applicable_offer_price: string;
  sales_tax_collector: string;
  volume: string;
  par_value: string;
  announcement_date: string;
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend: string;
  tax_on_bonus: string;
  tax_on_right: string;
  zakat_amount: string;
  ledgers: string;
}

const Vouchers = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const token = sessionStorage.getItem("token") || "";
  const [headLabel, setHeadLabel] = React.useState("");
  const tx = sessionStorage.getItem("rejectedTxName") || "";
  const amcEdit = sessionStorage.getItem("rejectedAmc") || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  let txnTypes = JSON.parse(sessionStorage.getItem("txnType") || "");
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState("");
  const [accLoading, setAccLoading] = useState(false);
  const [unitHolderData, setUnitHolderData] = useState<any>([]);
  const paymentType = sessionStorage.getItem("payment_type") || "";

  const [psxList, setPsxList] = useState<any>([]);
  const [stockMarketDataFund, setStockMarketDataFund] = useState<any>([]); // PSX data of fund
  const [moneyMarketDataFund, setMoneyMarketDataFund] = useState<any>([]); // money market data of fund
  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const [counterFundAccount, setCounterFundAccount] = useState<any>([]);
  const amcName = sessionStorage.getItem("amc_name") || "";
  let [sstPercentage, setSSTPercentage] = useState("");
  let [whtITPercentage, setWHTITPercentage] = useState("");
  let [sstWHTPercentage, setSSTWHTPercentage] = useState("");

  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response
  const [primaryDealerData, setPrimaryDealerData] = useState<any>([]); // Money Maket Securities Hook - primaryDealerData

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [allCounterFundsAccounts, setAllCounterFundsAccounts] = useState<any>(
    []
  ); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data

  const [unitHolderFieldReadOnly, setUnitHolderFieldReadOnly] = useState(false);
  const [loadBtnEnable, setLoadBtnEnable] = useState(false);

  const [viewModalAddNewHolder, setViewModalAddNewUnitHolder] = useState(false);

  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  const [propsState, setPropsState] = useState({
    amc_code: "",
    folioNo: "",
    unitholder: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
  });

  // for legders states
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [ledgerAccountCode, setLegderAccountCode] = useState("");
  const [ledgerAccountName, setLegderAccountName] = useState("");

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{}]);

  const [totalDebit, setTotalDebit] = useState("0");
  const [totalCredit, setTotalCredit] = useState("0");
  const [balance, setBalance] = useState("0");

  //for voucher debit / credit
  //debit
  const [allLedgerData, setAllLedgerData] = useState<any>([]);
  const [selectedLedgerDebit, setSelectedLedgerDebit] = useState("");
  const [valueSelectedLedgerDebit, setValueSelectedLedgerDebit] =
    React.useState<string | null>("");
  const [inputValueSelectedLedgerDebit, setInputValueSelectedLedgerDebit] =
    React.useState("");
  const [amountDebit, setAmountDebit] = useState<any>("0");
  const [descriptionDebit, setDescriptionDebit] = useState("");
  const [invoiceNumberDebit, setInvoiceNumberDebit] = useState("");
  const [debitFormData, setDebitFormData] = useState<any>([]);
  const [totalDebitCount, setTotalDebitCount] = useState("0");
  const [viewDebitIndex, setViewDebitIndex] = useState("");

  //credit
  const [selectedLedgerCredit, setSelectedLedgerCredit] = useState("");
  const [valueSelectedLedgerCredit, setValueSelectedLedgerCredit] =
    React.useState<string | null>("");
  const [inputValueSelectedLedgerCredit, setInputValueSelectedLedgerCredit] =
    React.useState("");
  const [amountCredit, setAmountCredit] = useState<any>("0");
  const [descriptionCredit, setDescriptionCredit] = useState("");
  const [invoiceNumberCredit, setInvoiceNumberCredit] = useState("");
  const [creditFormData, setCreditFormData] = useState<any>([]);
  const [totalCreditCount, setTotalCreditCount] = useState("0");
  const [editCreditIndex, setEditCreditIndex] = useState("");
  const [viewCreditIndex, setViewCreditIndex] = useState("");

  //for credit edit
  const [editValueSelectedLedgerCredit, setEditValueSelectedLedgerCredit] =
    React.useState<string | null>("");
  const [
    editInputValueSelectedLedgerCredit,
    setEditInputValueSelectedLedgerCredit,
  ] = React.useState("");
  const [editAmountCredit, setEditAmountCredit] = useState<any>("0");
  const [editDescriptionCredit, setEditDescriptionCredit] = useState("");
  const [editInvoiceNumberCredit, setEditInvoiceNumberCredit] = useState("");

  //for ledger autocomplete
  const [ledgerOption, setLedgerOption] = useState<any>([]);

  const loadDailyNav = async (fund: string, nav_date: string) => {
    try {
      const amcResponse = await getDailyNavByDate(email, fund, nav_date);
      return amcResponse.data.data;
    } catch (error) {}
  };

  //for ledger to send to backend
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);

  const decimalValueNav = (value, len) => {
    if (value === 0 || value === parseInt(value, 10)) return value;
    value = value.toString();
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === "Fund") {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
      // sort data
      let sortedData: any = [];
      sortedData = response.data.data.sort(function (a, b) {
        let x = a.nature.toLowerCase();
        let y = b.nature.toLowerCase();
        if (x > y) {
          return 1;
        }
        if (x < y) {
          return -1;
        }
        return 0;
      });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if (val.nature === "Special Managed Account") {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${"SMA"} `,
            value: val.symbol_code,
          };
        } else {
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code})`,
            value: val.symbol_code,
          };
        }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) {}
  };

  const getUnitHoldersByAmcFolioNo = async (email, amc_code, foliono) => {
    try {
      setUnitHolderFieldReadOnly(true);
      const unitHolderResponse = await getUnitHoldersByAmcAndFolioNo(
        email,
        amc_code,
        foliono
      );
      let dataunit = unitHolderResponse.data.data;
      if (dataunit.length > 0) {
        setUnitHolderData(dataunit);

        if (headLabel === "saleofunit" || headLabel === "redemptionofunits") {
          await setCounterFieldsData(
            "UNITHOLDER_" + foliono,
            unitHolderResponse.data.data
          );
        } else if (
          headLabel === "conversionin" ||
          headLabel === "conversionout" ||
          headLabel === "unitconversion"
        ) {
          await setFolioNoData(
            "UNITHOLDER_" + foliono,
            unitHolderResponse.data.data
          );
        }
      } else {
        // clear the fields
        if (headLabel === "saleofunit" || headLabel === "redemptionofunints") {
          setFields({
            ...fields,
            counter_account_title: "",
            counter_account_number: "",
            counter_bank: "",
            counter_branch: "",
            folio_no: "",
            unitholder_name: "",
            current_holding: "",
          });
        } else if (
          headLabel === "conversionin" ||
          headLabel === "conversionout" ||
          headLabel === "unitconversion"
        ) {
          setFields({
            ...fields,
            counter_account_title: "",
            counter_account_number: "",
            counter_bank: "",
            counter_branch: "",
            unitholder_name: "",
            current_holding: "",
          });
        }
        toast.error(
          "Invalid folio no. (" +
            foliono.replaceAll(amc_code.toString() + "-", "") +
            ")"
        );
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      }
    }
    setUnitHolderFieldReadOnly(false);
  };

  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item: any) => {
      if (item.symbol_code === fund_code) {
        fields["nav"] = item.nav;
        fields["face_value"] = item.face_value;

        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== "" &&
          item.stock_market !== "[]" &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };

  // Function to get aLL Accounts of selected Fund from Instruction Section
  const [counterFundLoading, setCounterFundLoading] = useState(false);

  const getCounterFundAccountByFundName = async (fund_code: string) => {
    setCounterFundLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // label: `${val.account_no} (${val.account_code})`,
          label: `${val.bank_name}-${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllCounterFundsAccounts(temp);
    } catch (error) {}
    setCounterFundLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem("rejectedFlag") || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);
  const [unitsReadOnly, setUnitsReadOnly] = useState(false);
  const [discount_applicable_ReadOnly, setDiscount_Applicable_ReadOnly] =
    useState(false);

  React.useEffect(() => {
    const getAllLegders = async () => {
      const response = await getChartOfAccounts(email);
      if (response.data.status === 200) {
        setAllLedgerData(getAllLedgers(response.data.data.main_family));
      }
    };

    getAllLegders();
  }, []);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields["txnr_type"] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === "Other") {
      fields["counter_account_type"] = "Other";
      setFields(fields);
    }
    if (
      props.match.params.type === "debtmarketinvestment" ||
      props.match.params.type === "equityinvestment"
    ) {
      setGrossAmountReadOnly(true);
      setNetAmountReadOnly(true);
    }
  }, [props.match.params.type]);

  React.useEffect(() => {
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.name}`,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.bank}`,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
          },
        ]);
      }
    });
  }, [allLedgerData]);

  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates = moment(date).format("YYYY-MM-DD");
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: "",
    dr_amount: "",
    period: "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: "",
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  //code for edit transaction
  let [count, setCount] = useState(0);

  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem("rejectedTxObj") || " ");
      getFundByAMcCode(obj.amc_code);
      fields["payment_type"] = obj.payment_type || "";
      fields["txnr_type"] = obj.txnr_type || "";
      fields["amc_code"] = obj.amc_code || "";
      getAccountByFundName(obj.fund_code);
      fields["fund_code"] = obj.fund_code || "";
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields["instruction_date"] = obj.instruction_date || "";
      fields["execution_date"] = obj.execution_date || "";
      fields["executed_date"] = obj.executed_date || "";
      fields["fund_account"] = obj.fund_account || "";
      fields["dividend_date"] = obj.dividend_date || "";
      fields["dividend_rate"] = obj.dividend_rate || "";
      fields["reinvest"] = obj.reinvest || "";
      fields["period"] = obj.period || "";
      fields["account_title"] = obj.account_title || "";
      fields["account_no"] = obj.account_number || "";
      fields["bank"] = obj.bank || "";
      fields["branch"] = obj.branch || "";
      fields["counter_account_type"] = obj.counter_account_type || "";
      fields["counter_type"] = obj.counter_type || "";
      fields["counter_account_title"] = obj.counter_account_title || "";
      fields["counter_account_number"] = obj.counter_account_number || "";
      fields["counter_bank"] = obj.counter_bank || "";
      fields["counter_branch"] = obj.counter_branch || "";
      fields["mode_of_payment"] = obj.mode_of_payment || "";
      fields["instrument_no"] = obj.instrument_no || "";
      fields["instrument_type"] = obj.instrument_type || "";
      fields["instrument_date"] = obj.instrument_date || "";
      fields["realized_date"] = obj.realized_date || "";
      fields["realized"] = obj.realized || "";
      fields["gross_amount"] = obj.gross_amount.toString() || "";
      fields["net_amount"] = obj.net_amount.toString() || "";
      fields["dr_amount"] = "0";
      fields["cr_amount"] = "0";
      fields["balance"] = obj.balance.toString() || "";
      fields["folio_no"] = obj.folio_no || "";
      fields["unitholder_name"] = obj.unitholder_name || "";
      fields["units"] = obj.units.toString() || "";
      fields["nav"] = obj.nav.toString() || "";
      fields["sale_date"] = obj.sale_date || "";
      fields["current_holding"] = obj.current_holding.toString() || "";
      fields["total_holding"] = obj.total_holding.toString() || "";
      fields["symbol"] = obj.symbol || "";
      fields["dividend_percentage"] = obj.dividend_percentage.toString() || "";
      fields["credit_date"] = obj.credit_date || "";
      fields["maturity_type"] = obj.maturity_type || "";
      fields["security_type"] = obj.security_type || "";
      fields["issue_date"] = obj.issue_date || "";
      fields["conversion_date"] = obj.conversion_date || "";
      fields["associated_transaction"] = obj.associated_transaction || "";
      fields["maturity_date"] = obj.maturity_date || "";
      fields["coupon_rate"] = obj.coupon_rate || "";
      fields["price"] = obj.price.toString() || "";
      fields["face_value"] = obj.face_value.toString() || "";
      fields["money_market_face_value"] =
        obj.money_market_face_value.toString() || "";
      fields["detail"] = obj.detail || "";
      fields["type"] = obj.type || "";

      fields["redemption_date"] = obj.redemption_date || "";
      fields["remain_holding"] = obj.remain_holding.toString() || "";
      fields["settlement_by"] = obj.settlement_by || "";
      fields["tax_type"] = obj.tax_type || "";
      fields["settlement_date"] = obj.settlement_date || "";
      fields["total_charges"] = obj.total_charges.toString() || "";
      fields["sale_txn_no"] = obj.sale_txn_no || "";
      fields["return_date"] = obj.return_date || "";
      fields["trx_id"] = obj.txn_id || "";
      fields["primary_dealer"] = obj.primary_dealer;
      fields["yield"] = obj.yield || "";

      if (headLabel === "" || headLabel === "") {
        fields["sale_load"] = obj.sale_load || "";
      }

      if (headLabel === "" || headLabel === "") {
        fields["redemption_load"] = obj.redemption_load || "";
        fields["tax_cgt"] = obj.tax_cgt || "";
      }

      fields["zakat_amount"] = obj.zakat_amount;
      fields["txn_status"] = "CREATED";
      // Dividend On Investment
      fields["volume"] = obj.volume;
      fields["par_value"] = obj.par_value;
      fields["announcement_date "] = obj.announcement_date;
      fields["bonus_percentage"] = obj.bonus_percentage;
      fields["bonus_credit_date"] = obj.bonus_credit_date;
      fields["bonus_gross_volume"] = obj.bonus_gross_volume;
      fields["tax_it_bonus_amount"] = obj.tax_it_bonus_amount;
      fields["net_bonus_volume"] = obj.net_bonus_volume;
      fields["right_share_percentage"] = obj.right_share_percentage;
      fields["right_share_credit_date"] = obj.right_share_credit_date;
      fields["right_share_gross_volume"] = obj.right_share_gross_volume;
      fields["tax_it_right_share_amount"] = obj.tax_it_right_share_amount;
      fields["net_right_share_volume"] = obj.net_right_share_volume;
      fields["tax_on_dvidend"] = obj.tax_on_dvidend;
      fields["tax_on_bonus"] = obj.tax_on_bonus;
      fields["tax_on_right"] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields["fund_ips_account"] = obj.fund_ips_account || "";
      fields["counter_party_ips_account"] = obj.counter_party_ips_account || "";
      fields["low_balance_agree"] = "";
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split(".")[0];
    let decimalVal = value.split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  //

  // const getTaxRate = () => {
  //   let taxFields = getTaxObj(props.match.params.type);
  //   setSSTPercentage(taxFields.tax_rate);
  //   setWHTITPercentage(taxFields.wht_it_rate);
  //   setSSTWHTPercentage(taxFields.wht_sst_rate);
  // };

  //end
  React.useEffect(() => {
    // getTaxRate();
    // if (!flag) {
    //   fields["payment_type"] = paymentType;
    //   fields["email"] = email;
    //   fields["amc_code"] = loggedInUser.amc_code;
    // }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);

      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(
          sessionStorage.getItem("loggedInUser") || ""
        );
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {}

      try {
        const response = await getChartOfAccounts(email);
        if (response.data.status == 200) {
          let ledgersacc = getLedgerAccounts(
            response.data.data.main_family[0].family[3].sub_family
          );
          setLegderAccounts(ledgersacc);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {}

      // get all transaction ledgers
      try {
        const response = await getTransactionsLedgers(email);
        if (response.data.status == 200) {
          let unitManagement =
            response.data.data.txn_catagory[0].unitManagementTxn;
          let txnLegders = await getLedgersByTxn(
            unitManagement,
            props.match.params.type
          );

          setTransactionLedgers(txnLegders);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {}

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: "Other",
          label: "Other",
        });
        setAmcdataCounter(temp);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }

      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace("BANK_", "")} (${val.bank_name})`,
            value: val.bank_code.replace("BANK_", ""),
          };
        });
        temp.unshift({ value: "Other", label: "Other" });
        setBankNameData(temp);
      } catch (error) {}
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) {}
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) {}

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: "Other", label: "Other" });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) {}

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  const renderFundAccounts = () => {
    return (
      ledgerAccounts &&
      ledgerAccounts.map((item: any, index: number) => {
        //   if(item.plsName==='PLS ACCOUNTS'){
        //   return (
        //     <optgroup style={{
        //       color:'black'
        //     }} label="PLS ACCOUNTS">
        //     <option key={index} value={item.code}>
        //       {item.account_title}
        //     </option>
        //     </optgroup>
        //   );
        // }else{
        //   return (
        //     <optgroup style={{
        //       color:'black'
        //     }}  label="CURRENT ACCOUNTS">
        //     <option key={index} value={item.code}>
        //       {item.account_title}
        //     </option>
        //     </optgroup>
        //   );

        // }
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  //Debit Ledger Funds DropDown selection
  const renderLedgerDropdown = () => {
    // return allLedgerData
    //   ? allLedgerData.map((item: any, index: any) => {
    //       if (item.name) {
    //         return (
    //           <option key={index} value={item.name}>
    //             {item.code} - {item.name}
    //           </option>
    //         );
    //       }
    //       if (item.bank) {
    //         return (
    //           <option key={index} value={item.bank}>
    //             {item.code} - {item.bank} - {item.account_title}
    //           </option>
    //         );
    //       }
    //     })
    //   : null;

    return ledgerOption.length > 1 ? (
      <Autocomplete
        className="mt-2 autocomplete"
        disablePortal
        id="combo-box-demo"
        options={ledgerOption}
        style={{ width: 300 }}
        PaperComponent={({ children }) => (
          <Paper style={{ background: "#1E1E2F", color: "white" }}>
            {children}
          </Paper>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select Ledger*" variant="standard" />
        )}
      />
    ) : null;
  };

  //add debit modal

  const getFundMoneyMarketPortfolioData = (fund_code, security_type) => {
    const s = allFunds.filter((item: any, index: number) => {
      return fund_code === item.symbol_code;
    });
    let data = JSON.parse(s[0].money_market);
    let securitiesData = data.filter((item: any, index: number) => {
      return security_type === item.security_type;
    });
    setMoneyMarketDataFund(securitiesData);
  };

  //modal for add debit entry
  const [viewAddDebitModal, setViewAddDebitModal] = useState(false);
  const renderModalForAddDebit = () => {
    if (viewAddDebitModal) {
      return (
        <Modal
          className=""
          dialogClassname="debitModal"
          show={true}
          // size="xl"
          backdrop={true}
          onHide={() => {
            setViewAddDebitModal(false);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewAddDebitModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add Debit Record</h6>
          </div>

          <div className="modal-body">
            <Row>
              <Col>
                <div className="form-group " data-tip="Select Ledger">
                  {ledgerOption.length > 1 ? (
                    <Autocomplete
                      className="mt-2 autocomplete"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgerOption}
                      style={{ width: "100%" }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{ background: "#1E1E2F", color: "white" }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={valueSelectedLedgerDebit}
                      onChange={(event: any, newValue: string | null) => {
                        setValueSelectedLedgerDebit(newValue);
                      }}
                      inputValue={inputValueSelectedLedgerDebit}
                      onInputChange={(event, newInputValue) => {
                        setInputValueSelectedLedgerDebit(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <div className="form-control w-100">
                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div>Amount</div>
                  <input
                    type="number"
                    value={parseInt(amountDebit)}
                    className={"form-control w-100 " + ErrorBorder(amountDebit)}
                    onChange={(e) => {
                      setAmountDebit(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Description</div>
                  <input
                    type="text"
                    placeholder="Describe the debit transaction"
                    value={descriptionDebit}
                    className={
                      "form-control w-100" + ErrorBorder(descriptionDebit)
                    }
                    onChange={(e) => {
                      setDescriptionDebit(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Invoice Number</div>
                  <input
                    type="text"
                    placeholder="Invoice Number"
                    value={invoiceNumberDebit}
                    className={
                      "form-control w-100" + ErrorBorder(invoiceNumberDebit)
                    }
                    onChange={(e) => {
                      setInvoiceNumberDebit(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between">
                  {/* <div>Execution Date</div> */}
                  <div className="hidden"></div>
                  <div
                    className="btn"
                    onClick={() => {
                      if (inputValueSelectedLedgerDebit === "") {
                        toast.error("Ledger selection is must!");
                      } else if (parseInt(amountDebit) === 0) {
                        toast.error("Amount should not be 0");
                      } else if (amountDebit === "") {
                        toast.error("Amount should not be empty");
                      } else if (debitFormData.length > 0) {
                        if (
                          !!debitFormData.find(
                            (element) =>
                              element.ledger === inputValueSelectedLedgerDebit
                          )
                        ) {
                          toast.error("Ledger already added to debit");
                          setInputValueSelectedLedgerDebit("");
                          setSelectedLedgerDebit("");

                          setAmountDebit("0");
                          setDescriptionDebit("");
                          setInvoiceNumberDebit("");
                          setViewAddDebitModal(false);
                        } else {
                          setDebitFormData((prevState) => [
                            ...prevState,
                            {
                              ledger: inputValueSelectedLedgerDebit,
                              amount: amountDebit,
                              description: descriptionDebit,
                              invoiceNumber: invoiceNumberDebit,
                            },
                          ]);
                          setTotalDebit((prevState) =>
                            (
                              parseInt(prevState) + parseInt(amountDebit)
                            ).toString()
                          );
                          toast.success("Debit added successfully!");

                          setInputValueSelectedLedgerDebit("");
                          setSelectedLedgerDebit("");

                          setAmountDebit("0");
                          setDescriptionDebit("");
                          setInvoiceNumberDebit("");
                          setViewAddDebitModal(false);
                        }
                      } else {
                        setDebitFormData((prevState) => [
                          ...prevState,
                          {
                            ledger: inputValueSelectedLedgerDebit,
                            amount: amountDebit,
                            description: descriptionDebit,
                            invoiceNumber: invoiceNumberDebit,
                          },
                        ]);
                        setTotalDebit((prevState) =>
                          (
                            parseInt(prevState) + parseInt(amountDebit)
                          ).toString()
                        );
                        toast.success("Debit added successfully!");

                        setInputValueSelectedLedgerDebit("");
                        setSelectedLedgerDebit("");
                        setAmountDebit("0");
                        setDescriptionDebit("");
                        setInvoiceNumberDebit("");
                        setViewAddDebitModal(false);
                      }
                    }}
                  >
                    Add
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  //view debit modal
  const [viewViewDebitModal, setViewViewDebitModal] = useState(false);
  const renderModalForViewDebit = (index) => {
    if (viewViewDebitModal === true) {
      let viewValue = { ...debitFormData[index] };

      return (
        <Modal
          className=""
          dialogClassname="debitModal"
          show={true}
          // size="xl"
          backdrop={true}
          onHide={() => {
            setViewViewDebitModal(false);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewViewDebitModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Debit Record</h6>
          </div>

          <div className="modal-body">
            <Row>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="form-group " data-tip="Select Ledger">
                  {ledgerOption.length > 1 ? (
                    <Autocomplete
                      className="mt-2  autocomplete"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgerOption}
                      style={{ width: 300 }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{ background: "#1E1E2F", color: "white" }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={viewValue.ledger}
                      disabled
                      onChange={(event: any, newValue: string | null) => {
                        viewValue.ledger(newValue);
                      }}
                      inputValue={viewValue.ledger}
                      onInputChange={(event, newInputValue) => {
                        viewValue.ledger(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <i className="mt-2 fa fa-spinner fa-spin fa-1x"></i>
                  )}
                </div>
                <div className="form-group">
                  <div>Amount</div>
                  <input
                    type="text"
                    value={viewValue.amount}
                    className={
                      "form-control w-100 " + ErrorBorder(amountCredit)
                    }
                    disabled
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Description</div>
                  <input
                    type="text"
                    placeholder="Describe the credit transaction"
                    value={viewValue.description}
                    className={
                      "form-control w-100" + ErrorBorder(descriptionCredit)
                    }
                    disabled
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Invoice Number</div>
                  <input
                    type="text"
                    placeholder="Invoice Number"
                    value={viewValue.invoiceNumber}
                    className={"form-control w-100"}
                    disabled
                  />
                </div>
                <div className="form-group d-flex justify-content-between">
                  {/* <div>Execution Date</div> */}
                  <div className="hidden"></div>
                  <div
                    className="btn"
                    onClick={() => {
                      setViewViewDebitModal(false);
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  //handle Debit Delete
  //delete credit
  const handleDebitDelete = (ledger, amount) => {
    if (debitFormData.length > 0) {
      setDebitFormData(debitFormData.filter((data) => data.ledger !== ledger));
    }
    setTotalDebit((parseInt(totalDebit) - parseInt(amount)).toString());
    toast.success("Debit record deleted!");
  };

  //Add Credit Modal
  const [viewAddCreditModal, setViewAddCreditModal] = useState(false);
  const renderModalForAddCredit = () => {
    if (viewAddCreditModal) {
      return (
        <Modal
          className=""
          dialogClassname="creditModal"
          show={true}
          // size="xl"
          backdrop={true}
          onHide={() => {
            setViewAddCreditModal(false);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewAddCreditModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add Credit Record</h6>
          </div>

          <div className="modal-body">
            <Row>
              <Col>
                <div className="form-group " data-tip="Select Ledger">
                  {ledgerOption.length > 1 ? (
                    <Autocomplete
                      className="mt-2 autocomplete w-100"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgerOption}
                      style={{ width: 300 }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{
                            background: "#1E1E2F",
                            color: "rgba(255, 255, 255, 0.5) !important",
                          }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={valueSelectedLedgerCredit}
                      onChange={(event: any, newValue: string | null) => {
                        setValueSelectedLedgerCredit(newValue);
                      }}
                      inputValue={inputValueSelectedLedgerCredit}
                      onInputChange={(event, newInputValue) => {
                        setInputValueSelectedLedgerCredit(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <div className="form-control w-100">
                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div>Amount</div>
                  <input
                    type="number"
                    value={parseInt(amountCredit)}
                    className={
                      "form-control w-100 " + ErrorBorder(amountCredit)
                    }
                    onChange={(e) => {
                      setAmountCredit(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Description</div>
                  <input
                    type="text"
                    placeholder="Describe the credit transaction"
                    value={descriptionCredit}
                    className={
                      "form-control w-100" + ErrorBorder(descriptionCredit)
                    }
                    onChange={(e) => {
                      setDescriptionCredit(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Invoice Number</div>
                  <input
                    type="text"
                    placeholder="Invoice Number"
                    value={invoiceNumberCredit}
                    className={
                      "form-control w-100" + ErrorBorder(invoiceNumberCredit)
                    }
                    onChange={(e) => {
                      setInvoiceNumberCredit(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group d-flex justify-content-between">
                  {/* <div>Execution Date</div> */}
                  <div className="hidden"></div>
                  <div
                    className="btn"
                    onClick={() => {
                      if (inputValueSelectedLedgerCredit === "") {
                        toast.error("Ledger selection is must!");
                      } else if (parseInt(amountCredit) === 0) {
                        toast.error("Amount should not be 0");
                      } else if (amountCredit === "") {
                        toast.error("Amount should not be empty");
                      } else if (creditFormData.length > 0) {
                        if (
                          !!creditFormData.find(
                            (element) =>
                              element.ledger === inputValueSelectedLedgerCredit
                          )
                        ) {
                          toast.error("Ledger already added to credit");
                          setInputValueSelectedLedgerCredit("");
                          setAmountCredit("0");
                          setDescriptionCredit("");
                          setInvoiceNumberCredit("");
                          setViewAddCreditModal(false);
                        } else {
                          setCreditFormData((prevState) => [
                            ...prevState,
                            {
                              ledger: inputValueSelectedLedgerCredit,
                              amount: amountCredit,
                              description: descriptionCredit,
                              invoiceNumber: invoiceNumberCredit,
                            },
                          ]);
                          setTotalCredit((prevState) =>
                            (
                              parseInt(prevState) + parseInt(amountCredit)
                            ).toString()
                          );
                          toast.success("Credit record added!");
                          setInputValueSelectedLedgerCredit("");
                          setAmountCredit("0");
                          setDescriptionCredit("");
                          setInvoiceNumberCredit("");
                          setViewAddCreditModal(false);
                        }
                      } else {
                        setCreditFormData((prevState) => [
                          ...prevState,
                          {
                            ledger: inputValueSelectedLedgerCredit,
                            amount: amountCredit,
                            description: descriptionCredit,
                            invoiceNumber: invoiceNumberCredit,
                          },
                        ]);
                        setTotalCredit((prevState) =>
                          (
                            parseInt(prevState) + parseInt(amountCredit)
                          ).toString()
                        );
                        toast.success("Credit record added!");
                        setInputValueSelectedLedgerCredit("");
                        setAmountCredit("0");
                        setDescriptionCredit("");
                        setInvoiceNumberCredit("");
                        setViewAddCreditModal(false);
                      }
                    }}
                  >
                    Add
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  //view credit modal
  const [viewViewCreditModal, setViewViewCreditModal] = useState(false);
  const renderModalForViewCredit = (index) => {
    if (viewViewCreditModal === true) {
      let viewValue = { ...creditFormData[index] };

      return (
        <Modal
          className=""
          dialogClassname="creditModal"
          show={true}
          // size="xl"
          backdrop={true}
          onHide={() => {
            setViewViewCreditModal(false);
          }}
        >
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewViewCreditModal(false);
              }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Credit Record</h6>
          </div>

          <div className="modal-body">
            <Row>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="form-group " data-tip="Select Ledger">
                  {ledgerOption.length > 1 ? (
                    <Autocomplete
                      className="mt-2 autocomplete"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgerOption}
                      style={{ width: 300 }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{ background: "#1E1E2F", color: "white" }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={viewValue.ledger}
                      disabled
                      onChange={(event: any, newValue: string | null) => {
                        viewValue.ledger(newValue);
                      }}
                      inputValue={viewValue.ledger}
                      onInputChange={(event, newInputValue) => {
                        viewValue.ledger(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <div className="form-control w-100">
                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div>Amount</div>
                  <input
                    type="text"
                    value={viewValue.amount}
                    className={
                      "form-control w-100 " + ErrorBorder(amountCredit)
                    }
                    disabled
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Description</div>
                  <input
                    type="text"
                    placeholder="Describe the credit transaction"
                    value={viewValue.description}
                    className={
                      "form-control w-100" + ErrorBorder(descriptionCredit)
                    }
                    disabled
                  />
                </div>
                <div className="form-group">
                  {/* <div>Execution Date</div> */}
                  <div>Invoice Number</div>
                  <input
                    type="text"
                    placeholder="Invoice Number"
                    value={viewValue.invoiceNumber}
                    className={"form-control w-100"}
                    disabled
                  />
                </div>
                <div className="form-group d-flex justify-content-between">
                  {/* <div>Execution Date</div> */}
                  <div className="hidden"></div>
                  <div
                    className="btn"
                    onClick={() => {
                      setViewViewCreditModal(false);
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </Row>
          </div>
        </Modal>
      );
    }
  };

  //delete credit
  const handleCreditDelete = (ledger, amount) => {
    if (creditFormData.length > 0) {
      setCreditFormData(
        creditFormData.filter((data) => data.ledger !== ledger)
      );
      setTotalCredit((parseInt(totalCredit) - parseInt(amount)).toString());
    }
    toast.success("Credit record deleted!");
  };

  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for primary dealer data
  const renderPrimaryDealerDropdown = () => {
    return primaryDealerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.company_name}>
          {/* {item.name} */}
          {`${item.company_name} (${item.company_code}) `}
        </option>
      );
    });
  };

  //render dropdown for psx data
  const renderPsxDataDropdown = () => {
    return psxList.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol}>
          {item.company_name} ({item.symbol})
        </option>
      );
    });
  };

  //render dropdown for money market securities data of fund
  const renderMoneyMarketSecurityData = () => {
    return moneyMarketDataFund.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code}>
          {item.name}
        </option>
      );
    });
  };

  //render dropdown for psx data of fund
  const renderStockMarketData = () => {
    return stockMarketDataFund.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code}>
          {item.code}
        </option>
      );
    });
  };

  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code.replace("SECURITY_", "")}>
          {item.name.replace("SECURITY_", "")}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);
  // filter securities data based on security_type selection
  const renderFilterSecurityDataDropdown = (security_type: string) => {
    const check = securityTypeData.filter((item: any, index: string) => {
      return item.security_type === security_type;
    });
    setSecurityData(check);
  };

  const renderSecuritiesDropDown = () => {
    return securityData.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code.replace("SECURITY_", "")}>
          {item.name}
        </option>
      );
    });
  };

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: "Other", label: "Other" });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === "Other") {
      setFields({
        ...fields,
        counter_branch: "",
      });
      setInputType(true);
    }
  };

  // handle data for Fund Account Dropdown selection
  const setFundDataCounter = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      accountNoData.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields["counter_account_type"] = item.account_code;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.account_no;
          fields["counter_bank"] = item.bank_name;
          fields["counter_branch"] = item.branch_name;
          setFields(fields);
        }
      });
    }
  };

  const [branchInputType, setBranchInputType] = useState(false);
  const onOtherBranchSelection = (val) => {
    if (val === "Other") {
      setFields({
        ...fields,
        branch: "",
      });
      setBranchInputType(true);
    }
  };
  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === "") {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === "") {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState("");

  const createLegders = () => {
    if (headLabel == "saleofunit" || headLabel == "conversionin") {
      // GROSS AMOUNT
      transactionLedgers[0].amc_code = amc_code;
      transactionLedgers[0].fund_code = fields["fund_code"];

      transactionLedgers[0].account_number = fields["account_no"];
      transactionLedgers[0].account_title = fields["account_title"];
      transactionLedgers[0].bank_code = fields["bank_code"];
      transactionLedgers[0].branch = fields["branch"];
      transactionLedgers[0].balance = "";
      transactionLedgers[0].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[0].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[0].counter_bank = fields["counter_bank"];
      transactionLedgers[0].counter_branch = fields["counter_branch"];

      transactionLedgers[0].description =
        "Gross Amount & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[0].instruction_date = fields["instruction_date"];
      transactionLedgers[0].instrument_by = fields["instrument_by"];
      transactionLedgers[0].instrument_date = fields["instrument_date"];
      transactionLedgers[0].instrument_no = fields["instrument_no"];
      transactionLedgers[0].instrument_type = fields["instrument_type"];
      transactionLedgers[0].realized = fields["realized"];
      transactionLedgers[0].realized_by = fields["realized_by"];
      transactionLedgers[0].realized_date = fields["realized_date"];
      transactionLedgers[0].entered_by = "";
      transactionLedgers[0].evidence = "";

      transactionLedgers[0].child_family_code = 0;
      transactionLedgers[0].child_family_name = "";
      transactionLedgers[0].gl_code = +ledgerAccountCode;
      transactionLedgers[0].gl_name = ledgerAccountName;
      transactionLedgers[0].debit_amount = fields["gross_amount"];
      transactionLedgers[0].credit_amount = "0";
      transactionLedgers[0].period = "2021-2022";

      // SALE LOAD

      transactionLedgers[1].amc_code = amc_code;
      transactionLedgers[1].fund_code = fields["fund_code"];
      transactionLedgers[1].account_number = fields["account_no"];
      transactionLedgers[1].account_title = fields["account_title"];
      transactionLedgers[1].bank_code = fields["bank_code"];
      transactionLedgers[1].branch = fields["branch"];
      transactionLedgers[1].balance = "";
      transactionLedgers[1].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[1].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[1].counter_bank = fields["counter_bank"];
      transactionLedgers[1].counter_branch = fields["counter_branch"];
      // transactionLedgers[1].desc='Sale Load & '+fields['detail']+' & '+fields['folio_no'];
      transactionLedgers[1].description =
        "Sale Load & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[1].instruction_date = fields["instruction_date"];
      transactionLedgers[1].instrument_by = fields["instrument_by"];
      transactionLedgers[1].instrument_date = fields["instrument_date"];
      transactionLedgers[1].instrument_no = fields["instrument_no"];
      transactionLedgers[1].instrument_type = fields["instrument_type"];
      transactionLedgers[1].realized = fields["realized"];
      transactionLedgers[1].realized_by = fields["realized_by"];
      transactionLedgers[1].realized_date = fields["realized_date"];
      transactionLedgers[1].entered_by = "";
      transactionLedgers[1].evidence = "";

      transactionLedgers[1].debit_amount = "0";
      transactionLedgers[1].credit_amount = fields["sale_load"];
      transactionLedgers[1].period = "2021-2022";

      // NET AMOUNT

      transactionLedgers[2].amc_code = amc_code;
      transactionLedgers[2].fund_code = fields["fund_code"];
      transactionLedgers[2].account_number = fields["account_no"];
      transactionLedgers[2].account_title = fields["account_title"];
      transactionLedgers[2].bank_code = fields["bank_code"];
      transactionLedgers[2].branch = fields["branch"];
      transactionLedgers[2].balance = "";
      transactionLedgers[2].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[2].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[2].counter_bank = fields["counter_bank"];
      transactionLedgers[2].counter_branch = fields["counter_branch"];
      // transactionLedgers[2].desc='Net Amount & '+fields['detail']+' & '+fields['folio_no'];
      transactionLedgers[2].description =
        "Net Amount & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[2].instruction_date = fields["instruction_date"];
      transactionLedgers[2].instrument_by = fields["instrument_by"];
      transactionLedgers[2].instrument_date = fields["instrument_date"];
      transactionLedgers[2].instrument_no = fields["instrument_no"];
      transactionLedgers[2].instrument_type = fields["instrument_type"];
      transactionLedgers[2].realized = fields["realized"];
      transactionLedgers[2].realized_by = fields["realized_by"];
      transactionLedgers[2].realized_date = fields["realized_date"];
      transactionLedgers[2].entered_by = "";
      transactionLedgers[2].evidence = "";
      transactionLedgers[2].debit_amount = "0";
      transactionLedgers[2].credit_amount = fields["net_amount"];
      transactionLedgers[2].period = "2021-2022";

      return transactionLedgers;
    }

    if (headLabel == "redemptionofunits" || headLabel == "conversionout") {
      // Net Amount
      transactionLedgers[0].amc_code = amc_code;
      transactionLedgers[0].fund_code = fields["fund_code"];
      transactionLedgers[0].account_number = fields["account_no"];
      transactionLedgers[0].account_title = fields["account_title"];
      transactionLedgers[0].bank_code = fields["bank_code"];
      transactionLedgers[0].branch = fields["branch"];
      transactionLedgers[0].balance = "";
      transactionLedgers[0].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[0].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[0].counter_bank = fields["counter_bank"];
      transactionLedgers[0].counter_branch = fields["counter_branch"];

      // transactionLedgers[0].desc='Net Amount & '+fields['detail']+' & '+fields['folio_no'];
      transactionLedgers[0].description =
        "Net Amount & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[0].instruction_date = fields["instruction_date"];
      transactionLedgers[0].instrument_by = fields["instrument_by"];
      transactionLedgers[0].instrument_date = fields["instrument_date"];
      transactionLedgers[0].instrument_no = fields["instrument_no"];
      transactionLedgers[0].instrument_type = fields["instrument_type"];
      transactionLedgers[0].realized = fields["realized"];
      transactionLedgers[0].realized_by = fields["realized_by"];
      transactionLedgers[0].realized_date = fields["realized_date"];
      transactionLedgers[0].entered_by = "";
      transactionLedgers[0].evidence = "";
      transactionLedgers[0].debit_amount = "0";
      transactionLedgers[0].credit_amount = fields["net_amount"];
      transactionLedgers[0].period = "2021-2022";

      // Redemption Load
      transactionLedgers[1].amc_code = amc_code;
      transactionLedgers[1].fund_code = fields["fund_code"];
      transactionLedgers[1].account_number = fields["account_no"];
      transactionLedgers[1].account_title = fields["account_title"];
      transactionLedgers[1].bank_code = fields["bank_code"];
      transactionLedgers[1].branch = fields["branch"];
      transactionLedgers[1].balance = "";
      transactionLedgers[1].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[1].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[1].counter_bank = fields["counter_bank"];
      transactionLedgers[1].counter_branch = fields["counter_branch"];
      // transactionLedgers[1].desc='Redemption Load & '+fields['detail']+' & '+fields['folio_no'];
      transactionLedgers[1].description =
        "Redemption Load & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[1].instruction_date = fields["instruction_date"];
      transactionLedgers[1].instrument_by = fields["instrument_by"];
      transactionLedgers[1].instrument_date = fields["instrument_date"];
      transactionLedgers[1].instrument_no = fields["instrument_no"];
      transactionLedgers[1].instrument_type = fields["instrument_type"];
      transactionLedgers[1].realized = fields["realized"];
      transactionLedgers[1].realized_by = fields["realized_by"];
      transactionLedgers[1].realized_date = fields["realized_date"];
      transactionLedgers[1].entered_by = "";
      transactionLedgers[1].evidence = "";
      transactionLedgers[1].debit_amount = "0";
      transactionLedgers[1].credit_amount = fields["redemption_load"];
      transactionLedgers[1].period = "2021-2022";

      // CGT
      transactionLedgers[2].amc_code = amc_code;
      transactionLedgers[2].fund_code = fields["fund_code"];
      transactionLedgers[2].account_number = fields["account_no"];
      transactionLedgers[2].account_title = fields["account_title"];
      transactionLedgers[2].bank_code = fields["bank_code"];
      transactionLedgers[2].branch = fields["branch"];
      transactionLedgers[2].balance = "";
      transactionLedgers[2].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[2].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[2].counter_bank = fields["counter_bank"];
      transactionLedgers[2].counter_branch = fields["counter_branch"];
      // transactionLedgers[2].desc='CGT & '+fields['detail']+' & '+fields['folio_no'];
      transactionLedgers[2].description =
        "CGT & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[2].debit_amount = "0";
      transactionLedgers[2].credit_amount = fields["tax_cgt"];
      transactionLedgers[2].period = "2021-2022";

      // Zakat
      // transactionLedgers[3].amc_code=amc_code;
      // transactionLedgers[3].fund_code=fields['fund_code'];
      // transactionLedgers[3].account_number=fields['account_no'];
      // transactionLedgers[3].account_title=fields['account_title'];
      // transactionLedgers[3].bank_code=fields['bank_code'];
      // transactionLedgers[3].branch=fields['branch'];
      // transactionLedgers[3].balance='';
      // transactionLedgers[3].counter_account_number=fields['counter_account_number'];
      // transactionLedgers[3].counter_account_title=fields['counter_account_title'];
      // transactionLedgers[3].counter_bank=fields['counter_bank'];
      // transactionLedgers[3].counter_branch=fields['counter_branch'];

      // // transactionLedgers[3].desc='Net Amount & '+fields['detail']+' & '+fields['folio_no'];
      // transactionLedgers[3].description='Zakat & '+fields['detail']+' & '+fields['folio_no'];
      // transactionLedgers[3].instruction_date=fields['instruction_date'];
      // transactionLedgers[3].instrument_by=fields['instrument_by'];
      // transactionLedgers[3].instrument_date=fields['instrument_date'];
      // transactionLedgers[3].instrument_no=fields['instrument_no'];
      // transactionLedgers[3].instrument_type=fields['instrument_type'];
      // transactionLedgers[3].realized=fields['realized'];
      // transactionLedgers[3].realized_by=fields['realized_by'];
      // transactionLedgers[3].realized_date=fields['realized_date'];
      // transactionLedgers[3].entered_by='';
      // transactionLedgers[3].evidence='';
      // transactionLedgers[3].debit_amount='0';
      // transactionLedgers[3].credit_amount=fields['net_amount'];
      // transactionLedgers[3].period='2021-2022';

      // Gross Amount
      transactionLedgers[3].amc_code = amc_code;
      transactionLedgers[3].fund_code = fields["fund_code"];
      transactionLedgers[3].account_number = fields["account_no"];
      transactionLedgers[3].account_title = fields["account_title"];
      transactionLedgers[3].bank_code = fields["bank_code"];
      transactionLedgers[3].branch = fields["branch"];
      transactionLedgers[3].balance = "";
      transactionLedgers[3].counter_account_number =
        fields["counter_account_number"];
      transactionLedgers[3].counter_account_title =
        fields["counter_account_title"];
      transactionLedgers[3].counter_bank = fields["counter_bank"];
      transactionLedgers[3].counter_branch = fields["counter_branch"];
      // transactionLedgers[3].desc='Gross Amount & '+fields['detail']+' & '+fields['folio_no'];
      transactionLedgers[3].description =
        "Gross Amount & " + fields["detail"] + " & " + fields["folio_no"];
      transactionLedgers[3].instruction_date = fields["instruction_date"];
      transactionLedgers[3].instrument_by = fields["instrument_by"];
      transactionLedgers[3].instrument_date = fields["instrument_date"];
      transactionLedgers[3].instrument_no = fields["instrument_no"];
      transactionLedgers[3].instrument_type = fields["instrument_type"];
      transactionLedgers[3].realized = fields["realized"];
      transactionLedgers[3].realized_by = fields["realized_by"];
      transactionLedgers[3].realized_date = fields["realized_date"];
      transactionLedgers[3].entered_by = "";
      transactionLedgers[3].evidence = "";
      transactionLedgers[3].child_family_code = 0;
      transactionLedgers[3].child_family_name = "";
      transactionLedgers[3].gl_code = +ledgerAccountCode;
      transactionLedgers[3].gl_name = ledgerAccountName;
      transactionLedgers[3].debit_amount = fields["gross_amount"];
      transactionLedgers[3].credit_amount = "0";
      transactionLedgers[3].period = "2021-2022";
      return transactionLedgers;
    }
  };

  //Create Transaction Api Call
  //Ahsan Create Transaction Code
  const createTransaction = async () => {
    if (!chechFieldDisplay(headLabel, "nav")) {
      fields["nav"] = "";
    }
    if (headLabel === "unitconversion") {
      let conversionAmount = +fields["net_amount"] / +unitNav;
      fields["total_holding"] = conversionAmount.toFixed(2);
    } else {
      fields["counter_type"] =
        counterType !== "" ? counterType : counterAccounter;
    }

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (
        headLabel === "fundtransfer" &&
        fields["counter_account_type"] === fields["fund_account"]
      ) {
        toast.error("Both account should be not same");
      } else {
        setLoading(true);
        // if (flag) {
        //   if(headLabel==='saleofunit' || headLabel==='redemptionofunits'){
        //     fields['counter_account_type']=fields['counter_account_type'].replaceAll(fields['amc_code']+'-','')
        //     fields['counter_account_type']=fields['amc_code']+'-'+fields['counter_account_type'];
        //   }
        //   if(headLabel==='conversionin' || headLabel==='conversionout' || headLabel==='unitconversion'){
        //     fields['folio_no']=fields['folio_no'].replaceAll(fields['amc_code']+'-','');
        //     fields['folio_no']=fields['amc_code']+'-'+fields['folio_no'];
        //   }
        //   if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0 || parseFloat(fields['units'])<0 || parseFloat(fields['remain_holding'])<0 ){
        //     toast.error("Amounts or units or remaining holding are negative");
        //   }else if((headLabel==='redemptionofunits' && +fields['units']===0) || (headLabel==='conversionout' && +fields['units']===0) || (headLabel==='saleofunit' && +fields['units']===0)
        //   || (headLabel==='conversionin' && +fields['units']===0)){
        //     toast.error(" Units cannot be zero.");
        //   }
        //   else{
        //   try {
        //     const response = await EditTransaction(fields); // for Edit existed transaction
        //     if(response.data.status===400){
        //       setViewBankBalanceModal(true);
        //       toast.error(response.data.message);
        //     }else if(response.data.status===417){
        //       toast.warning(response.data.message);
        //     }else{
        //       setUnderStand(false);
        //       setViewBankBalanceModal(false);
        //       toast.success(response.data.message);
        //       setTimeout(() => {
        //         history.replace(`/admin/create-transaction`);
        //       }, 3000);
        //   }
        //   } catch (error:any) {
        //     setLoading(false);
        //     if(error.response!==undefined){
        //       toast.error(error.response.data.message);
        //       }else{
        //         toast.error('Something went wrong!');
        //       }
        //   }
        // }
        // } else {
        if (
          parseFloat(fields["net_amount"]) < 0 ||
          parseFloat(fields["gross_amount"]) < 0 ||
          parseFloat(fields["units"]) < 0 ||
          parseFloat(fields["remain_holding"]) < 0
        ) {
          toast.error("Amounts or units or remaining holding are negative");
        } else if (
          (headLabel === "redemptionofunits" && fields["units"] === "0") ||
          (headLabel === "conversionout" && fields["units"] === "0") ||
          (headLabel === "saleofunit" &&
            (fields["units"] === "0" || fields["units"] === "0.0000")) ||
          (headLabel === "conversionin" &&
            (fields["units"] === "0" || fields["units"] === "0.0000"))
        ) {
          toast.error(" Units cannot be zero.");
        } else {
          if (headLabel === "saleofunit" || headLabel === "redemptionofunits") {
            fields["counter_account_type"] = fields[
              "counter_account_type"
            ].replaceAll(fields["amc_code"] + "-", "");
            fields["counter_account_type"] =
              fields["amc_code"] + "-" + fields["counter_account_type"];
          }
          if (
            headLabel === "conversionin" ||
            headLabel === "conversionout" ||
            headLabel === "unitconversion"
          ) {
            fields["folio_no"] = fields["folio_no"].replaceAll(
              fields["amc_code"] + "-",
              ""
            );
            fields["folio_no"] = fields["amc_code"] + "-" + fields["folio_no"];
          }
          if (+balance != 0) {
            toast.error(
              `Balance should be 0, Other wise you can't create transaction`
            );
            return;
          }
          let finalLedgers = createLegders();
          fields.ledgers = JSON.stringify(finalLedgers);

          try {
            const response = await addMegaTransaction(fields); // for add new transaction
            if (response.data.status === 400) {
              setViewBankBalanceModal(true);
              toast.warning(response.data.message);
            } else if (response.data.status === 417) {
              toast.warning(response.data.message);
            } else {
              setUnderStand(false);
              setViewBankBalanceModal(false);
              sessionStorage.setItem("last_id", response.data.txn_id);
              toast.success(
                `${response.data.message} - ${response.data.txn_id}`
              );
              // toast.success(response.data.message);
              setTimeout(() => {
                history.replace(`/admin/create-transaction`);
              }, 3000);
            }
          } catch (error: any) {
            setLoading(false);
            if (error.response !== undefined) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Something went wrong!");
            }
          }
        }
        // }
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error("Kindly Fill All the Fields");
    }
  };

  //Creating Ledgers for credit/debit
  const handleCreate = async () => {
    setLoading(true);
    if (debitFormData.length === 0) {
      setLoading(false);

      return toast.error("Debit data can't be empty!");
    }
    if (creditFormData.length === 0) {
      setLoading(false);

      return toast.error("Credit data can't be empty!");
    }
    if (fields["fund_code"].length === 0) {
      setLoading(false);

      return toast.error("Fund Code can't be empty!");
    }
    if (fields["instruction_date"].length === 0) {
      setLoading(false);

      return toast.error("Instruction Date can't be empty!");
    }
    if (fields["settlement_date"].length === 0) {
      setLoading(false);

      return toast.error("Instruction Date can't be empty!");
    }
    if (fields["mode_of_payment"].length === 0) {
      setLoading(false);

      return toast.error("Mode of payment can't be empty!");
    }
    if (fields["instrument_type"].length === 0) {
      setLoading(false);

      return toast.error("Select instrument type");
    } else {
      let send = await getSendingLedgers();

      // await debitFormData.map(async (item, index) => {
      //   let ledger_code = item.ledger.split("-")[0].replace(/\s+/g, "");
      //   const ledgerEntitiesDebit = await getLedgerInfo(email, ledger_code);

      //  await setSendingLedgers((prevState) => [
      //     ...prevState,
      //     {
      //       account_number: "",
      //       account_title: "",
      //       amc_code: amc_code,
      //       balance: "",
      //       bank_code: "",
      //       branch: "",
      //       child_family_code: ledgerEntitiesDebit.child_family_code,
      //       child_family_name: ledgerEntitiesDebit.child_family_name,
      //       counter_account_number: "",
      //       counter_account_title: "",
      //       counter_bank: "",
      //       counter_branch: "",
      //       created_at: "",
      //       credit_amount: "0",
      //       debit_amount: item.amount,
      //       description: item.description,
      //       doc_type: "",
      //       entered_by: "",
      //       evidence: "",
      //       family_code: ledgerEntitiesDebit.family_code,
      //       family_name: ledgerEntitiesDebit.family_name,
      //       fund_code: fields["fund_code"],
      //       gl_code: ledgerEntitiesDebit.gl_code,
      //       gl_name: ledgerEntitiesDebit.gl_name,
      //       instruction_date: fields["instruction_date"],
      //       instrument_by: "",
      //       instrument_date: fields["instrument_date"],
      //       instrument_no: fields["instrument_no"],
      //       instrument_type: fields["instrument_type"],
      //       main_family_code: ledgerEntitiesDebit.main_family_code,
      //       main_family_name: ledgerEntitiesDebit.main_family_name,
      //       realized: "true",
      //       realized_by: "",
      //       realized_date: "",
      //       sub_family_code: ledgerEntitiesDebit.sub_family_code,
      //       sub_family_name: ledgerEntitiesDebit.sub_family_name,
      //     },
      //   ]);
      // });

      // await creditFormData.map(async (item, index) => {
      //   let ledger_code = item.ledger.split("-")[0].replace(/\s+/g, "");
      //   const ledgerEntitiesCredit = await getLedgerInfo(email, ledger_code);

      //  await setSendingLedgers((prevState) => [
      //     ...prevState,
      //     {
      //       account_number: "",
      //       account_title: "",
      //       amc_code: amc_code,
      //       balance: "",
      //       bank_code: "",
      //       branch: "",
      //       child_family_code: ledgerEntitiesCredit.child_family_code,
      //       child_family_name: ledgerEntitiesCredit.child_family_name,
      //       counter_account_number: "",
      //       counter_account_title: "",
      //       counter_bank: "",
      //       counter_branch: "",
      //       created_at: "",
      //       credit_amount: item.amount,
      //       debit_amount: "0",
      //       description: item.description,
      //       doc_type: "",
      //       entered_by: "",
      //       evidence: "",
      //       family_code: ledgerEntitiesCredit.family_code,
      //       family_name: ledgerEntitiesCredit.family_name,
      //       fund_code: fields["fund_code"],
      //       gl_code: ledgerEntitiesCredit.gl_code,
      //       gl_name: ledgerEntitiesCredit.gl_name,
      //       instruction_date: fields["instruction_date"],
      //       instrument_by: "",
      //       instrument_date: fields["instrument_date"],
      //       instrument_no: fields["instrument_no"],
      //       instrument_type: fields["instrument_type"],
      //       main_family_code: ledgerEntitiesCredit.main_family_code,
      //       main_family_name: ledgerEntitiesCredit.main_family_name,
      //       realized: "true",
      //       realized_by: "",
      //       realized_date: "",
      //       sub_family_code: ledgerEntitiesCredit.sub_family_code,
      //       sub_family_name: ledgerEntitiesCredit.sub_family_name,
      //     },
      //   ]);
      // });

      if (send) {
        fields.ledgers = JSON.stringify(sendingLedgers);
        if (fields.ledgers.length > 1) {
          console.log("Sending Ledgers => ", sendingLedgers);
          console.log("Fields Ledgers => ", fields.ledgers);
          try {
            const response = await addMegaTransaction(fields); // 'for add new transaction

            if (response.data.status === 400) {
              toast.warning(response.data.message);
              setSendingLedgers([]);
            } else if (response.data.status === 417) {
              toast.warning(response.data.message);
              setSendingLedgers([]);
            } else {
              console.log(response);
              toast.success(`Data added. ID: ${response.data.txn_id}`);
              setSendingLedgers([]);

              setTimeout(() => {
                toast.success("");
                history.push("/admin/create-transaction");
              }, 1000);
              // toast.success(response.data.message);
            }
          } catch (error: any) {
            setLoading(false);
            setSendingLedgers([]);
          }

          setLoading(false);
        } else {
          toast.error("Ledgers Empty");
        }
      }
    }

    // history.push("/admin/create-transaction");
  };

  const getSendingLedgers = async () => {
    let creditObject = {};
    let debitObject = {};

    await debitFormData.map(async (item, index) => {
      let ledger_code = item.ledger.split("-")[0].replace(/\s+/g, "");
      let ledgerEntitiesDebit = await getLedgerInfo(email, ledger_code);

      debitObject = {
        account_number: "",
        account_title: "",
        amc_code: amc_code,
        balance: "",
        bank_code: "",
        branch: "",
        child_family_code: ledgerEntitiesDebit.child_family_code,
        child_family_name: ledgerEntitiesDebit.child_family_name,
        counter_account_number: "",
        counter_account_title: "",
        counter_bank: "",
        counter_branch: "",
        created_at: "",
        credit_amount: "0",
        debit_amount: item.amount,
        description: item.description,
        doc_type: "",
        entered_by: "",
        evidence: "",
        family_code: ledgerEntitiesDebit.family_code,
        family_name: ledgerEntitiesDebit.family_name,
        fund_code: fields["fund_code"],
        gl_code: ledgerEntitiesDebit.gl_code,
        gl_name: ledgerEntitiesDebit.gl_name,
        instruction_date: fields["instruction_date"],
        instrument_by: "",
        instrument_date: fields["instrument_date"],
        instrument_no: fields["instrument_no"],
        instrument_type: fields["instrument_type"],
        main_family_code: ledgerEntitiesDebit.main_family_code,
        main_family_name: ledgerEntitiesDebit.main_family_name,
        realized: "true",
        realized_by: "",
        realized_date: "",
        sub_family_code: ledgerEntitiesDebit.sub_family_code,
        sub_family_name: ledgerEntitiesDebit.sub_family_name,
      };

      if (sendingLedgers.length > 0) {
        console.log(`In debit form ${index} =>`, sendingLedgers);

        return new Promise((resolve) => {
          sendingLedgers = [...sendingLedgers, debitObject];
          setTimeout(() => {
            resolve("resolved");
          }, 2000);
        });
        // return setSendingLedgers((prevState) => [...prevState, debitObject]);
      } else {
        console.log(`In debit form ${index} =>`, sendingLedgers);

        return new Promise((resolve) => {
          sendingLedgers = [debitObject];
          setTimeout(() => {
            resolve("resolved");
          }, 2000);
        });
        // return setSendingLedgers([debitObject]);
      }
    });

    await creditFormData.map(async (item, index) => {
      let ledger_code = item.ledger.split("-")[0].replace(/\s+/g, "");
      let ledgerEntitiesCredit = await getLedgerInfo(email, ledger_code);

      creditObject = {
        account_number: "",
        account_title: "",
        amc_code: amc_code,
        balance: "",
        bank_code: "",
        branch: "",
        child_family_code: ledgerEntitiesCredit.child_family_code,
        child_family_name: ledgerEntitiesCredit.child_family_name,
        counter_account_number: "",
        counter_account_title: "",
        counter_bank: "",
        counter_branch: "",
        created_at: "",
        credit_amount: item.amount,
        debit_amount: "0",
        description: item.description,
        doc_type: "",
        entered_by: "",
        evidence: "",
        family_code: ledgerEntitiesCredit.family_code,
        family_name: ledgerEntitiesCredit.family_name,
        fund_code: fields["fund_code"],
        gl_code: ledgerEntitiesCredit.gl_code,
        gl_name: ledgerEntitiesCredit.gl_name,
        instruction_date: fields["instruction_date"],
        instrument_by: "",
        instrument_date: fields["instrument_date"],
        instrument_no: fields["instrument_no"],
        instrument_type: fields["instrument_type"],
        main_family_code: ledgerEntitiesCredit.main_family_code,
        main_family_name: ledgerEntitiesCredit.main_family_name,
        realized: "true",
        realized_by: "",
        realized_date: "",
        sub_family_code: ledgerEntitiesCredit.sub_family_code,
        sub_family_name: ledgerEntitiesCredit.sub_family_name,
      };

      if (sendingLedgers.length > 0) {
        console.log(`In credit form ${index} =>`, sendingLedgers);

        return new Promise((resolve) => {
          sendingLedgers = [...sendingLedgers, creditObject];

          setTimeout(() => {
            resolve("resolved");
          }, 2000);
        });
        // return setSendingLedgers((prevState) => [...prevState, creditObject]);
      } else {
        console.log(`In credit form ${index} =>`, sendingLedgers);

        return new Promise((resolve) => {
          sendingLedgers = [creditObject];

          setTimeout(() => {
            resolve("resolved");
          }, 2000);
        });
        // return setSendingLedgers([creditObject]);
      }
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 10000);
    });
  };

  const getLedgerInfo = async (email, ledger_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code);
    return result.data.data;
  };

  const [accTilteName, setAccTiltleName] = useState("");
  const [bankBalance, setBankBalance] = useState("");
  // handle data for Fund Account Dropdown selection
  // const setFundAccountFieldsData = (selected) => {
  //   if (selected === 'Other') {
  //     //incase if other option is selected then user entrer data manually
  //     setAccTiltleName(selected);
  //     fields['account_title'] = '';
  //     fields['account_no'] = '';
  //     fields['bank'] = '';
  //     fields['branch'] = '';
  //     fields['fund_account'] = 'other';
  //     setBankBalance("");
  //     setFields(fields);
  //   } else {
  //     accountNoData.map((item, index) => {
  //       if (item.account_code === selected) {
  //         getBranchByName(item.bank_name);
  //         setAccTiltleName(item.account_title);
  //         fields['fund_account'] = item.account_code;
  //         fields['account_title'] = item.account_title;
  //         fields['account_no'] = item.account_no;
  //         fields['bank'] = item.bank_name;
  //         fields['branch'] = item.branch_name;
  //         setBankBalance(item.balance_amount);
  //         setFields(fields);
  //       }
  //     });
  //   }
  // };

  const setFundAccountFieldsData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["account_title"] = "";
      fields["account_no"] = "";
      fields["bank"] = "";
      fields["branch"] = "";
      fields["fund_account"] = "other";
      setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          // fields['fund_account'] = item.account_code;
          // fields['account_title'] = item.account_title;
          // fields['account_no'] = item.account_no;
          // fields['bank'] = item.bank;
          // fields['branch'] = item.branch;
          // setFields(fields);
          setFields({
            ...fields,
            fund_account: selected,
            account_title: item.account_title,
            account_no: item.account_no,
            bank: item.bank,
            branch: item.branch,
          });
          setLegderAccountCode(item.code.toString());
          setLegderAccountName(item.bank.toString());
        }
      });
    }
  };

  // handle data for Fund Account Dropdown selection for unit conversion
  const setFundAccountDataCounter = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      allCounterFundsAccounts.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields["counter_account_type"] = item.account_code;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.account_no;
          fields["counter_bank"] = item.bank_name;
          fields["counter_branch"] = item.branch_name;
          setFields(fields);
        }
      });
    }
  };

  const setCounterFieldsData = (selected, unitHolderdata) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["unitholder_name"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      unitHolderdata.map((item, index) => {
        //map from unitHolder data
        if (item.folio_no === selected) {
          let code = selected.replace("UNITHOLDER_", "").split("-")[0];
          setAccTiltleName(item.folio_no);
          getCounterBranchByName(item.bank_name);
          // fields['counter_account_type'] = item.folio_no.replace('UNITHOLDER_','');
          fields["counter_account_type"] = item.folio_no.replace(
            "UNITHOLDER_" + code + "-".toString(),
            ""
          );
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.account_no;
          fields["counter_bank"] = item.bank_name;
          fields["counter_branch"] = item.branch_name;
          fields["unitholder_name"] = item.name;
          // fields['current_holding'] = item.balance_unit;
          fields["folio_no"] = item.folio_no.replace("UNITHOLDER_", "");
          setFields(fields);
          if (
            item.balance_unit !== "" &&
            item.balance_unit !== "[]" &&
            item.balance_unit !== undefined
          ) {
            let unitBlance = JSON.parse(item.balance_unit);
            if (unitBlance && typeof unitBlance !== "number") {
              const temp = unitBlance.filter((element) => {
                if (element.fund_code.trim() === fields["fund_code"]) {
                  return element;
                }
              });

              if (temp.length > 0) {
                fields["current_holding"] =
                  temp[0].fund_unit === "NaN"
                    ? "0"
                    : parseFloat(temp[0].fund_unit.toString().trim()).toFixed(
                        4
                      );
              } else {
                if (headLabel !== "funddividendpayout") {
                  // toast.error('No Units of selected Fund')
                }
                fields["current_holding"] = "0.00";
                setAccTiltleName(selected);
              }
            }
          } else {
            //empty
            fields["current_holding"] = "0.00";
            if (headLabel !== "funddividendpayout") {
              // toast.error('No Units of selected Fund');
            }
            setAccTiltleName(selected);
          }
        }
      });
      setFields(fields);
    }
  };

  const setFolioNoData = (selected, dataUnitHolder) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== "Other") {
      // if user select any account from dropdown then data fillout
      dataUnitHolder.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            fields["unitholder_name"] = item.name;
            if (
              item.balance_unit !== "" &&
              item.balance_unit !== "[]" &&
              item.balance_unit !== undefined
            ) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== "number") {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === fields["fund_code"]) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(temp[0].fund_unit.toString());
                  fields["current_holding"] = parseFloat(
                    temp[0].fund_unit.toString().trim()
                  ).toFixed(4);
                  setFields(fields);
                } else {
                  toast.error("Zero Units of selected Fund", {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  fields["current_holding"] = "0.00";
                  setFields(fields);
                }
              }
            } else {
              //empty
              toast.error("Zero Units of selected Fund", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              fields["current_holding"] = "0.00";
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      fields["current_holding"] = "0.00";
      setAccTiltleName(`${count}-${selected}`);
    }
  };

  const clearFundAccountFields = () => {
    setAccTiltleName("Unset");
    fields["fund_account"] = "";
    fields["account_title"] = "";
    fields["account_no"] = "";
    fields["bank"] = "";
    fields["branch"] = "";
    fields["nav"] = "";
    setFields(fields);
  };

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + "changes");
          fields["counter_account_type"] = item.company_code;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.iban;
          fields["counter_bank"] = item.bank_name;
          fields["counter_branch"] = item.branch;
          fields["sales_tax_collector"] = item.tax_collected;
          setFields(fields);
        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === "Other") {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields["counter_account_type"] = "Other";
      fields["counter_account_title"] = "";
      fields["counter_account_number"] = "";
      fields["counter_bank"] = "";
      fields["counter_branch"] = "";
      fields["current_holding"] = "";
      fields["folio_no"] = "";
      setFields(fields);
    } else {
      amcdata.map((item, index) => {
        if (item.account_no === selected) {
          setAccTiltleName(item.account_no + "changes");
          getCounterBranchByName(item.bank);
          fields["counter_account_type"] = item.account_no;
          fields["counter_account_title"] = item.account_title;
          fields["counter_account_number"] = item.account_no;
          fields["counter_bank"] = item.bank;
          fields["counter_branch"] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields["current_holding"] + "change");
    fields["counter_account_type"] = "";
    fields["counter_account_title"] = "";
    fields["counter_account_number"] = "";
    fields["counter_bank"] = "";
    fields["counter_branch"] = "";
    fields["current_holding"] = "";
    fields["folio_no"] = "";
    setFields(fields);
  };

  //Select data if Counter Account is Fund account
  const setFundData = (selected) => {
    counterFundAccount.map((item, index) => {
      if (item.account_no === selected) {
        setAccTiltleName(item.bank_name + "changes");
        fields["counter_account_type"] = item.account_no;
        fields["counter_account_title"] = item.account_title;
        fields["counter_account_number"] = item.account_no;
        fields["counter_bank"] = item.bank_name;
        fields["counter_branch"] = item.branch_name;
        fields["current_holding"] = parseFloat(item.balance_amount).toFixed(4);
        setFields(fields);
      }
    });
  };

  const [grossAmountInWords, setGrossAmountInWords] = useState("");
  const [netAmountInWords, setNetAmountInWords] = useState("");
  const [netAmountSeparator, setNetAmountSeparator] = useState("");
  const [grossAmountSeparator, setGrossAmountSeparator] = useState("");
  const [unitsSeparator, setUnitsSeparator] = useState("");
  const [unitsInWords, setUnitsInWord] = useState("");

  const setTotalRemainHolding = (amount, type) => {
    if (type === "NetAmount") {
      if (headLabel === "saleofunit" || headLabel === "conversionin") {
        if (amount.length === 0) {
          setFields({
            ...fields,
            net_amount: amount,
          });
        } else {
          setFields({
            ...fields,
            net_amount: amount,
          });
          if (headLabel == "saleofunit" || headLabel == "conversionin") {
            // setTotalDebit(amount);
            let totalcredit = (+fields["sale_load"] + +amount).toString();
            setTotalCredit(totalcredit);
            let balance = (+totalDebit - +totalcredit).toString();
            setBalance(balance);
          }
        }
      } else {
        if (amount.length === 0) {
          setFields({
            ...fields,
            net_amount: amount,
          });
        } else {
          setFields({
            ...fields,
            net_amount: amount,
          });
          if (
            headLabel == "redemptionofunits" ||
            headLabel == "conversionout"
          ) {
            // setTotalDebit(fields['gross_amount']);
            let totalcredit = (
              +fields["redemption_load"] +
              +fields["tax_cgt"] +
              +fields["zakat_amount"] +
              +amount
            ).toString();
            setTotalCredit(totalcredit);
            let balance = (+fields["gross_amount"] - +totalcredit).toString();
            setBalance(balance);
          }
        }
      }
    } else if (type === "GrossAmount") {
      let unit =
        parseFloat(fields["applicable_offer_price"]) <= 0
          ? 0
          : parseFloat(amount) / parseFloat(fields["applicable_offer_price"]);
      let a = unit.toFixed(4).toString();
      if (headLabel === "saleofunit" || headLabel === "conversionin") {
        let saleload = (
          parseFloat(fields["load_per_unit"]) * parseFloat(a)
        ).toFixed(2);
        let netamount = (
          parseFloat(amount) - parseFloat(saleload.replace("", "0"))
        ).toFixed(2);
        let remainingholding =
          parseFloat(fields["current_holding"]) + parseFloat(a);
        let remainholding = remainingholding.toFixed(4).toString();
        if (amount.length === 0) {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            sale_load: "0",
            units: "0.0000",
            total_holding: fields["current_holding"],
          });
          setNetAmountSeparator("");
          setNetAmountInWords("");
          setGrossAmountSeparator("");
          setGrossAmountInWords("");
          setUnitsSeparator("");
          setUnitsInWord("");
          if (headLabel == "saleofunit" || headLabel == "conversionin") {
            setTotalDebit("0");
            setTotalCredit("0");
            setBalance("0");
          }
        } else {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: netamount,
            sale_load: saleload,
            total_charges: "0",
            units: a,
            total_holding: remainholding,
          });
          let inwordUnits = inWords(a);
          setUnitsInWord(inwordUnits);
          let inwordNetAmount = inWords(netamount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          let unitSeparator = numberWithCommas(a);
          setUnitsSeparator(unitSeparator);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(netamount);
          setNetAmountSeparator(netAmountSeparator);

          // accounting
          if (headLabel == "saleofunit" || headLabel == "conversionin") {
            setTotalDebit(amount);
            let totalcredit = (+saleload + +netamount).toString();
            setTotalCredit(totalcredit);
            setBalance((+amount - +totalcredit).toString());
          }
        }
      } else if (
        headLabel === "conversionout" ||
        headLabel === "redemptionofunits" ||
        headLabel === "unitconversion"
      ) {
        let redemptionload = (
          parseFloat(fields["load_per_unit"]) * parseFloat(a)
        ).toFixed(2);
        let remainingholding =
          parseFloat(fields["current_holding"]) - parseFloat(a);
        let remainholding = remainingholding.toFixed(4).toString();
        let net_amount = (
          amount -
          parseFloat(redemptionload.replace("", "0")) -
          parseFloat(fields["tax_cgt"].replace("", "0")) -
          parseFloat(fields["zakat_amount"] || "0")
        ).toFixed(2);
        if (amount.length === 0) {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            redemption_load: "0",
            units: "0.0000",
            remain_holding: fields["current_holding"],
          });
          setNetAmountSeparator("");
          setNetAmountInWords("");
          setGrossAmountSeparator("");
          setGrossAmountInWords("");
          setUnitsSeparator("");
          if (
            headLabel == "redemptionofunits" ||
            headLabel == "conversionout"
          ) {
            setTotalDebit("0");
            setTotalCredit("0");
            setBalance("0");
          }
        } else {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: net_amount,
            redemption_load: redemptionload,
            total_charges: "0",
            units: a,
            remain_holding: remainholding,
          });
          if (
            headLabel == "redemptionofunits" ||
            headLabel == "conversionout"
          ) {
            setTotalDebit(amount);
            let totalcredit = (
              +redemptionload +
              +fields["tax_cgt"] +
              +fields["zakat_amount"] +
              +net_amount
            ).toString();
            setTotalCredit(totalcredit);
            let balance = (+amount - +totalcredit).toString();
            setBalance(balance);
          }
          let inwordNetAmount = inWords(net_amount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          let unitSeparator = numberWithCommas(a);
          setUnitsSeparator(unitSeparator);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(net_amount);
          setNetAmountSeparator(netAmountSeparator);
        }
      } else {
        if (amount.length === 0) {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
          });
          setNetAmountSeparator("");
          setNetAmountInWords("");
          setGrossAmountSeparator("");
          setGrossAmountInWords("");
        } else {
          setFields({
            ...fields,
            gross_amount: amount,
            net_amount: amount,
            total_charges: "0",
          });
          let inwordNetAmount = inWords(amount);
          setNetAmountInWords(inwordNetAmount);
          let inwordGrossAmount = inWords(amount);
          setGrossAmountInWords(inwordGrossAmount);
          let grossAmountSeparator = numberWithCommas(amount);
          setGrossAmountSeparator(grossAmountSeparator);
          let netAmountSeparator = numberWithCommas(amount);
          setNetAmountSeparator(netAmountSeparator);
        }
      }
    }
  };

  //set Current holding

  const setCurentHolding = (unit_value) => {
    //case to handle
    let total_Hold,
      gross_am = 0;
    let netamount = 0;
    if (fields.payment_type === "Receipt") {
      total_Hold = +fields["current_holding"] + +unit_value;
      gross_am = +unit_value * +fields["nav"];
      if (chechFieldDisplay(headLabel, "total_holding")) {
        fields["total_holding"] = parseFloat(total_Hold).toFixed(4);
      }
      if (chechFieldDisplay(headLabel, "nav")) {
        fields["gross_amount"] = gross_am.toFixed(2);
        fields["net_amount"] = gross_am.toFixed(2);
      }
      setAccTiltleName(
        (
          +fields["gross_amount"] +
          +fields["total_holding"] * +unit_value
        ).toString()
      );
    } else if (
      fields.payment_type === "Payment" ||
      headLabel === "unitconversion"
    ) {
      total_Hold = +fields["current_holding"] - +unit_value;
      gross_am = +unit_value * +fields["nav"];
      let red_load =
        unit_value *
        (+fields["nav"] - +fields["offer_price"]) *
        (1 - +fields["percentage_of_discount"] / 100);
      netamount =
        gross_am - red_load ||
        0 - parseFloat(fields["tax_cgt"].replace("", "0"));

      if (chechFieldDisplay(headLabel, "remain_holding")) {
        fields["remain_holding"] = parseFloat(total_Hold).toFixed(4);
      }
      if (chechFieldDisplay(headLabel, "nav")) {
        fields["gross_amount"] = gross_am.toFixed(2);
        fields["redemption_load"] = red_load.toFixed(2);
        fields["net_amount"] = netamount.toFixed(2);

        if (unit_value !== "") {
          let a = inWords(fields["gross_amount"]);
          let b = inWords(fields["net_amount"]);
          // let c = inWords(unit_value);
          setGrossAmountInWords(a);
          setNetAmountInWords(b);
          // setUnitsInWord(c);
          //// separator
          let unitSepa = numberWithCommas(unit_value);
          let grossAmountSepa = numberWithCommas(fields["gross_amount"]);
          let netAmountSepa = numberWithCommas(fields["net_amount"]);
          setUnitsSeparator(unitSepa);
          setGrossAmountSeparator(grossAmountSepa);
          setNetAmountSeparator(netAmountSepa);
          if (
            headLabel == "redemptionofunits" ||
            headLabel == "conversionout"
          ) {
            setTotalDebit(fields["gross_amount"]);
            let totalcredit = (
              +fields["redemption_load"] +
              +fields["tax_cgt"] +
              +fields["zakat_amount"] +
              +fields["net_amount"]
            ).toString();
            setTotalCredit(totalcredit);
            let balance = (+fields["gross_amount"] - +totalcredit).toString();
            setBalance(balance);
          }
        } else {
          setGrossAmountInWords("");
          setNetAmountInWords("");
          setGrossAmountSeparator("");
          setNetAmountSeparator("");
          setUnitsSeparator("");
          //setUnitsInWord('');
        }
      }
      setAccTiltleName(
        (
          +fields["total_holding"] / +fields["gross_amount"] +
          +unit_value
        ).toString()
      );
    } else {
    }
  };

  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === "") || value.substring(0, 1) === "-") {
      return "required-border";
    } else {
      return "";
    }
  };
  //

  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false);
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input
                      type="number"
                      value={bankBalance || "0"}
                      readOnly
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields["detail"]}
                      className={"form-control w-100 "}
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setUnderStand(!underStand);
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: "Yes",
                          });
                          createTransaction();
                        }}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  let [currentHolding, setCurrentHolding] = useState("");

  const setFolioNoDataForStep2 = (selected, funCode) => {
    count = count + 1;
    setAccTiltleName(`${selected}-${count}`);
    if (selected !== "Other") {
      // if user select any account from dropdown then data fillout
      unitHolderData.map((item, index) => {
        if (item.folio_no) {
          if (item.folio_no === selected) {
            // fields['unitholder_name'] = item.name;
            setFields({
              ...fields,
              unitholder_name: item.name,
            });
            if (
              item.balance_unit !== "" &&
              item.balance_unit !== "[]" &&
              item.balance_unit !== undefined
            ) {
              let unitBlance = JSON.parse(item.balance_unit);
              if (unitBlance && typeof unitBlance !== "number") {
                const temp = unitBlance.filter((element) => {
                  if (element.fund_code.trim() === funCode) {
                    return element;
                  }
                });

                if (temp.length > 0) {
                  setAccTiltleName(
                    temp[0].fund_unit.toString().trim().toString()
                  );
                  setFields({
                    ...fields,
                    current_holding: parseFloat(
                      temp[0].fund_unit.toString().trim()
                    ).toFixed(4),
                  });
                  currentHolding = parseFloat(
                    temp[0].fund_unit.toString().trim()
                  ).toFixed(4);
                  setCurrentHolding(currentHolding);
                } else {
                  toast.error("Zero Units of selected Fund", {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                  });
                  setAccTiltleName(`${temp.length + index}`);
                  setFields({
                    ...fields,
                    current_holding: "0.00",
                  });
                  // setCurrentHolding('0.00');
                  currentHolding = "0.00";
                  setCurrentHolding(currentHolding);
                }
              }
            } else {
              //empty
              toast.error("Zero Units of selected Fund", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
              });
              // fields['current_holding'] = '0.00';
              setFields({
                ...fields,
                current_holding: "0.00",
              });
              currentHolding = "0.00";
              setCurrentHolding(currentHolding);
              count = count + 1;
              setAccTiltleName(`${selected}-${count}-${selected}`);
            }
          }
        }
      });
    } else {
      // fields['current_holding'] = '0.00';
      setFields({
        ...fields,
        current_holding: "0.00",
      });
      currentHolding = "0.00";
      setCurrentHolding(currentHolding);
      setAccTiltleName(`${count}-${selected}`);
    }
  };

  const [finishFlag, setFinishFlag] = useState(false);

  const Next = async () => {
    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      setFields({
        ...fields,
        txnr_type: "conversionout",
      });
      fields["txnr_type"] = "conversionout";
      setFields(fields);
      if (
        parseFloat(fields["net_amount"]) < 0 ||
        parseFloat(fields["gross_amount"]) < 0 ||
        parseFloat(fields["units"]) < 0 ||
        parseFloat(fields["remain_holding"]) < 0
      ) {
        toast.error("Amounts or units or remain holding are negative");
      } else if (
        fields["txnr_type"] === "conversionout" &&
        +fields["units"] === 0
      ) {
        toast.error(" Units cannot be zero.");
      } else {
        sessionStorage.setItem("Txn1", JSON.stringify(fields));
        setFinishFlag(true);
        history.replace(`/admin/beta-transaction/conversionin`);
      }
    } else {
      setErrorShow(true);
      toast.error("Kindly Fill All the Fields");
    }
  };

  React.useEffect(() => {
    if (finishFlag) {
      let data: string = sessionStorage.getItem("Txn1") || "";
      let prevTxnData = JSON.parse(data);
      // for conversion of units 2nd step
      setFolioNoDataForStep2(fields["folio_no"], prevTxnData.counter_type);
      setFields({
        ...fields,
        fund_code: prevTxnData.counter_type,
        fund_account: prevTxnData.counter_account_type,
        account_title: prevTxnData.counter_account_title,
        account_no: prevTxnData.counter_account_number,
        bank: prevTxnData.counter_bank,
        branch: prevTxnData.counter_branch,
        counter_type: prevTxnData.fund_code,
        counter_account_type: prevTxnData.fund_account,
        counter_account_title: prevTxnData.account_title,
        counter_account_number: prevTxnData.account_no,
        counter_bank: prevTxnData.bank,
        counter_branch: prevTxnData.branch,
        conversion_date: prevTxnData.redemption_date,
        redemption_date: "",
        units: "",
        nav: "",
        percentage_of_discount: "",
        load_per_unit: "",
        offer_price: "",
        applicable_offer_price: "",
        current_holding: currentHolding,
        total_holding: "",
        redemption_load: "",
        tax_cgt: "",
        zakat_amount: "",
        gross_amount: prevTxnData.net_amount,
        net_amount: "",
        txnr_type: "conversionin",
        payment_type: "Receipt",
      });
      setUnitsSeparator("");
      setGrossAmountSeparator("");
      setGrossAmountInWords("");
      setNetAmountSeparator("");
      setNetAmountInWords("");
    }
  }, [finishFlag]);

  const Finish = async () => {
    setLoading(true);
    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
      if (
        parseFloat(fields["net_amount"]) < 0 ||
        parseFloat(fields["gross_amount"]) < 0 ||
        parseFloat(fields["units"]) < 0 ||
        parseFloat(fields["remain_holding"]) < 0
      ) {
        toast.error("Amounts or units or remain holding are negative");
      } else if (
        fields["txnr_type"] === "conversionin" &&
        +fields["units"] === 0
      ) {
        toast.error("Units cannot be zero.");
      } else {
        let data: string = sessionStorage.getItem("Txn1") || "";
        let txnsData: any = [];
        txnsData.push(JSON.parse(data));

        txnsData[0].folio_no = txnsData[0].folio_no.replaceAll(
          txnsData[0].amc_code + "-",
          ""
        );
        txnsData[0].folio_no =
          txnsData[0].amc_code + "-" + txnsData[0].folio_no;

        fields["folio_no"] = fields["folio_no"].replaceAll(
          fields["amc_code"] + "-",
          ""
        );
        fields["folio_no"] = fields["amc_code"] + "-" + fields["folio_no"];
        txnsData.push(fields);
        const response = await addConversionOfUnitsTransaction(email, txnsData);
        toast.success(response.data.message);
        sessionStorage.removeItem("Txn1");
        setTimeout(() => {
          history.replace(`/admin/transaction-types`);
        }, 3000);
      }
    } else {
      setErrorShow(true);
      toast.error("Kindly Fill All the Fields");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (headLabel === "saleofunit") {
      setFields({
        ...fields,
        counter_account_type: propsState.folioNo.replaceAll(
          propsState.amc_code + "-",
          ""
        ),
        folio_no: propsState.folioNo,
        unitholder_name: propsState.unitholder,
        counter_account_title: propsState.account_title,
        counter_account_number: propsState.account_no,
        counter_bank: propsState.bank,
        counter_branch: propsState.branch,
      });
    } else if (headLabel === "conversionin") {
      setFields({
        ...fields,
        // counter_account_type:'Other',
        folio_no: propsState.folioNo.replaceAll(propsState.amc_code + "-", ""),
        unitholder_name: propsState.unitholder,
        // counter_account_title: propsState.account_title,
        // counter_account_number:propsState.account_no,
        // counter_bank:propsState.bank,
        // counter_branch:propsState.branch
      });
    }
    setViewModalAddNewUnitHolder(false);
  }, [propsState]);

  const renderModalAddNewUnitHolder = () => {
    switch (viewModalAddNewHolder) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewModalAddNewUnitHolder(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewModalAddNewUnitHolder(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add UnitHolder</h6>
            </div>
            <div className="modal-body">
              <>
                <AddUnitHolder setPropState={setPropsState} />
              </>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer />
              <div className="">
                <div className="input-holder">
                  <div className="row">
                    <h4 className=" card-title">
                      <i
                        className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                        onClick={() => {
                          history.replace("/admin/create-transaction");
                        }}
                      ></i>
                      {CapTxnType(headLabel)} Transaction
                    </h4>
                  </div>

                  {headLabel === "unitconversion" && (
                    <span>
                      Step<span className="text-primary"> 1</span> of 2
                    </span>
                  )}
                  {finishFlag === true && (
                    <span>
                      Step<span className="text-primary"> 2</span> of 2
                    </span>
                  )}
                  {/* <Link
                    to="/admin/bulk-upload"
                    className="btn btn-primary btn-sm mr-3"
                    replace
                  >
                    <i className="fa fa-upload mr-2"></i> Bulk Upload
                  </Link> */}
                </div>
                <div className=" ">
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Instruction</h4>
                        </div>
                        <div className="card-body">
                          <div>
                            <div className="form-group " data-tip="Select Fund">
                              Fund Name*
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accFundLoading ? (
                                <div className="form-control w-100">
                                  <i className="fa fa-spinner fa-spin fa-1x"></i>
                                </div>
                              ) : (
                                <select
                                  className={
                                    "form-control w-100 " +
                                    ErrorBorder(fields["fund_code"])
                                  }
                                  value={fields["fund_code"]}
                                  disabled={finishFlag}
                                  onChange={(e) => {
                                    fields["fund_code"] = e.target.value;
                                    setFields(fields);

                                    clearFundAccountFields();
                                    getAccountByFundName(e.target.value);
                                    if (headLabel === "unitconversion") {
                                      let filterToAcc = allFunds.filter(
                                        (acc) =>
                                          acc.symbol_code !== e.target.value
                                      );
                                      let temp = filterToAcc.map((val) => {
                                        if (
                                          val.nature ===
                                          "Special Managed Account"
                                        ) {
                                          return {
                                            ...val,
                                            label: `${val.fund_name} (${
                                              val.symbol_code
                                            }) - ${"SMA"} `,
                                            value: val.symbol_code,
                                          };
                                        } else {
                                          return {
                                            ...val,
                                            label: `${val.fund_name} (${val.symbol_code})`,
                                            value: val.symbol_code,
                                          };
                                        }
                                      });
                                      setAllCounterFunds(temp);
                                    }
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {fundCheck
                                      ? "No Fund Found"
                                      : "Select Fund"}
                                  </option>
                                  {renderFundsDropdown()}
                                </select>
                              )}
                            </div>
                            <div className="form-group">
                              <div>Instruction Date</div>
                              <input
                                type="date"
                                value={fields["instruction_date"]}
                                readOnly
                                className={
                                  "form-control w-100 " +
                                  ErrorBorder(fields["instruction_date"])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    instruction_date: e.target.value,
                                    //realized_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group">
                              {/* <div>Execution Date</div> */}
                              <div>Settlement Date</div>
                              <input
                                type="date"
                                value={fields["execution_date"]}
                                min={disableDates}
                                readOnly={finishFlag}
                                className={
                                  "form-control w-100" +
                                  ErrorBorder(fields["execution_date"])
                                }
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    execution_date: e.target.value,
                                    settlement_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            {headLabel !== "pledgedin" &&
                              headLabel !== "pledgedout" && (
                                <div className="form-group">
                                  <div>Mode of Payment*</div>
                                  <select
                                    value={fields["mode_of_payment"]}
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(fields["mode_of_payment"])
                                    }
                                    disabled={finishFlag}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        mode_of_payment: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {" "}
                                      Select Payment
                                    </option>
                                    {renderModeOfPayments()}
                                  </select>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    {headLabel !== "pledgedin" && headLabel !== "pledgedout" && (
                      <Col className="col-md-4">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">Instrument</h4>
                          </div>
                          <div className="card-body">
                            <div>
                              <div className="form-group">
                                <div>Instrument Type</div>
                                <select
                                  className={"form-control w-100 "}
                                  value={fields["instrument_type"]}
                                  disabled={finishFlag}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      instrument_type: e.target.value,
                                    });
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {" "}
                                    Select Type
                                  </option>
                                  {renderiTypeDataDropdown()}
                                </select>
                              </div>

                              <>
                                <div className="form-group">
                                  <div>Instrument No./Reference No</div>
                                  <input
                                    defaultValue={fields["instrument_no"]}
                                    type="text"
                                    className={"form-control w-100 "}
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        instrument_no: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <div>Instrument Date / Deposit Date</div>
                                  <input
                                    type="date"
                                    defaultValue={fields["instrument_date"]}
                                    className={
                                      "form-control w-100 " +
                                      ErrorBorder(fields["instrument_date"])
                                    }
                                    onChange={(e) => {
                                      setFields({
                                        ...fields,
                                        instrument_date: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </>

                              {/* <div className="form-check">
                              <label className="form-check-label1">
                                <input type="checkbox" className="form-check-input" value="" checked={realize}
                                onChange={(e)=>{
                                  let flag='';	                                  
                                  if(realize===true){
                                    setRealize(!realize);
                                    flag='false';
                                  }else{
                                    setRealize(!realize);
                                    flag='true';
                                  }
                                  setFields({
                                    ...fields,
                                    realized: flag,
                                  });
                                }}
                                />
                                <span className="form-check-sign"><span className="check"></span></span>
                               Realized in Bank
                                </label>
                           </div>
                      
                            <div className="form-group">
                              <input
                                type="date"
                                defaultValue={fields['realized_date']}
                                className={'form-control w-100 '}
                                onChange={(e) => {
                                  let date =  moment(e.target.value).format('YYYY-MM-DD');
                                  setFields({
                                    ...fields,
                                    realized_date: date,
                                  });
                                }}
                              />
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col>
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div>
                            <h4 className="card-title">Difference</h4>
                          </div>
                        </div>
                        <div className="card-body">
                          {totalCredit && totalDebit ? (
                            <input
                              type="text"
                              className="form-control w-100"
                              value={
                                parseInt(totalDebit) - parseInt(totalCredit)
                              }
                              readOnly
                            />
                          ) : null}
                        </div>
                        <div className="card-footer">
                          <div className="form-group">
                            {/* <div>Execution Date</div> */}
                            <small>
                              Difference between debit & credit should be 0
                            </small>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div>
                            <h4 className="card-title">Debit</h4>
                          </div>
                          <div>
                            <button
                              className=" btn-round btn-icon btn btn-success"
                              onClick={() => {
                                setViewAddDebitModal(true);
                              }}
                            >
                              {" "}
                              <i className="fa fa-plus"></i>{" "}
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          {debitFormData ? (
                            <table className="table" id="myTable">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Ledger Code</th>
                                  <th>Ledger Name</th>
                                  <th>Amount</th>
                                  <th>Description</th>
                                  <th>Invoice Number</th>
                                  <th colSpan={2}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {debitFormData.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <small>
                                          {item.ledger
                                            .split("-")[0]
                                            .replace(/\s+/g, "")}
                                        </small>
                                      </td>
                                      <td>
                                        <small>
                                          {item.ledger.split("-")[1].trim()}
                                        </small>
                                      </td>

                                      <td>{item.amount}</td>
                                      <td>{item.description}</td>
                                      <td>{item.invoiceNumber}</td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleDebitDelete(
                                            item.ledger,
                                            item.amount
                                          );
                                        }}
                                      >
                                        <i className="tim-icons icon-simple-remove"></i>
                                      </td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setViewDebitIndex(index);
                                          setViewViewDebitModal(true);
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : null}
                        </div>
                        <div className="card-footer">
                          <div className="form-group">
                            {/* <div>Execution Date</div> */}
                            <div>Total Debit</div>
                            <input
                              type="text"
                              placeholder="Total Debit"
                              readOnly
                              value={totalDebit}
                              className={
                                "form-control w-100" + ErrorBorder(totalDebit)
                              }
                              onChange={(e) => {
                                setTotalDebit(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <div>
                            <h4 className="card-title">Credit</h4>
                          </div>
                          <div>
                            <button
                              className=" btn-round btn-icon btn btn-success"
                              onClick={() => {
                                setViewAddCreditModal(true);
                              }}
                            >
                              {" "}
                              <i className="fa fa-plus"></i>{" "}
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          {creditFormData ? (
                            <table className="table" id="myTable">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Ledger Code</th>
                                  <th>Ledger Name</th>
                                  <th>Amount</th>
                                  <th>Description</th>
                                  <th>Invoice Number</th>
                                  <th colSpan={2}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {creditFormData.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <small>
                                          {item.ledger
                                            .split("-")[0]
                                            .replace(/\s+/g, "")}
                                        </small>
                                      </td>
                                      <td>
                                        <small>
                                          {item.ledger.split("-")[1].trim()}
                                        </small>
                                      </td>

                                      <td>{item.amount}</td>
                                      <td>{item.description}</td>
                                      <td>{item.invoiceNumber}</td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleCreditDelete(
                                            item.ledger,
                                            item.amount
                                          );
                                        }}
                                      >
                                        <i
                                          className="tim-icons icon-simple-remove"
                                          onClick={() => {}}
                                        ></i>
                                      </td>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setViewCreditIndex(index);
                                          setViewViewCreditModal(true);
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : null}
                        </div>
                        <div className="card-footer">
                          <div className="form-group">
                            {/* <div>Execution Date</div> */}
                            <div>Total Credit</div>
                            <input
                              type="text"
                              placeholder="Invoice Number"
                              readOnly
                              value={totalCredit}
                              className={
                                "form-control w-100" + ErrorBorder(totalCredit)
                              }
                              onChange={(e) => {
                                setTotalCredit(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* {transactionLedgers.length>0 && (
            <Row>
            <Col md="4">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Accounting</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      {(headLabel=='saleofunit' || headLabel=='conversionin') && (
                      <>
                      <Row className='mt-2'>
                        <Col>
                        <label>Bank Account
                         {ledgerAccountCode=='' ? '' : <small className='text-muted'> ({ledgerAccountCode})</small>}
                         </label>
                        </Col>
                       
                        <Col>
                        <small className='text-muted'> {transactionLedgers[0]!=undefined ? transactionLedgers[0].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['gross_amount'])}</span></Col>
                      </Row>

                      <Row className='mt-2'>
                        <Col>
                        <label>Load on Sale
                         <small className='text-muted'> {transactionLedgers[1]!=undefined ? (transactionLedgers[1].gl_code) : ''}</small>
                         </label>
                        </Col>
                        <Col>
                        <small className='text-muted'> {transactionLedgers[1]!=undefined ? transactionLedgers[1].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['sale_load'])}</span></Col>
                      </Row>
                       
                      <Row className='mt-2'>
                        <Col>
                        <label>Sale of Unit
                         <small className='text-muted text-center'> {transactionLedgers[2]!=undefined ? (transactionLedgers[2].gl_code) : ''}</small>
                         </label>
                        </Col>
                        <Col>
                        <small className='text-muted'> {transactionLedgers[2]!=undefined ? transactionLedgers[2].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['net_amount'])}</span></Col>
                      </Row>
                      </>
                      )}

                    {(headLabel=='redemptionofunits' || headLabel=='conversionout') && (
                      <>
                      <Row className='mt-2'>
                        <Col>
                        <label>Bank Account
                         {ledgerAccountCode=='' ? '' : <small className='text-muted'> ({ledgerAccountCode})</small>}
                         </label>
                        </Col>
                       
                        <Col>
                        <small className='text-muted'> {transactionLedgers[0]!=undefined ? transactionLedgers[0].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['net_amount'])}</span></Col>
                      </Row>

                      <Row className='mt-2'>
                        <Col>
                        <label>{headLabel=='redemptionofunits'? 'Redemption Load Payable on Redemption of Units':'Transfer Load Payable'}
                         <small className='text-muted'> {transactionLedgers[1]!=undefined ? (transactionLedgers[1].gl_code) : ''}</small>
                         </label>
                        </Col>
                        <Col>
                        <small className='text-muted'> {transactionLedgers[1]!=undefined ? transactionLedgers[1].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['redemption_load'])}</span></Col>
                      </Row>

                      <Row className='mt-2'>
                        <Col>
                        <label>{headLabel=='redemptionofunits'? 'CGT Payable on Redemptions ':'CGT Payable on Conversion Out'}
                         <small className='text-muted'> {transactionLedgers[2]!=undefined ? (transactionLedgers[2].gl_code) : ''}</small>
                         </label>
                        </Col>
                        <Col>
                        <small className='text-muted'> {transactionLedgers[2]!=undefined ? transactionLedgers[2].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['tax_cgt'])}</span></Col>
                      </Row>
                       
                      <Row className='mt-2'>
                        <Col>
                        <label>Zakat Payable on Redemption of Units
                         <small className='text-muted'> {transactionLedgers[3]!=undefined ? (transactionLedgers[3].gl_code) : ''}</small>
                         </label>
                        </Col>
                        <Col>
                        <small className='text-muted'> {transactionLedgers[3]!=undefined ? transactionLedgers[3].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['zakat_amount'])}</span></Col>
                      </Row>
                      
                      <Row className='mt-2'>
                        <Col>
                        <label>{headLabel=='redemptionofunits'? 'Redemption of Units ':'Conversion Out of Units'}
                         <small className='text-muted text-center'> {transactionLedgers[4]!=undefined ? (transactionLedgers[4].gl_code) : ''}</small>
                         </label>
                        </Col>
                        <Col>
                        <small className='text-muted'> {transactionLedgers[4]!=undefined ? transactionLedgers[4].type : ''}</small>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(fields['gross_amount'])}</span></Col>
                      </Row>
                      </>
                      )}

                      <hr/>

                      <Row className='mt-2'>
                        <Col>
                        <label>Total Debit</label>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(totalDebit)}</span></Col>
                      </Row>

                      <Row className='mt-2'>
                        <Col>
                        <label>Total Credit</label>
                        </Col>
                        <Col> <span className='float-right text-primary'>{numberWithCommas(totalCredit)}</span></Col>
                      </Row>

                      <Row className='mt-2'>
                        <Col>
                        <label>Balance</label>
                        </Col>
                        <Col> 
                        <input
                          type="number"
                          value={numberWithCommas(balance)}
                          className={'form-control w-100 '}
                          readOnly
                        />
                        </Col>
                      </Row>

                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )} */}

                  {/* <div>
            <button
                  className="btn btn-primary"
                  onClick={() => {
                    createLegders();
                  }}
                >
                   Create Ledgers
                </button>
            </div> */}

                  {headLabel !== "unitconversion" && finishFlag == false && (
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            handleCreate();
                          }}
                          disabled={
                            Boolean(Loading) ||
                            parseInt(totalCredit) - parseInt(totalDebit) !==
                              0 ||
                            debitFormData.length === 0 ||
                            creditFormData.length === 0
                          }
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>
                              {tx === "cashdividend" ? "Update" : "Create"}
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}

                  {headLabel === "unitconversion" && (
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            // createTransaction();
                            Next();
                          }}
                          disabled={Boolean(Loading)}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>{flag ? "Update" : "Next"}</span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  {finishFlag == true && (
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            // createTransaction();
                            Finish();
                          }}
                          disabled={Boolean(Loading)}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>{flag ? "Update" : "Finish"}</span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {renderModalForAddCredit()}

                {renderModalForViewCredit(viewCreditIndex)}
                {renderModalForAddDebit()}
                {renderModalForViewDebit(viewDebitIndex)}

                {renderModalForBankBalance()}
                {renderModalAddNewUnitHolder()}
              </div>
              <div style={{ display: "none" }}>{accTilteName}</div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Vouchers;
