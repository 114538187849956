import axios from 'axios';
import Config from './../../config/index';
import RefreshTokenHandler from './refresh-token';

/**
 *
 * @param email
 * @param amc_name
 * @param bank_name
 * @param branch_name
 * @param purpose_of_account
 * @param iban
 * @param fund_name
 * @param bank_account_title
 * @param nature_of_transaction
 * @param currency
 */
const addAccount = async (
  email: string,
  amc_code: string,
  bank_name: string,
  branch_name: string,
  account_no: string,
  fund_code: string,
  bank_account_title: string,
  nature_of_account: string,
  currency: string,
  balance_amount: string,
  head_email: string,
  snaCheck: boolean,
  head_phone: string,
  bm_email: string,
  bm_phone: string,
  folio_no: string,
  bank_city: string
) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account/`;
  const sma = snaCheck.toString();
  try{
  const result: any = await axios.post(
    url,
    {
      email,
      amc_code,
      bank_name,
      branch_name,
      account_no,
      fund_code,
      bank_account_title,
      nature_of_account,
      currency,
      balance_amount,
      head_email,
      sma,
      head_phone,
      bm_email,
      bm_phone,
      folio_no,
      bank_city
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await addAccount( email,
      amc_code,
      bank_name,
      branch_name,
      account_no,
      fund_code,
      bank_account_title,
      nature_of_account,
      currency,
      balance_amount,
      head_email,
      snaCheck,
      head_phone,
      bm_email,
      bm_phone,
      folio_no,
      bank_city)
    }else {
      throw err;
      // return result
    }
  }else {
    throw err
  }
  }
};

const getAccounts = async (
  email: string,
  page_number: string = '',
  page_size: string = ''
) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account?email=${email}&page_number=${page_number}&page_size=${page_size}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAccounts(email,page_number, page_size)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
      }
}
};

const getAccountByFund = async (email: string, fund_name: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account/by-fund?email=${email}&fund_code=${fund_name}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAccountByFund(email,fund_name)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
      }
}
};

const getAccountByAmc = async (email: string, amcCode: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account/by-amc-code?email=${email}&amc_code=${amcCode}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getAccountByAmc(email,amcCode)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
      }
}
};

/**
 *
 *
 * @param search_value
 */
const searchBankAccAPI = async (email: string, search_value: string) => {
  const token = sessionStorage.getItem('token') || '';
  try{
  const url = `${Config.baseUrl}/account?email=${email}&search_value=${search_value}`;
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await searchBankAccAPI(email,search_value)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }

}
};

const updateAccountStatus = async (
  email: string,
  iban: string,
  status: string
) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account/`;
  try{
  const result: any = await axios.put(
    url,
    { email, iban, status },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
  }catch(err:any) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateAccountStatus(email,iban, status)
      }else {
        throw err;
        // return result
      }
    }else {
          throw err
    }
  }
};
const EditAccount = async (
  email: string,
  amc_code: string,
  bank_name: string,
  branch_name: string,
  account_no: string,
  fund_code: string,
  bank_account_title: string,
  nature_of_account: string,
  currency: string,
  balance_amount: string,
  head_email: string,
  snaCheck: boolean,
  head_phone: string,
  bm_email: string,
  bm_phone: string,
  folio_no: string,
  account_code: string,
  bank_city:string
) => {
  const token = sessionStorage.getItem('token') || '';
  const sma = snaCheck.toString();
  const url = `${Config.baseUrl}/account/update`;
 try{
  const result: any = await axios.post(
    url,
    {
      email,
      amc_code,
      bank_name,
      branch_name,
      account_no,
      fund_code,
      bank_account_title,
      nature_of_account,
      currency,
      balance_amount,
      head_email,
      sma,
      head_phone,
      bm_email,
      bm_phone,
      folio_no,
      account_code,
      bank_city
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
 }catch(err:any) {
  if(err.response.data.status == 401) { 
  let responseToHandle = err.response.data
  let result = await RefreshTokenHandler.handleIt(responseToHandle)
  if(result.status) {
   return await EditAccount( email,
    amc_code,
    bank_name,
    branch_name,
    account_no,
    fund_code,
    bank_account_title,
    nature_of_account,
    currency,
    balance_amount,
    head_email,
    snaCheck,
    head_phone,
    bm_email,
    bm_phone,
    folio_no,
    account_code,
    bank_city)
  }else {
    throw err;
    // return result
  }
}else {
  throw err
}
}
};

const getMultiAccountByCodes = async (email: string, fund_code: string) => {
  const token = sessionStorage.getItem('token') || '';
  const url = `${Config.baseUrl}/account/by-multiple-fund?email=${email}&fund_code=${fund_code}`;
  try{
  const result: any = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err:any) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getMultiAccountByCodes(email,fund_code)
    }else {
      throw err;
      // return result
    }
  }else {
        throw err
  }
}
};

export {
  getAccountByFund,
  addAccount,
  EditAccount,
  getAccounts,
  searchBankAccAPI,
  updateAccountStatus,
  getAccountByAmc,
  getMultiAccountByCodes,
};
