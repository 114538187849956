
import React, { useState } from 'react';	
// import Header from './../components/Header';	
import { Container, Row, Col, Input, Label, FormGroup } from 'reactstrap';	
import { Modal } from 'react-bootstrap';	
import moment from 'moment';	
import { getAllTransactions } from './../stores/services/transactions.service';	
import ReactTooltip from 'react-tooltip';	
import ViewTransaction from './view-beta-txn';
import { BsTable } from 'react-icons/bs';	

const TransactionListing = () => {	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  let [transactions, setTransactions] = useState([]);	
  let [activePage, setActivePage] = useState('');	
  const [trx_history, setTrxHistory] = useState([]);	
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  let [paginationArr, setPaginationArr] = useState(['']);	
  const userEmail = sessionStorage.getItem('email') || '';	
  const [transaction, setTransaction] = useState<any>([]);

  const [resLoading, setResLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  React.useEffect(() => {
    
    async function getTransactions(){
    setResLoading(true);
    await getAllTransactions(	
      sessionStorage.getItem('email') || '',	
      '',	
      '',	
      userEmail,	
      activePage,	
      userEmail	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 &&	
          10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);	
        setTransactions(response.data.data);
        setResLoading(false);	
      })	
      .catch((err) => {	
        setResLoading(false);
      });	
      setResLoading(false);
    }

    getTransactions();
  }, [activePage]);	

  const [searchValue, setSearchValue] = useState('');	
  const searchFilters = async(statusVar = undefined as any,	searchVar = undefined as any,	pageNumb: string = ''	) => {	
    return new Promise(async(resolve, reject) => {
      let search=false;
      let clear=false;
      if(searchVar===''){ // if click on clear button
        search=false;
        clear=true;
      }else{ // if click on search button
        search=true;
        clear=false;
      }	
      setResLoading(true);
      setSearchLoading(search);
      setClearLoading(clear);	
      await getAllTransactions(	
        sessionStorage.getItem('email') || '',	
        '',	
        searchVar === undefined ? searchValue : searchVar,	
        userEmail,	
        '',	
        userEmail	
      )	
        .then((response) => {	
          let pageArr = [''];	
          let existingPageIndex = paginationArr.findIndex(	
            (x) => x === response.data.page_info.next_page_number	
          );	
          if (	
            existingPageIndex === -1 &&	
            response.data.data.length === 10 &&	
            10 * pageArr.length !==	
              parseInt(response.data.page_info.total_count)	
          ) {	
            pageArr.push(response.data.page_info.next_page_number);	
          }	
          setPaginationArr(pageArr);	
          setTransactions(response.data.data);
          setResLoading(false);	
          setSearchLoading(false);
          setClearLoading(false);
          resolve('Success');	
        })	
        .catch((err) => {	
          setResLoading(false);
          setSearchLoading(false);
          setClearLoading(false);
          reject('Error');	
        });	
        setResLoading(false);
        setSearchLoading(false);
        setClearLoading(false);
    });	
  };

  const viewCheckListPopup = () => {	
    switch (viewChecklist) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="xl"	
          >	
            <div className="">	
              <button	
                aria-hidden={true}	
                className="close"	
                data-dismiss="modal"	
                type="button"	
                onClick={() => {	
                  setViewCheckList(false);	
                }}	
              >	
                <i className="tim-icons icon-simple-remove" />	
              </button>	
              <h6 className="title title-up">Approval Check List  </h6>	
            </div>	
            <div className="modal-body">	
              <div> 	
                {trx_checklist.map((val: any, ind: any) => {	
                  return (	
                    <Row key={ind} className="pt-3">	
                      	
                      <Col md="4">	
                      <text className="text-primary">{val.serial_no}.</text> <text>{val.message}</text>	
                      </Col>	
                      <Col md="8">	
                        <div className="row"> 	
                            <div className="col-sm-3">	
                             	
                            <FormGroup check>	
                              <Label check>	
                                <Input 	
                                checked={val.value == 'compliant'}	
                                type="checkbox"	
                                value="compliant"	
                                readOnly />	
                                <span className="form-check-sign" />	
                                Compliant	
                              </Label>	
                            </FormGroup>	
                            </div>	
                            <div className="col-sm-3">	
 	
                            <FormGroup check>	
                              <Label check>	
                                <Input type="checkbox"	
                                id="unlisted"	
                                checked={val.value == 'na'}	
                                value="na"	
                                readOnly />	
                                <span className="form-check-sign" />	
                                N /A	
                              </Label>	
                            </FormGroup>	
                            </div>	
                            <div className="col-sm-6">	
                              <div className="form-group">	
                                <input	
                                  type="text"	
                                  placeholder="Comments"	
                                  value={val.comment}	
                                  className="form-control"	
                                  readOnly	
                                />	
                                </div> 	
                            </div>	
                        </div>	
                      </Col>	
                    </Row>	
                  );	
                })}	
 	
                <Row>	
                  <Col>	
                    <div className=" ">	
                      <button	
                        className="btn btn-primary mt-3"	
                        onClick={() => {	
                          setViewCheckList(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const [view, setView] = useState(false);	
  const [data, setData] = useState<any>([]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };	
  const renderData = () => {	
    return data.map((value: any, index: number) => {	
      return (	
        <>	
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (	
            <Col md="6" key={index}>	
              <div className="input-holder left">	
                <p className="label">{titleCase(value[0])}</p>	
                <div className="input-1 hide-overflow">	
                  <p	
                    style={{	
                      fontSize: '12px',	
                    }}	
                  >	
                    {typeof value[1] === 'string'	
                      ? value[0] === 'file'	
                        ? 'N/A'	
                        : value[1]	
                      : value[0] === 'file'	
                      ? 'N/A'	
                      : ''}	
                  </p>	
                </div>	
              </div>	
            </Col>	
          ) : (	
            ''	
          )}	
        </>	
      );	
    });	
  };	
  const CapTxnType = (type: any) => {	
    let i, txType;	
    for (i = 0; i < txnTypes.length; i++) {	
      if (txnTypes[i].api_name === type) {	
        txType = txnTypes[i].dis_name;	
        break;	
      }	
    }	
    return txType;	
  };	
  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (	
    <>	
        <div className="content">
         <Row>
           <Col md="12">
      <Container fluid>	
        {/* <Header />	 */}
        {!view ? (	
          <div className="card ">	
            <div className="card-header">	
              <h4 className="card-title">Transaction Listing</h4>	
            </div>	
            <div className="card-body">	
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                  <ReactTooltip	
                    textColor="white"	
                    backgroundColor="black"	
                    effect="float"	
                  /> 	
                    <input	
                      type="search"	
                      id="myInput"	
                      placeholder="Transaction Id"	
                      data-tip="Search Transaction"	
                      className="form-control"	
                      value={searchValue}	
                      onChange={(e) => {	
                        setSearchValue(e.target.value);	
                      }}	
                    /> 	
                  </div>
                </div>
                <div className="col-md-4">
                  
                <button	
                    className="btn btn-primary ml-2 mt-a"	
                    disabled={Boolean(searchLoading)}
                    onClick={() => {	
                      if (searchValue.trim() !== '') {	
                        searchFilters().then(() => {	
                          setActivePage('');	
                        });	
                      }	
                    }}	
                  >	
                   {searchLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Searching...</span>
                    </>
                  ) : (
                   'Search'
                  )}
                   {/* <span>	
                      <i className="fa fa-search"></i>&nbsp;Search	
                    </span>	 */}
                  </button>	
                  <button	
                    className="btn btn-default ml-2 mt-a"	
                    disabled={Boolean(clearLoading)}
                    onClick={() => {	
                      // paginationArr.length = 0;	
                      // setPaginationArr(paginationArr);	
                      searchFilters('', '', '').then(() => {	
                        setActivePage('');	
                        setSearchValue('');	
                      });	
                    }}	
                  >	
                    {clearLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Clear</span>
                    </>
                  ) : (
                   'Clear'
                  )}
                    {/* <span>	
                      <i className="fa fa-trash"></i>&nbsp;Clear	
                    </span>	 */}
                  </button>	

                </div>
              </div> 
              
              <div>
                {resLoading ? (
                  <div className="d-flex justify-content-center my-5">	
                  <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
                  </div>
                ):(
                transactions.length>0 ? (
                  <div className='table-responsive'>
                <table className="table mt-1">	
                  <thead>	
                    <tr>	
                      <th>Transaction Id</th>	
                      <th>Transaction Type</th>	
                      <th>Net Amount</th>	
                      <th>Date Created</th>	
                      <th>Actions</th>	
                      <th>Status</th>	
                    </tr>	
                  </thead>	
                  <tbody>	
                    {transactions.map((transaction: any, index: number) => {	
                      return (	
                        <tr key={index}>	
                          <td>{transaction.txn_id}</td>	
                          <td>	
                            {transaction.txnr_type	
                              ? CapTxnType(transaction.txnr_type)	
                              : 'N/A'}	
                          </td>	
                          <td> {numberWithCommas(transaction.net_amount)} </td>
                          <td>	
                            {transaction.txn_history	
                              ? transaction.txn_history[0]?.created_at	
                                ? moment(	
                                    transaction.txn_history[0].created_at	
                                  ).format('DD-MM-YYYY')	
                                : 'N/A'	
                              : 'N/A'}	
                          </td>	
                          <td className=" ">	
                            <div className="multi-icons pointer">	
                                
                              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                                  setView(true);	
                                  setData(transaction);	
                                }}	>
                                    <i className="fa fa-eye">
                                </i>
                              </button> 
                                
                            </div>	
                          </td>	
                          <td>{transaction.txn_status}</td>	
                        </tr>	
                      );	
                    })}	
                  </tbody>	
                </table>
                </div>
                ):(
                  <div className="table-empty">	
                  <BsTable className="icon" />	
                  <p className="text-center empty-text">No record found</p>	
                  </div>
                )
                )}
              <div	
                className="row pr-3 pointer"	
                style={{	
                  float: 'right',	
                }}	
              >	
                {/* PREVIOUS BUTTON */}	
                {activePage !== paginationArr[0] ? (	
                  <i	
                    className="fa fa-arrow-left"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index - 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
                {/* NEXT BUTTON */}	
                {activePage !== paginationArr[paginationArr.length - 1] ? (	
                  <i	
                    className="fa ml-3 fa-arrow-right"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index + 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
              </div>
              </div>	
            </div>	
          </div>	
        ) : (	
          <div className="">	
            <h4 className="card-title">	
              	
              <i className="fa fa-angle-left mr-2 pointer"	
              onClick={() => {	
                setView(false);	
              }}></i>	
              View - Transaction	
            </h4>	
            {/* <div className="form-holder">	
                {data ? <Row>{renderData()}</Row> : ''}	
                <Row className="pt-3 pb-5">	
                  {trx_history.map((hist: any, index: number) => {	
                    return hist.action !== 'CREATED' ? (	
                      <Col	
                        md="3"	
                        onClick={() => {	
                          if (!hist.check_list) {	
                            return	
                          }	
                          setTrxCheckList(hist.check_list);	
                          setViewCheckList(true);	
                        }}	
                      >	
                        <p className="number-text">{index}</p>	
                        <div className="auth-box">	
                          <p className="title">{hist.updated_by_role}</p>	
                          <p className="small mt-4">Status:</p>	
                          <p className="accepted">{hist.action}</p>	
                          {hist.updated_at !== '' ? (	
                            <>	
                              <p className="small mt-4">Last update</p>	
                              <p className="comment">	
                                {moment(hist.updated_at).format('DD-MMM-YYYY')}	
                              </p>	
                              <p className="comment">	
                                {moment(hist.updated_at).format('h:mm a')}	
                              </p>	
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {hist.note !== '' ? (	
                            <>	
                              <p className="small mt-4">Comment</p>	
                              <p className="comment">{hist.note}</p>	
                            </>	
                          ) : (	
                              ''	
                            )}	
                        </div>	
                      </Col>	
                    ) : (	
                        ''	
                      );	
                  })}	
                </Row>	
                {viewCheckListPopup()}	
              </div> */}	
            <ViewTransaction data={data} />	
            <div className="line"></div>	
            <Row>	
              {trx_history.map((hist: any, index: number) => {	
                return (	
                  <Col	
                    md="3"	
                    onClick={() => {	
                      if (hist.action === 'CREATED') {	
                        return;	
                      }	
                      if (!hist.check_list) return;	
                      setTrxCheckList(hist.check_list);	
                      setViewCheckList(true);	
                    }}	
                  >	 
                    <div className="card process-card pointer ">	
                      <div className="card-header">
                      <h4 className="card-title">	
                          <span className="card-number">
                          {index + 1}
                          </span>
                           <span className="mr-1">
                                </span> <i className="tim-icons icon-single-02 mr-1"></i> 	 {hist.updated_by_role}
                          </h4>	
                         
                      </div>	 
                      <div className="card-body text-center">	 
                      <p className="small mt-2">Status:</p>	
                      <p className="accepted">{hist.action}</p>	
                      {hist.updated_at !== '' ? (	
                        <>	
                          <p className="small mt-2">Last update</p>	
                          <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>	 	
                        </>	
                      ) : (	
                        ''	
                      )}	
                      {hist.note !== '' ? (	
                        <>	
                          <p className="small mt-2">Comment</p>	
                          <p className="comment">{hist.note}</p>	
                        </>	
                      ) : (	
                        ''	
                      )}	
                      {hist.created_by !== '' ? (	
                        <>	
                          <p className="small mt-2">Created By</p>	
                          <p className="comment">{hist.created_by}</p>	
                          <p className="small mt-2">Created At</p>	
                          <h4 className="comment">  {moment(hist.created_at).format("DD-MM-YYYY")}</h4>	
                        </>	
                      ) : (	
                        ''	
                      )}	
                    </div>	
                    </div>	
                  </Col>	
                );	
              })}	
            </Row>	
          </div>	
        )}	
      </Container>	
 </Col>
 </Row>
      </div>
    </>	
  );	
};	
export default TransactionListing;