import React, { useState } from "react";
// import Header from './../components/Header';
import { Container, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import Menu from "@material-ui/core/Menu";

const BetaTxnType = () => {
  const history = useHistory();
  const clearTransactiionHistory = () => {
    sessionStorage.removeItem("rejectedTxObj");
    sessionStorage.removeItem("rejectedTxName");
    sessionStorage.removeItem("rejectedFlag");
    sessionStorage.removeItem("rejectedAmc");
  };
  let txnTypeCatagory = JSON.parse(
    sessionStorage.getItem("txnTypeCatagory") || ""
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let last_id = sessionStorage.getItem("last_id") || "";
  const renderMenuPopup = () => {
    return (
      <Menu
        className=" shadow"
        id="simple-menu"
        anchorEl={anchorEl}
        // PaperProps={{
        //   style: {
        //     width: '70',
        //     outline: 'none',
        //     background: '#5ad2d2',
        //   },
        // }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="  p-2 ">{last_id}</div>
      </Menu>
    );
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              {/* <Header /> */}
              <Row>
                <Col md="12 input-holder">
                  <h4 className="card-title">Transaction Types</h4>
                  <Link
                    to="/admin/bulk-upload"
                    className="btn btn-primary btn-sm"
                    replace
                  >
                    <i className="fa fa-upload mr-2"></i> Bulk Upload
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <div className="card ml-3">
                      <div className="card-header">
                        <h4 className="card-title">
                          {" "}
                          <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                          Unit Management
                        </h4>
                      </div>
                      <div className="card-body">
                        {txnTypeCatagory[0].unitManagementTxn.map(
                          (item: any, index: number) => {
                            return (
                              <MenuItem
                                style={{
                                  minHeight: "0px", // mobile view
                                }}
                                key={index}
                                className="box-element"
                                onClick={() => {
                                  history.replace(
                                    `/admin/unit-management-transaction/${item.api_name}`
                                  );
                                  if (item.api_name === "unitconversion") {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      "Payment"
                                    );
                                  } else {
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                  }
                                  clearTransactiionHistory();
                                }}
                              >
                                <span className="text-hover text-silver">
                                  <span
                                    className={item.api_name + " ico"}
                                  ></span>
                                  <i className="fa fa-angle-right"></i>{" "}
                                  {item.dis_name}
                                </span>
                              </MenuItem>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <div className="card" style={{ minWidth: "105%" }}>
                        <div className="card-header">
                          <h4 className="card-title">
                            <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                            Vouchers
                          </h4>
                        </div>
                        <div className="card-body">
                          {txnTypeCatagory[4].vouchersTxn.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{
                                    minHeight: "0px", // mobile view
                                  }}
                                  className="box-element"
                                  key={index}
                                  onClick={() => {
                                    history.replace(
                                      `/admin/vouchers/${item.api_name}`
                                    );
                                    sessionStorage.setItem(
                                      "payment_type",
                                      `${item.payment_type}`
                                    );
                                    clearTransactiionHistory();
                                  }}
                                >
                                  <span className="text-hover text-silver">
                                    <span
                                      className={item.api_name + " ico"}
                                    ></span>
                                    <i className="fa fa-angle-right"></i>{" "}
                                    {item.dis_name}
                                  </span>
                                </MenuItem>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div className="card ml-2">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                        Fund Management
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[1].fundManagementTxn.map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              className="box-element"
                              key={index}
                              onClick={() => {
                                history.replace(
                                  `/admin/fund-management-transaction/${item.api_name}`
                                );
                                sessionStorage.setItem(
                                  "payment_type",
                                  `${item.payment_type}`
                                );
                                clearTransactiionHistory();
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                      <MenuItem
                        style={{
                          minHeight: "0px", // mobile view
                        }}
                        className="box-element"
                        key="4"
                        onClick={() => {
                          history.replace(`/admin/fund-dividend-payout`);
                          // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                          // clearTransactiionHistory();
                        }}
                      >
                        <span className="text-hover text-silver">
                          <span className={" ico"}></span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {"Fund Dividend Payout"}
                        </span>
                      </MenuItem>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                        Investments
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[2].investmentTxn.map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              key={index}
                              className="box-element"
                              onClick={() => {
                                history.replace(
                                  `/admin/investment-transaction/${item.api_name}`
                                );
                                sessionStorage.setItem(
                                  "payment_type",
                                  `${item.payment_type}`
                                );
                                clearTransactiionHistory();
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {" "}
                        <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                        Fee Payments
                      </h4>
                    </div>
                    <div className="card-body">
                      {txnTypeCatagory[3].feePaymentTxn.map(
                        (item: any, index: number) => {
                          return (
                            <MenuItem
                              style={{
                                minHeight: "0px", // mobile view
                              }}
                              className="box-element"
                              key={index}
                              onClick={() => {
                                history.replace(
                                  `/admin/fee-payment-transaction/${item.api_name}`
                                );
                                sessionStorage.setItem(
                                  "payment_type",
                                  `${item.payment_type}`
                                );
                                clearTransactiionHistory();
                              }}
                            >
                              <span className="text-hover text-silver">
                                <span className={item.api_name + " ico"}></span>
                                <i className="fa fa-angle-right"></i>{" "}
                                {item.dis_name}
                              </span>
                            </MenuItem>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>

                {/* Niscellaneous */}
              </Row>
              {last_id !== "" && (
                <>
                  <ReactTooltip
                    textColor="white"
                    backgroundColor="black"
                    effect="float"
                  />
                  <AiOutlineInfoCircle
                    data-tip="Last Transaction"
                    className={"float-right mt-3"}
                    onClick={(e) => {
                      handleClick(e);
                    }}
                  />
                </>
              )}
              {renderMenuPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BetaTxnType;
