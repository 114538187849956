import { env } from './../env';

const Config = {

      // IP
      baseUrl: `${env.REACT_APP_BASE_URL}`, //deployed
      socketBaseUrl: `${env.REACT_APP_SOCKET_URL}`, //deployed
      imageBaseUrl: `${env.REACT_APP_IMAGE_URL}`, //forimage

    // Naya Tell
    // baseUrl: "http://124.109.39.158:3000/api", //deployed
    // socketBaseUrl: "http://124.109.39.158:3000/", //deployed
    // imageBaseUrl: "http://124.109.39.158:3000/", //forimage 

    // PTCL IP
    // baseUrl: "http://182.191.93.242:3000/api", //deployed
    // socketBaseUrl: "http://182.191.93.242:3000/", //deployed
    // imageBaseUrl: "http://182.191.93.242:3000/", //forimage

    // Local NayaTel
    // baseUrl: "http://192.168.18.183:3000/api", //deployed
    // socketBaseUrl: "http://192.168.18.183:3000/", //deployed
    // imageBaseUrl: "http://192.168.18.183:3000/", //forimage

    // baseUrl: "..//182.191.93.242:3000/api", //deployed
    // socketBaseUrl: "..//182.191.93.242:3000/", //deployed
    // imageBaseUrl: "..//182.191.93.242:3000/", //forimage

    // VPS
    // baseUrl: "http://167.86.69.108:3000/api", //deployed
    // socketBaseUrl: "http://167.86.69.108:3000/", //deployed
    // imageBaseUrl: "http://167.86.69.108:3000/", //forimage

    // vps links for production beta
    // baseUrl: "https://beta.macs-core.com:3000/api", //deployed
    // socketBaseUrl: "https://beta.macs-core.com:3000/", //deployed
    // imageBaseUrl: "https://beta.macs-core.com:3000/", //forimage

    // VPS-2
    //  baseUrl: "https://macs-core.com:3000/api", //deployed
    //  socketBaseUrl: "https://macs-core.com:3000/", //deployed
    //  imageBaseUrl: "https://macs-core.com:3000/", //forimage
}
export default Config