
import React from 'react';
import { useState } from 'react';
import { searchAPI } from './../stores/services/amc.service';
import { Modal } from 'react-bootstrap';
import { Container, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  getFundByAmc,
  getInstrumentType
} from './../stores/services/funds.service';
import {
  getModeOfPayments,
} from './../stores/services/transactions.service';
import {
  getAccountByFund,
} from './../stores/services/account.service';
import { getAllBanks } from './../stores/services/bank.service';
import { getBranchByBankName } from './../stores/services/branch.service';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  getTaxObj,
  checkTaxBoolean,
} from './field';
import {
  addMegaTransaction,
  EditTransaction,
  getFlow
} from './../stores/services/beta-transaction.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllPsx,
  getAllSecurities,
} from './../stores/services/security.service';
import {	
  inWords	
} from './../stores/services/template.service';
import { getBrokerByType } from './../stores/services/broker.service';
import { getTaxAmount, getTaxType } from './../stores/services/tax.service';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {numberWithCommas,accountTitle_AccountNumber_Regex, getLedgerAccounts, getLedgersByTxn,
getCounterAccountLedgers, getAllLedgers, getGrossAmountLedgersByTxn, getSSTAmountLedgersByTxn} from './../utils/customFunction';
import { Link } from 'react-router-dom';
import NumberFormat from "react-number-format";

import {getChartOfAccounts, getTransactionsLedgers, getLedgerInfoByCode} from "./../stores/services/chatofaccounts.service";
import { IoIosClose } from 'react-icons/io';

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

interface FieldValues {
  email: string;
  txnr_type: string;
  amc_code: string;
  fund_code: string;
  instruction_date: string;
  execution_date: string;
  executed_date: string;
  fund_account: string;
  reinvest: string;
  account_title: string;
  period: string;
  account_no: string;
  bank: string;
  branch: string;
  counter_account_type: string;
  counter_type:string;
  counter_account_title: string;
  counter_account_number: string;
  counter_bank: string;
  counter_branch: string;
  mode_of_payment: string;
  payment_type: string;
  instrument_no: string;
  instrument_type: string;
  instrument_date: string;
  realized_date: string;
  realized:string;
  gross_amount: string;
  net_amount: string;
  dr_amount: string;
  cr_amount: string;
  balance: string;
  folio_no: string;
  unitholder_name: string;
  units: string;
  nav: string;
  dividend_date: string;
  dividend_rate: string;
  sale_date: string;
  current_holding: string;
  total_holding: string;
  symbol: string;
  dividend_percentage: string;
  credit_date: string;
  maturity_type: string;
  security_type: string;
  issue_date: string;
  conversion_date: string;
  associated_transaction: string;
  maturity_date: string;
  coupon_rate: string;
  price: string;
  face_value: string;
  money_market_face_value: string;
  detail: string;
  type: string;
  redemption_date: string;
  remain_holding: string;
  settlement_by: string;
  tax_type: string;
  settlement_date: string;
  total_charges: string;
  txn_charges: string;
  sale_txn_no: string;
  return_date: string;
  txn_id: string;
  tenor: string;
  yield: string;
  last_coupon_date: string;
  next_coupon_date: string;
  fund_ips_account: string;
  counter_party_ips_account: string;
  system_tax: string;
  primary_dealer: string;
  broker_commission: string;
  tax_sst:string;
  tax_wht_it:string;
  tax_wht_sst:string;
  tax_cgt:string;
  sale_load:string;
  redemption_load:string;
  low_balance_agree: string;
  offer_price:string;
  load_per_unit:string;
  percentage_of_discount:string;
  applicable_offer_price:string;
  sales_tax_collector: string;
  volume:string;
  par_value:string;
  announcement_date: string; 
  bonus_percentage: string;
  bonus_credit_date: string;
  bonus_gross_volume: string;
  tax_it_bonus_amount: string;
  net_bonus_volume: string;
  right_share_percentage: string;
  right_share_credit_date: string;
  right_share_gross_volume: string;
  tax_it_right_share_amount: string;
  net_right_share_volume: string;
  tax_on_dvidend:string;
  tax_on_bonus:string;
  tax_on_right:string;
  zakat_amount:string;
  ledgers:string;
}

const FeePaymentTxns = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [headLabel, setHeadLabel] = React.useState('');
  const tx = sessionStorage.getItem('rejectedTxName') || '';
  const amcEdit = sessionStorage.getItem('rejectedAmc') || false;
  const [amcdata, setAmcdata] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [accountNoData, setAccountNoData] = useState<any>([]);
  const [MOPData, setMOPData] = useState<any>([]);
  const [iTypeData, setITypeData] = useState<any>([]);
  const [accFundLoading, setAccFundLoading] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  let [counterType, setCounterType] = useState('');
  const [accLoading, setAccLoading] = useState(false);
  const paymentType = sessionStorage.getItem('payment_type') || '';

  const [psxList, setPsxList] = useState<any>([]);
  const [stockMarketDataFund, setStockMarketDataFund] = useState<any>([]); // PSX data of fund
  const [borkerByTypeData, setBorkerByTypeData] = useState<any>([]);
  const [securityTypeData, setSecurityTypeData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const amcName = sessionStorage.getItem('amc_name') || '';
  let [sstPercentage, setSSTPercentage]=useState('');
  let [whtITPercentage, setWHTITPercentage]=useState('');
  let [sstWHTPercentage, setSSTWHTPercentage]=useState('');
  // type
  const [inflowType, setInFlowType] = useState<any>([]); // Types For Inflow Transaction Data
  const [outflowType, setOutFlowType] = useState<any>([]); // Types For Outflow Transaction Data
  const [flowLoading, setFlowLoading] = useState<boolean>(false); // will Be true When all Apis give Response
  const [primaryDealerData, setPrimaryDealerData] = useState<any>([]); // Money Maket Securities Hook - primaryDealerData

  //for data checks
  const [fundCheck, setFundCheck] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [accountCheck, setAccountCheck] = useState(false);
  const [brokerCountFlag, setBrokerCountFlag] = useState(false);
  const [toAccountData, setToAccountData] = useState<any>([]); // all Account Numbers  against a Fund
  const [allCounterFunds, setAllCounterFunds] = useState<any>([]); // all Funds Data related to an Amc
  const [stockMarketData, setStockMarketData] = useState<any>([]); // Types For Equity Investment Transaction Data
  const [reinest, setReinvest] = useState<boolean>(false);

  const [mmSecurityLoading, setMMSecurityLoading] = useState(false);

  
  const [totalDebit, setTotalDebit] = useState('0');
  const [totalCredit, setTotalCredit] = useState('0');
  const [balance, setBalance] = useState('0');

  const [selectedLedgerCode, setSelectedLedgerCode] = useState('');
  const [selectedInputLedgerCode, setSelectedInputLedgerCode] = useState('');
  const [selectedLedgerName, setSelectedLedgerName] = useState('');
  const [ledgerType, setLedgerType] = useState('');
  const [ledgerAmount, setLedgerAmount] = useState('');
  const [ledgerDescription, setLedgerDescription] = useState('');
  const [ledgerInvoice, setLedgerInvoice] = useState('');
  const [ledgerAmountType, setLedgerAmountType] = useState('');

  const getFundByAMcCode = async (code: string) => {
    if (counterAccounter === 'Fund') {
      clearCounterAccountFields();
    }
    setAccountCheck(false);
    setMMSecurityLoading(true);
    setAccFundLoading(true);
    allFunds.length = 0;
    setAllFunds(allFunds);
    // accountNoData.length = 0;
    // setAccountNoData(accountNoData);
    // setToAccountData(accountNoData);
    if (!flag) {
      clearFundAccountFields();
    }
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(email, code);
       // sort data
       let sortedData:any=[]; 
       sortedData = response.data.data.sort(function(a,b){
         let x = a.nature.toLowerCase();
         let y = b.nature.toLowerCase();
         if(x>y){return 1;}
         if(x<y){return -1;}
         return 0;
       });
      // setAllFunds(response.data.data);
      setAllFunds(sortedData);
      let temp = sortedData.map((val) => {
        if(val.nature==='Special Managed Account'){
          return {
            ...val,
            label: `${val.fund_name} (${val.symbol_code}) - ${'SMA'} `,
            value: val.symbol_code,
          };
        }else{
        return {
          ...val,
          label: `${val.fund_name} (${val.symbol_code})`,
          value: val.symbol_code,
        };
      }
      });
      setAllCounterFunds(temp);
      setStockMarketData([]);
      if (response.data.data.length < 1) {
        setFundCheck(true);
      } else {
        setFundCheck(false);
      }
      setAccFundLoading(false);
    } catch (error) {}
   
  };

  const getAccountByFundName = async (fund_code: string) => {
    allFunds.map((item:any) => {
      if (item.symbol_code === fund_code) {
        fields['nav'] = item.nav;
        fields['face_value']=item.face_value;
           
        setFields(fields);

        let stock_market_data = [] as any;
        if (
          item.stock_market !== '' &&
          item.stock_market !== '[]' &&
          item.stock_market !== undefined
        ) {
          let stock_arr = JSON.parse(item.stock_market);
          stock_arr.map((item, ind) => {
            stock_market_data.push(item);
          });
          setStockMarketData(stock_market_data);
        } else {
          setStockMarketData([]);
        }
      }
    });
    setAccLoading(true);
    //get account by fund name for dropdown
    try {
      const accResponse = await getAccountByFund(email, fund_code);
      let temp = accResponse.data.data.map((val) => {
        return {
          ...val,
          // label: `${val.account_no} (${val.account_code})`,
          label: `${val.bank_name} ${val.branch_name} (${val.account_title})`,
          value: val.account_code,
        };
      });
      // temp.unshift({ value: 'Other', label: 'Other' });
      setToAccountData(temp);
      setAccountNoData(temp);
      if (accResponse.data.data.length < 1) {
        setAccountCheck(true);
      } else {
        setAccountCheck(false);
      }
    } catch (error) {}
    setAccLoading(false);
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const flag = sessionStorage.getItem('rejectedFlag') || false;
  const [grossAmountReadOnly, setGrossAmountReadOnly] = useState(false);
  const [netAmountReadOnly, setNetAmountReadOnly] = useState(false);

  React.useEffect(() => {
    setHeadLabel(props.match.params.type);
    fields['txnr_type'] = props.match.params.type;

    // setFields(fields);
    counterAccounter = getCounterAccount(props.match.params.type);
    if (counterAccounter === 'Other') {
      fields['counter_account_type'] = 'Other';
      setFields(fields);
    }
  }, [props.match.params.type]);

  const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');

  // filed Object Hook For geting data from fields / object for transaction creation
  let date = new Date();
  let disableDates=moment(date).format('YYYY-MM-DD');
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: '',
    amc_code: loggedInUser.amc_code,
    dividend_date: '',
    dividend_rate: '',
    fund_code: '',
    reinvest: 'false',
    instruction_date: moment(date).format('YYYY-MM-DD'),
    execution_date: moment(date).format('YYYY-MM-DD'),
    executed_date: moment(date).format('YYYY-MM-DD'),
    fund_account: '',
    account_title: '',
    account_no: '',
    bank: '',
    branch: '',
    counter_account_type: '',
    counter_type:'',
    counter_account_title: '',
    counter_account_number: '',
    counter_bank: '',
    counter_branch: '',
    mode_of_payment: '',
    payment_type: '',
    instrument_no: '',
    instrument_type: '',
    instrument_date: moment(date).format('YYYY-MM-DD'),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format('YYYY-MM-DD'),
    realized:'true',
    gross_amount: '',
    net_amount: '',
    dr_amount: '',
    period: '',
    cr_amount: '',
    balance: '',
    folio_no: '',
    unitholder_name: '',
    units: '',
    nav: '',
    sale_date: moment(date).format('YYYY-MM-DD'),
    current_holding: '0',
    total_holding: '',
    symbol: '',
    dividend_percentage: '',
    credit_date: '',
    maturity_type: '',
    security_type: '',
    issue_date: moment(date).format('YYYY-MM-DD'),
    conversion_date: moment(date).format('YYYY-MM-DD'),
    associated_transaction: '',
    maturity_date: moment(date).format('YYYY-MM-DD'),
    coupon_rate: '',
    price: '',
    face_value: '',
    money_market_face_value: '',
    detail: '',
    type: '',
    redemption_date: moment(date).format('YYYY-MM-DD'),
    remain_holding: '0',
    settlement_by: '',
    tax_type: '',
    settlement_date: moment(date).format('YYYY-MM-DD'),
    total_charges: '0',
    txn_charges: '',
    sale_txn_no: '',
    return_date: '',
    txn_id: '',
    last_coupon_date: moment(date).format('YYYY-MM-DD'),
    next_coupon_date: moment(date).format('YYYY-MM-DD'),
    yield: '',
    tenor: '',
    fund_ips_account: '',
    counter_party_ips_account: '',
    system_tax: '',
    primary_dealer:'',
    broker_commission:'',
    tax_sst:'',
    tax_wht_it:'',
    tax_wht_sst:'',
    tax_cgt:'0',
    sale_load:'',
    redemption_load:'0',
    low_balance_agree:'',
    offer_price:'',
    load_per_unit:'',
    percentage_of_discount:'0',
    applicable_offer_price:'',
    sales_tax_collector:'SRB', // default value SRB
    volume:'',
    par_value:'',
    announcement_date:'',     // Dividend on Investment
    bonus_percentage:'',
    bonus_credit_date:'',
    bonus_gross_volume:'',
    tax_it_bonus_amount:'',
    net_bonus_volume:'',
    right_share_percentage:'',
    right_share_credit_date:'',
    right_share_gross_volume:'',
    tax_it_right_share_amount:'',
    net_right_share_volume:'',
    tax_on_dvidend:'',
    tax_on_bonus:'',
    tax_on_right:'',
    zakat_amount:'0',
    ledgers:''
  });

  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [ledgeroptions, setLedgerOptions] = useState<any>([]);

  // for legders states
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [ledgerCounterAccounts, setLegderCounterAccounts] = useState<any>([]);
  const [ledgerAccountCode, setLegderAccountCode] = useState('');
  const [ledgerAccountName, setLegderAccountName] = useState('');
  const [ledgerCounterAccountCode, setLegderCounterAccountCode] = useState('');
  const [ledgerCounterAccountName, setLegderCounterAccountName] = useState('');

  const [subFamilyCode, setSubFamilyCode]= useState('');
  const [subFamilyName, setSubFamilyName]= useState('');

  const [grossAmountLedgers, setGrossAmountLedgers] = useState<any>([]);
  const [sstAmountLedgers, setSSTAmountLedgers] = useState<any>([]);

  const [sstLedger, setSSTLedger] = useState('');

  const [transactionLedgers, setTransactionLedgers] = useState<any>([{
    amc_code:amc_code,
    fund_code:fields['fund_code'],
    account_number:'',
    account_title:'',
    bank_code:'',
    branch:'',
    child_family_code:'',
    child_family_name:'',
    counter_account_number:'',
    counter_account_title:'',
    counter_bank:'',
    counter_branch:'',
    credit_amount:'',
    debit_amount:'',
    description:'',
    entered_by:'',
    evidence:'',
    family_code:'',
    family_name:'',
    gl_code:'',
    gl_name:'',
    instruction_date:'',
    instrument_by:'',
    instrument_date:'',
    instrument_no:'',
    instrument_type:'',
    main_family_code:'',
    main_family_name:'',
    realized:'',
    realized_by:'',
    realized_date:'',
    sub_family_code:'',
    sub_family_name:'',
  }]);
  

  //code for edit transaction
  let [count, setCount] = useState(0);
  const [PIB_fields, setPIBFields] = useState(false);
  
  React.useEffect(() => {
    if (flag) {
      const obj = JSON.parse(sessionStorage.getItem('rejectedTxObj') || ' ');
      getFundByAMcCode(obj.amc_code);
      fields['payment_type'] = obj.payment_type || '';
      fields['txnr_type'] = obj.txnr_type || '';
      fields['amc_code'] = obj.amc_code || '';
      getAccountByFundName(obj.fund_code);
      fields['fund_code'] = obj.fund_code || '';
      getBranchByName(obj.bank);
      getCounterBranchByName(obj.counter_bank);
      fields['instruction_date'] = obj.instruction_date || '';
      fields['execution_date'] = obj.execution_date || '';
      fields['executed_date'] = obj.executed_date || '';
      fields['fund_account'] = obj.fund_account || '';
      fields['dividend_date'] =
        obj.dividend_date || '';
      fields['dividend_rate'] = obj.dividend_rate || '';
      fields['reinvest'] = obj.reinvest || '';
      fields['period'] = obj.period || '';
      fields['account_title'] = obj.account_title || '';
      fields['account_no'] = obj.account_number || '';
      fields['bank'] = obj.bank || '';
      fields['branch'] = obj.branch || '';
      fields['counter_account_type'] = obj.counter_account_type || '';
      fields['counter_type'] = obj.counter_type || '';
      fields['counter_account_title'] = obj.counter_account_title || '';
      fields['counter_account_number'] = obj.counter_account_number || '';
      fields['counter_bank'] = obj.counter_bank || '';
      fields['counter_branch'] = obj.counter_branch || '';
      fields['mode_of_payment'] = obj.mode_of_payment || '';
      fields['instrument_no'] = obj.instrument_no || '';
      fields['instrument_type'] = obj.instrument_type || '';
      fields['instrument_date'] = obj.instrument_date || '';
      fields['realized_date'] = obj.realized_date || '';
      fields['realized'] = obj.realized || '';
      fields['gross_amount'] = obj.gross_amount.toString() || '';
      fields['net_amount'] = obj.net_amount.toString() || '';
      fields['dr_amount'] = '0';
      fields['cr_amount'] = '0';
      fields['balance'] = obj.balance.toString() || '';
      fields['folio_no'] = obj.folio_no || '';
      fields['unitholder_name'] = obj.unitholder_name || '';
      fields['units'] = obj.units.toString() || '';
      fields['nav'] = obj.nav.toString() || '';
      fields['sale_date'] = obj.sale_date || '';
      fields['current_holding'] = obj.current_holding.toString() || '';
      fields['total_holding'] = obj.total_holding.toString() || '';
      fields['symbol'] = obj.symbol || '';
      fields['dividend_percentage'] = obj.dividend_percentage.toString() || '';
      fields['credit_date'] = obj.credit_date || '';
      fields['maturity_type'] = obj.maturity_type || '';
      fields['security_type'] = obj.security_type || '';
      fields['issue_date'] = obj.issue_date || '';
      fields['conversion_date'] = obj.conversion_date || '';
      fields['associated_transaction'] = obj.associated_transaction || '';
      fields['maturity_date'] = obj.maturity_date || '';
      fields['coupon_rate'] = obj.coupon_rate || '';
      fields['price'] = obj.price.toString() || '';
      fields['face_value'] = obj.face_value.toString() || '';
      fields['money_market_face_value'] = obj.money_market_face_value.toString() || '';
      fields['detail'] = obj.detail || '';
      fields['type'] = obj.type || '';
      if (obj.reinvest === 'true') {
        setReinvest(true);
      } else {
        setReinvest(false);
      }
      fields['redemption_date'] = obj.redemption_date || '';
      fields['remain_holding'] = obj.remain_holding.toString() || '';
      fields['settlement_by'] = obj.settlement_by || '';
      fields['tax_type'] = obj.tax_type || '';
      fields['settlement_date'] = obj.settlement_date || '';
      fields['total_charges'] = obj.total_charges.toString() || '';
      fields['sale_txn_no'] = obj.sale_txn_no || '';
      fields['return_date'] = obj.return_date || '';
      fields['trx_id'] = obj.txn_id || '';
      fields['primary_dealer'] = obj.primary_dealer;
      fields['yield'] = obj.yield || '';
      if(headLabel==='advisoryfee' || headLabel==='auditorsfee' || headLabel==='managementfee' || headLabel==='trusteefee' || headLabel==='custodyfee' 
      || headLabel==='shariahadvisoryfee' || headLabel==='cdcfee' || headLabel==='listingfee' || headLabel==="listingfee" || headLabel==="brokagefee" ||
      headLabel==='printingfee' || headLabel==='professionalfee' || headLabel==='ratingfee' || headLabel==='taxpayment' || headLabel==='secpfee' || headLabel==='otherpayment'){
      fields['tax_sst']=obj.tax_sst || '';
      fields['tax_wht_it']=obj.tax_sst || '';
      fields['tax_wht_sst']=obj.tax_sst || '';
      fields['sales_tax_collector']=obj.sales_tax_collector || '';
      }

      if(headLabel==='' || headLabel===''){
        fields['sale_load']=obj.sale_load || '';
      }

      if(headLabel==='equityinvestment'){
        fields['broker_commission']=obj.broker_commission || '';
        fields['tax_sst']=obj.tax_sst || '';
        fields['sales_tax_collector']=obj.sales_tax_collector || '';
      }

      if(headLabel==='' || headLabel===''){
        fields['redemption_load']=obj.redemption_load || '';
        fields['tax_cgt']=obj.tax_cgt || '';
      }
      if (headLabel === 'moneymarketpurchase' || headLabel === 'saleofsecurities') {
        if (obj.security_type === 'PIB') {
          setPIBFields(true);
          fields['last_coupon_date'] = obj.last_coupon_date || '';
          fields['next_coupon_date'] = obj.next_coupon_date || '';
          fields['tenor'] = obj.tenor || '';
        }
      }
      fields['zakat_amount'] = obj.zakat_amount;
      fields['txn_status'] = 'CREATED';

      setSecurites(obj.securities);
        // Dividend On Investment
        fields['volume'] = obj.volume;
        fields['par_value'] = obj.par_value;
        fields['announcement_date '] = obj.announcement_date ;
        fields['bonus_percentage'] = obj.bonus_percentage;
        fields['bonus_credit_date'] = obj.bonus_credit_date;
        fields['bonus_gross_volume'] = obj.bonus_gross_volume;
        fields['tax_it_bonus_amount'] = obj.tax_it_bonus_amount;
        fields['net_bonus_volume'] = obj.net_bonus_volume;
        fields['right_share_percentage'] = obj.right_share_percentage;
        fields['right_share_credit_date'] = obj.right_share_credit_date;
        fields['right_share_gross_volume'] = obj.right_share_gross_volume;
        fields['tax_it_right_share_amount'] = obj.tax_it_right_share_amount;
        fields['net_right_share_volume'] = obj.net_right_share_volume;
        fields['tax_on_dvidend'] = obj.tax_on_dvidend;
        fields['tax_on_bonus'] = obj.tax_on_bonus;
        fields['tax_on_right'] = obj.tax_on_right;
      count = count + 1;
      setCount(count);
      fields['fund_ips_account'] = obj.fund_ips_account || '';
      fields['counter_party_ips_account'] = obj.counter_party_ips_account || '';
      fields['low_balance_agree']=''
    }
  }, []);

  const [bankNameData, setBankNameData] = useState<any>([]);
  const [amcdataCounter, setAmcdataCounter] = useState<any>([]); // all Amc Data

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };
  //

  const getTaxRate=()=>{
    let taxFields = getTaxObj(props.match.params.type);
    setSSTPercentage(taxFields.tax_rate);
    setWHTITPercentage(taxFields.wht_it_rate);
    setSSTWHTPercentage(taxFields.wht_sst_rate);
  };

  //end
  React.useEffect(() => {
    getTaxRate();
    if (!flag) {
      fields['payment_type'] = paymentType;
      fields['email'] = email;
      fields['amc_code'] = loggedInUser.amc_code;
    }

    setFlowLoading(true);
    const fetchAmc = async () => {
      MOPData.length = 0;
      setMOPData(MOPData);
  
      // get funds by amc_code
      try {
        const loggedInUser = JSON.parse(sessionStorage.getItem('loggedInUser') || '');
        await getFundByAMcCode(loggedInUser.amc_code);
        //get all broker data
        // const brokerResponse = await getBrokersByAmc(email, loggedInUser.amc_code);
        // setAllBrokers(brokerResponse.data.data);
      } catch (error) {
       
      }


      try{
        const response = await getChartOfAccounts(email);
        if(response.data.status==200){
         let ledgersacc = getLedgerAccounts(response.data.data.main_family[0].family[3].sub_family);
         setLegderAccounts(ledgersacc);
         let allLedgers =  getAllLedgers(response.data.data.main_family);
         setAllLedgers(allLedgers);
         ledgerDropDown(allLedgers);
      }else{
        toast.error(response.data.message);
      }
      }catch(error){

      }

      // get all transaction ledgers
       try{
         const response = await getTransactionsLedgers(email);
         if(response.data.status==200){
           let feeTxns = response.data.data.txn_catagory[3].feePaymentTxn;
            let txnLegders = await getLedgersByTxn(feeTxns, props.match.params.type);
            setTransactionLedgers(txnLegders);

            let grossledgers= await getGrossAmountLedgersByTxn(feeTxns, props.match.params.type);
            setGrossAmountLedgers(grossledgers);
            if(grossledgers.length>0){
              setGrossAmountReadOnly(true);
            }

            let sstledgers= await getSSTAmountLedgersByTxn(feeTxns, props.match.params.type);
            setSSTAmountLedgers(sstledgers);

         }else{
            toast.error(response.data.message);
         }
       }catch(error){

       }

      try {
        const response = await searchAPI(email, loggedInUser.amc_code);
        let temp = response.data.data.map((val) => {
          return {
            ...val,
            label: `${val.account_no}`,
            value: val.account_no,
          };
        });
        temp.unshift({
          value: 'Other',
          label: 'Other',
        });
        setAmcdataCounter(temp);
      } catch (err:any) {
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }
      }
     
      //api call to fetch data for bank dropdown
      try {
        const bankResponse = await getAllBanks(email);
        let temp = bankResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.bank_code.replace('BANK_','')} (${val.bank_name})`,
            value: val.bank_code.replace('BANK_',''),
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });
        setBankNameData(temp);
      } catch (error) {}
      //get all getModeOfPayments list for dropdown
      try {
        const response = await getModeOfPayments(email);
        setMOPData(response.data.mode_of_payment);
      } catch (error) {}
      //get InstrumentType data
      try {
        const inTypeResponse = await getInstrumentType(email);
        setITypeData(inTypeResponse.data.instrument_types);
      } catch (error) {}

      if(props.match.params.type==='equityinvestment'){
      //get Broker data
      try {
        const response = await getAllPsx(email);
        let sortedData = response.data.data.sort(function(a,b){
          let x = a.company_name.toLowerCase();
          let y = b.company_name.toLowerCase();
          if(x>y){return 1;}
          if(x<y){return -1;}
          return 0;
        });
        // setPsxList(response.data.data);
        setPsxList(sortedData);
      } catch (error) {}
    }

      try {
        const brokerResponse = await getBrokerByType(email, counterAccounter);
        let temp = brokerResponse.data.data.map((val) => {
          return {
            ...val,
            label: `${val.company_name} (${val.company_code})`,
            value: val.company_code,
          };
        });
        temp.unshift({ value: 'Other', label: 'Other' });

        setBorkerByTypeData(temp);
        brokerResponse.data.data.length > 0
          ? setBrokerCountFlag(false)
          : setBrokerCountFlag(true);
      } catch (error) {}
      

      if(props.match.params.type==='taxpayment'){
      //get tax type data
      try {
        const taxTypeRes = await getTaxType(email);
        setTaxData(taxTypeRes.data.tax_names);
      } catch (error) {}
    }

    if(props.match.params.type==='inflow' || props.match.params.type==='outflow'){
      try {
        const flowTypeRes = await getFlow(email);
        setInFlowType(flowTypeRes.data.inflow);
        setOutFlowType(flowTypeRes.data.outflow);
      } catch (error) {}
    }

    if(props.match.params.type==='debtmarketinvestment'){
      //get security data
      try {
        setMMSecurityLoading(true);
        const res = await getAllSecurities(email);
        setSecurityTypeData(res.data.data);
        setMMSecurityLoading(false);
      } catch (error) {}
      //API all to get Broker Primary Dealer
      try {
        const res = await getBrokerByType(email, 'Primary Dealer');
        setPrimaryDealerData(res.data.data);
      } catch (error) {}
    }

      setFlowLoading(false);
    };
    fetchAmc();
  }, []);

  const ledgerDropDown = (allLedgerData)=>{
    allLedgerData.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.name}`,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOptions([
            {
              label: `${item.code} - ${item.bank}`,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOptions((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
          },
        ]);
      }
    });
  }

  const renderFundAccounts = () => {
    return (
      ledgerAccounts && ledgerAccounts.map((item: any, index: number) => {
      //   if(item.plsName==='PLS ACCOUNTS'){
      //   return (
      //     <optgroup style={{
      //       color:'black'
      //     }} label="PLS ACCOUNTS">
      //     <option key={index} value={item.code}>
      //       {item.account_title}
      //     </option>
      //     </optgroup>
      //   );
      // }else{
      //   return (
      //     <optgroup style={{
      //       color:'black'
      //     }}  label="CURRENT ACCOUNTS">
      //     <option key={index} value={item.code}>
      //       {item.account_title}
      //     </option>
      //     </optgroup>
      //   );

      // }
      return (
      <option key={index} value={item.code}>
            {item.account_title}
          </option>
      );
      })
    );
  };


  const renderGrossAmountLedgers = () => {
    return (
      grossAmountLedgers && grossAmountLedgers.map((item: any, index: number) => {
      return (
      <option key={index} value={item.gl_code}>
            {item.gl_name}
          </option>
      );
      })
    );
  };

  const renderSSTAmountLedgers = () => {
    return (
      sstAmountLedgers && sstAmountLedgers.map((item: any, index: number) => {
      return (
      <option key={index} value={item.gl_code}>
            {item.gl_name}
          </option>
      );
      })
    );
  };

   //render dropdown for flow data
   const renderinFlowTypeDropdown = () => {
    return (
      inflowType &&
      inflowType.map((item: any, index: number) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })
    );
  };
  const renderOutFlowTypeDropdown = () => {
    return (
      outflowType &&
      outflowType.map((item: any, index: number) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })
    );
  };

  //render dropdown for tax data
  const renderTaxTypeDropdown = () => {
    return taxData.map((item: any, index: number) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  //render dropdown for mop data
  const renderModeOfPayments = () => {
    return MOPData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if(item.nature==='Special Managed Account'){
        return (
          <>
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code}) - {'SMA'}
          </option>
          </>
        );
      }else{
      return (
        <option key={index} value={item.symbol_code}>
         {item.fund_name} ({item.symbol_code}) 
        </option>
      );
    }
    });
  };
  
  //render dropdown for iTypeData data
  const renderiTypeDataDropdown = () => {
    return iTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item}>
          {item}
        </option>
      );
    });
  };

   //render dropdown for primary dealer data
   const renderPrimaryDealerDropdown = () => {
    return primaryDealerData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.company_name}>
          {/* {item.name} */}
          {`${item.company_name} (${item.company_code}) ` }
        </option>
      );
    });
  };

    //render dropdown for psx data of fund
    const renderStockMarketData = () => {
      return stockMarketDataFund.map((item: any, index: string) => {
        return (
          <option
            key={index}
            value={item.code}
          >{item.code}</option>
        );
      });
    };

  //render dropdown for security data
  const renderSecurityDataDropdown = () => {
    return securityTypeData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.code.replace('SECURITY_','')}>
          {item.name.replace('SECURITY_','')}
        </option>
      );
    });
  };

  const [securityData, setSecurityData] = useState([]);

  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [allCounterbranchesData, setAllCounterBranchesData] = useState<any>([]);

  const getBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };


  const getCounterBranchByName = async (name: string) => {
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name);
      let temp = response.data.data.map((val) => {
        return {
          ...val,
          label: `${val.branch_name}`,
          value: val.branch_name,
        };
      });
      temp.unshift({ value: 'Other', label: 'Other' });
      setAllCounterBranchesData(temp);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) {}
  };

  const [inputType, setInputType] = useState(false);
  const onOtherSelection = (val) => {
    if (val === 'Other') {
      setFields({
        ...fields,
        counter_branch: '',
      });
      setInputType(true);
    }
  };

  // handle data for Fund Account Dropdown selection
  const setFundDataCounter = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // if user select any account from dropdown then data fillout
      accountNoData.map((item, index) => {
        if (item.account_code === selected) {
          getCounterBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          fields['counter_account_type'] = item.account_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch_name;
          setFields(fields);

            //////
            termDepositEntity['bank']=item.bank_name;
            termDepositEntity['branch']=item.branch;
            setTermDepositEntity(termDepositEntity);
        }
      });
    }
  };

  //for Validation
  const validate = async () => {
    let count = 0;
    requiredFields.map((item, index) => {
      if (flag) {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      } else {
        const value = fields[item];
        if (value === '') {
          count = count + 1;
        }
      }
    });
    if (count > 0) {
      return false;
    } else {
      return true;
    }
  };
  const [unitNav, setUnitNav] = useState('');

  const createLegders =()=>{
      transactionLedgers.map((item:any)=>{
      if(item.amount_type=='Net Amount'){
        item.amc_code=amc_code;
        item.fund_code=fields['fund_code'];
        item.account_number=fields['account_no'];
        item.account_title=fields['account_title'];
        item.bank_code=fields['bank'];
        item.branch=fields['branch'];
        item.balance='';
        item.counter_account_number=fields['counter_account_number'];
        item.counter_account_title=fields['counter_account_title'];
        item.counter_bank=fields['counter_bank'];
        item.counter_branch=fields['counter_branch'];

        item.description=item.amount_type+' '+fields['detail'];

        item.instruction_date=fields['instruction_date'];
        item.instrument_by='';
        item.instrument_date=fields['instrument_date'];
        item.instrument_no=fields['instrument_no'];
        item.instrument_type=fields['instrument_type'];
        item.realized=fields['realized'];
        item.realized_by='';
        item.realized_date=fields['realized_date'];
        item.entered_by='';
        item.evidence='';
        item.period='2021-2022';
      }else{
        item.amc_code=amc_code;
        item.fund_code=fields['fund_code'];
        item.balance='';

        if(item.amount_type!='Other')
        item.description=item.amount_type+' '+fields['detail'];

        item.instruction_date=fields['instruction_date'];
        item.instrument_by='';
        item.instrument_date=fields['instrument_date'];
        item.instrument_no=fields['instrument_no'];
        item.instrument_type=fields['instrument_type'];
        item.realized=fields['realized'];
        item.realized_by='';
        item.realized_date=fields['realized_date'];
        item.entered_by='';
        item.evidence='';
        item.period='2021-2022';
      }

      })
      return transactionLedgers;

  }

  //Create Transaction Api Call
  const createTransaction = async () => {
    if (headLabel === 'unitconversion') {
      let conversionAmount = +fields['net_amount'] / +unitNav;
      fields['total_holding'] = conversionAmount.toFixed(2);
    } else {
      fields['counter_type'] = counterType!==''? counterType: counterAccounter;
    }
    if(headLabel==='debtmarketinvestment'){
      fields['securities'] = JSON.stringify(moneyMarketSecurities);
    }
    else{
      fields['securities'] = JSON.stringify(securites);
    }

    const isValid = await validate(); // check validation for Fields Data
    if (isValid) {
        setLoading(true);
        if (flag) {
          if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0){
            toast.error("Amounts are negative");
          }
          else{
          try {
            const response = await EditTransaction(fields); // for Edit existed transaction
            if(response.data.status===400){
              setViewBankBalanceModal(true);
              toast.error(response.data.message);
            }else if(response.data.status===417){
              toast.warning(response.data.message);
            }else{
              setUnderStand(false);
              setViewBankBalanceModal(false);
              toast.success(response.data.message);
              setTimeout(() => {
                history.replace(`/admin/create-transaction`);
              }, 3000);
          }
          } catch (error:any) {
            setLoading(false);
            if(error.response!==undefined){
              toast.error(error.response.data.message);
              }else{
                toast.error('Something went wrong!');
              }
          }
        }
        } else {
          if(parseFloat(fields['net_amount'])<0 || parseFloat(fields['gross_amount'])<0){
            toast.error("Amounts are negative");
          }
          else{
            if(+balance!=0){
              toast.error(`Balance should be 0, Other wise you can't create transaction`);
              return;
            }
          let finalLedgers = createLegders();
          fields.ledgers=JSON.stringify(finalLedgers);
          try {
            const response = await addMegaTransaction(fields); // for add new transaction
            if(response.data.status===400){
              setViewBankBalanceModal(true);
              toast.warning(response.data.message);
            }else if(response.data.status===417){
              toast.warning(response.data.message);
            }else{
              setUnderStand(false);
              setViewBankBalanceModal(false);
            sessionStorage.setItem('last_id', response.data.txn_id);
            toast.success(`${response.data.message} - ${response.data.txn_id}`)
            // toast.success(response.data.message);
            setTimeout(() => {
              history.replace(`/admin/create-transaction`);
            }, 3000);
          }
          } catch (error:any) {
            setLoading(false);
            if(error.response!==undefined){
            toast.error(error.response.data.message);
            }else{
              toast.error('Something went wrong!');
            }
          }
        }
        }
        setLoading(false);
      
    } else {
      setLoading(false);
      setErrorShow(true);
      toast.error('Kindly Fill All the Fields');
    }
  };

  const [accTilteName, setAccTiltleName] = useState('');
  const [bankBalance, setBankBalance]= useState('');
  // handle data for Fund Account Dropdown selection
  const setFundAccountFieldsData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['account_title'] = '';
      fields['account_no'] = '';
      fields['bank'] = '';
      fields['branch'] = '';
      fields['fund_account'] = 'other';
      setFields(fields);
    } else {
      ledgerAccounts.map((item, index) => {
        if (item.code.toString() === selected.toString()) {
          getBranchByName(item.bank_name);
          setAccTiltleName(item.account_title);
          // fields['fund_account'] = item.account_code;
          // fields['account_title'] = item.account_title;
          // fields['account_no'] = item.account_no;
          // fields['bank'] = item.bank;
          // fields['branch'] = item.branch;
          // setFields(fields);
          setFields({
            ...fields,
          fund_account:selected,
          account_title: item.account_title,
          account_no: item.account_no,
          bank: item.bank,
          branch: item.branch
        })

        transactionLedgers.map((items:any)=>{
         if(items.amount_type=='Net Amount'){
            items.gl_code=+item.code;
            items.gl_name=item.bank.toString();
            items.sub_family_code= +item.plsCode;
            items.sub_family_name= item.plsName.toString();
         }
        })
        }
      });
    }
  };
   
  const clearFundAccountFields = () => {
    setAccTiltleName('Unset');
    fields['fund_account'] = '';
    fields['account_title'] = '';
    fields['account_no'] = '';
    fields['bank'] = '';
    fields['branch'] = '';
    fields['nav'] = '';
    setFields(fields);
  };

  const setGrossAmountLedger = (selected) => {
      grossAmountLedgers.map((item, index) => {
        if (item.gl_code.toString() === selected.toString()) {
        transactionLedgers.map((items:any)=>{
         if(items.amount_type=='Gross Amount'){

            items.child_family_code=+item.child_family_code;
            items.child_family_name=item.child_family_name;
            items.desc=item.desc;
            items.family_code=+item.family_code;
            items.family_name=item.family_name;
            items.gl_code=+item.gl_code;
            items.gl_name=item.gl_name;
            items.main_family_code=+item.main_family_code;
            items.main_family_name=item.main_family_name;
            items.sub_family_code=+item.sub_family_code;
            items.sub_family_name=item.sub_family_name;
            items.type=item.type;
         }
        })
        }
      });
  
  };

  const setSSTAmountLedger = (selected) => {
    sstAmountLedgers.map((item, index) => {
      if (item.gl_code.toString() === selected.toString()) {
      transactionLedgers.map((items:any)=>{
       if(items.amount_type=='SST/PST Amount'){

          items.child_family_code=+item.child_family_code;
          items.child_family_name=item.child_family_name;
          items.desc=item.desc;
          items.family_code=+item.family_code;
          items.family_name=item.family_name;
          items.gl_code=+item.gl_code;
          items.gl_name=item.gl_name;
          items.main_family_code=+item.main_family_code;
          items.main_family_name=item.main_family_name;
          items.sub_family_code=+item.sub_family_code;
          items.sub_family_name=item.sub_family_name;
          items.type=item.type;
       }
      })
      }
    });

};

  // Set Data in Fields from selected Broker
  const setBrokerData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      borkerByTypeData.map((item, index) => {
        if (item.company_code === selected) {
          setAccTiltleName(item.bank_name + 'changes');
          fields['counter_account_type'] = item.company_code;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.iban;
          fields['counter_bank'] = item.bank_name;
          fields['counter_branch'] = item.branch;
          fields['sales_tax_collector'] = item.tax_collected;
          setFields(fields);
          
        }
      });
    }
  };

  // Set Data in Fields from selected Broker
  const setAmcAccountData = (selected) => {
    if (selected === 'Other') {
      //incase if other option is selected then user entrer data manually
      setAccTiltleName(selected);
      fields['counter_account_type'] = 'Other';
      fields['counter_account_title'] = '';
      fields['counter_account_number'] = '';
      fields['counter_bank'] = '';
      fields['counter_branch'] = '';
      fields['current_holding'] = '';
      fields['folio_no'] = '';
      setFields(fields);
    } else {
      // amcdata.map((item, index) => {
        amcdataCounter.map((item, index) => {
        if (item.account_no!=undefined && item.account_no.toString() === selected.toString()) {
          setAccTiltleName(item.account_no + 'changes');
          getCounterBranchByName(item.bank);
          fields['counter_account_type'] = item.account_no;
          fields['counter_account_title'] = item.account_title;
          fields['counter_account_number'] = item.account_no;
          fields['counter_bank'] = item.bank;
          fields['counter_branch'] = item.branch;
          setFields(fields);
        }
      });
    }
  };

  //clear fund account fileds if fund or amc is changed beacause these properties are depend on Funds
  const clearCounterAccountFields = () => {
    setAccTiltleName(fields['current_holding'] + 'change');
    fields['counter_account_type'] = '';
    fields['counter_account_title'] = '';
    fields['counter_account_number'] = '';
    fields['counter_bank'] = '';
    fields['counter_branch'] = '';
    fields['current_holding'] = '';
    fields['folio_no'] = '';
    setFields(fields);
  };


const [grossAmountInWords, setGrossAmountInWords]=useState('');
const [netAmountInWords, setNetAmountInWords]=useState('');
const [netAmountSeparator, setNetAmountSeparator]=useState('');
const [grossAmountSeparator, setGrossAmountSeparator]=useState('');
const [unitsSeparator, setUnitsSeparator]=useState('');

const setTotalRemainHolding=(amount, type)=>{
  if(type==="NetAmount"){
    if(amount.length===0){
    setFields({
      ...fields,
      net_amount: amount,
    });
    }else{
    setFields({
      ...fields,
      net_amount: amount,
      total_charges: '0',
    });
    }
  }else if(type==="GrossAmount"){
  if(headLabel==="advisoryfee" || headLabel==="auditorsfee" || headLabel==="managementfee" || headLabel==="trusteefee" || headLabel==="custodyfee"
  || headLabel==="shariahadvisoryfee" || headLabel==="cdcfee" || headLabel==="listingfee" || headLabel==="brokagefee" ||
  headLabel==="printingfee" || headLabel==="professionalfee" || headLabel==="ratingfee" || headLabel==="taxpayment" || headLabel==="secpfee" || headLabel==="otherpayment"){
    let grossamount=amount;
    if(grossamount!=''){
    let sstAmount=((parseFloat(sstPercentage)/100)*(parseFloat(grossamount))).toFixed(2);
    let totalamount=((parseFloat(grossamount)+parseFloat(sstAmount))).toFixed(2);
    let whtITAmount=((parseFloat(whtITPercentage)/100)*parseFloat(totalamount)).toFixed(2);
    let whtsstAmount=((parseFloat(sstWHTPercentage)/100)*parseFloat(sstAmount)).toFixed(2);
    let netAmount=(parseFloat(totalamount)-parseFloat(whtITAmount)-parseFloat(whtsstAmount)).toFixed(2);
    setFields({
    ...fields,
    gross_amount:grossamount,
    net_amount:netAmount.toString(),
    tax_sst:sstAmount.toString(),
    total_charges:totalamount.toString(),
    tax_wht_it:whtITAmount.toString(),
    tax_wht_sst:whtsstAmount.toString()
    });
    let inwordNetAmount = inWords(netAmount);
    netAmount==='NaN' ? setNetAmountInWords('') : setNetAmountInWords(inwordNetAmount);
    let inwordGrossAmount = inWords(amount);
    setGrossAmountInWords(inwordGrossAmount);
    let grossAmountSeparator= numberWithCommas(amount);
    setGrossAmountSeparator(grossAmountSeparator);
    let netAmountSeparator= numberWithCommas(netAmount);
    netAmount=='NaN' ? setNetAmountSeparator('') : setNetAmountSeparator(netAmountSeparator);

    // accounting


    let totaldebit=0, totalcredit=0;
    transactionLedgers.map((item:any)=>{
      if(item.amount_type=='Gross Amount'){
        item.debit_amount=amount;
        item.credit_amount='0';
      }
      if(item.amount_type=='SST/PST Amount'){
        item.debit_amount=sstAmount.toString();
        item.credit_amount='0';
      }
      if(item.amount_type=='WH Income Tax Amount'){
        item.debit_amount='0';
        item.credit_amount=whtITAmount.toString();
      }
      if(item.amount_type=='WH Sale Tax Amount'){
        item.debit_amount='0';
        item.credit_amount=whtsstAmount.toString();
      }
      if(item.amount_type=='Net Amount'){
        item.debit_amount='0';
        item.credit_amount=netAmount.toString();
      }
      if(item.type=='Debit'){
        totaldebit+= +item.debit_amount;
        }
       if(item.type=='Credit'){
        totalcredit+= +item.credit_amount;
       }
    })
    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
    }else{
    setFields({
        ...fields,
        gross_amount:'',
        net_amount:''
    });
    setGrossAmountInWords('');
    setGrossAmountSeparator('');
    setNetAmountInWords('');
    setNetAmountSeparator('');
}
  }else{
  if(amount.length===0){
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: amount,
    // units:'0.0000'
  });
  setNetAmountSeparator('');
  setNetAmountInWords('');
  setGrossAmountSeparator('');
  setGrossAmountInWords('');
  }else{
  setFields({
    ...fields,
    gross_amount:amount,
    net_amount: amount,
    total_charges: '0',
    // units:a
  });
    let inwordNetAmount = inWords(amount);
    setNetAmountInWords(inwordNetAmount);
    let inwordGrossAmount = inWords(amount);
    setGrossAmountInWords(inwordGrossAmount);
    let grossAmountSeparator= numberWithCommas(amount);
    setGrossAmountSeparator(grossAmountSeparator);
    let netAmountSeparator= numberWithCommas(amount);
    setNetAmountSeparator(netAmountSeparator);
  }
  }
  }
};

  const[termDepositEntity, setTermDepositEntity] = useState<any>({
    code:'',
    name:'',
    principal_amount:'',
    interest_rate:'',
    from_date:moment(date).format('YYYY-MM-DD'),
    to_date:moment(date).format('YYYY-MM-DD'),
    bank:'',
    branch:'',
  });

  const [moneyMarketSecurities, setMoneyMarketSecurities] = useState<any>([]);

  const [securites, setSecurites] = useState<any>([]);

  //retrun error border class
  const ErrorBorder = (value) => {
    if ((errorShow && value === '') || (value.substring(0,1)==='-')) {
      return 'required-border';
    } else {
      return '';
    }
  };
  //

  const [viewBankBalanceModal, setViewBankBalanceModal]= useState(false);
  const [underStand, setUnderStand] = useState(false)
  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
             <div className="modal-header ">
              <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setUnderStand(false);
                    setViewBankBalanceModal(false); 
                  }}
              >
                  <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
          </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <div></div> Balance
                            <input type="number"
                            value={bankBalance || '0'}
                            readOnly
                            className="form-control w-100" />
                               
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            Detail*
                            <input
                              type="text"
                              defaultValue={fields['detail']}
                              className={
                                'form-control w-100 '
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  detail: e.target.value,
                                });
                              }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                    <div className="form-inline">
                        <div className="form-group mr-2">
                            <input
                              type="checkbox"
                              // defaultValue={underStand}
                              // className={
                              //   'form-control w-100 '
                              // }
                              onChange={(e) => {
                               setUnderStand(!underStand)
                              }}
                            />
                        </div>
                        <span>I understand</span>
                    </div>
                    </div>
                    </div>
                    {underStand===true ? (
                      <>
                    <div className="row">
                    <div className="col-md-6">
                        <button className="btn btn-primary btn-block"
                        disabled={Boolean(Loading)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: 'Yes',
                          });
                          createTransaction();
                          
                        }}>
                            {Loading ? (
                        <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                      </>
                      ) : (
                        <span>Proceed</span>
                      )}
                        </button>
                    </div>
                    <div className="col-md-6">
                        <button className="btn btn-info btn-block" onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}>
                           <span>Cancel</span>
                        </button>
                    </div>
                       </div>
                       </>
                       ):(
                         ''
                       )}
                {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const [finishFlag, setFinishFlag] = useState(false);

  const getLedgesDetailByLedgerCode = async(ledgerCode)=>{
    try{
      const response = await getLedgerInfoByCode(email,ledgerCode)
      return response.data.data;

    }catch(error:any){

    }
  }

  const [viewModalForLedgers, setViewModalForLedgers] = useState(false);
  // add more ledgers
  const renderModalForAddMoreLedgers = () => {
    switch (viewModalForLedgers) {
      case true:
        return (
            <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => { 
              // setViewMoneyMarketModal(false);
              // setPIBFields(false);
             }}
        > 
        <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setViewModalForLedgers(false);
                clearFields();
            }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Add New Ledger</h6>
          </div>

            <div className="modal-body">
                <div className="  m-3">

                <Row> 
                  <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Ledgers</h4>
                      </div>
                      <div className="card-body">

                        <Row>
                        <Col>
                        <div className="form-group " data-tip="Select Ledger">
                  {ledgeroptions.length > 0 ? (
                    <Autocomplete
                      className="mt-2 border-bottom autocomplete border-gray"
                      disablePortal
                      id="combo-box-demo"
                      options={ledgeroptions}
                      style={{ width: "100%" }}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{ background: "#1E1E2F", color: "white" }}
                        >
                          {children}
                        </Paper>
                      )}
                      value={selectedLedgerCode}
                      onChange={(event: any, newValue: any | null) => {
                        setSelectedLedgerCode(newValue);
                      }}
                      inputValue={selectedInputLedgerCode}
                      onInputChange={(event, newInputValue) => {
                        setSelectedInputLedgerCode(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Ledger*"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <div className="form-control w-100">
                      <i className="fa fa-spinner fa-spin fa-1x"></i>
                    </div>
                  )}
                </div>
                          </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Amount Type
                              <select
                                className="form-control w-100"
                                value={ledgerAmountType}
                                onChange={(e) => {
                                   setLedgerAmountType(e.target.value);
                                   if(e.target.value=='Gross Amount'){
                                     setLedgerAmount(fields['gross_amount'].toString());
                                   }
                                   if(e.target.value=='PS/SST'){
                                    setLedgerAmount(fields['tax_sst'].toString());
                                  }
                                  if(e.target.value=='WH IT'){
                                    setLedgerAmount(fields['tax_wht_it'].toString());
                                  }
                                  if(e.target.value=='WH ST'){
                                    setLedgerAmount(fields['tax_wht_st']);
                                  }
                                  if(e.target.value=='Net Amount'){
                                    setLedgerAmount(fields['net_amount'].toString());
                                  }
                                  if(e.target.value=='Other'){
                                    setLedgerAmount('');
                                  }
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Amount Type
                            </option>
                              <option value="Gross Amount">Gross Amount</option>
                              <option value="PS/SST">PS/SST</option>
                                <option value="WH IT">WH IT</option>
                                <option value="WH ST">WH ST</option>
                              <option value="Net Amount">Net Amount</option>
                              <option value="Other">Other</option>
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                            <div className="form-group">
                              Type
                              <select
                                className="form-control w-100"
                                value={ledgerType}
                                onChange={(e) => {
                                   setLedgerType(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                              <option value="Debit">Debit</option>
                              <option value="Credit">Credit</option>
                              </select>
                            </div>
                        </Col>
                        </Row>

                        <Row>
                        <Col>
                          <div className="form-group">
                          Amount
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerAmount}
                            onChange={(e) => {
                             setLedgerAmount(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>

                  <Row>
                        <Col>
                          <div className="form-group">
                          Description
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerDescription}
                            onChange={(e) => {
                             setLedgerDescription(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>
                  <Row>
                        <Col>
                          <div className="form-group">
                          Invoice
                          <div className="form-group">
                            <input
                            className={
                              'form-control '
                            }
                           value={ledgerInvoice}
                            onChange={(e) => {
                             setLedgerInvoice(e.target.value);
                            }}
                          />
                            </div>

                      </div>
                    </Col>
                  </Row>

                      </div>
                    </div>
                    </Col>

                    </Row>

                    <div className=" ">

                    <div className=" mt-3">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => {
                          if(selectedInputLedgerCode==''){
                            toast.error('Select Ledger');
                            return;
                          }
                          if(ledgerAmount==''){
                            toast.error('Enter Amount');
                            return;
                          }
                          if(ledgerType==''){
                            toast.error('Select Type');
                            return;
                          }
                          addLedgersInArray();
                          setViewModalForLedgers(false);
                        }}  >Add</button>
                    </div>
                </div>
                </div>
            </div>
        </Modal>
        );
      default:
        return '';
    }
  };

  const addLedgersInArray=async()=>{
    const ledgerInfo = await getLedgesDetailByLedgerCode(selectedInputLedgerCode.split("-")[0].trim());

    // if(ledgerType=='Debit'){
    //   let totaldebit =(+totalDebit + +ledgerAmount).toString()
    //   let totalcredit =(+fields['sale_load'] + +fields['net_amount'] + 0).toString();
    //   setTotalDebit(totaldebit);
    //   setTotalCredit(totalcredit);
    //   setBalance((+totaldebit - +totalcredit).toString())
    // }else{
    //   let totaldebit =(+totalDebit).toString()
    //   let totalcredit =(+fields['sale_load'] + +fields['net_amount'] + +ledgerAmount).toString();
    //   setTotalDebit(totaldebit);
    //   setTotalCredit(totalcredit);
    //   setBalance((+totaldebit - +totalcredit).toString())
    // }
    let newLedger = {
      amc_code:amc_code,
      fund_code:fields['fund_code'],
      account_number:fields['account_no'],
      account_title:fields['account_title'],
      bank_code:fields['bank'],
      branch:fields['branch'],
      child_family_code:ledgerInfo.child_family_code,
      child_family_name:ledgerInfo.child_family_name,
      counter_account_number:fields['counter_account_number'],
      counter_account_title:fields['counter_account_title'],
      counter_bank:fields['bank'],
      counter_branch:fields['branch'],
      credit_amount:ledgerType=='Credit' ? ledgerAmount : '0',
      debit_amount:ledgerType=='Debit' ? ledgerAmount : '0',
      description:ledgerDescription,
      entered_by:'',
      evidence:'',
      family_code:ledgerInfo.family_code,
      family_name:ledgerInfo.family_name,
      gl_code:+(selectedInputLedgerCode.split("-")[0].replace(/\s+/g, "")),
      gl_name:selectedInputLedgerCode.split("-")[1],
      instruction_date:fields['instruction_date'],
      instrument_by:'',
      instrument_date:fields['instrument_date'],
      instrument_no:fields['instrument_no'],
      instrument_type:fields['instrument_type'],
      main_family_code:ledgerInfo.main_family_code,
      main_family_name:ledgerInfo.main_family_name,
      realized:'',
      realized_by:'',
      realized_date:'',
      sub_family_code:ledgerInfo.sub_family_code,
      sub_family_name:ledgerInfo.sub_family_name,
      amount_type:ledgerAmountType,
      type:ledgerType
    }
    setTransactionLedgers([...transactionLedgers, newLedger]);
    let totaldebit=0, totalcredit=0;
    let data =[...transactionLedgers, newLedger]
    data.map((item:any)=>{
      if(item.type=='Debit'){
        totaldebit+= +item.debit_amount;
        }
       if(item.type=='Credit'){
        totalcredit+= +item.credit_amount;
       }
    });
    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());

    clearFields();
  }

  const clearFields=()=>{
    setSelectedLedgerCode('');
    setSelectedInputLedgerCode('');
    setSelectedLedgerName('');
    setLedgerAmountType('');
    setLedgerType('');
    setLedgerAmount('');
    setLedgerDescription('');
    setLedgerInvoice('');
  }

  return (
    <>
     <div className="content">
      <Row>
       <Col md="12">
      <Container fluid>
        <ToastContainer />
        {/* <Header /> */}
        <div className="">
        <div className="input-holder"> 
                  <div className="row">
                    <h4 className=" card-title">
                        <i className="fa fa-angle-left mr-2 ml-3 mt-1 pointer" onClick={() => {
                            history.replace('/admin/create-transaction')
                        }}></i>
                        {CapTxnType(headLabel)} Transaction</h4> 
                    </div>
                <Link to="/admin/bulk-upload" className="btn btn-primary btn-sm mr-3" replace>
                     <i className="fa fa-upload mr-2"></i> Bulk Upload
               </Link> 

              </div>
          <div className=" ">
            
            <Row >
              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Instruction</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      {/* fund dropdown , itx value depend on amc value  */}
                      <div
                        className="form-group "
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div>
                        ) : (
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['fund_code'])
                            }
                            value={fields['fund_code']}
                            disabled={finishFlag}
                            onChange={(e) => {
                              fields['fund_code'] = e.target.value;
                              setFields(fields);
                              
                              clearFundAccountFields();
                              getAccountByFundName(e.target.value);

                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {fundCheck ? 'No Fund Found' : 'Select Fund'}
                            </option>
                            {renderFundsDropdown()}
                          </select>
                        )}
                      </div>
                      <div className="form-group">
                        <div>Instruction Date</div>
                        <input
                          type="date"
                          value={fields['instruction_date']}
                          readOnly
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['instruction_date'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              instruction_date: e.target.value,
                              //realized_date: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        {/* <div>Execution Date</div> */}
                        <div>Settlement Date</div>
                        <input
                          type="date"
                          value={fields['execution_date']}
                          min={disableDates}
                          readOnly={finishFlag}
                          className={'form-control w-100' + ErrorBorder(fields['execution_date'])
                          }
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              execution_date: e.target.value,
                              settlement_date: e.target.value
                            });
                          }}
                        />
                      </div>
                      
                      <div className="form-group">
                        <div>Mode of Payment*</div>
                        <select
                          value={fields['mode_of_payment']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['mode_of_payment'])
                          }
                          disabled={finishFlag}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              mode_of_payment: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Payment
                          </option>
                          {renderModeOfPayments()}
                        </select>
                      </div>

                      <div className="form-group">
                        <div>Instrument Type</div>
                        <select
                          className={'form-control w-100 '}
                          value={fields['instrument_type']}
                          disabled={finishFlag}
                          onChange={(e) => {
                            setFields({
                              ...fields,
                              instrument_type: e.target.value,
                            });
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Type
                          </option>
                          {renderiTypeDataDropdown()}
                        </select>
                      </div>
                      {fields.payment_type === 'Receipt' && (
                        <>
                          <div className="form-group">
                            <div>Instrument No./Reference No</div>
                            <input
                              defaultValue={fields['instrument_no']}
                              type="text"
                              className={'form-control w-100 '}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  instrument_no: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <div>Instrument Date / Deposit Date</div>
                            <input
                              type="date"
                              defaultValue={fields['instrument_date']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['instrument_date'])
                              }
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  instrument_date: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                
                    </div>
                  </div>
                </div>
              </Col>

              <Col>
                <div className="card">
                  <div className="card-header">
                    {/* condition to for label selection */}
                    <h4 className="card-title">Beneficiary Account
                    </h4>
                  </div>
                  <div className="card-body">
                    <div>

                      {/* End of fund selection in the case of Unit Conversion Transaction */}
                      
                        <div className="form-group">
                          <ReactTooltip
                            textColor="black"
                            backgroundColor="white"
                            effect="float"
                          />

                          {finishFlag===false && ( // conversion of unit is selected and click on next button then is title will not show.
                          <div className="text-capitalize">
                            {counterAccounter === 'Fund'
                              ? 'Fund Account'
                              : counterAccounter}
                          </div>
                          )}

                        
                          {counterAccounter === 'Fund' ? (
                            //For fund
                            <div data-tip="First Select Fund Name">
                              <ReactTooltip
                                textColor="white"
                                backgroundColor="black"
                                effect="float"
                              />
                              {accLoading ? (
                                <div className="form-control w-100">
                                   <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                                </div>
                              ) : fields['fund_code'].trim() === '' ? (
                                <div className="form-control    w-100">
                                  First Select Fund
                                </div>
                              ) : (
                                <Select
                                className="react-select info"
                                classNamePrefix={"react-select "+ ErrorBorder(fields['counter_account_type'])}
                                  label={'Select'}
                                  isDisabled={false}
                                  onChange={(e) => {
                                    setFields({
                                      ...fields,
                                      counter_account_type: e.value,
                                    });
                                    setFundDataCounter(e.value);
                                  }}
                                  value={toAccountData.filter(
                                    (option) =>
                                      option.value ===
                                      fields['counter_account_type']
                                  )}
                                  isClearable={false}
                                  isSearchable={true}
                                  name="color"
                                  options={toAccountData}
                                />
                              )}
                            </div>
                          ) : //For Other Counter Amc
                          counterAccounter === 'amc' ? (
                            accFundLoading ? (
                              <div className="form-control w-100">
                                 <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                              <Select
                              className="react-select info"
                              classNamePrefix="react-select"
                                label={'Select'}
                                isDisabled={false}
                                onChange={(e) => {
                                  let taxCollector='';
                                  if(e.value==='Other'){
                                    taxCollector='Other'
                                  }else{
                                 let amcTaxCollector = amcdataCounter.filter((item) =>
                                  item.value ===e.value)
                                  taxCollector=amcTaxCollector[0].tax_collector;
                                  }
                                  fields['counter_account_type'] = e.value;
                                  fields['sales_tax_collector'] = taxCollector;
                                  setFields(fields);
                                  // setFields({
                                  //   ...fields,
                                  //   counter_account_type: e.value,
                                  // });
                                  setAmcAccountData(e.value);
                                }}
                                value={amcdataCounter.filter(
                                  (option) =>
                                    option.value ===
                                    fields['counter_account_type']
                                )}
                                isClearable={false}
                                isSearchable={true}
                                name="color"
                                options={amcdataCounter}
                              />
                            )
                          ) : //For Other Counter Accounts

                          counterAccounter === 'Other' ? (
                            <input
                              type="text"
                              value={fields['counter_account_type']}
                              className="form-control w-100"
                              readOnly
                            />
                          ) : (
                            //For Other Brokers
                            finishFlag===false && ( // check the conversion of unit next button 
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select'}
                              isDisabled={false}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  counter_account_type: e.value,
                                });
                                setBrokerData(e.value);
                              }}
                              value={borkerByTypeData.filter(
                                (option) =>
                                  option.value ===
                                  fields['counter_account_type']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={borkerByTypeData}
                            />
                            )
                          )}
                        </div>
                      
                      <div className="form-group">
                        <div>Account Title</div>
                        <input
                          type="text"
                          value={fields['counter_account_title']}
                          className={'form-control w-100 '+ErrorBorder(fields['counter_account_title'])}
                          readOnly={finishFlag}
                          onChange={(e) => {
                            if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                              {
                                counterType='Other';
                                setCounterType(counterType);
                                setFields({
                                  ...fields,
                                  counter_account_title: e.target.value || '',
                                });
                              }
                            // setFields({
                            //   ...fields,
                            //   counter_account_title: e.target.value,
                            // });
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <div>Account Number</div>
                        <input
                          type="text"
                          value={fields['counter_account_number']}
                          className={'form-control w-100 '}
                          readOnly={finishFlag}
                          onChange={(e) => {
                            if(e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value=='')
                            {
                              counterType='Other';
                              setCounterType(counterType);
                              setFields({
                              ...fields,
                              counter_account_number: e.target.value,
                              });
                            }
                            // setFields({
                            //   ...fields,
                            //   counter_account_number: e.target.value,
                            // });
                          }}
                        />
                      </div>
                      <div className="form-group w-100 d-flex">
                        <div className="w-50 flex mr-2">
                          <div>Bank</div>
                          {/* <div className="form-group">
                          <input className="form-control"
                              value={fields['counter_bank']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_bank: e.target.value,
                                  });
                                }} />
                                </div> */}
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            label={'Select Bank'}
                            // isDisabled={false}
                            isDisabled={finishFlag}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                counter_bank: e.value,
                              });
                              getCounterBranchByName(e.value);
                              termDepositEntity['bank']=e.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            value={bankNameData.filter(
                              (option) =>
                                option.value === fields['counter_bank']
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name="color"
                            options={bankNameData}
                          />
                        </div>

                        {finishFlag===true ? (
                            <div className="w-50 flex">
                            <div>Branch</div>
                            <div className="d-flex">
                                <input
                                  type="text"
                                  value={fields['counter_branch']}
                                  className="form-control w-100 "
                                  readOnly={finishFlag}
                                />
                                </div>
                                </div>
                          ):(
                        <div className="w-50 flex mr-2">
                          <div>Branch</div>
                          <div className="form-group">
                          {/* <input className="form-control"
                              value={fields['counter_branch']}
                              onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }} /> */}
                                
                          {inputType ? (
                            <div className="d-flex">
                              <input
                                type="text"
                                value={fields['counter_branch']}
                                className="form-control w-100 "
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    counter_branch: e.target.value,
                                  });
                                  termDepositEntity['branch']=e.target.value;
                                  setTermDepositEntity(termDepositEntity);
                                }}
                              />
                              <RiArrowGoForwardFill
                                onClick={() => {
                                  setInputType(false);
                                  setFields({
                                    ...fields,
                                    counter_branch: '',
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                              label={'Select Branch'}
                              // isDisabled={false}
                              isDisabled={finishFlag}
                              onChange={(e) => {
                                setFields({
                                  ...fields,
                                  counter_branch: e.branch_name,
                                });
                                onOtherSelection(e.value);
                                 termDepositEntity['branch']=e.branch_name;
                                 setTermDepositEntity(termDepositEntity);
                              }}
                              value={allCounterbranchesData.filter(
                                (option) =>
                                  option.value === fields['counter_branch']
                              )}
                              isClearable={false}
                              isSearchable={true}
                              name="color"
                              options={allCounterbranchesData}
                            />
                          )}
                        </div>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transaction Details</h4>
                  </div>
                  <div className="card-body">
                    <div>

                      {/* type */}
                      {chechFieldDisplay(headLabel, 'type') && (
                          <div className="form-group">
                            <div>Type*</div>
                            {flowLoading ? (
                              <div className="form-control w-100">
                                <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                              </div>
                            ) : (
                              <select
                                className={
                                  'form-control w-100 ' + ErrorBorder(fields['type'])
                                }
                                defaultValue={fields['type']}
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    type: e.target.value,
                                  });
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Type
                                </option>
                                {headLabel === 'inflow'
                                  ? renderinFlowTypeDropdown()
                                  : renderOutFlowTypeDropdown()}
                              </select>
                            )}
                          </div>
                        )}


                      {chechFieldDisplay(headLabel, 'dividend_date') && (
                        <div className="form-group">
                          <div>Dividend Date</div>
                          <input
                            type="date"
                            defaultValue={fields['dividend_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['dividend_date'])
                            }
                            onChange={(e) => {
                              let date = moment(e.target.value).format('YYYY-MM-DD');
                              setFields({
                                ...fields,
                                dividend_date: date,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'dividend_rate') && (
                        <div className="form-group">
                          <div>Dividend Rate</div>
                          <input
                            type="number"
                            value={fields['dividend_rate']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['dividend_rate'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              let grossAmount =
                                parseFloat(value) *
                                parseFloat(fields['current_holding']) *
                                parseFloat(fields['face_value']);
                              setFields({
                                ...fields,
                                dividend_rate: value,
                                gross_amount: grossAmount.toFixed(2),
                                net_amount: grossAmount.toFixed(2),
                              });
                            }}
                          />
                        </div>
                      )}

                      {chechFieldDisplay(headLabel, 'sale_txn_no') && (
                        <div className="form-group">
                          <div>Sale Txn No</div>
                          <input
                            type="number"
                            defaultValue={fields['sale_txn_no']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['sale_txn_no'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                sale_txn_no: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'return_date') && (
                        <div className="form-group">
                          <div>Return Date</div>
                          <input
                            type="date"
                            defaultValue={fields['return_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['return_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                return_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'symbol') && (
                        <div className="form-group">
                          <div>Symbol*</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['symbol'])
                            }
                            defaultValue={fields['symbol']}
                            onChange={(e) => {
                              let vol= stockMarketDataFund.filter((item, index)=>{
                                return (item.code===e.target.value);
                                })
                              setFields({
                                ...fields,
                                volume: vol[0].unit,
                                symbol: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderStockMarketData()}
                          </select>
                        </div>
                      )}

                      {/* {headLabel==='cashdividend' && ( */}
                        {chechFieldDisplay(headLabel, 'volume') && (
                        <div className="form-group">
                            <div>Volume</div>
                            <input
                              type="number"
                              defaultValue={fields['volume']}
                              className={
                                'form-control w-100 '+ErrorBorder(fields['volume'])
                              }
                              onChange={(e) => {
                                let value=decimalValue(e.target.value,2);
                                let grossamount = (parseFloat(value) * (parseFloat(fields['dividend_percentage'])/100) * parseFloat(fields['par_value'] || '0')).toFixed(2);
                                setFields({
                                  ...fields,
                                  volume: value,
                                  gross_amount: grossamount,
                                });
                              }}
                            />
                          </div>
                        )}

                      {chechFieldDisplay(headLabel, 'par_value') && (
                    <div className="form-group">
                        <div>Par Value</div>
                        <input
                          type="number"
                          defaultValue={fields['par_value']}
                          className={
                            'form-control w-100 '+ErrorBorder(fields['par_value'])
                          }
                          onChange={(e) => {
                            let value=decimalValue(e.target.value,2);
                            let grossamount = (parseFloat(fields['volume'] || '0') * (parseFloat(fields['dividend_percentage'])/100) * parseFloat(value)).toFixed(2);
                            setFields({
                              ...fields,
                              par_value: value,
                              gross_amount: grossamount,
                            });
                          }}
                        />
                      </div>
                      )}

                      {chechFieldDisplay(headLabel, 'announcement_date') && (
                         <div className="form-group">
                         <div>Announcement Date</div>
                         <input
                              type="date"
                              defaultValue={fields['announcement_date']}
                              className={
                                'form-control w-100 ' +
                                ErrorBorder(fields['announcement_date'])
                              }
                              onChange={(e) => {
                               let date= moment(e.target.value).format('YYYY-MM-DD')
                                setFields({
                                  ...fields,
                                  announcement_date: date,
                                });
                              }}
                            />
                       </div>
                       )}

                      {chechFieldDisplay(headLabel, 'maturity_type') && (
                        <div className="form-group">
                          <div>Maturity Type*</div>
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['maturity_type'])
                            }
                            defaultValue={fields['maturity_type']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                maturity_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            <option value="Security Maturity">
                              Security Maturity
                            </option>
                            <option value="Coupon Maturity">
                              Coupon Maturity
                            </option>
                          </select>
                        </div>
                      )}

                        {chechFieldDisplay(headLabel, 'primary_dealer') && (
                            <div className="form-group">
                              <div>Primary Dealer</div>
                              <select 
                                className="form-control" 
                                // defaultValue={}
                                onChange={(e) => {
                                  setFields({
                                    ...fields,
                                    primary_dealer: e.target.value,
                                  });
                                }}
                              >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Type
                                  </option>
                                  {renderPrimaryDealerDropdown()}
                                </select> 
                            </div>

                          )}

                      {chechFieldDisplay(headLabel, 'security_type') && (
                        <div className="form-group">
                          <div>Security Type*</div>
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['security_type'])
                            }
                            defaultValue={fields['security_type']}
                            onChange={(e) => {
                              if (
                                headLabel == 'saleofsecurities' ||
                                headLabel === 'moneymarketpurchase'
                              ) {
                                if (e.target.value == 'PIB') {
                                  setPIBFields(true);
                                } else {
                                  setPIBFields(false);
                                }
                              }
                              setFields({
                                ...fields,
                                security_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderSecurityDataDropdown()}
                          </select>
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'issue_date') && (
                        <div className="form-group">
                          <div>Issue Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['issue_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['issue_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                issue_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      
                      {chechFieldDisplay(
                        headLabel,
                        'associated_transaction'
                      ) && (
                        <div className="form-group">
                          <div>Associated Txn No*</div>
                          <input
                            type="text"
                            defaultValue={fields['associated_transaction']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['associated_transaction'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                associated_transaction: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'maturity_date') && (
                        <div className="form-group">
                          <div>Maturity Date</div>
                          <input
                            type="date"
                            defaultValue={fields['maturity_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['maturity_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                maturity_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'coupon_rate') && (
                        <div className="form-group">
                          <div>Coupon Rate*</div>
                          <input
                            type="number"
                            value={fields['coupon_rate']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['coupon_rate'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                coupon_rate: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {/* {PIB_fields && (
                        <div className="form-group">
                          <div>Last Coupon Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['last_coupon_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['last_coupon_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                last_coupon_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {PIB_fields && (
                        <div className="form-group">
                          <div>Next Coupon Date</div>
                          <input
                            type="Date"
                            defaultValue={fields['next_coupon_date']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['next_coupon_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                next_coupon_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'yield') && (
                        <div className="form-group">
                          <div>Yield*</div>
                          <input
                            type="number"
                            value={fields['yield']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['yield'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 4);
                              setFields({
                                ...fields,
                                yield: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {/* {PIB_fields && (
                        <div className="form-group">
                          <div>Tenor</div>
                          <input
                            defaultValue={fields['tenor']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['tenor'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                tenor: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'price') && (
                        <div className="form-group">
                          <div>Price*</div>
                          <input
                            type="number"
                            value={fields['price']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['price'])
                            }
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                price: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'face_value') && (
                        <div className="form-group">
                          <div>Face Value*</div>
                          <input
                            type="number"
                            value={fields['face_value']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['face_value'])
                            }
                            readOnly
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                face_value: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'money_market_face_value') && (
                        <div className="form-group">
                          <div>Money Market Face Value*</div>
                          <input
                            type="number"
                            value={fields['money_market_face_value']}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['money_market_face_value'])
                            }
                            // readOnly
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              setFields({
                                ...fields,
                                money_market_face_value: value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'detail') && (
                        <div className="form-group">
                          <div>Detail*</div>
                          <input
                            type="text"
                            defaultValue={fields['detail']}
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['detail'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                detail: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}


                {(headLabel==='equityinvestment' || headLabel==='advisoryfee' || headLabel==='auditorsfee' || headLabel==='managementfee' || headLabel==='trusteefee' || headLabel==='custodyfee' 
                      || headLabel==='shariahadvisoryfee' || headLabel==='cdcfee' || headLabel==='listingfee' || headLabel==='brokagefee' ||
                      headLabel==='printingfee' || headLabel==='professionalfee' || headLabel==='ratingfee' || headLabel==='taxpayment' || headLabel==='secpfee' || headLabel==='otherpayment') && (
                        <div className="">
                          <div> Sales Tax Collector </div>
                          <select	
                        className={`form-control  w-100 `}	
                        value={fields['sales_tax_collector']}	
                        onChange={(e) => {	
                          setFields({
                            ...fields,
                            sales_tax_collector:e.target.value
                          })
                        }}	
                      >	
                        {/* <option value="" defaultChecked hidden>	
                          Select Revenue Authority
                        </option>	 */}
                        <option value="SRB">SRB - Sindh Revenue Board</option>	
                        {/* <option value="FBR">FBR - Federal Board Of Revenue</option>	 */}
                        <option value="PRA">PRA - Punjab Revenue Authority</option>	                       
                        <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>	
                        <option value="BRA">BRA - Balochistan Revenue Authority</option>	
                        <option value="">None</option>	
                      </select>

                          </div>
                          )}

                        {headLabel==='termdeposit' && (
                          <>
                          <div className="form-group">
                            <div> Type</div>
                            <select className='form-control w100'
                            onChange={(e)=>{
                              termDepositEntity['code']=e.target.value;
                              // termDepositEntity['name']=e.nativeEvent.target[e.target.selectedIndex].text;
                               termDepositEntity['name']=e.nativeEvent.target===null ? '' : e.nativeEvent.target[e.target.selectedIndex].text;
                              setTermDepositEntity(termDepositEntity);
                            }}
                            >
                            <option value='' hidden>Select Type</option>
                            <option value='TDR'>Term Deposit Receipts</option>
                            <option value='CDR'>Call Deposit Receipts</option>
                            <option value='LOP'>Loan on Phone</option>
                            </select>

                          </div>
                          <div className="form-group">
                          <div>Principal Amount</div>
                          <input
                            type="number"
                            // defaultValue={fields['detail']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['principal_amount']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                          <div className="form-group">
                          <div>Interest Rate</div>
                          <input
                            type="number"
                            // defaultValue={fields['detail']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['interest_rate']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <div>From Date</div>
                          <input
                            type="date"
                            defaultValue={termDepositEntity['from_date']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['from_date']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <div>To Date</div>
                          <input
                            type="date"
                            defaultValue={termDepositEntity['to_date']}
                            className={
                              'form-control w-100 '
                            }
                            onChange={(e) => {
                              termDepositEntity['to_date']=e.target.value;
                              setTermDepositEntity(termDepositEntity);
                            }}
                          />
                        </div>
                        </>
                        )}

                      {/* {chechFieldDisplay(headLabel, 'redemption_date') && (
                        <div className="form-group">
                          <div>Redemption Date</div>
                          <input
                            type="date"
                            defaultValue={fields['redemption_date']}
                            max={disableDates}
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['redemption_date'])
                            }
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                redemption_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                    
                      {chechFieldDisplay(headLabel, 'settlement_by') && (
                        <div className="form-group mt-3">
                          <div>Settlement By*</div>
                          <select
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['settlement_by'])
                            }
                            value={fields['settlement_by']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                settlement_by: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            <option value="NCCPL">NCCPL</option>
                            <option value="Direct">Direct</option>
                          </select>
                        </div>
                      )}
                      {/* {chechFieldDisplay(headLabel, 'settlement_date') && (
                        <div className="form-group">
                          <div>Settlement Date</div>
                          <input
                            type="date"
                            className={
                              'form-control w-100 ' +
                              ErrorBorder(fields['settlement_date'])
                            }
                            defaultValue={fields['settlement_date']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                settlement_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {/* {headLabel === 'moneymarketpurchase' && (
                        <div className="form-group">
                          <div>Fund IPS Account</div>
                          <input
                            type="text"
                            defaultValue={fields['fund_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                fund_ips_account: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      {headLabel === 'moneymarketpurchase' && (
                        <div className="form-group">
                          <div>Counter Party IPS Account</div>
                          <input
                            type="text"
                            defaultValue={fields['counter_party_ips_account']}
                            className={'form-control w-100 '}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                counter_party_ips_account: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )} */}
                      {chechFieldDisplay(headLabel, 'period') && (
                        <div className="form-group">
                          <div>Select Period</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['period'])
                            }
                            defaultValue={fields['period']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                period: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select period
                            </option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="Annual">Annual</option>
                            <option value="Occasional">Occasional</option>
                          </select>
                        </div>
                      )}
                      {chechFieldDisplay(headLabel, 'reinvest') && ( 

                        <FormGroup check>	
                        <Label check>	
                          <Input type="checkbox"	
                          checked={reinest}
                          onChange={(e) => {
                            setReinvest(!reinest);
                            setFields({
                              ...fields,
                              reinvest: e.target.value,
                            });
                          }} />	
                          <span className="form-check-sign" />	
                          Reinvest
                        </Label>	
                        </FormGroup>
                        )}

                      {chechFieldDisplay(headLabel, 'tax_type') && (
                        <div className="form-group mt-2">
                          <div>Tax Type*</div>
                          <select
                            className={
                              'form-control w-100 ' + ErrorBorder(fields['tax_type'])
                            }
                            defaultValue={fields['tax_type']}
                            onChange={(e) => {
                              setFields({
                                ...fields,
                                tax_type: e.target.value,
                              });
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Type
                            </option>
                            {renderTaxTypeDropdown()}
                          </select>
                        </div>
                      )}

                        
                    </div>
                  </div>
                </div>
              </Col>

              <Col>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Amount</h4>
                  </div>
                  <div className="card-body">
                    <div>

                    <div className="form-group">
                        <div>Fund Account*</div>
                        {accLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x" ></i> 
                          </div>
                        ) : fields['fund_code'].trim() === '' ? (
                          <div className="form-control    w-100">
                            Kindly First Select Fund
                          </div>
                        ) : (
                          <select className='form-control' value={fields['fund_account']} onChange={(e) => {
                            fields['fund_account']=e.target.value;
                            setFields(fields);
                            setFundAccountFieldsData(e.target.value);
                          }}>
                        <option value="" defaultChecked hidden>
                        {' '}
                        Select Fund Account
                      </option>
                        {renderFundAccounts()}
                      </select>
                        )}
                      </div>

                     {grossAmountLedgers.length>0 && (
                      <div className="form-group">
                        <div>Ledger Type Gross Amount*</div>
                          <select className='form-control' value={selectedLedgerCode} onChange={(e) => {
                            setSelectedLedgerCode(e.target.value);
                            setGrossAmountLedger(e.target.value);
                            setGrossAmountReadOnly(false);
                          }}>
                        <option value="" defaultChecked hidden>
                        {' '}
                        Select Gross Amount Ledger
                      </option>
                      {renderGrossAmountLedgers()}
                      </select>
                      </div>
                    )}

                  {sstAmountLedgers.length>0 && (
                      <div className="form-group">
                        <div>Ledger Type SST/PST*</div>
                          <select className='form-control' value={sstLedger} onChange={(e) => {
                            setSSTLedger(e.target.value);
                            setSSTAmountLedger(e.target.value);
                            setGrossAmountReadOnly(false);
                          }}>
                        <option value="" defaultChecked hidden>
                        {' '}
                        Select SST/PST Ledger
                      </option>
                      {renderSSTAmountLedgers()}
                      </select>
                      </div>
                    )}            
                       
                      <div className="form-group">
                        <div>Gross Amount*  {grossAmountSeparator===''? '' : <span className="float-right text-primary">{grossAmountSeparator}</span>}</div> 
                        <input
                          type="number"
                          value={fields['gross_amount']}
                          className={
                            'form-control w-100 ' +
                            ErrorBorder(fields['gross_amount'])
                          }
                          readOnly={grossAmountReadOnly}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            if(value.split('.')[0].length<=12){
                            setTotalRemainHolding(value,"GrossAmount");            
                            }               
                          }}
                        />
                        <small className="truncate" title={grossAmountInWords}>{grossAmountInWords==='' ? '' : (grossAmountInWords)}</small>
                      </div>

                      {chechFieldDisplay(headLabel, 'tax_sst') && (
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div>SST/PST %</div>
                                    <input type="number" value={sstPercentage} className="form-control w-100"
                                    onChange={(e)=>{
                                      let value = decimalValue(e.target.value, 2);
                                      let points=parseFloat(value || '0')/100;
                                      let calAmount=(points * parseFloat(fields['gross_amount'])).toFixed(2);
                                      calAmount=calAmount==='0.00' ? '0' : calAmount;
                                      let totalcharges=(parseFloat(fields['gross_amount']) + parseFloat(calAmount)).toFixed(2);
                                      setSSTPercentage(value);
                                      let whtsstamount=((parseFloat(sstWHTPercentage)/100)*parseFloat(calAmount)).toFixed(2);
                                      let whtitAmount=((parseFloat(whtITPercentage)/100)*parseFloat(totalcharges)).toFixed(2);
                                      let netamount=(parseFloat(totalcharges)-parseFloat(whtitAmount)-parseFloat(whtsstamount)).toFixed(2);
                                      setFields({
                                        ...fields,
                                        tax_sst:calAmount.toString(),
                                        total_charges:totalcharges.toString(),
                                        tax_wht_it:whtitAmount.toString(),
                                        tax_wht_sst:whtsstamount.toString(),
                                        net_amount:netamount.toString()
                                      });
                                      
                                      let inwordNetAmount = inWords(netamount);
                                      setNetAmountInWords(inwordNetAmount);
                                      let netAmountSeparator= numberWithCommas(netamount);
                                      setNetAmountSeparator(netAmountSeparator);

                                        // accounting
                                        // let totaldebit = (+fields['gross_amount'] + +calAmount).toString();
                                        // setTotalDebit(totaldebit);
                                        // let totalcredit = (+whtitAmount + +whtsstamount + +netamount).toString();
                                        // setTotalCredit(totalcredit);
                                        // setBalance((+totaldebit - +totalcredit).toString());

                                        let totaldebit=0, totalcredit=0;
                                        transactionLedgers.map((item:any)=>{
                                          if(item.amount_type=='SST/PST Amount'){
                                            item.debit_amount=calAmount.toString();
                                            item.credit_amount='0';
                                          }
                                          if(item.amount_type=='WH Income Tax Amount'){
                                            item.debit_amount='0';
                                            item.credit_amount=whtitAmount.toString();
                                          }
                                          if(item.amount_type=='WH Sale Tax Amount'){
                                            item.debit_amount='0';
                                            item.credit_amount=whtsstamount.toString();
                                          }
                                          if(item.amount_type=='Net Amount'){
                                            item.debit_amount='0';
                                            item.credit_amount=netamount.toString();
                                          }
                                          if(item.type=='Debit'){
                                            totaldebit+= +item.debit_amount;
                                            }
                                          if(item.type=='Credit'){
                                            totalcredit+= +item.credit_amount;
                                          }
                                        })
                                        setTotalDebit(totaldebit.toFixed(2).toString());
                                        setTotalCredit(totalcredit.toFixed(2).toString());
                                        setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-8">
                            <div className="form-group">
                             <div>SST/PST Amount</div>
                            <input
                          type="number"
                          value={fields['tax_sst']}
                          className={'form-control w-100' + ErrorBorder(fields['tax_sst'])}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);  
                            let percentage=(parseFloat(value)/parseFloat(fields['gross_amount'])*100).toFixed(2);
                            let totalcharges=(parseFloat(fields['gross_amount']) + parseFloat(value)).toFixed(2);
                            let sstwhtamount=((parseFloat(sstWHTPercentage)/100)*parseFloat(value)).toFixed(2);
                            let whtitAmount=((parseFloat(whtITPercentage)/100)*parseFloat(totalcharges)).toFixed(2);
                            let netamount=(parseFloat(totalcharges)-parseFloat(whtitAmount)-parseFloat(sstwhtamount)).toFixed(2);
                            setSSTPercentage(percentage.toString());
                            setFields({
                              ...fields,
                              tax_sst: value,
                              total_charges:totalcharges.toString(),
                              tax_wht_it:whtitAmount.toString(),
                              tax_wht_sst:sstwhtamount.toString(),
                              net_amount:netamount.toString()
                            });
                            let inwordNetAmount = inWords(netamount);
                            setNetAmountInWords(inwordNetAmount);
                            let netAmountSeparator= numberWithCommas(netamount);
                            setNetAmountSeparator(netAmountSeparator);

                            // accounting
                                      let totaldebit=0, totalcredit=0;
                                        transactionLedgers.map((item:any)=>{
                                          if(item.amount_type=='SST/PST Amount'){
                                            item.debit_amount=value;
                                            item.credit_amount='0';
                                          }
                                          if(item.amount_type=='WH Income Tax Amount'){
                                            item.debit_amount='0';
                                            item.credit_amount=whtitAmount.toString();
                                          }
                                          if(item.amount_type=='WH Sale Tax Amount'){
                                            item.debit_amount='0';
                                            item.credit_amount=sstwhtamount.toString();
                                          }
                                          if(item.amount_type=='Net Amount'){
                                            item.debit_amount='0';
                                            item.credit_amount=netamount.toString();
                                          }
                                          if(item.type=='Debit'){
                                            totaldebit+= +item.debit_amount;
                                            }
                                          if(item.type=='Credit'){
                                            totalcredit+= +item.credit_amount;
                                          }
                                        })
                                        setTotalDebit(totaldebit.toFixed(2).toString());
                                        setTotalCredit(totalcredit.toFixed(2).toString());
                                        setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                          }}
                        />
                        </div>
                            </div>
                            </div>
                      )}

                     {chechFieldDisplay(headLabel, 'total_charges') && (
                        <div className="form-group">
                        <div className="d-flex">
                        <div>Total Amount</div>
                        </div>
                        <input
                          type="number"
                          value={fields['total_charges']}
                          className={
                            'form-control w-100' + ErrorBorder(fields['total_charges'])
                          }
                          readOnly
                          onChange={(e) => {
                          let value = decimalValue(e.target.value, 2);

                          }}
                        />
                        </div>
                    )}
                        {chechFieldDisplay(headLabel, 'tax_wht_it') && (
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div>WH IT %</div>
                                    <input type="number" value={whtITPercentage} className="form-control w-100"
                                    onChange={(e)=>{
                                      let value = decimalValue(e.target.value, 2);
                                      // if(value.length===0){
                                      //   setFields({
                                      //     ...fields,
                                      //     tax_wht_it:'0'
                                      //   });
                                      //   setWHTITPercentage('0');
                                      // }else{
                                      let points= parseFloat(value || '0')/100;
                                      let calAmount= (points * parseFloat(fields['total_charges'])).toFixed(2);
                                      calAmount=calAmount==='0.00' ? '0' : calAmount;
                                      setWHTITPercentage(value)
                                      let netamount=(parseFloat(fields['total_charges'])-parseFloat(fields['tax_wht_sst'])-parseFloat(calAmount)).toFixed(2);
                                      netamount=netamount==='0.00' ? '0' : netamount;
                                    setFields({
                                      ...fields,
                                      tax_wht_it: calAmount.toString(),
                                      net_amount:netamount.toString()
                                    });
                                    let inwordNetAmount = inWords(netamount);
                                    setNetAmountInWords(inwordNetAmount);
                                    let netAmountSeparator= numberWithCommas(netamount);
                                    setNetAmountSeparator(netAmountSeparator);

                                    let totaldebit=0, totalcredit=0;
                                    transactionLedgers.map((item:any)=>{
                                      if(item.amount_type=='WH Income Tax Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=calAmount.toString();
                                      }
                                      if(item.amount_type=='Net Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=netamount.toString();
                                      }
                                      if(item.type=='Debit'){
                                        totaldebit+= +item.debit_amount;
                                        }
                                      if(item.type=='Credit'){
                                        totalcredit+= +item.credit_amount;
                                      }
                                    })
                                    setTotalDebit(totaldebit.toFixed(2).toString());
                                    setTotalCredit(totalcredit.toFixed(2).toString());
                                    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
      
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-8">
                            <div className="form-group">
                            <div>WH Income Tax Amount</div>
                            <input
                          type="number"
                          value={fields['tax_wht_it']}
                          className={
                            'form-control w-100' + ErrorBorder(fields['tax_wht_it'])
                          }
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            let percentage=(parseFloat(value)/parseFloat(fields['total_charges'])*100).toFixed(1);
                            // let totalcharges=parseFloat(fields['gross_amount']) + parseFloat(value);
                            let netamount=(parseFloat(fields['total_charges'])-parseFloat(fields['tax_wht_sst'])-parseFloat(value)).toFixed(2);
                            setWHTITPercentage(percentage.toString());
                            setFields({
                              ...fields,
                              tax_wht_it: value,
                             net_amount:netamount.toString()
                            });
                            let inwordNetAmount = inWords(netamount);
                            setNetAmountInWords(inwordNetAmount);
                            let netAmountSeparator= numberWithCommas(netamount);
                            setNetAmountSeparator(netAmountSeparator);

                            let totaldebit=0, totalcredit=0;
                                    transactionLedgers.map((item:any)=>{
                                      if(item.amount_type=='WH Income Tax Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=value;
                                      }
                                      if(item.amount_type=='Net Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=netamount.toString();
                                      }
                                      if(item.type=='Debit'){
                                        totaldebit+= +item.debit_amount;
                                        }
                                      if(item.type=='Credit'){
                                        totalcredit+= +item.credit_amount;
                                      }
                                    })
                                    setTotalDebit(totaldebit.toFixed(2).toString());
                                    setTotalCredit(totalcredit.toFixed(2).toString());
                                    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                          }}
                        />
                        </div>
                            </div>
                            </div>
                        )}

                        {chechFieldDisplay(headLabel, 'tax_wht_sst') && (
                            <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div>WH ST %</div>
                                    <input type="number" value={sstWHTPercentage} className="form-control w-100"
                                     onChange={(e)=>{
                                      let value = decimalValue(e.target.value, 2);
                                      // if(value.length===0){
                                      //   setFields({
                                      //     ...fields,
                                      //     tax_wht_sst:'0'
                                      //   });
                                      //   setSSTWHTPercentage('0');
                                      // }else{
                                      let points= parseFloat(value || '0')/100;
                                      let calAmount= (points * parseFloat(fields['tax_sst'])).toFixed(2);
                                      calAmount=calAmount==='0.00' ? '0' : calAmount;
                                      setSSTWHTPercentage(value)
                                      let netamount=(parseFloat(fields['total_charges'])-parseFloat(fields['tax_wht_it'])-parseFloat(calAmount)).toFixed(2);
                                      netamount=netamount==='0.00' ? '0' : netamount;
                                    setFields({
                                      ...fields,
                                      tax_wht_sst: calAmount.toString(),
                                      net_amount:netamount
                                    });
                                    let inwordNetAmount = inWords(netamount);
                                    setNetAmountInWords(inwordNetAmount);
                                    let netAmountSeparator= numberWithCommas(netamount);
                                    setNetAmountSeparator(netAmountSeparator);
                                   
                                    // let totalcredit = (+fields['tax_wht_it'] + +calAmount + +netamount).toString();
                                    // setTotalCredit(totalcredit);
                                    // setBalance((+totalDebit - +totalcredit).toString());
                                     
                                    let totaldebit=0, totalcredit=0;
                                    transactionLedgers.map((item:any)=>{
                                      if(item.amount_type=='WH Sale Tax Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=calAmount.toString();
                                      }
                                      if(item.amount_type=='Net Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=netamount.toString();
                                      }
                                      if(item.type=='Debit'){
                                        totaldebit+= +item.debit_amount;
                                        }
                                      if(item.type=='Credit'){
                                        totalcredit+= +item.credit_amount;
                                      }
                                    })
                                    setTotalDebit(totaldebit.toFixed(2).toString());
                                    setTotalCredit(totalcredit.toFixed(2).toString());
                                    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                                    }}  />
                                </div>
                            </div>
                            <div className="col-md-8">
                            <div className="form-group">
                                    <div>WH Sale Tax Amount</div>
                            <input
                          type="number"
                          value={fields['tax_wht_sst']}
                          className={
                            'form-control w-100' + ErrorBorder(fields['tax_wht_sst'])
                          }
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            let percentage=(parseFloat(value)/parseFloat(fields['tax_sst'])*100).toFixed(1);
                            
                            setSSTWHTPercentage(percentage.toString());
                            let netamount=(parseFloat(fields['total_charges'])-parseFloat(fields['tax_wht_it'])-parseFloat(value)).toFixed(2);
                          setFields({
                            ...fields,
                            tax_wht_sst: value,
                            net_amount:netamount.toString()
                          });
                          let inwordNetAmount = inWords(netamount);
                          setNetAmountInWords(inwordNetAmount);
                          let netAmountSeparator= numberWithCommas(netamount);
                          setNetAmountSeparator(netAmountSeparator);

                                let totaldebit=0, totalcredit=0;
                                    transactionLedgers.map((item:any)=>{
                                      if(item.amount_type=='WH Sale Tax Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=value;
                                      }
                                      if(item.amount_type=='Net Amount'){
                                        item.debit_amount='0';
                                        item.credit_amount=netamount.toString();
                                      }
                                      if(item.type=='Debit'){
                                        totaldebit+= +item.debit_amount;
                                        }
                                      if(item.type=='Credit'){
                                        totalcredit+= +item.credit_amount;
                                      }
                                    })
                                    setTotalDebit(totaldebit.toFixed(2).toString());
                                    setTotalCredit(totalcredit.toFixed(2).toString());
                                    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                          }}
                        />
                        </div>
                            </div>
                            </div>
                        )}                    


                      <div className="form-group">
                        {/* <div className="d-flex"> */}
                        <div>Net Amount* {netAmountSeparator===''? '' : <span className="float-right text-primary">{netAmountSeparator}</span>}</div>
 
                        <input
                          type="number"
                          value={fields['net_amount']}
                          className={
                            'form-control w-100 ' + ErrorBorder(fields['net_amount'])
                          }
                          readOnly={netAmountReadOnly}
                          onChange={(e) => {
                            let value = decimalValue(e.target.value, 2);
                            if(value.split('.')[0].length<=12){
                            // setTotalRemainHolding(value,"NetAmount");
                            if(value!==''){
                                setFields({
                                ...fields,
                                net_amount:value
                                })
                              let a = inWords(value);
                              setNetAmountInWords(a);
  
                               //// separator
                               let netAmountSeparator= numberWithCommas(value);
                               setNetAmountSeparator(netAmountSeparator);

                            let totaldebit=0, totalcredit=0;
                            transactionLedgers.map((item:any)=>{
                              if(item.amount_type=='Net Amount'){
                                item.debit_amount='0';
                                item.credit_amount=value;
                              }
                              if(item.type=='Debit'){
                                totaldebit+= +item.debit_amount;
                              }
                              if(item.type=='Credit'){
                                totalcredit+= +item.credit_amount;
                              }
                            })
                            setTotalDebit(totaldebit.toFixed(2).toString());
                            setTotalCredit(totalcredit.toFixed(2).toString());
                            setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }else{
                                setNetAmountInWords('');
                                setNetAmountSeparator('');
                              }
                            }
                          }}
                        />
                      <small className="truncate" title={netAmountInWords}>{netAmountInWords==='' ? '' : (netAmountInWords)}</small>
                      </div>

                      <div className="form-group">
                          <div> Payment Type </div>
                          <div  className="form-control" >
                            {fields['payment_type']}
                          </div>
                        </div>

                    </div>
                  </div>
                </div>

              </Col>
   
            </Row>

            <Row>
              <Col>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title text-primary">Ledgers
                  <button className="btn-round btn-icon btn btn-success float-right" onClick={() => {
                              setViewModalForLedgers(!viewModalForLedgers);
                          }}> <i className="fa fa-plus"></i> </button>
                  </h4>
                </div>
                <div className="card-body">
                  {transactionLedgers.length>0 && (
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Ledger</th>
                          <th className="text-right">Debit Amount</th>
                          <th className="text-right">Credit Amount</th>
                          <th>Type</th>
                          <th>Ledger Family</th>
                          <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                        {transactionLedgers.map((item:any, index:number)=>{
                          return(
                            <tr>
                            <td>
                              <label>{item.gl_name} <br/>
                              {item.gl_code=='' ? '' : <small className='text-muted'>({item.gl_code})</small>}
                              </label></td>
                            <td className="text-right">{numberWithCommas(item.debit_amount)}</td>
                            <td className="text-right">{numberWithCommas(item.credit_amount)}</td>
                            <td>{item.type}</td>
                            <td>{item.main_family_name}</td>
                            <td>
                            <IoIosClose
                              style={{ fontSize: '25px', color: 'red' }}
                              onClick={() => {
                                let array = [...transactionLedgers];
                                array.splice(index, 1);
                                setTransactionLedgers(array);
                                let totaldebit=0, totalcredit=0;
                                array.map((items, index) => {
                                  if(items.type=='Debit'){
                                    totaldebit+= +items.debit_amount;
                                    }
                                  if(items.type=='Credit'){
                                    totalcredit+= +items.credit_amount;
                                  }
                                });
                                setTotalDebit(totaldebit.toFixed(2).toString());
                                setTotalCredit(totalcredit.toFixed(2).toString());
                                setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
                              }}
                            />
                            </td>
                          </tr>
                          )
                        })}

                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Total Debit: <span className='text-primary'>{numberWithCommas(totalDebit)}</span></td>
                          <td>Total Credit: <span className='text-primary'>{numberWithCommas(totalCredit)}</span></td>
                          <td>Balance: <span className='text-primary'>{numberWithCommas(balance)}</span></td>
                        </tr>
                      </tfoot>

                    </table>
                  </div>
                  )}
                </div>
              </div>
              </Col>
            </Row>


            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    createTransaction();
                  }}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>{tx === 'cashdividend' ? 'Update' : 'Create'}</span>
                  )}
                </button>
              </div>
            </div>

          </div>
          {renderModalForBankBalance()}
          {renderModalForAddMoreLedgers()}
        </div>
        <div style={{ display: 'none' }}>{accTilteName}</div>
      </Container>
      </Col>
      </Row>
      </div>
    </>
  );
};

export default FeePaymentTxns;