import DCClogo from "../../assets/img/DCC-Logo.svg";
import MACSLogo from "../../assets/img/MACS-Logo.svg";
import VerticesLogo from "../../assets/vertices.svg";

const ReportHeader = (props: any) => {
  return (
    <>
      <div className=" d-flex justify-content-between align-items-center mb-3 ">
        <img src={VerticesLogo} alt="Vertices-Logo" className="" height="80" />
        <h3 className="text-black mt-2">{props.title}</h3>
        <img src={DCClogo} alt="DCC-Logo" className="" height="80" />
      </div>
    </>
  );
};

export default ReportHeader;
