import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
  getAllSecurities,
  uploadPsxFile,
  getAllPsx,
  searchSecurityAPI,
  updateEquitySecurityStatus
} from '../../../stores/services/security.service';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { ImCheckmark } from 'react-icons/im';
import { Modal } from 'react-bootstrap';
import { RiFilter2Fill } from 'react-icons/ri';
import * as fs from 'fs';
const createCsvWriter = require('csv-writer').createObjectCsvWriter;

const SecurityList = () => {
  const history = useHistory();
  const filepath = new Date().toISOString().split('T')[0] + '.csv';
  const email = sessionStorage.getItem('email') || '';
  const [data, setData] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  let [activePage, setActivePage] = useState('');
  const [isSearchActive, setSearchActive] = useState(false);
  let [paginationArr, setPaginationArr] = useState(['']);
  const [pageSize, setPageSize] = useState('10');

  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentBranchStatus, setCurrentBranchStatus] = useState('');
  const [symbol, setSymbol] = useState('');
  const [branchLoading, setbranchLoading] = useState(false);
  const updateStatus = async () => {
    setbranchLoading(true);
    let status = '';
    if (currentBranchStatus === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      const response = await updateEquitySecurityStatus(email, symbol, status);
      if(response.data.status==200){
      toast.success(response.data.message);
      fetchAllPsxFiles();
      }else{
        toast.error(response.data.message);
      }
      setStatusSetPopup(false);
    } catch (error) {}
    setbranchLoading(false);
  };

  React.useEffect(() => {
    sessionStorage.removeItem('securityObj');
    const getSecurities = async () => {
      setLoading(true);
      try {
        const response = await getAllSecurities(email, activePage, pageSize);
        if(response.data.data.length>0){
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10 
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      }else{
        setData([]);
        toast.error(response.data.message);
      }
      } catch (err:any) {
        // toast.error(error.response.data.message);
        if(err.response !== undefined ) {
          toast.error(err.response.data.message);	
        }else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getSecurities();
  }, [activePage]);

  const csvWriter = createCsvWriter({
    path: './app/containers/setup/security/' + 'data.csv',
    header: [
      { id: 'date', title: 'Date' },
      { id: 'symbol', title: 'Symbol' },
      { id: 'sector_code', title: 'Sector Code' },
      { id: 'company_name', title: 'Comp Name' },
      { id: 'open_rate', title: 'Open Rate' },
      { id: 'high_rate', title: 'High Rate' },
      { id: 'low_rate', title: 'Low Rate' },
      { id: 'close_rate', title: 'Close Rate' },
      { id: 'volume', title: 'Volume' },
      { id: 'ldcp', title: 'LDCP' },
    ],
  });

  const [psxList, setPsxList] = useState<any>([]);
  const fetchAllPsxFiles = async () => {
    setLoading(true);
    try {
      const response = await getAllPsx(email);
      setPsxList(response.data.data);
    } catch (err:any) {
      // toast.error(error.response.data.message);
      if(err.response !== undefined ) {
        toast.error(err.response.data.message);	
      }else {
        toast.error("Request Failed!")
      }
    }
    setLoading(false);
  };

  const downLoadCsv = async (key: string, dataValues: any) => {
    //seting header for security files
    const psxHeader = {
      date: 'date',
      symbol: 'symbol',
      sector_code: 'sector_code',
      company_name: 'company_name',
      open_rate: 'open_rate',
      high_rate: 'high_rate',
      low_rate: 'low_rate',
      close_rate: 'close_rate',
      volume: 'volume',
      ldcp: 'ldcp',
    };
    var makePsxComplete = [...[psxHeader], ...dataValues]; // combine header and data values

    await csvWriter.writeRecords(makePsxComplete); //make Csv file inside over dir
    //here we are reading data from csv and making itx URl and add this url to herf to download csv
    await fs.readFile(
      `./app/containers/setup/security/data.csv`,
      (err, data) => {
        var blob = new Blob(['\ufeff', data]); // make blob for csv data
        var a = window.document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = key; // downloaded file name
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a); //remove a tag
      }
    );
    fs.unlinkSync(`./app/containers/setup/security/data.csv`);
  };
  //here we are rendering psx files list in equity tab
  const renderPsxData = () => {
    return psxList.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.company_name}</td>
          <td>{items.symbol}</td>
          <td>{items.sector_code}</td>
          <td>{items.open_rate}</td>
          <td>{items.high_rate}</td>
          <td>{items.low_rate}</td>
          <td>{items.close_rate}</td>
          <td>{items.volume}</td>
          <td>{items.ldcp}</td>
          <td>	
            <div className="multi-icons pointer">	 
            <div	
              className="mr-3"	
              onClick={() => {	
                setStatusSetPopup(true);	
                setCurrentBranchStatus(items.status);	
                setSymbol(items.symbol);	
              }}	
            >	
            {items.status !== 'active' ? (	
              <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm">
                 <i className="tim-icons icon-simple-remove"> </i>
              </button>
              ) : (	
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm">
                    <i className="fa fa-check"></i>
                </button>
              )}

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {	
                  sessionStorage.setItem('securityEquityObj', JSON.stringify(items));	
                  history.replace('/admin/view-equity-security');	
                }}>
                    <i className="fa fa-eye"></i>
               </button>

               <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {	
                  sessionStorage.setItem('securityEquityObj', JSON.stringify(items));	
                  history.replace('/admin/edit-equity-security');	
                }}>
                    <i className="fa fa-edit"></i>
               </button>	
            </div>	
            </div>
          </td>
        </tr>
      );
    });
  };


  const renderUpdateEquitySecurityStatusPopup = () => {	
    switch (statusSetPopup) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal60w"	
            show={true}	
            // size="xl"
            backdrop={true}
            onHide={() => {
              setStatusSetPopup(false);
            }}
          >	
          <div className="modal-header justify-content-center">	
              <button aria-hidden="true" className="close" onClick={() => {	
                          setStatusSetPopup(false);	
                        }} data-dismiss="modal" type="button">	
                <i className="tim-icons icon-simple-remove"></i>	
                </button>	
                <h6 className="title title-up">Action</h6>	
          </div>	
            <div className="modal-body">	
              <div className="text-center">	
                <Row>	
                  <div className="mx-auto">	
                    {currentBranchStatus === 'active' ? (	
                      <img src="assets/ban.svg" alt="" width="70" />	
                    ) : (	
                      <img src="assets/check.svg" alt="" width="70" />	
                    )}	
                    <h1 className="pb-3">Are You Sure?</h1>	
                    Are you sure you want to{' '}	
                    {currentBranchStatus === 'active'	
                      ? 'Deactivate'	
                      : 'Activate'}{' '}	
                    this Branch?	
                  </div>	
                </Row>	
                <Row>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-primary"	
                        disabled={Boolean(branchLoading)}	
                        onClick={() => {	
                          updateStatus();	
                        }}	
                      >	
                        {branchLoading ? (	
                          <>	
                            <span	
                              className="spinner-border login-txt spinner-border-sm"	
                              role="status"	
                              aria-hidden="true"	
                            ></span>	
                            <span className="login-txt"> Loading...</span>	
                          </>	
                        ) : (	
                          'Yes'	
                        )}	
                      </button>	
                    </div>	
                  </Col>	
                  <Col>	
                    <div className="">	
                      <button	
                        className="btn btn-default"	
                        onClick={() => {	
                          setStatusSetPopup(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };

  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      td = data[i].name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }

  const searchSecurityData = async () => {
    setLoading(true);
    try {
      const response = await searchSecurityAPI(email, searchValue);
      setData(response.data.data)
      setSearchActive(true)
      setPageSize('100')
    } catch (error:any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  }

  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      fetchAllPsxFiles()
      setSearchActive(false)
      setPageSize('10')
    } else {
      setSearchValue(e.target.value);
    }
  }
  const renderData = () => {	
    return res.map((items: any, index: any) => {	
      return (	
        <tr key={index}>	
        <td>{items.security_type}</td>
          <td>{items.code.replace('SECURITY_','')}</td>	
          <td>{items.name}</td>		
          <td>{items.issue_date}</td>		
          <td>{items.maturity_date}</td>		
          <td>{items.face_value}</td>		
          <td>{items.price}</td>		
          <td>	
            {/* <ReactTooltip	
              textColor="white"	
              backgroundColor="black"	
              effect="float"	
            />	 */}
            <div className="multi-icons pointer">	 

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {	
                  sessionStorage.setItem('securityObj', JSON.stringify(items));	
                  history.replace('/admin/view-security');	
                }}>
                    <i className="fa fa-eye"></i>
               </button>

               <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm" onClick={() => {	
                  sessionStorage.setItem('securityObj', JSON.stringify(items));	
                  history.replace('/admin/edit-security');	
                }}>
                    <i className="fa fa-edit"></i>
               </button>

              {/* <RiFilter2Fill	 className="mt-2 ml-1"
                data-tip="Filter it Uses"	
                onClick={() => {	
                  history.replace('/admin/security-transactions');	
                  sessionStorage.setItem('securityName', items.name);	
                }}	
              />	 */}
            </div>	
          </td>	
        </tr>	
      );	
    });	
  };	
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const myRef1 = useRef<HTMLInputElement>(null);
  const uplaodFilePsx = async (fileDate: any) => {
    setFileUploadingFlag(true);
    await uploadPsxFile(sessionStorage.getItem('email') || '', fileDate)
      .then((response) => {
        if(response.data.status===200){
        toast.success(response.data.message);
        fetchAllPsxFiles();
        setFileName('');
        }else{
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
      });
    setFileUploadingFlag(false);
  };

  const [fileUploadingFlag, setFileUploadingFlag] = useState<boolean>(false);	
  const upload = (e: any) => {	
    setFileError('');	
    let file = e?.target.files[0];	
    let type = file.name.substring(file.name.lastIndexOf('.') + 1);	
    if (type === 'lis') {	
      setFile(file);	
      setFileName(file.name);	
      uplaodFilePsx(e?.target.files[0]);	
    } else {	
      toast.error('Please Upload Correct Format of File');	
    }	
  };	
  let [changePass, setChangePAssword] = useState(false);	
  const renderContent = () => {	
    const borderRadiusStyle = { borderRadius: 2 };	
    if (!changePass) {	
      return (	
        <div className="">	
          <div className="">	
            {/* <div className="form-group inline-box">	
              <ReactTooltip	
                textColor="white"	
                backgroundColor="black"	
                effect="float"	
              />	
              <input	
                type="search"	
                id="myInput"	
                data-tip="Name"	
                placeholder="Search Here"	
                className="form-control"	
                value={searchValue}	
                onChange={(e) => {	
                  // setSearchValue(e.target.value);	
                  // myFunction(e);	
                  handleSearchValue(e);	
                }}	
              />	
              <div className="btn btn-primary ml-3 mt-a" onClick={searchSecurityData}>	
              <i className="fa fa-search"></i> Search
              </div>	
            </div>	 */}
            <button	
              className="btn btn-primary btn-sm ml-4 float-right"	
              onClick={() => {	
                history.replace('/admin/setup-security');	
              }}	
            >	
              <i className="fa fa-plus mr-2"></i> Add New	
            </button>	
          </div>	
          <div className="table-responsive">	
            <table className="table ">	
              <thead>	
                <tr>
                  <th>Security Type</th>	
                  <th>Code</th>	
                  <th>Name</th>	
                  <th>Issue Date</th>
                  <th>Maturity Date</th>
                  <th>Face Value</th>
                  <th>Price</th>
                  <th >Action</th>	
                </tr>	
              </thead>	
              <tbody>{renderData()}</tbody>	
            </table>	
            {!isSearchActive &&
            <div	
              className="row pr-3 pointer"	
              style={{	
                float: 'right',	
              }}	
            >	
              {/* PREVIOUS BUTTON */}	
              {activePage !== paginationArr[0] ? (	
                <i	
                  className="fa fa-arrow-left"	
                  aria-hidden="true"	
                  onClick={() => {	
                    let pageArr = paginationArr;	
                    let index = pageArr.findIndex((x) => x === activePage);	
                    if (index !== -1) {	
                      setActivePage(paginationArr[index - 1]);	
                    }	
                  }}	
                ></i>	
              ) : (	
                ''	
              )}	
              {/* NEXT BUTTON */}	
              {activePage !== paginationArr[paginationArr.length - 1] ? (	
                <i	
                  className="fa ml-3 fa-arrow-right"	
                  aria-hidden="true"	
                  onClick={() => {	
                    let pageArr = paginationArr;	
                    let index = pageArr.findIndex((x) => x === activePage);	
                    if (index !== -1) {
                      setActivePage(paginationArr[index + 1]);	
                    }	
                  }}	
                ></i>	
              ) : (	
                ''	
              )}	
            </div>}
          </div>	
        </div>	
      );	
    } else {	
      return (
        <div>
          <Row>
          <div className="input-holder">	
          <button className='btn btn-primary btn-sm ml-3'
          onClick={()=>{
            history.replace('/admin/setup-equity-security');
          }}>
          <i className="fa fa-plus mr-2"></i> Add New	
                </button>
            <div	
              className="multi-input ml-auto"
              onClick={() => myRef1?.current?.click()}	
            >	
              <div className="form-group">	
                <div className="form-control">	
                  {fileName === '' ? 'Upload lis File' : fileName}	
                </div>	
                <input	
                  type="file"	
                  ref={myRef1}	
                  style={{ display: 'none' }}	
                  multiple={false}	
                  onChange={(e) => {	
                    upload(e);	
                  }}	
                />	
                {fileError ? (	
                  <p className="error-labels error-message2">{fileError}</p>	
                ) : (	
                  ''	
                )}	
                {/* <input type="file" id="amc_br" ref="amc_br_Uploader" style={{display: "none"}}/> */}	
                {/* <div className="icon">	
                  <img src="assets/upload.svg" alt="" width="20" />	
                </div> */}	
              </div>	
            </div>	
            {fileUploadingFlag &&  <i className="fa fa-spinner fa-spin fa-1x" ></i> }	
          </div>
          </Row>

          {!Loading ? (
            <div className="table-responsive">
            <table className="table">	
              <thead>	
                <tr>	
                  <th>Company Name</th>	
                  <th>Symbol</th>	
                  <th>Sector Code</th>	
                  <th>Open Rate</th>	
                  <th>High Rate</th>	
                  <th>Low Rate</th>	
                  <th>Close Rate</th>	
                  <th>Volume</th>	
                  <th>LDCP</th>
                  <th>Action</th>
                </tr>	
              </thead>	
              <tbody>{renderPsxData()}</tbody>	
            </table>
            </div>
          ) : (	
            <div className="d-flex">	
              <img src="assets/loader.svg" width="100" />	
            </div>	
          )}	
        </div>	
      );	
    }	
  };	
  return (	
    <>	
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>	
        <ToastContainer limit={1} />	
        <div className="card">	
          <div className="d-flex align-items-center"></div>	
          {!Loading ? (	
            <div className="">	
              <div className="card-header">	
              <h4 className="card-title">Security Listing</h4>	
              </div>	
              <div className="card-body">	
              <div className="w-100 d-flex mt-4">	
                <div	
                  onClick={() => {	
                    setChangePAssword(false);	
                  }}	
                  className={`card border shadow box-report user-header mr-2 w-50`}	
                >	
                  {!changePass && (	
                    <ImCheckmark className="tick-noti tick-img" />	
                  )}	
                	
                  <span className="card-title">Money Market</span>	
                </div>	
                <div	
                  onClick={() => {	
                    setChangePAssword(true);	
                    fetchAllPsxFiles();	
                  }}	
                  className={`card border shadow box-report user-header w-50`}	
                >	
                  {changePass && <ImCheckmark className="tick-noti tick-img" />}	
                  <span className="card-title">Equity</span>	
                </div>	
              </div>	
              {renderContent()}	
              </div>	
              {/* <div className="line"></div> */}	
            </div>	
          ) : (	
            <div className="d-flex justify-content-center my-5">	
               <i className="fa fa-spinner fa-spin fa-3x" ></i> 	
            </div>	
          )}	
        </div>	
      </Container>
      </Col>
        </Row>
       {renderUpdateEquitySecurityStatusPopup()}
      </div>	
    </>	
  );	
};	
export default SecurityList;