import React from 'react';
import { useState } from 'react';
import { Container, Row, Col, CardHeader, CardTitle, CardBody, Card } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';


const ViewBroker = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [bankName, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [brokerType, setBrokerType] = useState('');
  const [revenueAuthority, setRevenueAuthority] = useState('');
  const [iban, setIban] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [acc_title, setAccTitle] = useState('');
  const [code, setCode] = useState('');
  //error getting hooks
  const [companyName, setCompanyName] = useState('');

  const [active, setActive] = useState('');

  React.useEffect(() => {
      const obj= JSON.parse(sessionStorage.getItem('brokerObj') || '');
      setCompanyName(obj.company_name);
      setCode(obj.company_code);
      setAccTitle(obj.account_title);
      setBankName(obj.bank_name);
      setBranchName(obj.branch);
      setBrokerType(obj.company_type);
      setRevenueAuthority(obj.tax_collected);
      setIban(obj.iban);
      setActive(obj.active)
  }, []);


  return (
    <>
    <div className="content">
        <Row>
          <Col md="12">
      <Container fluid>
        <ToastContainer limit={1} />

        <div className="input-holder">
              <h4 className="card-title">
              <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    sessionStorage.removeItem('brokerObj');
                      history.replace('/admin/associated-beneficiaries')
                  }}></i>View - Associated Beneficiary</h4>
              <Link to="/admin/associated-beneficiaries" className="t-3 ml-auto btn btn-primary btn-sm" replace>
               <i className="fa fa-eye mr-2"></i> View All
              </Link>    
        </div> 

        <Row>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Associated Beneficiary
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group">
                    <label>Code:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 `}
                        value={code}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Company Name:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 `}
                        value={companyName}
                      />
                    </div>
                  </div>

                  <div className="form-group">

                    <label>Company Type:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 `}
                        value={brokerType}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Revenue Authority:</label>
                    <div className="">
                      <input
                        className="form-control w-100"
                        value={revenueAuthority}
                      />
                    </div>
                  </div>


                </CardBody>
              </Card>
          </Col>
          <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                  Bank Account Details
                  </CardTitle>
                </CardHeader>
                <CardBody>
                <div className="form-group">
                    <label>Account Title:</label>
                    <div className="">
                      <input
                        type="text"
                        className={`form-control  w-100 `}
                        value={acc_title}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>IBAN / Account No:</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        value={iban}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Bank Name:</label>
                    <div className="">
                      <input
                        className="form-control"
                        value={bankName}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Branch Name:</label>
                        <input type="text" className="form-control"
                         value={branchName}
                         />
                  </div>

                  <div className="form-group">	
                    <label>Active: <span className="color-amber">*</span></label>	
                        <input 
                        className="form-control"
                        value={active}
                       />
                         
                  </div>

                </CardBody>
              </Card>
          </Col>
        </Row> 

        <button
          className="btn btn-default"
          onClick={() => {
            sessionStorage.removeItem('brokerObj');
            history.replace('/admin/associated-beneficiaries');
          }}
        >
          Cancel
        </button> 

 
      </Container>
      </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewBroker;
