import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../../../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getFundByAmc } from "../../../stores/services/funds.service";
import { getChartOfAccounts } from "../../../stores/services/chatofaccounts.service";
import {
  getAllLedgers,
  getLedgerAccounts,
} from "../../../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../../../stores/services/daily-bank-profit-form.service";
import { getLedgerInfoByCode } from "../../../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../../../stores/services/beta-transaction.service";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyBankProfit = (props: any) => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [bank, setBank] = useState("");
  const [bankName, setBankName] = useState<any>("");
  const [profit, setProfit] = useState<any>(0.0);

  const [bankCheck, setBankCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [bankLoading, setBankLoading] = useState<any>(false);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);
  const [disableProfit, setDisableProfit] = useState<any>(false);
  const [profitRateConfirmed, setProfitRateConfirmed] = useState<any>(false);
  const [bankBalanceData, setBankBalanceData] = useState<any>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);
  const [date, setDate] = useState<any>(new Date());
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState<any>("");

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "daily_bank_profit",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: "",
    dr_amount: "",
    period: "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: "",
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  // React.useEffect(() => {
  //   setHeadLabel(props.match.params.type);
  //   fields["txnr_type"] = props.match.params.type;
  // }, [props.match.params.type]);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(email, code);
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const getBankAccounts = async () => {
      try {
        const response = await getChartOfAccounts(email);
        console.log("Charts of accounts => ", response);
        if (response.data.status == 200) {
          let ledgersacc = getLedgerAccounts(
            response.data.data.main_family[0].family[3].sub_family
          );
          setLegderAccounts(ledgersacc);
          let allLedgers = await getAllLedgers(response.data.data.main_family);
          setAllLedgers(allLedgers);
          console.log("Ledgers", allLedgers);
          // await ledgerDropDown(allLedgers);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {}
    };
    getFundByAMcCode(amc_code);
    getBankAccounts();
  }, []);

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return (
          <>
            <option key={index} value={item.symbol_code}>
              {item.fund_name} ({item.symbol_code}) - {"SMA"}
            </option>
          </>
        );
      } else {
        return (
          <option key={index} value={item.symbol_code}>
            {item.fund_name} ({item.symbol_code})
          </option>
        );
      }
    });
  };

  const renderBanksDropdown = () => {
    return (
      ledgerAccounts &&
      ledgerAccounts.map((item: any, index: number) => {
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  const handleAdd = () => {
    if (fund === "") {
      return toast.error("Fund is required");
    }
    // if (bank === "") {
    //   return toast.error("Bank is required");
    // }
    if (amc_code === "") {
      return toast.error("Invalid user. Login Again");
    }

    if (tableData.length === 0) {
      setTableData([
        {
          fund_code: fund,
          amc_code: amc_code,
          gl_code: bank,
          gl_name: bankName,
          profit: profit,
        },
      ]);
    } else {
      setTableData((prevState) => [
        ...prevState,
        {
          fund_code: fund,
          amc_code: amc_code,
          gl_code: bank,
          gl_name: bankName,
          profit: profit,
        },
      ]);
    }

    console.log(fund);
    console.log(amc_code);
    console.log(bank);
    console.log(bankName);
    console.log(tableData);
  };

  const handleGetAccounts = async () => {
    setLoading(true);
    setDisableProfit(false);
    setProfitRateConfirmed(false);

    if (fund === "") {
      setLoading(false);
      return toast.error("Fund is required");
    }
    // if (bank === "") {
    //   return toast.error("Bank is required");
    // }
    if (amc_code === "") {
      setLoading(false);
      return toast.error("Invalid user. Login Again");
    }

    if (!date) {
      setLoading(false);
      return toast.error("Date is required");
    }

    console.log("Bank Accounts", ledgerAccounts);
    // console.log(Array.from(ledgerAccounts.related_ledgers));

    const response = await getDailyBankBalance(fund, date);
    let bankBalance = response.data.data;
    console.log("Bank Balance response => ", response);

    if (ledgerAccounts) {
      ledgerAccounts.map((item, index) => {
        let currentProfit = 0;
        if (
          item.interest_rate === tableData[index]?.profit ||
          !tableData[index]?.profit
        ) {
          currentProfit = item.interest_rate;
        } else {
          currentProfit = tableData[index].profit;
        }

        setTotalBalance(
          totalBalance + parseInt(item.balance) * (parseInt(profit) / 100)
        );
        let calculatedBalance: any = 0;
        if (bankBalance) {
          bankBalance.map((balance, index) => {
            if (parseInt(balance.gl_code) === item.code) {
              calculatedBalance = parseFloat(
                balance.realized_opening_balance
              ).toFixed(2);
            }
          });
        }
        if (index === 0) {
          return setTableData([
            {
              fund_code: fund,
              amc_code: amc_code,
              gl_code: item.code,
              gl_name: item.bank,
              profit: currentProfit,
              branch: item.branch,
              account_title: item.account_title,
              account_number: item.account_no,
              balance: calculatedBalance,
              daily_profit: (
                (calculatedBalance * (currentProfit / 100)) /
                365
              ).toFixed(2),
              after_profit_balance: (
                parseFloat(calculatedBalance) +
                (calculatedBalance * (currentProfit / 100)) / 365
              ).toFixed(2),
              related_ledgers: item.related_ledgers,
            },
          ]);
        } else {
          return setTableData((prevState) => [
            ...prevState,
            {
              fund_code: fund,
              amc_code: amc_code,
              gl_code: item.code,
              gl_name: item.bank,
              profit: currentProfit,
              account_title: item.account_title,
              account_number: item.account_no,
              balance: calculatedBalance,
              daily_profit: (
                (calculatedBalance * (currentProfit / 100)) /
                365
              ).toFixed(2),
              after_profit_balance:
                calculatedBalance +
                ((calculatedBalance * (currentProfit / 100)) / 365).toFixed(2),
              related_ledgers: item.related_ledgers,
            },
          ]);
        }
      });
    }

    // if (tableData.length === 0) {
    //   setTableData([
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // } else {
    //   setTableData((prevState) => [
    //     ...prevState,
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // }
    setLoading(false);
  };

  // const handleCreate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   console.log("Table Data => ", tableData);
  //   setDisableProfit(false);
  //   setLoading(false);
  // };

  const handleConfirmProfitRate = () => {
    setDisableProfit(true);
    setLoading(true);
    tableData.map((data, index) => {
      if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
        setDisableProfit(false);
        setLoading(false);
        toast.error("Profit can only be 1% to 100%");
      }

      if (data.profit === "") {
        setDisableProfit(false);
        setLoading(false);
        toast.error("Profit can't be empty");
      }
    });

    console.log("Table Data => ", tableData);
    setProfitRateConfirmed(true);
    setLoading(false);
  };

  const handleDailyProfit = async () => {
    setLoading(true);
    let email = sessionStorage.getItem("email");
    await new Promise((resolve) => {
      tableData.map(async (data, index) => {
        if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
          setDisableProfit(false);
          setLoading(false);
          toast.error("Profit can only be 1% to 100%");
        }

        if (data.profit === "") {
          setDisableProfit(false);
          setLoading(false);
          toast.error("Profit can't be empty");
        }
        if (data.related_ledgers) {
          data.related_ledgers.map(async (ledger, index) => {
            const response = await getLedgerInfo(email, ledger);
            if (response) {
              if (sendingLedgers.length > 0) {
                if (response.family_code === 10206) {
                  await new Promise((resolve) => {
                    sendingLedgers = [
                      ...sendingLedgers,
                      {
                        account_number: data.account_number,
                        account_title: data.account_title,
                        amc_code: data.amc_code,
                        balance: data.balance,
                        bank_code: data.gl_code,
                        branch: data.branch,
                        child_family_code: response.child_family_code,
                        child_family_name: response.child_family_name,
                        counter_account_number: "",
                        counter_account_title: "",
                        counter_bank: "",
                        counter_branch: "",
                        created_at: "",
                        credit_amount: "0",
                        debit_amount: data.daily_profit,
                        description: "",
                        doc_type: "",
                        entered_by: "",
                        evidence: "",
                        family_code: response.family_code,
                        family_name: response.family_name,
                        fund_code: data.fund_code,
                        gl_code: response.gl_code,
                        gl_name: response.gl_name,
                        instruction_date: "",
                        instrument_by: "",
                        instrument_date: "",
                        instrument_no: "",
                        instrument_type: "",
                        main_family_code: response.main_family_code,
                        main_family_name: response.main_family_name,
                        realized: "true",
                        realized_by: "",
                        realized_date: "",
                        sub_family_code: response.sub_family_code,
                        sub_family_name: response.sub_family_name,
                      },
                    ];

                    setTimeout(() => {
                      resolve("resolved");
                    }, 3000);
                  });
                  console.log("Sending Data =>", sendingLedgers);
                } else if (response.family_code === 50107) {
                  await new Promise((resolve) => {
                    sendingLedgers = [
                      ...sendingLedgers,
                      {
                        account_number: data.account_number,
                        account_title: data.account_title,
                        amc_code: data.amc_code,
                        balance: data.balance,
                        bank_code: data.gl_code,
                        branch: data.branch,
                        child_family_code: response.child_family_code,
                        child_family_name: response.child_family_name,
                        counter_account_number: "",
                        counter_account_title: "",
                        counter_bank: "",
                        counter_branch: "",
                        created_at: "",
                        credit_amount: data.daily_profit,
                        debit_amount: "0",
                        description: "",
                        doc_type: "",
                        entered_by: "",
                        evidence: "",
                        family_code: response.family_code,
                        family_name: response.family_name,
                        fund_code: data.fund_code,
                        gl_code: response.gl_code,
                        gl_name: response.gl_name,
                        instruction_date: "",
                        instrument_by: "",
                        instrument_date: "",
                        instrument_no: "",
                        instrument_type: "",
                        main_family_code: response.main_family_code,
                        main_family_name: response.main_family_name,
                        realized: "true",
                        realized_by: "",
                        realized_date: "",
                        sub_family_code: response.sub_family_code,
                        sub_family_name: response.sub_family_name,
                      },
                    ];

                    setTimeout(() => {
                      resolve("resolved");
                    }, 3000);
                  });
                  console.log("Sending Data =>", sendingLedgers);
                }
              } else {
                if (response.family_code === 10206) {
                  await new Promise((resolve) => {
                    sendingLedgers = [
                      {
                        account_number: data.account_number,
                        account_title: data.account_title,
                        amc_code: data.amc_code,
                        balance: data.balance,
                        bank_code: data.gl_code,
                        branch: data.branch,
                        child_family_code: response.child_family_code,
                        child_family_name: response.child_family_name,
                        counter_account_number: "",
                        counter_account_title: "",
                        counter_bank: "",
                        counter_branch: "",
                        created_at: "",
                        credit_amount: "0",
                        debit_amount: data.daily_profit,
                        description: "",
                        doc_type: "",
                        entered_by: "",
                        evidence: "",
                        family_code: response.family_code,
                        family_name: response.family_name,
                        fund_code: data.fund_code,
                        gl_code: response.gl_code,
                        gl_name: response.gl_name,
                        instruction_date: "",
                        instrument_by: "",
                        instrument_date: "",
                        instrument_no: "",
                        instrument_type: "",
                        main_family_code: response.main_family_code,
                        main_family_name: response.main_family_name,
                        realized: "true",
                        realized_by: "",
                        realized_date: "",
                        sub_family_code: response.sub_family_code,
                        sub_family_name: response.sub_family_name,
                      },
                    ];

                    setTimeout(() => {
                      resolve("resolved");
                    }, 3000);
                  });
                  console.log("Sending Data =>", sendingLedgers);
                } else if (response.family_code === 50107)
                  await new Promise((resolve) => {
                    sendingLedgers = [
                      {
                        account_number: data.account_number,
                        account_title: data.account_title,
                        amc_code: data.amc_code,
                        balance: data.balance,
                        bank_code: data.gl_code,
                        branch: data.branch,
                        child_family_code: response.child_family_code,
                        child_family_name: response.child_family_name,
                        counter_account_number: "",
                        counter_account_title: "",
                        counter_bank: "",
                        counter_branch: "",
                        created_at: "",
                        credit_amount: data.daily_profit,
                        debit_amount: "0",
                        description: "",
                        doc_type: "",
                        entered_by: "",
                        evidence: "",
                        family_code: response.family_code,
                        family_name: response.family_name,
                        fund_code: data.fund_code,
                        gl_code: response.gl_code,
                        gl_name: response.gl_name,
                        instruction_date: "",
                        instrument_by: "",
                        instrument_date: "",
                        instrument_no: "",
                        instrument_type: "",
                        main_family_code: response.main_family_code,
                        main_family_name: response.main_family_name,
                        realized: "true",
                        realized_by: "",
                        realized_date: "",
                        sub_family_code: response.sub_family_code,
                        sub_family_name: response.sub_family_name,
                      },
                    ];

                    setTimeout(() => {
                      resolve("resolved");
                    }, 3000);
                  });
                console.log("Sending Data =>", sendingLedgers);
              }
            }
          });
        }
      });

      setTimeout(() => {
        resolve("resolved");
      }, 15000);
    });

    console.log("Table Data after Profit => ", tableData);
    if (sendingLedgers.length > 0) {
      console.log("Sending Data Final => ", sendingLedgers);
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = JSON.stringify(fund);
      if (fields.ledgers.length > 1) {
        try {
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else {
            console.log(result);
            toast.success(`Data added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              toast.success("");
              history.push("/admin/create-transaction");
            }, 1000);
            // toast.success(response.data.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    setProfitRateConfirmed(true);
    setLoading(false);
  };

  const getLedgerInfo = async (email, ledger_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code);
    return result.data.data;
  };

  return (
    <>
      <ToastContainer limit={1} />

      <div className="mt-4">
        <Row>
          <div className="col-md-1"></div>
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Daily Bank Profit</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-4" data-tip="Select Fund">
                    Fund Name*
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control w-100">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <select
                        className={"form-control w-100 "}
                        value={fund}
                        disabled={false}
                        onChange={(e) => {
                          setLoading(true);
                          setTableData([]);
                          setFund(e.target.value);
                          setLoading(false);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {fundCheck ? "No Fund Found" : "Select Fund"}
                        </option>
                        {renderFundsDropdown()}
                      </select>
                    )}
                  </div>

                  <div className="form-group col-md-4" data-tip="Select Date">
                    Select Date
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {accFundLoading ? (
                      <div className="form-control w-100">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <input
                        className="form-control"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        disabled={!fund}
                      />
                    )}
                  </div>

                  {/* <div className="form-group col-md-4" data-tip="Select Bank">
                    Bank Name*
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {fund && bankLoading ? (
                      <div className="form-control w-100">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <select
                        className={"form-control w-100 "}
                        value={bank}
                        disabled={!fund}
                        onChange={(e) => {
                          let selectedIndex = e.target.selectedIndex;
                          setBankName(e.target[selectedIndex].textContent);
                          setBank(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {!fund
                            ? "Select fund first"
                            : bankCheck
                            ? "No Bank Found"
                            : "Select Bank Account"}
                        </option>

                        {renderBanksDropdown()}
                      </select>
                    )}
                  </div> */}
                </div>

                <Row>
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(Loading) || !fund}
                      onClick={() => handleGetAccounts()}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Get Accounts</span>
                      )}
                    </button>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
          <div className="col-md-1"></div>
        </Row>
      </div>

      {Loading ? (
        <Row>
          <div className="col-md-1"></div>
          <Col>
            <div className="form-control w-100">
              <i className="fa fa-spinner fa-spin fa-1x"></i>
            </div>
          </Col>
          <div className="col-md-1"></div>
        </Row>
      ) : tableData && tableData.length !== 0 ? (
        <Row>
          <div className="col-md-1"></div>
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title text-primary">Profit</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <td colSpan={4}>
                          Fund Code: &nbsp; &nbsp;
                          {tableData[0]?.fund_code}
                        </td>
                        <td colSpan={4}>
                          AMC Code: &nbsp; &nbsp; {tableData[0]?.amc_code}
                        </td>
                      </tr>
                      <tr>
                        <th>Account #</th>
                        <th>Account Title</th>
                        <th>Bank Code</th>
                        <th>Bank Name</th>
                        <th>Balance</th>
                        <th>Profit%</th>
                        <th>Daily Profit</th>
                        <th>Balance After Profit </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.account_number}</td>
                          <td>{data.account_title}</td>
                          <td>{data.gl_code}</td>
                          <td>{data.gl_name}</td>
                          <td>{data.balance}</td>
                          <td>
                            <div>
                              <input
                                type="number"
                                min="1"
                                max="100"
                                value={parseFloat(data.profit)}
                                className="form-control w-75"
                                disabled={disableProfit}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  if (e.target.value.length > 5) {
                                    return;
                                  }

                                  if (parseFloat(e.target.value) > 100.0) {
                                    return;
                                  }
                                  if (parseFloat(e.target.value) < 0.0) {
                                    return;
                                  }
                                  setProfit(parseFloat(e.target.value));
                                  data.profit = parseFloat(e.target.value);
                                  data.daily_profit = (
                                    (parseInt(data.balance) *
                                      (parseFloat(e.target.value) / 100)) /
                                    365
                                  ).toFixed(2);
                                  data.after_profit_balance = (
                                    parseFloat(data.balance) +
                                    (parseInt(data.balance) *
                                      (parseFloat(e.target.value) / 100)) /
                                      365
                                  ).toFixed(2);
                                }}
                              />
                            </div>
                          </td>

                          <td>{data.daily_profit}</td>
                          <td>{data.after_profit_balance}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(Loading)}
                      // onClick={() => handleCreate()}
                      onClick={() => handleDailyProfit()}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Save Daily Profit</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <div className="col-md-1"></div>
        </Row>
      ) : null}
    </>
  );
};

export default DailyBankProfit;
