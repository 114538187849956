
import React, { useEffect, useState } from 'react';
// import Header from './../components/Header';	
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';	
import ReactTooltip from 'react-tooltip';	
import { Modal } from 'react-bootstrap';	
import moment from 'moment';	
import { getAllPendingTransactions, searchTransactions } from './../stores/services/transactions.service';
import { getFundByAmc } from './../stores/services/funds.service';	
import ViewTransaction from './view-beta-txn';	
import { toast } from 'react-toastify';
import { getAllBanks } from './../stores/services/bank.service';	
import { transaction } from 'mobx';
import { BsTable } from 'react-icons/bs';	

const PendingTransactions = () => {	
  const email= sessionStorage.getItem('email') || '';
  const amc_code= sessionStorage.getItem('amc_code') || '';
  let [transactions, setTransactions] = useState<any>([]);	
  let [status, setStatus] = useState('');	
  const [trx_history, setTrxHistory] = useState([]);	
  const [viewChecklist, setViewCheckList] = useState(false);	
  const [trx_checklist, setTrxCheckList] = useState([]);	
  // let [lastPageNum, setLastPageNum] = useState('');	
  let [paginationArr, setPaginationArr] = useState(['']);	
  let [currentPage, setCurrentPage] = useState('');	
  let [activePage, setActivePage] = useState('');	

  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [resLoading, setResLoading] = useState(false);

  const [allFunds , setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState('')

  const [togglePagination, setTogglePagination] = useState(false);

  React.useEffect(() => {	
    async function getTransactions(){
      if(togglePagination!=true){
      setResLoading(true);
    await getAllPendingTransactions(	
      sessionStorage.getItem('email') || '',		
      status,	
      searchValue,	
      '',	
      activePage	
    )	
      .then((response) => {	
        let pageArr = paginationArr;	
        let existingPageIndex = paginationArr.findIndex(	
          (x) => x === response.data.page_info.next_page_number	
        );	
        if (	
          existingPageIndex === -1 &&	
          response.data.data.length === 10 
          // &&	
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
        ) {	
          pageArr.push(response.data.page_info.next_page_number);	
        }	
        setPaginationArr(pageArr);
        setTransactions(response.data.data);
        setResLoading(false);	
      })	
      .catch((err) => {	
        setResLoading(false);
      });	
      setResLoading(false);
    }else{
      if(searchValue!==''){
        searchValue='TXN_'+searchValue;
      }
      setResLoading(true);
      await searchTransactions(	
        sessionStorage.getItem('email') || '',
          amc_code,
          fund,
          status === '-1' ? '' : status,
          searchValue,
          activePage	
      )	
        .then((response) => {	
    
          if(response.data.data.length>0){
            let pageArr = paginationArr
            let existingPageIndex = paginationArr.findIndex(	
              (x) => x === response.data.page_info.next_page_number	
            );
            if (	
              existingPageIndex === -1 &&	
              response.data.data.length === 10 
              // &&	
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
            ) {	
              pageArr.push(response.data.page_info.next_page_number);	
            }	
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          }else{
            setTransactions([]);
          }  
          setResLoading(false);	
  
        })	
        .catch((err) => {	
          setResLoading(false);		
        });	
        setResLoading(false);
    }
  }
    getTransactions();
  }, [activePage]);	


  useEffect(()=>{
  const getFunds=async()=>{
    try{
      allFunds.length=0;
      setAllFunds(allFunds);
        const response = await getFundByAmc(email, amc_code);
        setAllFunds(response.data.data);
    }catch(error){
  
    }
  }

  getFunds();
},[]);
  
  // Fund Type Drop Down
  const renderFundsDropDown = () =>{
    return allFunds.map((item:any , index)=>{
        return (
            <option key={index} value={item.symbol_code}>
                {item.fund_name} - {item.symbol_code}
            </option>
        );
    })
  }


  const searchTransactionsByAMCAndFund  = async(statusVar = '', searchVar = '', amcCodeVar= '', fundCodeVar ='') => {
    setTogglePagination(true);	
    return new Promise(async(resolve, reject) => {
      if(searchVar!==''){
        searchVar='TXN_'+searchVar;
      }
      setResLoading(true);
      setSearchLoading(true);
      await searchTransactions(	
        sessionStorage.getItem('email') || '',
          amc_code,
          fundCodeVar,
          statusVar === '-1' ? '' : statusVar,
          searchVar,
          activePage
      )	
        .then((response) => {	
          if(response.data.data.length>0){
            // let pageArr = paginationArr
          
            paginationArr = paginationArr.filter(function(item) {
              return item == ''
          })
            let pageArr = paginationArr;	
            let existingPageIndex = paginationArr.findIndex(	
              (x) => x === response.data.page_info.next_page_number	
            );	
            if (	
              existingPageIndex === -1 &&	
              response.data.data.length === 10 
              // &&	
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)	
            ) {	
              pageArr.push(response.data.page_info.next_page_number);	
            }	
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          }else{
            setTransactions([]);
          }
          setResLoading(false);	
          setSearchLoading(false);	
  
          resolve('Success');	
  
        })	
        .catch((err) => {	
          setResLoading(false);
          setSearchLoading(false);		
          reject('Error');	
        });	
        setResLoading(false);
        setSearchLoading(false);	
    });	
  };
  
  const clearFilters = async(statusVar = undefined as any,	searchVar = undefined as any,	pageNumber: string = ''	) => {
    return new Promise(async(resolve, reject) => {
      // setLoading(true);
      setResLoading(true);	
      setClearLoading(true);
      await getAllPendingTransactions(	
        sessionStorage.getItem('email') || '',	
        statusVar === undefined ? status : statusVar,	
        searchVar === undefined ? searchValue : searchVar,	
        '',	
        activePage	
      )	
        .then((response) => {	
          let pageArr = paginationArr;	
          let existingPageIndex = paginationArr.findIndex(	
            (x) => x === response.data.page_info.next_page_number	
          );	
          if (	
            existingPageIndex === -1 &&	
            response.data.data.length === 10 &&	
            10 * pageArr.length !==	
              parseInt(response.data.page_info.total_count)	
          ) {	
            pageArr.push(response.data.page_info.next_page_number);	
          }	
          setPaginationArr(pageArr);	
          setTransactions(response.data.data);			
          setResLoading(false);
          setClearLoading(false);
          resolve('Success');
          setTogglePagination(false); // false search pagination.
        })	
        .catch((err) => {
          setResLoading(false);		
          setClearLoading(false);	
          if(err.response !== undefined ) {
            toast.error(err.response.data.message);	
          }else {
            toast.error("Request Failed!")
          }	
          reject('Error');	
        });	
        setResLoading(false);
        setClearLoading(false);
   });	
  };	

  const [bankData, setBankData] = useState<any>([]);	
  React.useEffect(() => {	
    const fetchBank = async () => {	
      try {	
        const bankResponse = await getAllBanks(email);	
        setBankData(bankResponse.data.data);	
      } catch (error) {		
      }	
    };	
    fetchBank();	
  }, []);

  const bankName=(bankcode)=>{
    const bankdata= bankData.filter((item: any, index: string)=>{
      return (item.bank_code===bankcode);
     });
     if(bankdata.length>0)
     return bankdata[0].bank_name;
     else
     return bankcode;
  }

  const  numberWithCommas = (amount:any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // const [Loading, setLoading] = useState(false);	
  const [view, setView] = useState(false);	
  const [data, setData] = useState<any>([]);	
  const titleCase = (value: string) => {	
    let sentence = value.toLowerCase().split('_');	
    for (let i = 0; i < sentence.length; i++) {	
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);	
    }	
    return sentence.join(' ');	
  };	
  const renderData = () => {		
    return data.map((value: any, index: number) => {	
      return (	
        <>	
          {value[0] !== 'txn_history' && value[0] !== 'file' ? (	
            <Col md="6" key={index}>	
              <div className="input-holder left">	
                <p className="label">{titleCase(value[0])}</p>	
                <div className="input-1 hide-overflow">	
                  <p	
                    style={{	
                      fontSize: '12px',	
                    }}	
                  >	
                    {typeof value[1] === 'string'	
                      ? value[0] === 'file'	
                        ? 'N/A'	
                        : value[1]	
                      : value[0] === 'file'	
                      ? 'N/A'	
                      : ''}	
                  </p>	
                </div>	
              </div>	
            </Col>	
          ) : (	
            ''	
          )}	
        </>	
      );	
    });	
  };	

  const [note, setNote] = useState('');

  const viewCheckListPopup = () => {	
    switch (viewChecklist) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="xl"	
          >	
            <div className="modal-header ">	
              <button	
                aria-hidden={true}	
                className="close"	
                data-dismiss="modal"	
                type="button"	
                onClick={() => {	
                  setViewCheckList(false);	
                }}	
              >	
                <i className="tim-icons icon-simple-remove" />	
              </button>	
              <h6 className="title title-up">Approval Check List  </h6>	
            </div>	
            <div className="modal-body">	
              <div> 
              <div className={'checlist-scroll'}>	
                {trx_checklist.map((val: any, ind: any) => {	
                  return (	
                    <Row key={ind} className="pt-3">	
                      	
                      <Col md="4">	
                      <text className="text-primary">{val.serial_no}.</text> <text>{val.message}</text>	
                      </Col>	
                      <Col md="8">	
                        <div className="row"> 	
                            <div className="col-sm-3">	
                             	
                            <FormGroup check>	
                              <Label check>	
                                <Input 	
                                checked={val.value == 'compliant'}	
                                type="checkbox"	
                                value="compliant"	
                                readOnly />	
                                <span className="form-check-sign" />	
                                Compliant	
                              </Label>	
                            </FormGroup>	
                            </div>	
                            <div className="col-sm-3">	
 	
                            <FormGroup check>	
                              <Label check>	
                                <Input type="checkbox"	
                                id="unlisted"	
                                checked={val.value == 'na'}	
                                value="na"	
                                readOnly />	
                                <span className="form-check-sign" />	
                                N /A	
                              </Label>	
                            </FormGroup>	
                            </div>	
                            <div className="col-sm-6">	
                              <div className="form-group">	
                                <input	
                                  type="text"	
                                  placeholder="Comments"	
                                  value={val.comment}	
                                  className="form-control"	
                                  readOnly	
                                />	
                                </div> 	
                            </div>	
                        </div>	
                      </Col>	
                    </Row>	
                  );	
                })}	
                </div>

                {note !== '' && (
                  <Row>
                    <div className="col-md-12">
                    <label>Reason of Reject</label>
                    <div className="form-group">
                      <textarea
                        placeholder="Reason"
                        className={'form-control w-100'}
                        value={note}
                        readOnly
                      ></textarea>
                    </div>
                    </div>
                  </Row>
                )}


                <Row>	
                  <Col>	
                    <div className=" ">	
                      <button	
                        className="btn btn-primary mt-3"	
                        onClick={() => {	
                          setViewCheckList(false);	
                        }}	
                      >	
                        Cancel	
                      </button>	
                    </div>	
                  </Col>	
                </Row>	
              </div>	
            </div>	
            	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  const renderDataOfTransaction = () => {	
    return transactions.map((transaction: any, index: any) => {	
      return (	
        <tr key={index}>	
          <td>{transaction.txn_id}</td>	
          <td>{transaction.amc_name ? transaction.amc_name : 'N/A'}</td>	
          <td>	
            {transaction.name_of_beneficiary	
              ? transaction.name_of_beneficiary	
              : 'N/A'}	
          </td>	
          <td>	
            {transaction.type_of_transaction	
              ? transaction.type_of_transaction	
              : 'N/A'}	
          </td>	
          <td>	
            {moment(transaction.txn_history[0].created_at).format(	
              'DD-MM-YYYY'	
            )}	
          </td>	
          <td className=" ">	
            <div className="multi-icons pointer">	
              <img	
                src="assets/view.svg"	
                alt=""	
                width="16"	
                onClick={() => {	
                  let objArr = Object.entries(transaction);	
                  setData(objArr);	
                  setView(true);	
                }}	
              />	
            </div>	
          </td>	
          <td>{transaction.txn_status}</td>	
        </tr>	
      );	
    });	
  };	
  let [searchValue, setSearchValue] = useState('');	
  const renderPsxData = () => {	
    return securites.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.type}</td>	
          <td>{items.market}</td>	
          <td>{items.symbol}</td>	
          <td>{items.quantity}</td>	
          <td>{items.avg_rate}</td>	
          <td>{items.gross_amount}</td>	
          <td>{items.deductions}</td>	
          <td>{items.additions}</td>	
          <td>{items.net_amount}</td>	
        </tr>	
      );	
    });	
  };	
  const [flagForSecutities, setFlagForSecutities] = useState(false);	
  const [securites, setSecurites] = useState<any>([]);	
  const renderSecuritiesModalPopup = () => {	
    switch (flagForSecutities) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => {	
              setFlagForSecutities(false);	
            }}	
          >	 

            <div className="modal-header ">
                        <button
                          aria-hidden={true}
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => {	
                            setFlagForSecutities(false);	
                            // clearTaxobject();	
                          }}	
                        >
                          <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">View Security </h6>
                      </div>

            <div className="modal-body">	
              <div className="">	
                {securites.length > 0 && (	
                  <div className="">	
                    <table className="table ">	
                      <thead>	
                        <tr>	
                          <th>Type</th>	
                          <th>Market</th>	
                          <th>Symbol</th>	
                          <th>Quantity</th>	
                          <th>Avg Rate</th>	
                          <th>Gross Amount</th>	
                          <th>Deduction</th>	
                          <th>Addition</th>	
                          <th>Net Amount</th>	
                        </tr>	
                      </thead>	
                      <tbody>{renderPsxData()}</tbody>	
                    </table>	
                  </div>	
                )}	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  //view tax table	
  const [viewTaxModal, setTaxModal] = useState(false);	
  const [totalTax, setTotalTax] = useState<any>([]);	
  //render Tax table data	
  const renderTaxData = () => {	
    return totalTax.map((items: any, index: number) => {	
      return (	
        <tr key={index}>	
          <td>{items.name}</td>	
          <td>{items.amount}</td>	
          <td>{items.calculatedAmount}</td>	
        </tr>	
      );	
    });	
  };	
  const renderTaxPopup = () => {	
    switch (viewTaxModal) {	
      case true:	
        return (	
          <Modal	
            className=""	
            dialogClassName="modal90w"	
            show={true}	
            size="lg"	
            backdrop={true}	
            onHide={() => {	
              setTaxModal(false);	
            }}	
          >	 
            <div className="modal-header ">
                        <button
                          aria-hidden={true}
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={() => {	
                            setTaxModal(false);	
                            // clearTaxobject();	
                          }}
                        >
                          <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h6 className="title title-up">View Txn Charges </h6>
                      </div>

            <div className="modal-body">	
              <div className="">	
                {totalTax.length > 0 && (	
                  <div className="">	
                    <table className="table">	
                      <thead>	
                        <tr>	
                          <th>Tax Name</th>	
                          <th>Tax Amount</th>	
                          <th>Calculated Amount</th>	
                        </tr>	
                      </thead>	
                      <tbody>{renderTaxData()}</tbody>	
                    </table>	
                  </div>	
                )}	
              </div>	
            </div>	
          </Modal>	
        );	
      default:	
        return '';	
    }	
  };	
  let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');	
  const CapTxnType = (type: any) => {	
    let i, txType;	
    for (i = 0; i < txnTypes.length; i++) {	
      if (txnTypes[i].api_name === type) {	
        txType = txnTypes[i].dis_name;	
        break;	
      }	
    }	
    return txType;	
  };	
  return (	
    <>	
    <div className="content">
  <Row>
    <Col md="12">
      <Container fluid>	
        {/* <Header />	 */}
        {!view ? (	
          <div className="card ">	
            <div className="card-header">	
              <h4 className="card-title">Transactions</h4>	
            </div>	
            <div className="card-body">	
            <div className="row">
              <div className="col-md-2">
                   <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      effect="float"	
                    /> 	
                    <div className="form-group">
                    <label>Transaction ID</label>
                      <input	
                        type="search"	
                        id="myInput"	
                        placeholder="Transaction Id"	
                        data-tip="Search Transaction"	
                        className="form-control"	
                        value={searchValue}	
                        onChange={(e) => {	
                          setSearchValue(e.target.value);	
                        }}	
                      />  
                      </div>
              </div>

              <div className='col-md-2'>
              <div className="form-group ml-2 mr-2">
                  <label >Select Fund</label>
                      <select
                          className={"form-control"}
                          value={fund}
                          onChange={(e) => {
                              setFund(e.target.value);
                          }}>
                          <option value="" defaultChecked hidden>
                          Select Fund
                          </option>
                          {renderFundsDropDown()}
                      </select>
                  </div>
              </div>

              <div className="col-md-2 ">
                <div className="form-group">
                  <label >Select Status</label>
                    <select	
                        className="form-control ml-2"	
                        onChange={(e) => {	
                          setStatus(e.target.value);	
                        }}	
                        value={status}	
                      >	
                        <option value="-1">Select type</option>	
                        <option value="COMPLIANCE">Compliance</option>	
                        <option value="AUTHORIZER-A">Authorizer A</option>	
                        <option value="AUTHORIZER-B">Authorizer B</option>	
                        <option value="INREVIEW">In Review</option>	
                        <option value="SIGNATORY-A">Signatory A</option>	
                        <option value="SIGNATORY-B">Signatory B</option>	
                        <option value="COMPLETED">Completed</option>	
                        <option value="REJECTED">Rejected</option>	
                      </select>	
                      </div>
              </div>

              <div className="col-md-4">
              <div className=" inline-box">
                <button	
                      className="btn btn-primary mt-4 ml-2"	
                      disabled={Boolean(searchLoading)}
                      onClick={() => {	
                        if ((searchValue || status!=="-1" || fund) !== '') {	
                          searchTransactionsByAMCAndFund(status, searchValue, '', fund).then(() => {	
                            setActivePage('');
                          });	
                        }	
                      }}	
                    >	
                    {searchLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Searching...</span>
                    </>
                    ) : (
                    'Search'
                    )}
                    </button>	

                    <button	
                      className="btn btn-default ml-2 mt-4 ml-2"	
                      disabled={Boolean(clearLoading)}
                      style={{	
                        width: '148px',	
                      }}	
                      onClick={() => {	
                        clearFilters('', '').then(() => {	
                          setStatus('-1');	
                          setSearchValue('');	
                          setActivePage('');	
                          setFund('');
                        });
                      }}	
                    >	
                     {clearLoading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Clear</span>
                    </>
                    ) : (
                    'Clear'
                    )}
                    </button>	
              </div>
              </div>
            </div> 

            <div className="">
            {resLoading ? (
              <div className="d-flex justify-content-center my-5">	
                <i className="fa fa-spinner fa-spin fa-3x" aria-hidden="true"></i> 
              </div>
                ):(
              transactions.length>0 ? (
                <div className="table-responsive">
                <table className="table mt-2">	
              <thead>	
                <tr>	
                  <th>Date</th>
                      <th>Transaction</th>	
                      <th>Fund</th>	
                      <th>Amount</th>
                      <th>Bank</th>	
                      <th>Counter Acc</th>
                      <th>Instrument</th>	
                      <th>Created</th>	
                      <th>Status</th>	
                      <th>Act</th>	
                </tr>	
              </thead>	
              <tbody>	
                {transactions.map((transaction: any, index: number) => {	
                  return (	
                    <tr key={index}>
                      <td>{moment(transaction.instruction_date).format('DD-MM-YYYY')} <br/>
                        <span className="sub-content">Exe: {moment(transaction.execution_date).format('DD-MM-YYYY')}</span>
                        </td>

                          <td>{transaction.txnr_type	
                              ? CapTxnType(transaction.txnr_type)	
                              : 'N/A'}<br/> <span className="sub-content">{transaction.txn_id.replace('TXN_','')}</span></td>	

                          <td>{transaction.fund_code}	<br/>
                          <span className="sub-content">{transaction.amc_code	
                              ? transaction.amc_code	
                              : 'N/A'}	</span>
                          </td>	

                          <td className="text-right pr-3">{numberWithCommas(transaction.net_amount)} <br/>
                          <span className="sub-content">{numberWithCommas(transaction.gross_amount)}</span>
                          </td>

                          <td>{transaction.bank}<br/>
                          <span className="sub-content">{transaction.account_number}</span></td>

                          <td>{transaction.counter_bank} <br/>
                          <span className="sub-content">{transaction.counter_account_number}</span></td>

                            <td>{transaction.mode_of_payment} <br/>
                            <span className="sub-content">{transaction.instrument_type} {transaction.instrument_no!=='' ? transaction.instrument_no +',': ''} {moment(transaction.instrument_date).format('DD-MM-YYYY')}</span></td>

                          <td>{moment(transaction.created_at).format('DD-MM-YYYY')} <br/>	
                          <span className="sub-content">{transaction.created_by.split('@')[0]}</span>	
                          </td>		
                          <td>{transaction.txn_status}</td>	 	
                      <td className=" ">	
                        <div className="multi-icons pointer">	
                          <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                                let objArr = Object.entries(transaction);	
                                let bankname=bankName(transaction.bank.toString())	
                                transaction.bank=bankname;
                                let counterbankname=bankName(transaction.counter_bank.toString())	
                                transaction.counter_bank=counterbankname;
                                setTrxHistory(transaction.txn_history);	 
                                setData(transaction);
                                toast.success(transaction.txn_history)	
                                setView(true);	
                              }}>
                                <i className="fa fa-eye">
                            </i>
                          </button> 
                        </div>	
                      </td>	
                    </tr>	
                  );	
                })}	
              </tbody>	
                </table>
            </div>
              ):(
                <div className="table-empty">	
                <BsTable className="icon" />	
                <p className="text-center empty-text">No record found</p>	
              </div>
              )
              )}
	
          {/* {togglePagination===true ? ( */}
              <div	
                className="row pointer pr-3"	
                style={{	
                  float: 'right',	
                }}	
              >	
                {/* PREVIOUS BUTTON */}	
                {activePage !== paginationArr[0] ? (	
                  <i	
                    className="fa fa-arrow-left"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index - 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
                {/* NEXT BUTTON */}	
                {activePage !== paginationArr[paginationArr.length - 1] ? (	
                  <i	
                    className="fa ml-3 fa-arrow-right"	
                    aria-hidden="true"	
                    onClick={() => {	
                      let pageArr = paginationArr;	
                      let index = pageArr.findIndex((x) => x === activePage);	
                      if (index !== -1) {	
                        setActivePage(paginationArr[index + 1]);	
                      }	
                    }}	
                  ></i>	
                ) : (	
                  ''	
                )}	
              </div>
                {/* ):(
                  ''
                )}	 */}
            </div>
            </div>	
          </div>	
        ) : (	
          <div className="">	
            <h4 className="card-title">	
              <i className="fa fa-angle-left mr-2 pointer" 	
                onClick={() => {	
                  setView(false);	
                }}></i>	
              View Transaction
            </h4>	
            <ViewTransaction data={data} />	
            	
              <div className="line"></div>

            <Row className=" pb-5">	
              {trx_history.map((hist: any, index: number) => {	
                return (	
                  <Col
                  md="3"
                  onClick={() => {
                    if (hist.action === 'REJECTED-AMC') {
                      setNote(hist.note);
                    } else {
                      setNote('');
                    }
                    if (hist.action === 'CREATED') {
                      return;
                    }
                    if (!hist.check_list) return;
                    setTrxCheckList(hist.check_list);
                    setViewCheckList(true);
                  }}
                >
                    <div className="card process-card pointer">	
                      <div className="card-header">	
                        <h4 className="card-title">
                        <i className="tim-icons icon-single-02 mr-1"></i> <span className="card-number">
                          {index+1}
                          </span>
                             {hist.updated_by_role} </h4>	
                      </div>	
                      <div className="card-body text-center">	 
                          
                          <p className="small mt-2">Status:</p>	
                          <h3 className="accepted text-primary">  {hist.action}    </h3>	 	
                          {hist.updated_at !== '' ? (	
                            <>	
                              <p className="small mt-2">by</p>	
                              <h4 className="comment text-primary">{hist.updated_by}</h4>	 
                              <p className="small mt-2">at</p>	
                              <h4 className="comment text-primary">{moment(hist.updated_at).format("DD-MM-YYYY, h:mm a")}</h4>	 
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {hist.note !== '' ? (	
                            <>	
                              <p className="small mt-2">Comment</p>	
                            <small className="comment dot-text" title={hist.note}>{hist.note}</small>		
                            </>	
                          ) : (	
                              ''	
                            )}	
                          {	
                            hist.created_by !== '' ? (	
                              <>	
                                <p className="small mt-2">by</p>	
                                <h4 className="text-primary">{hist.created_by}</h4>	
                                <p className="small mt-2"> at</p>	
                                <h4 className="comment text-primary"> {moment(hist.created_at).format("DD-MM-YYYY, h:mm a")} </h4>	
                              </>	
                            ) : (	
                                ''	
                              )}	
                        </div>		
                    </div>	
                  </Col>	
                );	
              })}	
            </Row>	
          </div>	
        )}	
        {viewCheckListPopup()}	
        {renderSecuritiesModalPopup()}	
        {renderTaxPopup()}	
      </Container>	
      </Col>
     </Row>
      </div>
    </>	
  );	
};	
export default PendingTransactions;	
